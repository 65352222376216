import React, { useEffect, useState } from 'react';
import { database, storage } from '../firebaseConfig';
import { ref as databaseRef, onValue, remove, update } from 'firebase/database';
import { ref as storageRef, deleteObject } from 'firebase/storage';
import './TeamContent.css';

const TeamContent = () => {
    const [videos, setVideos] = useState([]);
    const [inEditId, setInEditId] = useState(null);

    useEffect(() => {
        const videosRef = databaseRef(database, '/VideoWiz');
        onValue(videosRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const videoArray = Object.keys(data).map((key) => ({
                    id: key,
                    ...data[key]
                }));
                
                // Reverse the video array to display in reverse order
                setVideos(videoArray.reverse());
            }
        });
    }, []);

    const handleDelete = (videoId, storagePath) => {
        const fileRef = storageRef(storage, storagePath);

        deleteObject(fileRef)
            .then(() => {
                const videoRef = databaseRef(database, `/VideoWiz/${videoId}`);
                return remove(videoRef);
            })
            .then(() => {
                setVideos((prevVideos) => prevVideos.filter((video) => video.id !== videoId));
            })
            .catch((error) => {
                console.error('Error deleting video:', error);
            });
    };

    const handleSelect = (videoId) => {
        const updatedVideos = videos.map((video) => {
            if (video.id === videoId) {
                return { ...video, isSelected: !video.isSelected };
            }
            return video;
        });
        setVideos(updatedVideos);

        const videoRef = databaseRef(database, `/VideoWiz/${videoId}`);
        update(videoRef, { isSelected: !videos.find(video => video.id === videoId).isSelected });
    };

    const handleEdit = (videoId) => {
        setInEditId((prevId) => (prevId === videoId ? null : videoId));
    };

    return (
        <div className="team-content-grid">
            {videos.map((video) => (
                <div
                    key={video.id}
                    id={`content-video-item-${video.id}`}
                    className={`content-video-item ${
                        video.isSelected ? (inEditId === video.id ? 'edit-mode' : 'selected') : ''
                    }`}
                >
                    <div>
                        <button className="content-top-selected-button-2" onClick={() => handleDelete(video.id, video.storagePath)}>
                            <img src="../../img/delete.svg" alt="Delete" />
                        </button>

                        <div className="content-top-selected-buttons">
                            <button className="content-top-selected-button" onClick={() => handleSelect(video.id)}>
                                {video.isSelected ? 'Selected' : 'Select'}
                            </button>
                            <button className="content-top-selected-button-edit" onClick={() => handleEdit(video.id)}>
                                {inEditId === video.id ? 'In Editing' : 'In Edit'}
                            </button>
                        </div>
                    </div>
                    <video controls width="100%">
                        <source src={video.url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <p><strong>Description:</strong> {video.videoDescription}</p>
                    <p><strong>Transcript:</strong> {video.transcript}</p>
                    <p><strong>File Name:</strong> {video.name}</p>
                    <a href={video.youtubeLink} target="_blank" rel="noopener noreferrer"><strong>Youtube Link:</strong> {video.youtubeLink}</a>
                </div>
            ))}
        </div>
    );
};

export default TeamContent;
