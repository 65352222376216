import React from 'react';
import './HeaderLabel.css';  // Pfad zu Ihrer CSS-Datei für Stilisierungen

function HeaderLabel({ text }) {
    return (
        <h3 className="header-label">{text}</h3>
    );
}

export default HeaderLabel;
