import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PageLiveToggle from '../PageUtility/PageStatusToggle';
import './PrevPage.css';
import { QRCode } from 'react-qrcode-logo';
import ReviewImport from './ReviewImport';

function PrevPage({ page, onDelete }) {
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [showReviewImport, setShowReviewImport] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);

  const handleQRClick = () => {
    setShowQRModal(true);
  };

  const handleCloseModal = () => {
    setShowQRModal(false);
  };

  const handleImportClick = () => {
    setShowReviewImport(true);
  };

  const handleCloseReviewImport = () => {
    setShowReviewImport(false);
  };

  const contentToShow = page.isLive ? (
    <iframe
      src={`/${page.route}`} 
      width="350px"
      height="500px"
      title="Page Preview"
      frameBorder="0"
      allowFullScreen
      className="live-page-container-2"
    ></iframe>
  ) : (
    <div className="page-not-live-notice">
      <img src="../../img/lock.webp" alt="" />
      <p>Publish page to see preview</p>
      <PageLiveToggle pageId={page.id} />
    </div>
  );

  useEffect(() => {
    const timer = setTimeout(() => setShowSkeleton(false), 200);
    return () => clearTimeout(timer);
  }, []);

  function payoutContent(payoutOption, earnAmount) {
    switch (payoutOption) {
      case 'earnMoney':
        return (
          <>
            <img style={{ width: '15px' }} src="../../img/dollar.svg" alt="Dollar" />
            <p className="all-pages-gig-text">{earnAmount}</p>
          </>
        );
      case 'collaborate':
        return <p className="all-pages-gig-text">Free</p>;
      case 'earnRoyalties':
        return (
          <>
            <img src="../../img/prozent.svg" alt="Prozent" />
            <p className="all-pages-gig-text">{earnAmount}</p>
          </>
        );
      default:
        return null;
    }
  }

  const payoutInfo = payoutContent(page.payoutOption, page.earnAmount);
  const clickCount = page.pageData?.clickCount || 0;
  const reviews = page.pageData?.reviews;
  const reviewCount = reviews ? Object.keys(reviews).length : 0;
  const reviewRatingSum = reviews ? Object.values(reviews).reduce((sum, review) => sum + (review.rating || 0), 0) : 0;
  const reviewAverage = reviewCount > 0 ? (reviewRatingSum / reviewCount).toFixed(1) : 'No reviews';

  const pageCreatedRaw = page?.pageCreated;
  let pageCreated = 'No date available';

  if (pageCreatedRaw) {
    try {
      pageCreated = new Date(pageCreatedRaw).toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Invalid date format:', pageCreatedRaw);
    }
  } else {
    console.error('Invalid pageCreated:', pageCreatedRaw);
  }

  const logoImage = page.profileImg || '../../img/icon.png';

  return (
    <div key={page.id}>
      <div className="all-pages-grid-item-link">
        {showSkeleton ? (
          <div className="skeleton-loader-grid"></div>
        ) : (
          <li className={`all-pages-grid-item ${!page.hasGigSettings ? 'no-gig-settings' : ''}`}>
            <div>
              <button onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onDelete(page.id);
              }} className="all-page-delete-button">
                <img src="../../img/delete.svg" alt="Delete Icon" />
              </button>
            </div>
            {contentToShow}  
            <div className="all-pages-bottom">
              <div className="all-pages-bottom-top">
                <Link id="page-prev-live-link" to={`/${page.route}`} className="all-pages-grid-item-link">
                  <p>Share with Friends</p>
                  <img src="../../img/share.webp" alt="" />
                </Link>
                <Link to={!page.hasGigSettings ? `/create-page/${page.id}` : `/setting/page/${page.id}`} className="all-pages-grid-item-link">
                  Edit Page
                </Link>
              </div>
              <div className="all-pages-top-info-box">
                <h3 className={`all-pages-gig-status ${page.isLive ? 'live' : 'not-public'}`}>
                  {page.isLive ? 'Live' : 'Not Public'}
                </h3>
              </div>
              <p className="all-pages-route">aaartists.com/<span>{page.route}</span></p>
              <h3 className="all-pages-gig-title">{page.gigTitle}</h3>
              <div className="all-pages-gig-info-boxes">
                <div className="all-pages-gig-info-box">
                  <img src="../../img/rush.svg" alt="" />
                  <p className="all-pages-gig-text">{page.gigTime} Day Delivery</p>
                </div>
                <div className="all-pages-gig-info-box">
                  <img src="../../img/infinity_2.svg" alt="" />
                  <p className="all-pages-gig-text">{page.gigRevisions} Revisions</p>
                </div>
                <div className="all-pages-gig-info-box">
                  {payoutInfo}
                </div>
              </div>
              <div className="all-pages-gig-stats-boxes">
                <div className="all-pages-gig-stats-box">
                  <div>
                    <img src="../../img/cursor.svg" alt="" />
                    <p className="all-pages-gig-text">{page.clickCount}</p>
                  </div>
                  <p>Page Views</p>
                </div>
                <div className="all-pages-gig-stats-box">
                  <div>
                    <img src="../../img/star.svg" alt="" />
                    <p className="all-pages-gig-text">
                      {reviewCount > 0 ? `${reviewCount} Reviews (Average: ${page.reviewAverage})` : "No reviews yet"}
                    </p>
                  </div>
                  <p>Reviews</p>
                </div>
                <div className="all-pages-gig-stats-box">
                  <div>
                    <img style={{ width: '15px', marginRight: '15px' }} src="../../img/clock.svg" alt="" />
                    <p className="all-pages-gig-text">{pageCreated}</p>
                  </div>
                  <p>Page Created</p>
                </div>
              </div>
              <div className="all-pages-bottom-bottom">
                <div className="all-pages-top-label">
                  <p>More</p>
                  <hr />
                </div>
                <div>
                  <button className="page-prev-qr-button" onClick={handleImportClick}>
                    Import Reviews
                  </button>
                  {showReviewImport && (
                    <ReviewImport onClose={handleCloseReviewImport} />
                  )}
                  <button className="page-prev-qr-button" onClick={handleQRClick}>
                    QR Code
                  </button>
                </div>
              </div>
              {showQRModal && (
                <div className="prev-page-modal-overlay" onClick={handleCloseModal}>
                  <div className="prev-page-modal-content" onClick={e => e.stopPropagation()}>
                    <h2>Your QR Code</h2>
                    <p>aaartists.com/{page.route}</p>
                    <div className="prev-page-qr-container">
                      <QRCode
                        value={`https://aaartists.com/${page.route}`}
                        logoWidth={100}
                        logoHeight={100}
                        qrStyle="dots"
                        size={350}
                        ecLevel="H"
                      />
                    </div>
                    <button onClick={handleCloseModal}>Close</button>
                  </div>
                </div>
              )}
              {!page.hasGigSettings && (
                <div className="setup-reminder">
                  <p>No infos available</p>
                  <Link to={`/create-page/${page.id}`} className="finish-setup-button">Finish Page Setup</Link>
                </div>
              )}
            </div>
          </li>
        )}
      </div>
    </div>
  );
}

export default PrevPage;

