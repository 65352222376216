import React from 'react';
import './SlideStepperSteps.css';

function SlideStepperSteps({ currentStep, step1Text, step2Text, step3Text, step4Text }) {
  // Schritt-Texte werden entgegengenommen, aber nur zur Bestimmung der Anzahl der Schritte verwendet
  const steps = [
    step1Text,
    step2Text,
    step3Text,
    step4Text,
  ].filter(Boolean); // Filtert leere oder nicht definierte Schritte heraus

  const totalSteps = steps.length;
  
  // Begrenzung des aktuellen Schrittes auf maximal 4
  const limitedCurrentStep = Math.min(currentStep, 4);

  // Berechnung der Breite der Fortschrittslinie
  const progressWidth = `${(limitedCurrentStep / totalSteps) * 88}%`;

  return (
    <div className="slide-stepper-steps-container">
      <div className="slide-stepper-progress" style={{ width: progressWidth }} />
    </div>
  );
}

export default SlideStepperSteps;
