import React, { useState, useEffect } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './AddProject.css'; // Stellen Sie sicher, dass der Pfad zu Ihrer CSS-Datei korrekt ist.

function AddProject({ pageId, widgetKey, onToggleSettings, active, settingsRoute }) {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const projectsRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/projects`);
        const unsubscribe = onValue(projectsRef, (snapshot) => {
            if (snapshot.exists()) {
                setProjects(Object.values(snapshot.val()));
            } else {
                setProjects([]);
            }
        }, (error) => {
            console.error('Failed to fetch project data:', error);
        });
    
        return () => off(projectsRef, 'value', unsubscribe);
    }, [pageId, widgetKey]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const monthNames = ["January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"];
        const year = date.getFullYear();
        const month = monthNames[date.getMonth()];
        return `${month} ${year}`;
    }

    const className = `add-project ${settingsRoute ? 'settings-route' : ''} ${active ? 'is-active' : ''}`;

    const handleAddProjectClick = () => {
        onToggleSettings('AddProject');
    };

    return (
        <div className={className} onClick={handleAddProjectClick}>
            {settingsRoute && (
                <div className="edit-overlay">
                    <img src="../../img/edit.webp" alt="Edit" />
                    <span>Edit</span>
                </div>
            )}

            {projects.length > 0 ? (
                <div className="project-list-inner">
                    <h2>Milestones</h2>
                    {projects.map((project, index) => (
                        <div key={index} className="project-item-inner">
                            <img src={project.projectImg} alt="Project" className="project-image"/>
                            <div className="project-item-inner-bottom">
                                <h3>{project.projectTitle}</h3>
                                <p>{project.projectText}</p>
                                {project.collaborators && <p>Collaborators: <br/><span>{project.collaborators}</span></p>}
                                <p>Started: <br/><span>{formatDate(project.projectStartDate)}</span></p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                settingsRoute && (
                    <div className="gig-upload-song-button" onClick={handleAddProjectClick}>
                        <h5>My Projects</h5>
                        <img src="../../img/plus.svg" alt="Add" />
                    </div>
                )
            )}
        </div>
    );
}

export default AddProject;
