import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';
import './ArtistConnects.css'; // CSS importieren

const ArtistConnects = ({ artistID }) => {
  const [connects, setConnects] = useState(0);
  const [recentProfiles, setRecentProfiles] = useState([]);

  useEffect(() => {
    const fetchConnects = async () => {
      const chatsRef = ref(database, 'Chats');
      onValue(chatsRef, (snapshot) => {
        const data = snapshot.val();
        const connectIds = [];

        if (data) {
          Object.keys(data).forEach((chatId) => {
            const chat = data[chatId];

            // Überprüfen, ob der Artist Teil des Chats ist und mindestens zwei Nachrichten vorhanden sind
            const messages = chat.messages || {};
            if (
              (chat.initiatorId === artistID || chat.recipientId === artistID) &&
              Object.keys(messages).length >= 2
            ) {
              // Finde den Gesprächspartner (nicht der Artist selbst)
              const otherPartyId =
                chat.initiatorId === artistID ? chat.recipientId : chat.initiatorId;

              if (!connectIds.includes(otherPartyId)) {
                connectIds.push(otherPartyId);
              }
            }
          });

          setConnects(connectIds.length);

          // Nur die letzten 3 Verbindungen anzeigen
          const recentIds = connectIds.slice(-3);

          // Die Profilbilder der letzten drei Verbindungen abrufen
          const profilePromises = recentIds.map((id) => {
            const artistRef = ref(database, `Artists/${id}/artistImageUrl`);
            return new Promise((resolve) => {
              onValue(artistRef, (snapshot) => {
                const profileImg = snapshot.val();
                resolve(profileImg);
              });
            });
          });

          Promise.all(profilePromises).then((profiles) => {
            setRecentProfiles(profiles);
          });
        }
      });
    };

    fetchConnects();
  }, [artistID]);

  // Wenn keine Verbindungen vorhanden sind, nichts anzeigen
  if (connects === 0) {
    return null;
  }

  return (
    <div className="artist-connects-container">
      <p>{connects} Connects</p>
      <div className="connects-profiles">
        {recentProfiles.map((profileImg, index) => (
          <img key={index} src={profileImg} alt="Profile" className="connect-profile-img" />
        ))}
      </div>
    </div>
  );
};

export default ArtistConnects;
