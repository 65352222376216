import React from "react";
import "./CoinInfo.css";
import LikeTrader from "./LikeTrader";
import { useNavigate } from "react-router-dom";

const CoinInfo = ({ filteredLogs }) => {
  const navigate = useNavigate();

  // Aggregiere Volumen für Käufe, Verkäufe und Wallets
  const tokenStats = filteredLogs.reduce((acc, log) => {
    const token = log.token2_name;
    const tokenAddress = log.token2_address;
    const walletName = log.wallet_name || "Unknown Wallet";
    const walletGroup = log.wallet_group || "Unknown Group";
    const walletID = log.wallet_id;
    const value = parseFloat(log.value || 0);
    const soldValue = parseFloat(log.value || 0);
    const blockTime = log.block_time * 1000;

    // Initialisiere Token, falls nicht vorhanden
    if (!acc[tokenAddress]) {
      acc[tokenAddress] = {
        tokenName: token,
        bought: 0,
        sold: 0,
        wallets: new Set(),
        walletDetails: new Map(),
        lastTransaction: blockTime,
      };
    }

    // Addiere Wallet-Daten
    acc[tokenAddress].wallets.add(walletID);
    acc[tokenAddress].walletDetails.set(walletID, { name: walletName, group: walletGroup });

    // Aktualisiere Zeit der letzten Transaktion
    if (blockTime > acc[tokenAddress].lastTransaction) {
      acc[tokenAddress].lastTransaction = blockTime;
    }

    // Berechne Volumen
    if (log.token2_name === "Wrapped SOL") {
      const soldTokenAddress = log.token1_address;
      if (!acc[soldTokenAddress]) {
        acc[soldTokenAddress] = {
          tokenName: log.token1_name,
          bought: 0,
          sold: 0,
          wallets: new Set(),
          walletDetails: new Map(),
          lastTransaction: blockTime,
        };
      }
      acc[soldTokenAddress].sold += soldValue;
    } else {
      acc[tokenAddress].bought += value;
    }

    return acc;
  }, {});

  const sortedTokenStats = Object.entries(tokenStats).sort(
    (a, b) => b[1].bought - b[1].sold - (a[1].bought - a[1].sold)
  );

  const totalBought = sortedTokenStats.reduce((sum, [, stats]) => sum + stats.bought, 0);
  const totalSold = sortedTokenStats.reduce((sum, [, stats]) => sum + stats.sold, 0);
  const totalVolume = totalBought + totalSold;
  const buyPercentage = totalVolume ? (totalBought / totalVolume) * 100 : 0;
  const sellPercentage = totalVolume ? (totalSold / totalVolume) * 100 : 0;

  const getLastTransactionMinutes = (lastTransaction) => {
    const now = Date.now();
    return Math.floor((now - lastTransaction) / 60000);
  };

  const openDexscreener = (tokenAddress) => {
    const dexUrl = `https://dexscreener.com/solana/${encodeURIComponent(tokenAddress)}`;
    window.open(dexUrl, "_blank");
  };

  const analyzeToken = async (tokenAddress, tokenName) => {
    try {
      const response = await fetch("https://us-central1-awards-13a46.cloudfunctions.net/analyzeToken", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ tokenAddress, tokenName }),
      });

      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      const data = await response.json();
      alert(`Analysis completed: ${JSON.stringify(data)}`);
    } catch (error) {
      console.error("Error during analysis:", error);
      alert("There was an error during token analysis.");
    }
  };

  const visitAnalytics = (tokenAddress) => {
    const url = `/coin/${encodeURIComponent(tokenAddress)}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  

  const formatNumber = (num) =>
    num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  return (
    <div className="coin-info-container">
      <div className="buy-sell-volume-summary">
        <p style={{ color: "green" }}>
          <strong>Buy:</strong> {formatNumber(totalBought)}
        </p>
        <p style={{ fontSize: "32px" }}>
          {buyPercentage.toFixed(2)}% | {sellPercentage.toFixed(2)}%
        </p>
        <p style={{ color: "red" }}>
          <strong>Sell:</strong> {formatNumber(totalSold)}
        </p>
      </div>

      <h2>Token Übersicht</h2>
      <table>
        <thead>
          <tr>
            <th>Token</th>
            <th>Gekauft</th>
            <th>Verkauft</th>
            <th>Netto</th>
            <th>Anzahl Wallets</th>
            <th>Letzte Transaktion (Minuten)</th>
            <th>Visit Analytics</th>
            <th>Analyse</th>
            <th>Wallet Details</th>
          </tr>
        </thead>
        <tbody>
          {sortedTokenStats.map(([tokenAddress, stats], index) => {
            if (stats.tokenName === "Wrapped SOL") return null;

            const net = stats.bought - stats.sold;
            const isPositive = net > 0;
            const isEven = index % 2 === 0;

            const rowStyle = {
              backgroundColor: isPositive
                ? isEven
                  ? "#0A2001"
                  : "#0C2502"
                : isEven
                ? "#200101"
                : "#2B0102",
            };

            return (
              <tr key={index} style={rowStyle}>
                <td>
                  <span
                    className="token-link"
                    onClick={() => openDexscreener(tokenAddress)}
                    style={{ cursor: "pointer", color: "white", textDecoration: "underline" }}
                  >
                    {stats.tokenName}
                  </span>
                </td>
                <td>{formatNumber(stats.bought)}</td>
                <td>{formatNumber(stats.sold)}</td>
                <td>{formatNumber(net)}</td>
                <td>{stats.wallets.size}</td>
                <td>{getLastTransactionMinutes(stats.lastTransaction)} Minuten</td>
                <td>
                  <button
                    onClick={() => visitAnalytics(tokenAddress)}
                    className="visit-analytics-button"
                  >
                    Visit Analytics
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => analyzeToken(tokenAddress, stats.tokenName)}
                    className="analyze-button"
                    target="_blank"
                  >
                    Analysieren
                  </button>
                </td>
                <td>
                  <ul>
                    {Array.from(stats.walletDetails).map(([walletID, details]) => (
                      <li key={walletID}>
                        <strong>Name:</strong> {details.name} <br />
                        <strong>Group:</strong> {details.group} <br />
                        <LikeTrader walletID={walletID} />
                        <a
                          href={`/ia/${walletID}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="wallet-link-button"
                        >
                          Go to Wallet
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CoinInfo;
