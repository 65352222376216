import React, { useState, useEffect } from 'react';
import AudioPlayer from '../Audio/AudioPlayer';
import './SongVote.css';
import { ref, runTransaction, onValue } from 'firebase/database';
import { database } from '../firebaseConfig'; // Direkter Import der database
import { Link, useParams, useNavigate } from 'react-router-dom';
import CountryFlagEmoji from '../Utility/CountryFlagEmoji';


const SongVote = ({ songDetails, artistId }) => {
    const [hasPlayed, setHasPlayed] = useState(false);
    const { songID } = useParams(); // Extrahieren der songID aus der URL
    const [hasVoted, setHasVoted] = useState(false); // Neuer Zustand, um zu tracken, ob der Benutzer bereits abgestimmt hat
    const [artistImageUrl, setArtistImageUrl] = useState('');
    const [showThankYou, setShowThankYou] = useState(false);
    const [showAlreadyVoted, setShowAlreadyVoted] = useState(false); // Zustand für die Anzeige der Nachricht, dass bereits abgestimmt wurde


    const navigate = useNavigate();
   
  
    useEffect(() => {
        if (!artistId) return;
    
        const artistRef = ref(database, `Artists/${artistId}`);
        onValue(artistRef, (snapshot) => {
          const data = snapshot.val();
          if (data && data.artistImageUrl) {
            setArtistImageUrl(data.artistImageUrl);
          }
        });
    
    }, [artistId]);

    

    useEffect(() => {
        const voteStatus = localStorage.getItem(`votedFor${songID}`);
        if (voteStatus) {
            setHasVoted(true);
        }
    }, [songID]);


    useEffect(() => {
        const voteStatus = localStorage.getItem(`votedFor${songID}`);
        if (voteStatus) {
            setHasVoted(true);
        }
    }, [songID]);

    const handleVote = () => {
        if (hasVoted) {
            setShowAlreadyVoted(true); // Zeigt die Nachricht an, dass bereits abgestimmt wurde
            setTimeout(() => setShowAlreadyVoted(false), 2000); // Blendet die Nachricht nach 2 Sekunden aus
            return;
        }

        const votesRef = ref(database, `/Songs/${songID}/numberVotes`);
        runTransaction(votesRef, (currentVotes) => {
          return (currentVotes || 0) + 1;
        }).then(() => {
          setShowThankYou(true); // Dankeschön-Nachricht anzeigen
          setTimeout(() => setShowThankYou(false), 2000); // Nachricht nach 2 Sekunden ausblenden
          setHasVoted(true);
          localStorage.setItem(`votedFor${songID}`, 'true');
        }).catch(error => {
          console.error('Fehler beim Abstimmen: ', error);
        });
      };
      

    return (
        <div className="song-vote">
             {showThankYou && (
      <div className="thank-you-message">
        Thank you for voting!
      </div>
    )}
       {showAlreadyVoted && (
                <div className="thank-you-message">
                    Sorry, one vote per track per day.
                </div>
            )}
            <div className="song-vote-left">
            <button onClick={() => navigate(-1)} className="back-button-2">
              <img src="../img/left.svg" alt="" />
            <p>Back</p>
            </button>
                <div className="song-vote-header-bar">
                    <p>{songDetails.genre}</p>
                    <hr className="info-spacer" />
                    <p>{songDetails.selectedCountry}</p>
                    <hr className="info-spacer" />
                    <p>{songDetails.selectedArtistType}</p>
                </div>
                <div className="song-info-and-player">
                <div className="song-vote-right-mobile">
                <img src={songDetails.songCoverUrl} alt={`${songDetails.name} cover`}/>
            </div>
                    <div className="artist-box">
                {artistImageUrl && <img src={artistImageUrl} alt="Artist" className="artist-image"/>}
                {artistId ? (
    <Link to={`/artist/${artistId}`} className="artist-name-link" style={{ textDecoration: 'none', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
     <p>{songDetails.artist}</p>
     <CountryFlagEmoji country={songDetails.selectedCountry}/>
    </Link>
  ) : (
    <div style={{display: 'flex', width: '100%'}}>
       <p className="artist-name-link" style={{ color: 'white' }}>{songDetails.artist}</p> 
       <CountryFlagEmoji country={songDetails.selectedCountry}/>
      </div>
  )}
                    </div>
                    <h2 className="song-detail-song-name">{songDetails.name}</h2>
                    <div style={{width: '96%'}}>
                    <AudioPlayer 
  src={songDetails.fileUrl} 
  spotifyUrl={songDetails.spotifyLink} 
  onPlay={() => setHasPlayed(true)} 
  onSpotifyPlay={() => setHasPlayed(true)} // setHasPlayed wird auch auf true gesetzt, wenn Spotify geöffnet wird
/>
</div>
                    {hasPlayed && (
                    <div className="vote-box">
                        <p>How do you like this song?</p>
                <button className="vote-btn" onClick={handleVote}>
                  <p>Vote Up</p>
                  <img src="../img/up.svg" alt="" />
</button>
                </div>
            )}
                </div>
               
            </div>
            <div className="song-vote-right">
                <img src={songDetails.songCoverUrl} alt={`${songDetails.name} cover`}/>
            </div>
           
        </div>
    );
};

export default SongVote;


