import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './HomeHeader.css';
import logo from '../img/logo_2.png';
import { useAppContext } from '../Utility/AppContext';

function HomeHeader() {
  const { avatar, userData } = useAppContext();
  // Zustand hinzufügen, um zu überprüfen, ob das Laden abgeschlossen ist
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (avatar !== undefined) {
      // Sobald der Avatar (oder das Fehlen desselben) bekannt ist, aktualisieren Sie isLoading
      setIsLoading(false);
    }
  }, [avatar]);

  // Entscheidung, welches Bild angezeigt werden soll
  const avatarImage = !isLoading && avatar ? avatar : "../img/avatar.svg";

  return (
    <motion.div
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
      className="home-header"
    >
      <Link to="/">
        <img src={logo} alt="Logo" className="header-logo" />
      </Link>
      <div className="header-content">
        {/* Bedingte Renderlogik basierend auf isLoading */}
        {isLoading ? (
          // Option: Zeigen Sie hier einen Platzhalter oder Ladeindikator an
          <div>Loading...</div>
        ) : (
          <>
            {userData.artistName ? (
              <div style={{ color: 'white', marginRight: '0px', marginTop: '-3px', fontSize: '14px' }}>
                <Link to="/dashboard" className="text-for-experts" style={{}}>{userData.artistName}</Link>
              </div>
            ) : (
              <>
                <Link to="/submission" className="text-for-experts" style={{ textDecoration: 'none' }}>{userData.artistName}</Link>
                <Link to="/signup" style={{ textDecoration: 'none', color: 'white', border: '1px solid white', padding: '5px 10px', borderRadius: '5px', marginRight: '15px', marginTop: '-3px', fontSize: '14px' }}>APPLY NOW</Link>
              </>
            )}
            <Link to="/dashboard">
              <img src={avatarImage} alt="Avatar" className="header-avatar" />
            </Link>
          </>
        )}
      </div>
    </motion.div>
  );
}

export default HomeHeader;
