import React from 'react';
import { ref, set } from 'firebase/database';
import { database } from '../../firebaseConfig';

function GetInvestorCoins({ tokenAddress }) {
  const API_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE3MzIxODUwMDM3MjUsImVtYWlsIjoiaGFyZHZvcmV2aWJlYW5kaUBnbWFpbC5jb20iLCJhY3Rpb24iOiJ0b2tlbi1hcGkiLCJhcGlWZXJzaW9uIjoidjIiLCJpYXQiOjE3MzIxODUwMDN9.bE8JKbILy6DabOlJqUaQhutHI9jiL6Cni7KKiHVswdw';

  const fetchCoins = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        token: API_TOKEN,
      },
    };

    try {
      const response = await fetch(
        `https://pro-api.solscan.io/v2.0/account/token-accounts?address=${tokenAddress}&type=token&page=1&page_size=10`,
        requestOptions
      );
      const data = await response.json();

      if (!data || !data.data) {
        alert('No coins found for this wallet.');
        return;
      }

      const coins = data.data.map((coin) => ({
        tokenAccount: coin.token_account,
        tokenAddress: coin.token_address,
        amount: coin.amount / Math.pow(10, coin.token_decimals),
        tokenDecimals: coin.token_decimals,
        name: data.metadata.tokens?.[coin.token_address]?.token_name || 'Unknown',
        symbol: data.metadata.tokens?.[coin.token_address]?.token_symbol || 'Unknown',
        icon: data.metadata.tokens?.[coin.token_address]?.token_icon || null,
      }));

      // Save coins under the correct wallet
      const coinsRef = ref(database, `IA/wallets/${tokenAddress}/coins`);
      await set(coinsRef, coins);

      alert('Coins fetched and saved successfully!');
    } catch (error) {
      console.error('Error fetching coins:', error);
    }
  };

  return (
    <button onClick={fetchCoins} className="get-coins-button">
      Get Coins
    </button>
  );
}

export default GetInvestorCoins;
