import React, { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import './ReplyRateAnalytics.css'

function ReplyRateAnalytics({ userId, userName }) {
  const [replyRates, setReplyRates] = useState({
    lastWeek: null,
    lastMonth: null,
    previousMonth: null,
    total: null,
  });
  const [unansweredChats, setUnansweredChats] = useState(0); // State for unanswered messages in last two months
  const [isLoading, setIsLoading] = useState(true);

  // Helper function to calculate date ranges
  const getDateRanges = () => {
    const now = new Date();

    // Last week (7 days ago)
    const lastWeek = new Date(now);
    lastWeek.setDate(now.getDate() - 7);

    // Last month (current month - 1)
    const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 1);

    // Previous month (current month - 2)
    const previousMonthStart = new Date(now.getFullYear(), now.getMonth() - 2, 1);
    const previousMonthEnd = new Date(now.getFullYear(), now.getMonth() - 1, 1);

    // Date two months ago (current month - 2)
    const twoMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 2, 1);

    return { lastWeek, lastMonthStart, lastMonthEnd, previousMonthStart, previousMonthEnd, twoMonthsAgo };
  };

  // Function to calculate reply rate and unanswered messages based on time range
  const calculateReplyRate = async () => {
    const chatsRef = ref(database, 'Chats/');
    const { lastWeek, lastMonthStart, lastMonthEnd, previousMonthStart, previousMonthEnd, twoMonthsAgo } = getDateRanges();

    let stats = {
      lastWeek: { totalChats: 0, repliedChats: 0 },
      lastMonth: { totalChats: 0, repliedChats: 0 },
      previousMonth: { totalChats: 0, repliedChats: 0 },
      total: { totalChats: 0, repliedChats: 0 },
    };
    let unansweredCount = 0; // Variable to count unanswered chats from the last two months

    try {
      const snapshot = await get(chatsRef);
      if (snapshot.exists()) {
        const chats = snapshot.val();

        // Loop through each chat
        for (const chatId in chats) {
          const chat = chats[chatId];
          const initiatorId = chat.initiatorId;
          const createdAt = new Date(chat.createdAt);
          const messages = chat.messages || {};
          let hasReply = false;
          let lastMessageFromUser = false; // To track if the last message is from the userId
          let hasUnansweredReply = false; // To track if there's an unanswered reply

          // Check if the current user is the initiator of the chat
          if (initiatorId === userId) {
            stats.total.totalChats++;

            // Check the messages in the chat
            for (const messageId in messages) {
              const message = messages[messageId];

              // If there's a message from someone other than the userId, it's considered a reply
              if (message.senderId !== userId) {
                hasReply = true;
                lastMessageFromUser = false; // Reset to false since the reply came from the other person
              } else {
                lastMessageFromUser = true; // If user sends another message, update lastMessageFromUser
              }

              // Check if the last reply was not answered by the userId
              if (hasReply && !lastMessageFromUser) {
                hasUnansweredReply = true;
              }
            }

            // If there was at least one reply, count this chat as replied
            if (hasReply) {
              stats.total.repliedChats++;
            }

            // Check if the chat was created in the last two months
            if (createdAt >= twoMonthsAgo) {
              // Check if the chat has an unanswered reply
              if (hasUnansweredReply) {
                unansweredCount++; // Increment unanswered chat count
              }
            }

            // Determine which time period the chat belongs to
            if (createdAt >= lastWeek) {
              stats.lastWeek.totalChats++;
              if (hasReply) stats.lastWeek.repliedChats++;
            } else if (createdAt >= lastMonthStart && createdAt < lastMonthEnd) {
              stats.lastMonth.totalChats++;
              if (hasReply) stats.lastMonth.repliedChats++;
            } else if (createdAt >= previousMonthStart && createdAt < previousMonthEnd) {
              stats.previousMonth.totalChats++;
              if (hasReply) stats.previousMonth.repliedChats++;
            }
          }
        }

        // Calculate reply rates for each period
        const replyRates = {
          lastWeek: stats.lastWeek.totalChats > 0 ? ((stats.lastWeek.repliedChats / stats.lastWeek.totalChats) * 100).toFixed(2) : 0,
          lastMonth: stats.lastMonth.totalChats > 0 ? ((stats.lastMonth.repliedChats / stats.lastMonth.totalChats) * 100).toFixed(2) : 0,
          previousMonth: stats.previousMonth.totalChats > 0 ? ((stats.previousMonth.repliedChats / stats.previousMonth.totalChats) * 100).toFixed(2) : 0,
          total: stats.total.totalChats > 0 ? ((stats.total.repliedChats / stats.total.totalChats) * 100).toFixed(2) : 0,
        };

        setReplyRates(replyRates);
        setUnansweredChats(unansweredCount); // Set unanswered chats count
      } else {
        console.log('No chats found.');
        setReplyRates({ lastWeek: 0, lastMonth: 0, previousMonth: 0, total: 0 });
        setUnansweredChats(0);
      }
    } catch (error) {
      console.error('Error fetching chats:', error);
      setReplyRates({ lastWeek: 0, lastMonth: 0, previousMonth: 0, total: 0 });
      setUnansweredChats(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch the data on component mount
  useEffect(() => {
    calculateReplyRate();
  }, [userId]);

  if (isLoading) {
    return <p>Loading reply rate...</p>;
  }

  return (
    <div className="reply-rate-analytics">
      <h2>{userName}</h2>
      <p><strong>Last Week:</strong> <br/>{replyRates.lastWeek}%</p>
      <p><strong>Last Month:</strong> <br/> {replyRates.lastMonth}%</p>
      <p><strong>Previous Month:</strong> <br/> {replyRates.previousMonth}%</p>
      <p><strong>Total:</strong><br/> {replyRates.total}%</p>
      <p><strong>Unanswered Chats (Last 2 Months):</strong> <br/>{unansweredChats}</p> {/* Display unanswered chats count */}
    </div>
  );
}

export default ReplyRateAnalytics;
