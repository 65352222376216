import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getDatabase, ref, push, onValue } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import "./WalletTrades.css";

const WalletTrades = () => {
  const { walletID } = useParams(); // Holen des walletID-Parameters aus der URL
  const db = getDatabase();
  const storage = getStorage();

  const [tradeData, setTradeData] = useState({
    tokenID: "",
    buyPrice: "",
    sellPrice: "",
  });
  const [trades, setTrades] = useState([]);
  const [image, setImage] = useState(null); // Für das Bild
  const [uploadProgress, setUploadProgress] = useState(0); // Upload-Fortschritt
  const [uploadedImageUrl, setUploadedImageUrl] = useState(""); // Hochgeladene URL

  // Handle Input Changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTradeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle File Upload Change
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  // Upload Image to Firebase Storage
  const handleImageUpload = () => {
    if (!image) {
      console.error("No image selected.");
      return;
    }

    const storagePath = `/IA/wallets/${walletID}/images/${image.name}`;
    const storageReference = storageRef(storage, storagePath);

    uploadBytes(storageReference, image)
      .then(() => getDownloadURL(storageReference))
      .then((url) => {
        setUploadedImageUrl(url); // URL für Vorschau speichern
        setImage(null); // Eingabefeld zurücksetzen
        console.log("Image uploaded successfully:", url);
      })
      .catch((error) => console.error("Error uploading image:", error));
  };

  // Save Trade to Firebase
  const handleSaveTrade = () => {
    if (!walletID) {
      console.error("No walletID provided in URL");
      return;
    }

    const tradesRef = ref(db, `/IA/wallets/${walletID}/trades`);
    const tradeWithImage = { ...tradeData, imageUrl: uploadedImageUrl };
    push(tradesRef, tradeWithImage)
      .then(() => {
        setTradeData({ tokenID: "", buyPrice: "", sellPrice: "" }); // Reset fields
        setUploadedImageUrl(""); // Zurücksetzen der Bild-URL
      })
      .catch((error) => console.error("Error saving trade:", error));
  };

  // Fetch Trades from Firebase
  useEffect(() => {
    if (!walletID) {
      console.error("No walletID provided in URL");
      return;
    }

    const tradesRef = ref(db, `/IA/wallets/${walletID}/trades`);
    const unsubscribe = onValue(tradesRef, (snapshot) => {
      const data = snapshot.val();
      const tradesArray = data
        ? Object.keys(data).map((key) => ({ id: key, ...data[key] }))
        : [];
      setTrades(tradesArray);
    });
    return () => unsubscribe();
  }, [walletID, db]);

  return (
    <div className="wallet-trades-container">
      <h2>Trades for Wallet: {walletID}</h2>
      <div className="wallet-trades-form">
        <label>
          Token ID:
          <input
            type="text"
            name="tokenID"
            value={tradeData.tokenID}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Buy Price:
          <input
            type="text"
            name="buyPrice"
            value={tradeData.buyPrice}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Sell Price:
          <input
            type="text"
            name="sellPrice"
            value={tradeData.sellPrice}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Upload Image:
          <input type="file" onChange={handleFileChange} />
        </label>
        <button onClick={handleImageUpload}>Upload Image</button>
        {uploadedImageUrl && (
          <div className="image-preview">
            <p>Uploaded Image:</p>
            <img src={uploadedImageUrl} alt="Uploaded" />
          </div>
        )}
        <button onClick={handleSaveTrade}>Save Trade</button>
      </div>
      <div className="wallet-trades-list">
        <h3>Saved Trades:</h3>
        {trades.length === 0 ? (
          <p>No trades available.</p>
        ) : (
          <ul>
            {trades.map((trade) => (
              <li key={trade.id} className="trade-item">
                <strong>Token ID:</strong> {trade.tokenID} <br />
                <strong>Buy Price:</strong> {trade.buyPrice} <br />
                <strong>Sell Price:</strong> {trade.sellPrice} <br />
                {trade.imageUrl && (
                  <div className="trade-image">
                    <strong>Image:</strong>
                    <img src={trade.imageUrl} alt="Trade" />
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default WalletTrades;
