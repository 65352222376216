import React, { useState, useRef, useEffect } from 'react';
import './AudioPlayer.css';
import playIcon from '../img/play.svg';
import pauseIcon from '../img/pause.svg';
import SpotifySongs from './SpotifySongs';

const AudioPlayer = ({ src, spotifyUrl, onPlay, onSpotifyPlay }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
  const [showSpotify, setShowSpotify] = useState(false);

  useEffect(() => {
    // Audio-Element nur initialisieren, wenn eine Quelle vorhanden ist
    if (src) {
      audioRef.current = new Audio(src);
      audioRef.current.preload = "auto"; // Aktiviert das Preloading

      const audio = audioRef.current;

      const setAudioData = () => {
        setDuration(audio.duration);
        setCurrentTime(audio.currentTime);
      };

      const setAudioTime = () => setCurrentTime(audio.currentTime);

      audio.addEventListener('loadedmetadata', setAudioData);
      audio.addEventListener('timeupdate', setAudioTime);

      return () => {
        audio.removeEventListener('loadedmetadata', setAudioData);
        audio.removeEventListener('timeupdate', setAudioTime);
      };
    }
  }, [src]);

  const togglePlayPause = async () => {
    if (src) {
      setIsPlaying(!isPlaying);
      if (!isPlaying) {
        try {
          await audioRef.current.play();
          onPlay && onPlay();
        } catch (error) {
          console.error('Fehler beim Abspielen des Audios', error);
          setIsPlaying(false);
        }
      } else {
        audioRef.current.pause();
      }
    } else if (spotifyUrl && !showSpotify) {
      setShowSpotify(true);
      onSpotifyPlay && onSpotifyPlay();
    } else {
      setShowSpotify(false);
    }
  };

  const handleProgressClick = (e) => {
    if (!src) return;
    const clickX = e.nativeEvent.offsetX;
    const totalWidth = e.currentTarget.offsetWidth;
    const newTime = (clickX / totalWidth) * duration;
    audioRef.current.currentTime = newTime;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };


  return (
    <div className="audio-player">
      <button onClick={togglePlayPause} className="play-pause-button" style={{marginLeft: '0px', marginTop: '0px', width: '45px', height: '45px', border: '1px solid white'}}>
      {isPlaying ? <img src={pauseIcon} style={{marginLeft: '1px'}}className="pause-icon" alt="Pause" /> : <img src={playIcon} className="play-icon" alt="Play" />}
      </button>
      <span>{formatTime(currentTime)}</span>
      <div className="progress-bar" onClick={handleProgressClick}>
        <div className="progress" style={{ width: `${(currentTime / duration) * 100}%` }}></div>
      </div>
      <span>{formatTime(duration)}</span>
      {spotifyUrl && showSpotify && (
        <SpotifySongs spotifyUrl={spotifyUrl} />
      )}
    </div>
  );
};

export default AudioPlayer;
