import React from 'react';
import './ShowDailyContent.css';

const ShowDailyContent = ({ entries }) => {
  return (
    <div className="rss-daily-content-container">
      <h3 className="rss-daily-content-header">Daily Content Entries</h3>
      {entries.length > 0 ? (
        <div className="rss-video-grid">
          {entries.map((entry) => (
            <div key={entry.id} className="rss-video-item">
              <h4>{entry.title}</h4>
              <p><strong>Publication Date:</strong> {new Date(entry.pubDate).toLocaleString()}</p>
              <p><strong>Group:</strong> {entry.group}</p>
              {entry.link && entry.link.includes("youtube.com") && (
                <iframe
                  className="rss-video-iframe"
                  src={`https://www.youtube.com/embed/${new URL(entry.link).searchParams.get("v")}`}
                  title={entry.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="rss-no-entries">No entries available.</p>
      )}
    </div>
  );
};

export default ShowDailyContent;
