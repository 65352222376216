import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get, update} from 'firebase/database';
import { database } from '../../firebaseConfig';
import './ServiceSelection.css';
import services from '../PageUtility/servicesData';
import LoadingScreen from '../../Utility/LoadingScreen';

function ServiceSelection({ pageId, onSuccess, onServiceSelected,  onDisableNext, onError }) {
  const [selectedService, setSelectedService] = useState('');
  const [initialServiceFetched, setInitialServiceFetched] = useState(false); // State, um zu verfolgen, ob der initiale Dienstwert abgerufen wurde
  const [serviceType, setServiceType] = useState('Music Artist');
  const [isLoading, setIsLoading] = useState(false); // Ladezustand hinzugefügt



  useEffect(() => {
    if (pageId && !initialServiceFetched) {
      setIsLoading(true); // Ladezustand aktivieren
      const servicesRef = databaseRef(database, `Pages/${pageId}/pageData`);
      get(servicesRef).then(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          if (data.service) {
            setSelectedService(data.service);
          }
          if (data.serviceType) {
            setServiceType(data.serviceType);
          }
        }
        setInitialServiceFetched(true);
        setIsLoading(false); // Ladezustand deaktivieren
      }).catch(error => {
        console.error('Failed to fetch initial service:', error);
        onError(error.message);
        setIsLoading(false); // Ladezustand deaktivieren bei einem Fehler
      });
    }
  }, [pageId, initialServiceFetched, onError]);


  const toggleService = (service) => {
    setSelectedService(service.name);  // Setzen des ausgewählten Dienstnamens
    setServiceType(service.type);  // Setzen des Diensttyps
    saveServiceData(service);  // Speichern des Dienstes in der Datenbank
    onServiceSelected(service.name, service.type);  // Senden des Dienstnamens und -typs an die übergeordnete Komponente
};


const saveServiceData = async (service) => {
  if (pageId) {
    const pageDataRef = databaseRef(database, `Pages/${pageId}/pageData`);
    try {
      const updates = {
        service: service.name, // Name des Dienstes
        serviceType: service.type, // Typ des Dienstes
        'offers/0': null // Löschen aller Daten unter dem Pfad /offers/0
      };
      await update(pageDataRef, updates); // Aktualisierung der Daten in der Datenbank
    } catch (error) {
      console.error('Failed to save service data:', error);
      onError('Failed to save service data.');
    }
  } else {
    onError('No pageId provided');
  }
};

  
  

  useEffect(() => {
    onDisableNext(!selectedService); // Button aktivieren, wenn ein Service ausgewählt ist
  }, [selectedService, onDisableNext]);




  return (
    <div className="settings-service-selection-container">
      <div className="create-page-header-fonts">
        <h2>Select Your Skill to Sell</h2>
        <p>Decide what to sell and share your skills with potential buyers.</p>
      </div>
      <div className="service-type-toggle">
  <button
    className={`service-type-toggle-button ${serviceType === 'Music Artist' ? 'active' : ''}`}
    onClick={() => setServiceType('Music Artist')}
  >
    Music Artists
  </button>
  <button
    className={`service-type-toggle-button ${serviceType === 'Music Industry' ? 'active' : ''}`}
    onClick={() => setServiceType('Music Industry')}
  >
    Music Industry
  </button>
</div>

{services.filter(service => service.type === serviceType).map((service, index) => (
  <button key={index} className={`service-settings-item ${selectedService === service.name ? "selected" : ""}`} onClick={() => toggleService(service)}>
    <img src={service.img} alt={service.name} className="service-settings-image" />
    <div className="service-settings-info">
      <strong className="service-settings-name">{service.name}</strong>
      <p className="service-settings-description">{service.description}</p>
    </div>
  </button>
))}


    </div>
  );
}

export default ServiceSelection;
