import React, { useState, useEffect, useCallback } from 'react';
import './PlaySpy.css';

const PlaySpy = ({ onClose, onTreasureClick }) => { // onTreasureClick wird an das Parent-Component übergeben
  const [cubePosition, setCubePosition] = useState({ top: 0, left: 0 });
  const [cursorPosition, setCursorPosition] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 }); // Set initial cursor position to center
  const [isCubeVisible, setIsCubeVisible] = useState(false);

  // Debouncing function to improve cursor performance
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  // Generate cube position within the screen, avoiding the first and last 100px
  useEffect(() => {
    const randomTop = Math.random() * (window.innerHeight - 200) + 100; // Between 100px and (window.innerHeight - 100px)
    const randomLeft = Math.random() * (window.innerWidth - 100) + 50; // Avoid the edges
    const randomPosition = {
      top: randomTop + 'px',
      left: randomLeft + 'px',
      position: 'absolute'
    };
    setCubePosition(randomPosition);

    // Set cursor position to the center when modal opens
    setCursorPosition({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
  }, []);

  // Track the cursor position with debouncing for smoothness
  const handleMouseMove = useCallback(
    debounce((e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });

      // Check if the cursor is close to the cube
      const cursorRadius = 100; // The "light" radius around the cursor
      const distance = Math.sqrt(
        Math.pow(e.clientX - parseFloat(cubePosition.left), 2) + 
        Math.pow(e.clientY - parseFloat(cubePosition.top), 2)
      );
      if (distance < cursorRadius) {
        setIsCubeVisible(true);
      } else {
        setIsCubeVisible(false);
      }
    }, 5), // Reduced debounce time for smoother experience
    [cubePosition]
  );

  // Close the popup if clicking outside the cube
  const handleClickOutside = (e) => {
    const cubeRect = document.querySelector('.play-spy-cube').getBoundingClientRect();
    if (
      e.clientX < cubeRect.left || 
      e.clientX > cubeRect.right || 
      e.clientY < cubeRect.top || 
      e.clientY > cubeRect.bottom
    ) {
      onClose();
    }
  };

  const handleCubeClick = () => {
    onTreasureClick(); // Ruft die Funktion auf, um `PlayTreasure1` zu öffnen und `PlaySpy` zu schließen
  };

  return (
    <div className="play-spy-overlay" onMouseMove={handleMouseMove} onClick={handleClickOutside}>
      {/* Spotlight */}
      <div
        className="play-spy-spotlight"
        style={{
          top: cursorPosition.y - 100 + 'px', // Adjust spotlight to be centered around the cursor
          left: cursorPosition.x - 100 + 'px',
        }}
      />
      {/* Cube */}
      <div
        className="play-spy-cube"
        style={{ top: cubePosition.top, left: cubePosition.left, opacity: isCubeVisible ? 1 : 0 }}
        onClick={handleCubeClick} // Füge den Click-Handler hinzu
      >
        {/* Red container is now visible, with the image inside */}
        <div className="play-spy-cube-background"></div>
        <img src="/../../img/jumper_goat.gif" alt="Widget Cube" className="play-spy-cube-img" />
      </div>
      {/* Custom cursor with widget image */}
      <div
        className="play-spy-cursor"
        style={{
          top: cursorPosition.y - 25 + 'px', // Adjust position to center image over cursor
          left: cursorPosition.x - 25 + 'px',
        }}
      >
        <img src="/../../img/flashlight.svg" alt="Cursor Widget" className="play-spy-cursor-img" />
      </div>
      <h2 className="play-spy-h2">Where is he?</h2>
    </div>
  );
};

export default PlaySpy;
