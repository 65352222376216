import React, { useState, useEffect } from 'react';
import { ref, onValue, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import './SellArt.css';
import CreateCollabModal from './CreateCollabModal';
import BuyProModal from './BuyProModal';
import CollabLoginPopup from './CollabLoginPopup';
import FinishSettingsModal from './FinishSettingsModal';
import CollabSuccessModal from './CollabSuccessModal'; 
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const serviceIcons = {
  sellBeats: '../img/music.svg',
  sellLicense: '../img/auction.svg',
  sellVerse: '../img/verses.svg',
  openForCollab: '../img/waves.svg',
  offerMentorship: '../img/hands.svg',
  reviewArtists: '../img/wave_1.svg',
  discoverTalent: '../img/trophy.svg',
  paidServices: '../img/check.svg',
};

const serviceTitles = {
  sellBeats: 'Beats for Sale',
  sellLicense: 'Licenses Available',
  sellVerse: 'Custom Verses',
  openForCollab: 'Open for Collaboration',
  offerMentorship: 'Mentorship',
  reviewArtists: 'Music Review',
  discoverTalent: 'Looking for Talent',
  paidServices: 'Paid Services Available',
};

const serviceDescriptions = {
  sellBeats: 'Purchase exclusive beats.',
  sellLicense: 'Buy a license for commercial use.',
  sellVerse: 'Get a custom verse for your track.',
  openForCollab: 'Open for collaborations.',
  offerMentorship: 'Get mentorship from this industry expert.',
  reviewArtists: 'Have your work reviewed by this professional.',
  discoverTalent: 'Interested in discovering new musical talent.',
  paidServices: 'Explore paid services offered by this artist.',
};

const SellArt = ({ artistId }) => {
  const [artistDetails, setArtistDetails] = useState({});
  const [artistImageUrl, setArtistImageUrl] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showCollabModal, setShowCollabModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showFinishSettingsModal, setShowFinishSettingsModal] = useState(false);
  const [showCollabLoginPopup, setShowCollabLoginPopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [userCredits, setUserCredits] = useState(0);
  const [isPremium, setIsPremium] = useState(false);
  const [hasSettings, setHasSettings] = useState(null);
  const [hasExpertSettings, setHasExpertSettings] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isChatExists, setIsChatExists] = useState(false);

  useEffect(() => {
    if (!artistId) return;

    const artistRef = ref(database, `/Artists/${artistId}`);
    onValue(artistRef, (snapshot) => {
      const data = snapshot.val() || {};
      setArtistDetails(data);

      if (data.artistImageUrl) {
        setArtistImageUrl(data.artistImageUrl);
      }
    });
  }, [artistId]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        setCurrentUserId(user.uid);

        const userRef = ref(database, `Artists/${user.uid}`);
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          setUserCredits(userData.collabCredits || 0);
          setIsPremium(userData.isPremium || false);
          setHasSettings(userData.hasSettings || false);
          setHasExpertSettings(userData.hasExpertSettings || false);
        }

        checkChatExists(user.uid, artistId);
      } else {
        setIsLoggedIn(false);
        setCurrentUserId(null);
      }
    });

    return () => unsubscribe();
  }, [artistId]);

  const checkChatExists = async (currentUserId, artistID) => {
    const chatId1 = `${currentUserId}_${artistID}`;
    const chatId2 = `${artistID}_${currentUserId}`;

    const chatRef1 = ref(database, `Chats/${chatId1}`);
    const chatRef2 = ref(database, `Chats/${chatId2}`);

    const chatSnapshot1 = await get(chatRef1);
    const chatSnapshot2 = await get(chatRef2);

    if (chatSnapshot1.exists() || chatSnapshot2.exists()) {
      setIsChatExists(true);
    }
  };

  const handleServiceClick = (serviceKey) => {
    if (isChatExists) return;

    if (!isLoggedIn) {
      setShowCollabLoginPopup(true);
      return;
    }

    if (!hasSettings && !hasExpertSettings) {
      setShowFinishSettingsModal(true);
      return;
    }

    if (isPremium || userCredits > 0) {
      setShowCollabModal(true);
    } else {
      setShowUpgradeModal(true);
    }
  };

  const handleSuccessMessage = async () => {
    setShowSuccessModal(true);
    await checkChatExists(currentUserId, artistId);
    setTimeout(() => {
      setShowSuccessModal(false);
    }, 3000);
  };

  const availableServices = Object.keys(serviceIcons).filter((service) => artistDetails[service]);

  // Gib nichts zurück, wenn keine Opportunities vorhanden sind
  if (availableServices.length === 0) {
    return null;
  }

  return (
    <div className="sell-art">
      <h3>Opportunities</h3>

      <div className="sell-art-container">
        {availableServices.map((serviceKey, index) => (
          <div
            key={index}
            className="sell-art-item"
            onClick={() => handleServiceClick(serviceKey)}
            style={{ cursor: isChatExists ? 'not-allowed' : 'pointer', opacity: isChatExists ? 0.6 : 1 }}
          >
            <img src={serviceIcons[serviceKey]} alt={serviceTitles[serviceKey]} className="service-icon" />
            <div className="service-text">
              <h4>{serviceTitles[serviceKey]}</h4>
              <p>{serviceDescriptions[serviceKey]}</p>
            </div>
            <img style={{ right: '10px', opacity: '0.5' }} className="forward_icon_sell" src="../img/forward.svg" alt="" />
          </div>
        ))}
      </div>

      {showCollabModal && currentUserId && (
        <CreateCollabModal
          senderId={currentUserId}
          receiverId={artistId}
          receiverImageUrl={artistImageUrl}
          onClose={() => setShowCollabModal(false)}
          onMessageSent={(receiverId) => {
            console.log(`Message sent to ${receiverId}`);
            setShowCollabModal(false);
            handleSuccessMessage();
          }}
        />
      )}
      {showUpgradeModal && (
        <div className="modal-overlay">
          <BuyProModal onClose={() => setShowUpgradeModal(false)} />
        </div>
      )}

      {showFinishSettingsModal && (
        <FinishSettingsModal onClose={() => setShowFinishSettingsModal(false)} />
      )}
      {showCollabLoginPopup && (
        <CollabLoginPopup onClose={() => setShowCollabLoginPopup(false)} />
      )}
      {showSuccessModal && (
        <CollabSuccessModal onClose={() => setShowSuccessModal(false)} />
      )}
    </div>
  );
};

export default SellArt;
