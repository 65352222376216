import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get, query, orderByChild, equalTo } from 'firebase/database';
import { database } from "../firebaseConfig";
import './HomeAwards.css';
import playIcon from '../img/play.svg';
import pauseIcon from '../img/pause.svg';
import AudioBar from '../Audio/AudioBar';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import SpotifySongs from '../Audio/SpotifySongs';
import ScrollVideo from '../Utility/ScrollVideo';
import ScrollVideoMobile from '../Utility/ScrollVideoMobile';
import CountryFlagSVG from '../Utility/CountryFlagEmoji';

function HomeAwards() {
  const [awardWinningSongs, setAwardWinningSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const fetchSongs = async () => {
      const songsRef = query(databaseRef(database, 'Songs'), orderByChild('chartlists/AwardWinners'), equalTo(true));
      const snapshot = await get(songsRef);
      if (snapshot.exists()) {
        const songsData = snapshot.val();
        const songsArray = Object.entries(songsData).map(([id, data]) => ({
          id,
          ...data,
        })).reverse(); // Hier wendest du .reverse() an
        setAwardWinningSongs(songsArray);
      }
    };
    fetchSongs();
  }, []);
  

  useEffect(() => {
    const handleScroll = () => {
      const scrollDistance = window.scrollY;
      const startScroll = 1000; // Animation startet nach 900px Scrollen
      const endScroll = 1400; // Animation endet bei 1300px Scrollen
      const image = document.querySelector('.scroll-video-mobile img');
      
      if (!image) return; // Beendet die Funktion früh, wenn das Bild nicht gefunden wird

      if (scrollDistance > startScroll && scrollDistance < endScroll) {
        // Berechnet den Skalierungsfaktor zwischen 1 und 1.1 basierend auf der aktuellen Scroll-Position
        const scaleIncrement = (scrollDistance - startScroll) / (endScroll - startScroll);
        const scale = 1 + (0.1 * scaleIncrement); // Skaliert von 100% auf 110%
        image.style.transform = `scale(${scale})`;
      } else if (scrollDistance >= endScroll) {
        // Stellt sicher, dass das Bild bei 110% bleibt, sobald der Endpunkt erreicht ist
        image.style.transform = 'scale(1.1)';
      } else if (scrollDistance <= startScroll) {
        // Stellt sicher, dass das Bild bei 100% bleibt, wenn es vor dem Startpunkt ist
        image.style.transform = 'scale(1)';
      }
    };

    // Event-Listener beim Mounten hinzufügen
    window.addEventListener('scroll', handleScroll);

    // Cleanup-Funktion, um den Event-Listener beim Unmounten zu entfernen
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); // Leerarray als Dependency, damit der Effekt nur beim Mounten ausgeführt wird


  const togglePlayPause = (song) => {
    if (currentSong && currentSong.id === song.id && isPlaying) {
      setIsPlaying(false);
    } else {
      setCurrentSong(song);
      setIsPlaying(true);
    }
  };


  return (
    <div className="home-awards">
      <h2>AWARD WINNERS</h2>
      <p>Raw talent and passion, our award winners stand tall, their names etched in the halls of sonic fame, forever pioneers.</p>
      <div className="home-awards-body">
        <div className="home-awards-left">
          <div className="home-awards-grid">
          {awardWinningSongs.slice(0, 10).map((song, index, slicedArray) => (
    <div key={song.id} className={`home-award-song ${index % 2 === 0 ? 'top-row' : 'bottom-row'} ${index >= slicedArray.length - 2 ? 'border-bottom-white' : ''}`}>
    <div className="home-award-song-left">
    <span style={{marginTop: '15px'}} className="chart-position">{index + 1}.</span>
                  <img src={song.songCoverUrl} alt="" />
                </div>
                <div className="home-award-song-middle">
  <Link to={`/song/${song.id}`} style={{ textDecoration: 'none', color: 'white' }}>
    <h4 className="song-name-awards">{song.name}</h4>
  </Link>
  <div style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
    {song.artistID ? (
      <Link to={`/artist/${song.artistID}`} style={{ textDecoration: 'none', color: 'white' }}>
        <p className="song-name-awards">{song.artist}</p>
      </Link>
    ) : (
      <p className="song-name-awards-2" style={{ color: 'white' }}>{song.artist}</p>
    )}
    {song.selectedCountry && <CountryFlagSVG country={song.selectedCountry} />}
  </div>
</div>

                <div className="home-award-song-right">

                  <button className="play-pause-button" onClick={() => togglePlayPause(song)}>
                    {currentSong && currentSong.id === song.id && isPlaying ? <img className="pause-icon" src={pauseIcon} alt="Pause" /> : <img className="play-icon" src={playIcon} alt="Play" />}
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="home-awards-grid-mobile">
          {awardWinningSongs.slice(0, 8).map((song, index, slicedArray) => (
    <div key={song.id} className={`home-award-song ${index % 2 === 0 ? 'top-row' : 'bottom-row'} ${index >= slicedArray.length - 2 ? 'border-bottom-white' : ''}`}>
    <div className="home-award-song-left">
    <span style={{marginTop: '15px'}} className="chart-position">{index + 1}.</span>
 
                  <img src={song.songCoverUrl} alt="" />
                </div>
                <div className="home-award-song-middle">
  <Link to={`/song/${song.id}`} style={{ textDecoration: 'none', color: 'white' }}>
    <h4 className="song-name-awards">{song.name}</h4>
  </Link>
  <div style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
    <p className="song-name-awards">{song.artist}</p>
    {song.selectedCountry && <CountryFlagSVG country={song.selectedCountry} />}
  </div>
</div>

                
                <div className="home-award-song-right">
           
                  <button className="play-pause-button" onClick={() => togglePlayPause(song)}>
                    {currentSong && currentSong.id === song.id && isPlaying ? <img className="pause-icon" src={pauseIcon} alt="Pause" /> : <img className="play-icon" src={playIcon} alt="Play" />}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="home-awards-right">
          <div className="scroll-video-desktop">
          <ScrollVideo />
          </div>
          <div className="scroll-video-mobile">
    <img src="/img/AAA_Plate.webp" alt="AAA Music Awards Red Record" />
</div>

        </div>
      </div>
      {isPlaying && currentSong && (
        <div className="audio-bar-container">
          {currentSong.fileUrl ? (
            <AudioBar
              url={currentSong.fileUrl}
              songImg={currentSong.songCoverUrl || currentSong.imageUrl}
              artistName={currentSong.artist}
              songName={currentSong.name}
              isPlaying={isPlaying}
              togglePlayPause={() => setIsPlaying(false)}
            />
          ) : (
            <SpotifySongs spotifyUrl={currentSong.spotifyLink} />
          )}
        </div>
      )}
    </div>
  );
}

export default HomeAwards;