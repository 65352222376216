import React, { useState } from 'react';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ref as databaseRef, get, push, set } from 'firebase/database';
import { storage, database, auth } from '../../firebaseConfig';
import { motion } from 'framer-motion';

const OrderStartChat = ({ isOpen, onClose, pageId }) => {
    const [inputMessage, setInputMessage] = useState('');
    const [imageUploadUrl, setImageUploadUrl] = useState('');
    const [uploading, setUploading] = useState(false);
    const [messageSent, setMessageSent] = useState(false);

    const fetchArtistIdFromPage = async (pageId) => {
        const pageRef = databaseRef(database, `Pages/${pageId}/artistId`);
        const snapshot = await get(pageRef);
        if (snapshot.exists()) {
            return snapshot.val();
        } else {
            console.error('Artist ID not found for given page ID');
            return null;
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setUploading(true);
        const fileRef = storageRef(storage, `chatImages/${new Date().getTime()}_${file.name}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on('state_changed',
            snapshot => {},
            error => {
                console.error('Upload failed:', error);
                setUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                    setImageUploadUrl(downloadURL);
                    setUploading(false);
                });
            }
        );
    };

    const handleSendMessage = async () => {
        const recipientId = await fetchArtistIdFromPage(pageId);
        const currentUser = auth.currentUser;
    
        if (!currentUser) {
            console.error('No user logged in');
            return;
        }
    
        const initiatorId = currentUser.uid;
        if (!recipientId) {
            console.error('Recipient ID could not be retrieved');
            return;
        }
    
        if (inputMessage.length < 5) {
            console.error('Message should be at least 5 characters long');
            return;
        }
    
        // Generate a consistent chatId regardless of who is the sender or receiver
        const chatId = [initiatorId, recipientId].sort().join('_');
        const chatRef = databaseRef(database, `Chats/${chatId}`);
        const nowIso = new Date().toISOString();  // Timestamp for both the message and last message update
    
        const snapshot = await get(chatRef);
        if (snapshot.exists()) {
            // Chat already exists, append a message and update last message timestamp
            const messagesRef = databaseRef(database, `Chats/${chatId}/messages`);
            const newMessageRef = push(messagesRef);
            await set(newMessageRef, {
                message: inputMessage,
                imageUrl: imageUploadUrl,
                senderId: initiatorId,
                type: "offer-start",
                timestamp: nowIso
            });
            await set(databaseRef(database, `Chats/${chatId}/lastMessageAt`), nowIso);
        } else {
            // No chat found, create new chat and add the first message
            await set(chatRef, {
                initiatorId: initiatorId,
                recipientId: recipientId,
                initiatorId_recipientId: chatId, // Store the composite key for easy querying
                createdAt: nowIso,
                lastMessageAt: nowIso  // Also set when creating a new chat
            });
            const messagesRef = databaseRef(database, `Chats/${chatId}/messages`);
            const newMessageRef = push(messagesRef);
            await set(newMessageRef, {
                message: inputMessage,
                imageUrl: imageUploadUrl,
                senderId: initiatorId,
                type: "offer-start",
                timestamp: nowIso
            });
        }
    
        setInputMessage(''); // Reset the text input field
        setImageUploadUrl(''); // Reset the image URL
        setMessageSent(true); // Set the messageSent state to true
    
        // Show "Message sent" for 5 seconds, then close the chat
        setTimeout(() => {
            setMessageSent(false);
            onClose();
        }, 1500);
    };

    const isMessageValid = inputMessage.length >= 5; // Check if message has at least 5 characters

    const openFileDialog = () => {
        const fileInput = document.getElementById('file-input');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error('File input not found');
        }
    };
    
    const modalVariants = {
        hidden: {
            opacity: 0,
            scale: 0.95,
            transition: {
                duration: 0.2,
                ease: "easeInOut"
            }
        },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.3,
                delay: 0.1,
                ease: "easeInOut"
            }
        },
        exit: {
            opacity: 0,
            scale: 0.95,
            transition: {
                duration: 0.05,
                ease: "easeInOut"
            }
        }
    };

    return (
        <motion.div
            className="order-modal-backdrop"
            onClick={onClose}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <motion.div className="order-modal-content" onClick={e => e.stopPropagation()} variants={modalVariants}>
                <h4>Start Chat</h4>
                <div className="order-back-button" onClick={onClose}>
                    <img src='../../img/left.svg' alt="" />
                </div>
                {messageSent ? (
                    <div className="message-sent-div">
                        <img src="../../img/check.webp" alt=""/>
                       <p>Message sent</p>
                        </div>
                ) : (
                    <>
                        <div className="order-textarea-wrapper">
                            <textarea
                                value={inputMessage}
                                onChange={e => setInputMessage(e.target.value)}
                                placeholder="Type here"
                            />
                            <button className="chat-image-upload-2" onClick={openFileDialog}>
                                <img src='../../img/uploading.svg' alt="Upload Image" />
                            </button>
                        </div>
                        <input
                            id="file-input"
                            type="file"
                            accept="image/*"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                        />
                        {uploading && <div style={{bottom: '100px', left: '45px', width: '35px', height: '35px'}} className="spinner-3"></div>}
                        {imageUploadUrl && <img src={imageUploadUrl} onClick={openFileDialog} alt="Uploaded" className="chat-preview-uploaded-img" style={{ width: '100px' }} />}
                        <div className="order-start-chat-button">
                            <button onClick={onClose}>Close Chat</button>
                            <button id="order-start-second-button" onClick={handleSendMessage} disabled={!isMessageValid} className={!isMessageValid ? "disabled-buttoni" : ""}>
                                <p>Send Message</p>
                            </button>
                        </div>
                    </>
                )}
            </motion.div>
        </motion.div>
    );
};

export default OrderStartChat;
