import React, { useState } from 'react';
import InvestHolders from './InvestHolders';
import LogsView from './LogsView';
import './Invest.css';

function Invest() {
  const [showHolders, setShowHolders] = useState(true);

  return (
    <div className="invest-container">
      <h1 className="invest-title">Invest</h1>
      <div className="invest-tabs-container">
   
        <span
          className={`invest-tab ${!showHolders ? 'active' : ''}`}
          onClick={() => setShowHolders(false)}
        >
          Logs
        </span>
        <span
          className={`invest-tab ${showHolders ? 'active' : ''}`}
          onClick={() => setShowHolders(true)}
        >
          Investors
        </span>
      </div>
      <div className="invest-content">
        {showHolders ? <InvestHolders /> : <LogsView />}
      </div>
    </div>
  );
}

export default Invest;
