import Resizer from 'react-image-file-resizer';

export const compressImage = (file) =>
  new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      1200, // Maximale Breite
      1200, // Maximale Höhe
      'JPEG', // Zielformat
      75, // Qualitätseinstellung
      0, // Rotation
      (blob) => { // Direkte Verwendung des Blob, der von Resizer zurückgegeben wird
        resolve(blob);
      },
      'blob' // Rückgabetyp Blob
    );
  });

