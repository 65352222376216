import React from 'react';
import './ShowStepperSteps.css';

function ShowStepperSteps({ currentStep, step1Text, step2Text, step3Text, step4Text }) {
  const steps = [
    { id: 1, text: step1Text },
    { id: 2, text: step2Text },
    { id: 3, text: step3Text },
    { id: 4, text: step4Text },
  ];

  return (
    <div className="stepper-steps-container">
      {steps.map((step, index) => (
        <React.Fragment key={step.id}>
          <div
            className={`stepper-step ${step.id <= currentStep ? 'active' : ''}`}
          >
            {step.text}
          </div>
          {index < steps.length - 1 && <hr />} {/* <hr> nur zwischen den Schritten, nicht nach dem letzten */}
        </React.Fragment>
      ))}
    </div>
  );
}

export default ShowStepperSteps;
