import React, { useState, useEffect } from 'react';
import { auth, database } from '../firebaseConfig';
import { ref as databaseRef, get, query, remove, orderByChild, equalTo } from 'firebase/database';
import './MySongs.css';
import { useNavigate } from 'react-router-dom';
import SongItem from './SongItem';
import BuyProModal from '../Pages/BuyProModal';
import ViralBuyModal from '../Pages/ViralBuyModal';

function formatDate(isoString) {
  const date = new Date(isoString);
  return new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(date);
}

function MySongs() {
  const [songs, setSongs] = useState([]);
  const [songToDelete, setSongToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [certificateData, setCertificateData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState({});
  const [isBuyProModalOpen, setIsBuyProModalOpen] = useState(false);
  const [isViralBuyModalOpen, setIsViralBuyModalOpen] = useState(false); // Zustand für ViralBuyModal
  const [songCredits, setSongCredits] = useState(0); // State für Song-Credits
  const [isPremium, setIsPremium] = useState(false); // State für Premium-Status

  const navigate = useNavigate();

  const handleImageLoaded = (songId) => {
    setImageLoaded((prev) => ({ ...prev, [songId]: true }));
  };

  const confirmDelete = (songId) => {
    setSongToDelete(songId);
  };

  function SkeletonLoader() {
    return (
      <div className="skeleton-loader">
        <div className="skeleton-image-2"></div>
      </div>
    );
  }

  const deleteSong = async (songId) => {
    try {
      const songRef = databaseRef(database, `Songs/${songId}`);
      await remove(songRef);
      setSongs(songs.filter((song) => song.id !== songId));
      setSongToDelete(null);
    } catch (error) {
      console.error('Could not delete song: ', error);
    }
  };

  const checkUserStatus = async (userId) => {
    const userRef = databaseRef(database, `Artists/${userId}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      const { isPremium, songCredits } = snapshot.val();
      setSongCredits(songCredits || 0); // Setze die Song-Credits im State
      setIsPremium(isPremium || false); // Setze den Premium-Status im State
      return { isPremium, songCredits };
    }
    return { isPremium: false, songCredits: 0 };
  };

  const handleButtonClick = async () => {
    const user = auth.currentUser;
    if (user) {
      const { isPremium, songCredits } = await checkUserStatus(user.uid);
      if (isPremium || songCredits > 0) {
        navigate('/submit-song'); // Leite den Benutzer weiter, wenn er Premium ist oder Song-Credits hat
      } else {
        // Wähle zufällig ein Modal aus
        const randomModal = Math.random() < 0.5 ? 'BuyProModal' : 'ViralBuyModal';
        if (randomModal === 'BuyProModal') {
          setIsBuyProModalOpen(true);
        } else {
          setIsViralBuyModalOpen(true); // Zeige das ViralBuyModal
        }
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const userId = user.uid;
        const fetchSongs = async () => {
          const songsRef = query(databaseRef(database, 'Songs'), orderByChild('artistID'), equalTo(userId));
          const snapshot = await get(songsRef);
          if (snapshot.exists()) {
            const songsData = snapshot.val();
            const songsArray = Object.entries(songsData).map(([id, data]) => ({
              id,
              ...data,
            }));
            setSongs(songsArray.reverse()); // Hier die Reihenfolge umkehren
            setTimeout(() => setIsLoading(false), 350);
          } else {
            setSongs(null);
          }
        };

        fetchSongs();
        checkUserStatus(userId); // Überprüfe den Status des Benutzers beim Laden der Seite
      }
    });

    return () => unsubscribe();
  }, []);

  const isInPlaylist = (chartlists) => {
    return Object.values(chartlists || {}).some((status) => status === true);
  };

  function VerticalText({ text }) {
    const letters = text.split('').map((letter, index) => (
      <span key={index} className="vertical-letter">
        {letter}
      </span>
    ));

    return <div className="vertical-text">{letters}</div>;
  }

  function NomineeRibbon() {
    return (
      <div className="award-ribbon">
        <img src="../img/icon.png" alt="Nominee Ribbon" />
        <VerticalText text="NOMINEE" />
      </div>
    );
  }

  function AwardWinnerRibbon() {
    return (
      <div className="award-ribbon">
        <img src="../img/icon.png" alt="Nominee Ribbon" />
        <VerticalText text="WINNER" />
      </div>
    );
  }

  const awardCount = songs ? songs.filter((song) => song.awardDate).length : 0;
  const nominationCount = songs ? songs.filter((song) => song.nominatedDate && !song.awardDate).length : 0;
  const inReviewCount = songs ? songs.filter((song) => !song.nominatedDate && !song.awardDate && !isInPlaylist(song.chartlists)).length : 0;

  return (
    <div className="mySongs2">
      <div className="mySongsMain">
        {songToDelete && (
          <div className="delete-confirm-modal-overlay">
            <div className="delete-confirm-modal">
              <p>Are you sure you want to delete this song?</p>
              <button
                style={{ backgroundColor: 'var(--red)', color: 'white' }}
                onClick={() => deleteSong(songToDelete)}
              >
                Delete
              </button>
              <button onClick={() => setSongToDelete(null)}>Cancel</button>
            </div>
          </div>
        )}

        {songs === null ? (
          <p>No songs available</p>
        ) : songs.length > 0 ? (
          <>
            {songs.map((song) => (
              <SongItem
                key={song.id}
                song={song}
                confirmDelete={confirmDelete}
                isLoading={isLoading}
                handleImageLoaded={handleImageLoaded}
                NomineeRibbon={NomineeRibbon}
                AwardWinnerRibbon={AwardWinnerRibbon}
                SkeletonLoader={SkeletonLoader}
                isInPlaylist={isInPlaylist}
              />
            ))}
            <button className="upload-next-container" onClick={handleButtonClick}>
              <img className="plus-song-icon-3" src="./img/upload-song.webp" alt="Upload Next" />
              <p>Upload New Song</p>
              <div className="zero-credits-comp">
                {isPremium ? 'VIP' : `${songCredits} Credits`}
              </div> {/* Zeige "PRO" an, wenn der Benutzer Premium ist, ansonsten Song-Credits */}
              <div className="songs-get-vip-button">
                <p>Get VIP Pass</p>
                <img className="vip-pass-icon" src="./img/aaa_pass.webp" alt="Upload Next" />
              </div>
            </button>
          </>
        ) : (
          <p></p>
        )}
      </div>

      {/* Modals */}
      {isBuyProModalOpen && <BuyProModal onClose={() => setIsBuyProModalOpen(false)} />}
      <ViralBuyModal isOpen={isViralBuyModalOpen} onClose={() => setIsViralBuyModalOpen(false)} />
    </div>
  );
}

export default MySongs;
