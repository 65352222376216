import React from 'react';
import './EarnMoneySettings.css'; // Stellen Sie sicher, dass der Pfad korrekt ist

// Definieren der Feature-Optionen für jede payoutOption
const featuresOptions = {
  earnMoney: [
    { name: 'Fast Payment Processing', img: '/path/to/image1.png' },
    { name: 'No Minimum Payout', img: '/path/to/image2.png' },
    { name: 'Multiple Payment Methods', img: '/path/to/image3.png' },
    { name: 'Real-time Earnings Tracking', img: '/path/to/image4.png' },
    { name: 'Monthly Bonuses', img: '/path/to/image5.png' },
    { name: 'Flexible Withdrawal Dates', img: '/path/to/image6.png' }
  ],
  earnRoyalties: [
    { name: 'Lifetime Earnings', img: '/path/to/image7.png' },
    { name: 'Graduated Royalty Scale', img: '/path/to/image8.png' },
    { name: 'Royalties on Resales', img: '/path/to/image9.png' },
    { name: 'Performance Bonuses', img: '/path/to/image10.png' },
    { name: 'Transparent Reporting', img: '/path/to/image11.png' },
    { name: 'Legacy Benefits', img: '/path/to/image12.png' }
  ],
  License: [
    { name: 'Exclusive Licensing Rights', img: '/path/to/image13.png' },
    { name: 'Time-limited Exclusivity', img: '/path/to/image14.png' },
    { name: 'Geographic Exclusivity', img: '/path/to/image15.png' },
    { name: 'Product Specific License', img: '/path/to/image16.png' },
    { name: 'Custom Licensing Terms', img: '/path/to/image17.png' },
    { name: 'License Renewal Options', img: '/path/to/image18.png' }
  ]
};

const FeatureSelector = ({ payoutOption, selectedFeatures, onFeatureClick }) => {
  const features = featuresOptions[payoutOption] || [];

  return (
    <div className="create-earn-settings-features-container">
      {features.map((feature, index) => (
        <button key={index}
                className={`create-earn-settings-feature-button ${selectedFeatures.includes(feature.name) ? 'selected' : ''}`}
                onClick={() => onFeatureClick(feature)}>
          <img src={feature.img} alt={feature.name} />
          <span>{feature.name}</span>
        </button>
      ))}
    </div>
  );
};


export default FeatureSelector;
