import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const ToggleSwitch = ({ isChecked, onChange }) => {
  const [animation, setAnimation] = useState(isChecked ? 'on' : 'off');

  useEffect(() => {
    // Aktualisiere den Animationszustand nur, wenn sich `isChecked` ändert
    setAnimation(isChecked ? 'on' : 'off');
  }, [isChecked]); // Abhängigkeit von isChecked, um nur bei Änderungen zu reagieren

  const toggleSwitchAnimation = {
    off: { x: 2, backgroundColor: '#ff0000' },  // Rote Kugel, links, wenn "off"
    on: { x: 21, backgroundColor: '#41B516' }   // Blaue Kugel, rechts, wenn "on"
  };

  return (
    <div
      className="toggle-switch-3"
      onClick={onChange}
    >
      <motion.div
        style={{
          width: '17px',
          height: '17px',
          borderRadius: '50%',
          position: 'absolute'
        }}
        animate={animation}
        variants={toggleSwitchAnimation}
        transition={{ type: "spring", stiffness: 700, damping: 30 }}
      />
    </div>
  );
};

export default ToggleSwitch;
