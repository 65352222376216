import React, { useState, useEffect } from 'react';
import { ref, onValue, set } from 'firebase/database';
import { database, auth } from '../firebaseConfig';
import './ListTiktokVideo.css';
import ViralBuyModal from './ViralBuyModal'; // Importiere das Modal

function ListTikTokVideos({ selectedType, favorites }) {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showViralBuyModal, setShowViralBuyModal] = useState(false); // State für das Modal
  const [isEligible, setIsEligible] = useState(false); // State für die Berechtigung
  const videosPerPage = 6;
  const [page, setPage] = useState(0);
  const [savedVideos, setSavedVideos] = useState([]);

  const artistId = auth.currentUser?.uid; // Authentifizierter Benutzer

  useEffect(() => {
    if (!artistId) return;

    // Lade TikTok-Videos
    const videosRef = ref(database, '/Viral/tiktok/');
    onValue(videosRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        let videoList = Object.keys(data).map((key) => ({
          id: key,
          url: data[key].url,
          type: data[key].type || '',
          timestamp: data[key].timestamp || 0,
        }));

        // Sortiere nach Datum
        videoList.sort((a, b) => b.timestamp - a.timestamp);

        // Filtere nach ausgewähltem Typ, es sei denn, der Typ ist 'favorites'
        if (selectedType && selectedType !== 'favorites') {
          videoList = videoList.filter((video) => video.type === selectedType);
        }

        setVideos(videoList);
      }
      setIsLoading(false);
    });

    // Lade gespeicherte Videos
    const savedVideosRef = ref(database, `/Artists/${artistId}/viral/tiktok`);
    onValue(savedVideosRef, (snapshot) => {
      const savedData = snapshot.val() || [];
      setSavedVideos(savedData);
    });

    // Prüfe PlayViral-Status und isPremium
    const playViralStatusRef = ref(database, `/Artists/${artistId}/PlayViral/status`);
    const isPremiumRef = ref(database, `/Artists/${artistId}/isPremium`);

    onValue(playViralStatusRef, (snapshot) => {
      const playViralStatus = snapshot.val() || false;
      
      onValue(isPremiumRef, (premiumSnapshot) => {
        const isPremium = premiumSnapshot.val() || false;

        // Setze die Berechtigung basierend auf den Werten
        if (playViralStatus || isPremium) {
          setIsEligible(true); // Berechtigt
        } else {
          setIsEligible(false); // Nicht berechtigt
        }
      });
    });

    setPage(0); // Setze die Seite zurück, wenn die Komponente geladen wird
  }, [selectedType, artistId]);

  // Speichern oder Entfernen von Videos
  const toggleSaveVideo = (videoId) => {
    const savedVideosRef = ref(database, `/Artists/${artistId}/viral/tiktok`);

    if (savedVideos.includes(videoId)) {
      const updatedSavedVideos = savedVideos.filter(id => id !== videoId);
      set(savedVideosRef, updatedSavedVideos);
    } else {
      const updatedSavedVideos = [...savedVideos, videoId];
      set(savedVideosRef, updatedSavedVideos);
    }
  };

  // Weiter zur nächsten Seite
  const goToNextPage = () => {
    if (isEligible) {
      setPage((prevPage) => Math.min(prevPage + 1, Math.ceil(displayedVideos.length / videosPerPage) - 1));
      scrollToTop();
    } else {
      setShowViralBuyModal(true); // Zeige das Modal, wenn der Benutzer nicht berechtigt ist
    }
  };

  // Zurück zur vorherigen Seite
  const goToPreviousPage = () => {
    if (isEligible) {
      setPage((prevPage) => Math.max(prevPage - 1, 0));
      scrollToTop();
    } else {
      setShowViralBuyModal(true); // Zeige das Modal, wenn der Benutzer nicht berechtigt ist
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 850, behavior: 'smooth' });
  };

  const closeViralBuyModal = () => {
    setShowViralBuyModal(false); // Schließe das Modal
  };

  const displayedVideos = selectedType === 'favorites'
    ? videos.filter((video) => favorites.includes(video.id)) // Zeige nur Favoriten an
    : videos; // Zeige alle Videos basierend auf dem Typ

  return (
    <div>
      {isLoading ? (
        <div style={styles.spinnerContainer}>
          <div style={styles.spinner}></div>
        </div>
      ) : (
        <div>
          <div style={styles.pagination} className="tiktok-video-pagination">
            <button onClick={goToPreviousPage} disabled={page === 0}><img className="tiktok-video-pagination-back" src="../../img/down.svg" alt="Zurück" /></button>
            <button onClick={goToNextPage} disabled={(page + 1) * videosPerPage >= displayedVideos.length}><img src="../../img/down.svg" className="tiktok-video-pagination-next" alt="Weiter" /></button>
          </div>
          <div style={styles.videoContainer}>
            {displayedVideos.slice(page * videosPerPage, (page + 1) * videosPerPage).map((video) => (
              <TikTokVideo
                key={video.id}
                url={video.url}
                type={video.type}
                isSaved={savedVideos.includes(video.id)}
                onToggleSave={() => toggleSaveVideo(video.id)}
              />
            ))}
          </div>
          <div style={styles.pagination} className="tiktok-video-pagination">
            <button onClick={goToPreviousPage} disabled={page === 0}><img className="tiktok-video-pagination-back" src="../../img/down.svg" alt="Zurück" /></button>
            <button onClick={goToNextPage} disabled={(page + 1) * videosPerPage >= displayedVideos.length}><img src="../../img/down.svg" className="tiktok-video-pagination-next" alt="Weiter" /></button>
          </div>
        </div>
      )}

      {/* ViralBuyModal */}
      <ViralBuyModal isOpen={showViralBuyModal} onClose={closeViralBuyModal} />
    </div>
  );
}

function TikTokVideo({ url, type, isSaved, onToggleSave }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const videoId = url.match(/video\/(\d+)/)[1];

  return (
    <div style={styles.videoCard}>
      <div className="saveToggliContainer" onClick={onToggleSave}>
        {isSaved ? (
          <img src="../../img/herzibinki.svg" alt="Gespeichert" />
        ) : (
          <img src="../../img/heart.svg" alt="Nicht gespeichert" />
        )}
      </div>
      {!isLoaded && (
        <div style={styles.spinnerContainer}>
          <div style={styles.spinner}></div>
          <p className="finding-trends-text">Finding trends</p>
        </div>
      )}
      <iframe
        width="400"
        height="700"
        src={`https://www.tiktok.com/player/v1/${videoId}?controls=1&autoplay=0`}
        allow="fullscreen"
        title={`TikTok Video ${videoId}`}
        style={{ ...styles.iframe, display: isLoaded ? 'block' : 'none' }}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
}

const styles = {
  videoContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '40px',
    padding: '0px 40px',
    alignItems: 'center',
  },
  videoCard: {
    borderRadius: '15px',
    overflow: 'visible',
    backgroundColor: '#010101',
    padding: '0px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    height: 'auto',
    position: 'relative',
    aspectRatio: '9/16',
    marginTop: '30px',
    marginBottom: '30px',
  },
  saveToggleContainer: {
    position: 'absolute',
    top: '-40px',
    right: '10px',
    zIndex: 10,
    backgroundColor: 'grey',
    borderRadius: '5px',
    padding: '5px',
  },
  iframe: {
    border: 'none',
    borderRadius: '10px',
    height: '100%',
    backgroundColor: 'black',
    width: '100%',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: '20px',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '700px',
  },
  spinner: {
    border: '4px solid rgba(0, 0, 0, 0.1)',
    borderTop: '4px solid #333',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 0.5s linear infinite',
  },
};

export default ListTikTokVideos;
