import React, { useEffect, useState } from 'react';
import { ref, get, set, remove } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';
import './SaveFavoritesButton.css';
import CollabLoginPopup from '../../Pages/CollabLoginPopup';

function SaveArtistButton({ artistId, pageId }) {
  const [isFavorite, setIsFavorite] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false); // State für das Login-Popup

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user);
        checkFavorite(user.uid);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkFavorite = (userId) => {
    const favRef = ref(database, `/Artists/${userId}/favorites`);
    get(favRef).then(snapshot => {
      if (snapshot.exists()) {
        const favorites = snapshot.val();
        const favoriteIds = Object.keys(favorites);
        setIsFavorite(favoriteIds.includes(pageId));
      }
    }).catch(error => console.error("Error fetching favorites:", error));
  };

  const toggleFavorite = () => {
    if (!currentUser) {
      setLoginPopupOpen(true); // Zeige das Login-Popup, wenn der Benutzer nicht eingeloggt ist
      return;
    }

    const favRef = ref(database, `/Artists/${currentUser.uid}/favorites/${pageId}`);
    if (isFavorite) {
      remove(favRef)
        .then(() => {
          console.log("Favorite removed successfully.");
          setIsFavorite(false);
        })
        .catch(error => console.error("Failed to remove favorite:", error));
    } else {
      const newFavorite = {
        artistId: artistId,
        pageId: pageId,
        timestamp: new Date().toISOString(),
        type: 'market'
      };
      set(favRef, newFavorite)
        .then(() => {
          console.log("Favorite added successfully.");
          setIsFavorite(true);
        })
        .catch(error => console.error("Failed to add favorite:", error));
    }
  };

  return (
    <>
      <button className={`gig-save-button ${isFavorite ? 'active' : ''}`} onClick={toggleFavorite}>
        <p>{isFavorite ? 'Saved Artist' : 'Save Artist'}</p>
        <img src={isFavorite ? "../../img/herzibinki.svg" : "../../img/love.svg"} alt="Save Artist" />
      </button>
      {isLoginPopupOpen && (
        <CollabLoginPopup
          isOpen={isLoginPopupOpen}
          onClose={() => setLoginPopupOpen(false)}
        />
      )}
    </>
  );
}

export default SaveArtistButton;
