import React, { createContext, useContext, useState, useEffect } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { database } from '../firebaseConfig';

const PageDetailsContext = createContext();

export function usePageDetails() {
  return useContext(PageDetailsContext);
}

export const PageDetailsProvider = ({ children, pageId }) => {
  const [pageDetails, setPageDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const pageRef = ref(database, `Pages/${pageId}/pageData`);
    const unsubscribe = onValue(pageRef, (snapshot) => {
      if (snapshot.exists()) {
        setPageDetails(snapshot.val());
      } else {
        setError('Page details not found.');
      }
      setLoading(false);
    }, (err) => {
      console.error('Error fetching page details:', err);
      setError('Failed to fetch page details.');
      setLoading(false);
    });

    return () => unsubscribe();
  }, [pageId]); // Stelle sicher, dass dieser Hook nur ausgeführt wird, wenn sich pageId ändert

  const updatePageDetails = (updates) => {
    const pageRef = ref(database, `Pages/${pageId}/pageData`);
    update(pageRef, updates)
      .then(() => {
        console.log("Page details updated successfully.");
      })
      .catch((error) => {
        console.error('Error updating page details:', error);
      });
  };

  if (loading) {
    return <div>Loading page details...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <PageDetailsContext.Provider value={{ pageDetails, updatePageDetails }}>
      {children}
    </PageDetailsContext.Provider>
  );
};
