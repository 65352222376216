import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from "../firebaseConfig";
import './TeamAnalyticsDashboard.css';

// Define images for each category, including ViralBuyModal
const categoryImages = {
  BottomAwardsDescButton: '/img/bottom_awards.webp',
  CollabTopButton: '/img/collab_top.webp',
  CtaBigAwardsBanner: '/img/cta_big_awards.webp',
  MarketSideFilter: '/img/market_side_filter.webp',
  AwardsFullPopup: '/img/awards_full_popup.webp',
  applyButtonClicks: '/img/apply_button_clicks.webp',
  awardsCtaPopup: '/img/awards_cta_popup.webp',
  CollabLoginPopup: '/img/collab_login.webp',
  FooterSignupButton: '/img/footer_signup_button.webp',
  HomePromoMini: '/img/home_promo_mini.jpg',
  ProceedToCheckout: '/img/checkout_button.webp',
  premiumButtonClicks: '/img/premiumButtonClick.jpg',
  ViralBuyModal: '/img/viral_buy.webp', // New image for ViralBuyModal
};

const TeamAnalyticsDashboard = () => {
  const [analyticsData, setAnalyticsData] = useState([]);

  useEffect(() => {
    const analyticsRef = ref(database, '/Analytics');

    onValue(analyticsRef, (snapshot) => {
      const analyticsSnapshot = snapshot.val();
      const analyticsList = [];

      if (analyticsSnapshot) {
        // Iterate over each category node (e.g., ProceedToCheckout, ViralBuyModal, etc.)
        Object.keys(analyticsSnapshot).forEach(key => {
          const entryData = analyticsSnapshot[key];
          const entryCount = Object.keys(entryData).length;
          const variantsCount = {};

          // Depending on the category, track different fields
          Object.values(entryData).forEach(entry => {
            let variant;
            switch (key) {
              case 'BottomAwardsDescButton':
              case 'CollabTopButton':
              case 'FooterSignupButton':
              case 'HomePromoMini':
                case 'premiumButtonClicks': // Track variant for ViralBuyModal
                variant = entry.variant || 'unknown';
                break;
              case 'ProceedToCheckout':
              case 'ViralBuyModal': // Track variant for ViralBuyModal
                variant = entry.variantId || 'unknown';
                break;
              case 'CtaBigAwardsBanner':
              case 'MarketSideFilter':
              case 'AwardsFullPopup':
                variant = entry.variantId || 'unknown';
                break;
              case 'applyButtonClicks':
              case 'awardsCtaPopup':
                variant = entry.variant || 'unknown';
                break;
              case 'CollabLoginPopup':
                variant = 'login_required'; // No variants, just track login
                break;
              default:
                variant = 'unknown';
                break;
            }

            // Count occurrences of each variant
            variantsCount[variant] = (variantsCount[variant] || 0) + 1;
          });

          // Sort variants by frequency
          const sortedVariants = Object.entries(variantsCount).sort((a, b) => b[1] - a[1]);

          analyticsList.push({
            key,
            entryCount,
            sortedVariants,
          });
        });

        // Sort the analyticsList by entryCount in descending order
        analyticsList.sort((a, b) => b.entryCount - a.entryCount);
      }

      setAnalyticsData(analyticsList);
    });
  }, []);

  return (
    <div className="team-analytics-dashboard">
      <h1 className="team-analytics-dashboard-title">Apply & Sign-Up Analytics</h1>
      <div className="team-analytics-dashboard-list">
        {analyticsData.map((item) => (
          <div key={item.key} className="team-analytics-dashboard-item">
            <div className="team-analytics-dashboard-item-header">
              {/* Display category image if available */}
              {categoryImages[item.key] && (
                <img
                  src={categoryImages[item.key]}
                  alt={`${item.key} icon`}
                  className="team-analytics-dashboard-item-image"
                />
              )}
              <span className="team-analytics-dashboard-item-count">{item.entryCount} Klicks</span>
              <h2 className="team-analytics-dashboard-item-title">{item.key}</h2>
            </div>
            <ul className="team-analytics-dashboard-variants">
              {item.sortedVariants.map(([variant, count], index) => (
                <li key={index} className="team-analytics-dashboard-variant">
                  <span className="team-analytics-dashboard-variant-name">{variant}</span>: 
                  <span className="team-analytics-dashboard-variant-count">{count}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamAnalyticsDashboard;
