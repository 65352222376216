import React, { useState, useEffect, useRef } from 'react';
import Papa from 'papaparse';
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import { storage } from '../firebaseConfig'; // Importiere das Firebase-Storage-Objekt
import { saveAs } from 'file-saver'; // Korrekt importieren
import './Woody.css'; // Importiere das CSS-File

function Woody() {
  const [baseCsvData, setBaseCsvData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [removedColumns, setRemovedColumns] = useState([]);
  const [duplicateCount, setDuplicateCount] = useState(0);
  const [remainingCount, setRemainingCount] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalStep, setModalStep] = useState(1);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [message, setMessage] = useState('');
  const fileInputRef = useRef(null);  // Ref für den Dateiupload-Input

  // Basis-CSV aus Firebase laden
  useEffect(() => {
    const loadCsv = async () => {
      const csvRef = ref(storage, 'AAA_Music_Database.csv');
      try {
        const url = await getDownloadURL(csvRef);
        fetch(url)
          .then(response => response.text())
          .then(csvText => {
            Papa.parse(csvText, {
              header: true,
              complete: (results) => {
                setBaseCsvData(results.data);
              }
            });
          });
      } catch (error) {
        console.error('Fehler beim Laden der CSV-Datei:', error);
      }
    };

    loadCsv();
  }, []);

  // Neue CSV hochladen
// Neue CSV hochladen
const handleNewCsvUpload = (e) => {
    const file = e.target.files[0];

    // Check, ob die Datei existiert
    if (!file) {
        console.error('Keine Datei hochgeladen');
        return;
    }

    setUploadedFileName(file.name.split('.csv')[0]); // Dateinamen ohne Erweiterung speichern

    Papa.parse(file, {
        header: true,
        complete: (results) => {
            analyzeCsv(results.data);
            setModalIsOpen(true); // Öffne das Modal nach dem Hochladen
        }
    });
};



const analyzeCsv = (newData) => {
  // Normalisierung der Basis-CSV-Daten (alles in Kleinbuchstaben)
  const normalizedBaseCsvData = baseCsvData.map(entry => ({
    email: entry.email?.trim().toLowerCase(), // Kleinbuchstaben
    name: entry.name?.trim(),
    snippet: entry.snippet?.trim(),
  }));

  const standardColumns = ['email', 'name', 'snippet'];
  const firstEntry = newData[0] || {};

  // Identifiziere alle Spalten, die nicht zu den Standardspalten gehören
  const columnsToRemove = Object.keys(firstEntry).filter(column => !standardColumns.includes(column));

  // Bereinige die neuen Daten und normalisiere die E-Mail-Adressen
  const cleanedData = newData.map(entry => {
    let cleanedEntry = {};
    standardColumns.forEach(column => {
      cleanedEntry[column] = entry[column] || entry[column.toLowerCase()] || '';
      cleanedEntry[column] = cleanedEntry[column].trim(); // Trim
      if (column === 'email') {
        cleanedEntry[column] = cleanedEntry[column].toLowerCase(); // E-Mail in Kleinbuchstaben umwandeln
      }
    });
    return cleanedEntry;
  });

  // Doppelte Einträge finden
  const duplicates = cleanedData.filter(entry =>
    normalizedBaseCsvData.some(baseEntry => baseEntry.email === entry.email)
  );

  // Filtere die neuen Einträge, die nicht in den Basis-CSV-Daten vorhanden sind
  const filtered = cleanedData.filter(entry =>
    !normalizedBaseCsvData.some(baseEntry => baseEntry.email === entry.email)
  );

  // Zustand setzen
  setDuplicateCount(duplicates.length);
  setRemainingCount(filtered.length);
  setFilteredData(filtered);
  setRemovedColumns(columnsToRemove);
  setModalStep(1);
};

  
  // Export der gefilterten CSV-Daten
  const exportFilteredData = () => {
    const csv = Papa.unparse(filteredData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const exportFileName = `${uploadedFileName}_export.csv`; // Dynamischer Exportname
    saveAs(blob, exportFileName);

    // Weiter zum nächsten Schritt im Modal
    setModalStep(2);
  };

  // Bestätigen und Hochladen der neuen Einträge in Firebase
  const confirmAndUploadCsv = () => {
    const updatedBaseCsvData = [...baseCsvData, ...filteredData.map(entry => ({
      email: entry.email,
      name: entry.name,
      snippet: entry.snippet,
    }))];

    const updatedCsv = Papa.unparse(updatedBaseCsvData);

    // Upload der aktualisierten CSV-Datei zu Firebase
    const csvRef = ref(storage, 'AAA_Music_Database.csv');
    uploadString(csvRef, updatedCsv).then(() => {
      setMessage('Die neuen Einträge wurden erfolgreich hochgeladen und die Datei wurde aktualisiert!');
      
      // Schließen des Modals und zurücksetzen des Input-Feldes
      setModalIsOpen(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';  // Input-Field leeren
      }
    }).catch(error => {
      console.error('Fehler beim Hochladen der Datei:', error);
      setMessage('Fehler beim Hochladen der Datei');
    });

    setBaseCsvData(updatedBaseCsvData); // Intern aktualisieren
  };

  // Schließen des Modals beim Klick außerhalb
  const handleCloseModal = (e) => {
    if (e.target.classList.contains('woody-modal-overlay')) {
      setModalIsOpen(false);
    }
  };

  // Letzte 10 Einträge aus der Basis-CSV holen
  const lastTenEntries = baseCsvData.slice(-10);

  return (
    <div className="woody-container">
      <h2>Woody Component</h2>
      <p>Hier können Sie eine neue CSV-Datei hochladen und mit der Basis-CSV vergleichen.</p>
      <input 
        className="input-woody-container"
        type="file" 
        accept=".csv" 
        onChange={handleNewCsvUpload} 
        ref={fileInputRef}  // Ref dem Input-Field zuweisen
      />

      {/* Anzeige der letzten 10 Einträge und der Gesamtanzahl der Einträge */}
      <div className="woody-summary">
        <p>Anzahl der Einträge: {baseCsvData.length}</p>
        <table className="woody-summary-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Name</th>
              <th>Snippet</th>
            </tr>
          </thead>
          <tbody>
            {lastTenEntries.map((entry, index) => (
              <tr key={index}>
                <td>{entry.email}</td>
                <td>{entry.name}</td>
                <td>{entry.snippet}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {modalIsOpen && (
        <div className="woody-modal-overlay" onClick={handleCloseModal}>
          <div className="woody-modal-content">
            <button className="woody-modal-close" onClick={() => setModalIsOpen(false)}>✕</button>
            {modalStep === 1 && (
              <div>
                <h2>Analyse-Ergebnisse</h2>
                <p>Doppelte Einträge: {duplicateCount}</p>
                <p>Verbleibende Einträge: {remainingCount}</p>
                {removedColumns.length > 0 && (
                  <div>
                    <h4>Entfernte Spalten:</h4>
                    <ul>
                      {removedColumns.map((column, index) => (
                        <li key={index}>{column}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <button className="woody-modal-button" onClick={exportFilteredData}>Weiter und exportieren</button>
              </div>
            )}

            {modalStep === 2 && (
              <div>
                <h2>Neues CSV exportiert</h2>
                <p>Möchten Sie die neuen Einträge in die Basisdatei speichern?</p>
                <button className="woody-modal-button" onClick={confirmAndUploadCsv}>Bestätigen und speichern</button>
                <button className="woody-modal-button cancel-button" onClick={() => setModalIsOpen(false)}>Abbrechen</button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Woody;
