import React, { useState, useEffect } from 'react';
import { ref, push, set, get, remove, update } from 'firebase/database';
import { database } from '../../firebaseConfig';
import SettingsSingleInput from '../PageUtility/SettingsSingleInput';
import SettingsSingleTextarea from '../PageUtility/SettingsSingleTextarea';
import HeaderLabel from '../PageUtility/HeaderLabel';
import CategoryLabel from '../PageUtility/CategoryLabel';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ProjectImageUploader from '../PageUtility/ProjectImageUploader';
import './AddPageProjectSettings.css';

function AddPageProjectSettings({ pageId, widgetKey }) {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const projectsRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/projects`);
        get(projectsRef).then((snapshot) => {
            if (snapshot.exists()) {
                setProjects(Object.entries(snapshot.val()).map(([key, value]) => ({ id: key, ...value })));
            }
        });
    }, [pageId, widgetKey]);

    const validateProject = (project) => {
        return project.projectImg && project.projectTitle && project.projectText && project.projectStartDate;
    };

    const handleProjectChange = (id, field, value) => {
        setProjects(projects.map(project => project.id === id ? { ...project, [field]: value } : project));
    };

    const handleImageChange = (projectId, imageUrl) => {
        setProjects(projects.map(project =>
            project.id === projectId ? { ...project, projectImg: imageUrl } : project
        ));
    };

    const saveProjectsToDatabase = () => {
        const updates = {};
        projects.forEach(project => {
            if (!validateProject(project)) {
                return; // Skip this project or show an error
            }
            const projectRef = `Pages/${pageId}/pageData/widgets/${widgetKey}/projects/${project.id}`;
            updates[projectRef] = { ...project };
            delete updates[projectRef].id; // Remove local id before updating
        });
        update(ref(database), updates).catch(error => {
            console.error('Failed to save projects:', error);
        });
    };

    const addNewProject = () => {
        const newProject = {
            projectImg: '',
            projectTitle: '',
            projectText: '',
            projectStartDate: new Date(),
            collaborators: ''
        };
        const newRef = push(ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/projects`));
        setProjects([...projects, { id: newRef.key, ...newProject }]);
    };

    const deleteProject = (id) => {
        const projectRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/projects/${id}`);
        remove(projectRef);
        setProjects(projects.filter(project => project.id !== id));
    };

    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%'}}>
            <HeaderLabel text="My Projects" />
            <CategoryLabel text="Add Projects"/>
            {projects.length > 0 ? (
                <>
                    {projects.map((project, index) => (
                        <div style={{width: '88%', marginTop: '15px', padding:'15px 5px', borderRadius: '15px', position: 'relative', backgroundColor: 'var(--black)', display: 'flex', alignItems: 'center', flexDirection: 'column',}} key={project.id}>
                            <ProjectImageUploader
                                projectId={project.id}
                                pageId={pageId}
                                onImageChange={(url) => handleImageChange(project.id, url)}
                                initialImageUrl={project.projectImg}
                            />
                            <SettingsSingleInput
                                type="text"
                                value={project.projectTitle}
                                onChange={(e) => handleProjectChange(project.id, 'projectTitle', e.target.value)}
                                placeholder="Enter Project Title"
                                labelText="Project Title"
                            />
                            <SettingsSingleTextarea
                                value={project.projectText}
                                onChange={(e) => handleProjectChange(project.id, 'projectText', e.target.value)}
                                placeholder="Enter Project Description"
                                labelText="Project Description"
                            />
                      
                            <SettingsSingleInput
                                type="text"
                                value={project.collaborators}
                                onChange={(e) => handleProjectChange(project.id, 'collaborators', e.target.value)}
                                placeholder="Enter Collaborators"
                                labelText="Collaborators"
                            />
                            <button className="page-projects-delete" onClick={() => deleteProject(project.id)}><img src="../../img/delete.svg" alt=""/></button>
                            <label className="date-picker-label">Start of Project</label>
                            <DatePicker
                                className="react-datepicker"
                                selected={new Date(project.projectStartDate)}
                                onChange={(date) => handleProjectChange(project.id, 'projectStartDate', date)}
                                showMonthYearPicker
                         />
                        </div>
                    ))}
                    <button className="save-all-project-button" onClick={saveProjectsToDatabase}>Save Project</button>
                    <button className="add-new-project-button" onClick={addNewProject}>Add New Project</button>
                </>
            ) : (
                <div className="add-first-project" onClick={addNewProject} style={{textAlign: 'center', fontSize: '20px', border: '1px dashed #ccc', cursor: 'pointer' }}>
                  <img src="../../img/plus_icon.webp" alt="" />
                  <p> Add first Project</p> 
                </div>
            )}
        </div>
    );
}

export default AddPageProjectSettings;
