import React, { useState, useEffect, useRef } from 'react';
import { ref as databaseRef, get, query, orderByChild, equalTo } from 'firebase/database';
import { database } from "../firebaseConfig";
import './HomeNominees.css';
import './HomeHeader.css';
import playIcon from '../img/play.svg';
import pauseIcon from '../img/pause.svg';
import AudioBar from '../Audio/AudioBar';
import { Link } from 'react-router-dom';
import SpotifySongs from '../Audio/SpotifySongs';
import CountryFlagEmoji from '../Utility/CountryFlagEmoji';

function HomeNominees() {
  const [nomineeSongs, setNomineeSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [showAudioBar, setShowAudioBar] = useState(false); // Neue State-Variable
  const songRefs = useRef([]);

  useEffect(() => {
    const fetchSongs = async () => {
      const songsRef = query(databaseRef(database, 'Songs'), orderByChild('chartlists/Nominees'), equalTo(true));
      const snapshot = await get(songsRef);
      if (snapshot.exists()) {
        const songsData = snapshot.val();
        const songsArray = Object.entries(songsData).map(([id, data]) => ({
          id,
          ...data,
          artistID: data.artistID?.trim(), // Trim artistID hier, um sicherzustellen, dass es keine unsichtbaren Zeichen gibt
        })).reverse();
        setNomineeSongs(songsArray);
      }
    };
    fetchSongs();
  }, []);
  
  
  

  const togglePlayPause = (song) => {
    if (currentSong && currentSong.id === song.id && isPlaying) {
      setIsPlaying(false);
    } else {
      setCurrentSong(song);
      setIsPlaying(true);
    }
  };

  const scrollContainerByIndex = (newIndex) => {
    const container = document.querySelector('.home-nominees-grid');
    const targetElement = songRefs.current[newIndex];
  
    if (targetElement) {
      const scrollAmount = targetElement.offsetLeft - container.offsetLeft;
      container.scrollTo({ left: scrollAmount, behavior: 'smooth' });
    }
  };
  

  const nextSongs = () => {
    const newIndex = Math.min(startIndex + 1, nomineeSongs.length - 1);
    setStartIndex(newIndex);
    scrollContainerByIndex(newIndex);
  };
  
  const prevSongs = () => {
    const newIndex = Math.max(startIndex - 1, 0);
    setStartIndex(newIndex);
    scrollContainerByIndex(newIndex);
  };
  
  function VerticalText({ text }) {
    // Teilt den Text in einzelne Zeichen und wandelt ihn in ein Array von `span`-Elementen um
    const letters = text.split('').map((letter, index) => (
      <span key={index} className="vertical-letter">{letter}</span>
    ));
  
    return <div className="vertical-text">{letters}</div>;
  }
  
  // Und dann verwenden Sie die Komponente in Ihrem Ribbon so:
  <VerticalText text="NOMINEE" />
  

  return (
    <div className="home-nominees">
      <h2>Nominees</h2>
      <p>Meet the game-changers, the trendsetters, the artists who paint our sonic landscape with bold strokes and fearless beats.</p>
      <div className="home-nominees-body">
        <div className="home-nominees-left">
          <div className="home-nominees-grid">
          {nomineeSongs.slice(0, 20).map((song, index) => (
  <div ref={el => songRefs.current[index] = el} 
       key={song.id} 
       className={`home-nominee-song ${index % 3 === 0 ? 'first' : ''}`}>
    <div className="award-ribbon">
      <img src="../img/icon.png" alt="Award Ribbon" />
      <VerticalText text="NOMINEE" />
    </div>
    <div className="home-nominee-song-top">
      <Link to={`/song/${song.id}`}>
        <img src={song.songCoverUrl} alt={song.name} />
      </Link>
    </div>
    <div className="home-nominee-song-bottom">
      <Link to={`/song/${song.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <h4>{song.name}</h4>
      </Link>
      {song.artistID ? (
    <Link to={`/artist/${song.artistID}`} style={{ textDecoration: 'none', color: 'white' }}>
      <p>
        {song.artist}
        {song.selectedCountry && <CountryFlagEmoji country={song.selectedCountry} />}
      </p>
    </Link>
  ) : (
    <p style={{ color: 'white' }}>
      {song.artist}
      {song.selectedCountry && <CountryFlagEmoji country={song.selectedCountry} />}
    </p>
  )}
    </div>
    <button className="play-pause-button" 
            style={{position: 'absolute', bottom: '85px', left: '35px'}} 
            onClick={(e) => { e.stopPropagation(); togglePlayPause(song); }}>
      {currentSong && currentSong.id === song.id && isPlaying 
       ? <img src={pauseIcon} style={{marginLeft: '1px'}} className="pause-icon" alt="Pause" /> 
       : <img src={playIcon} className="play-icon" alt="Play" />}
    </button>
  </div>
))}


          </div>
          <div className="navigation-buttons">
            <button onClick={prevSongs}><img className="nav-arrow" src="../img/left.svg" alt="" /></button>
            <button onClick={nextSongs}><img className="nav-arrow" src="../img/right.svg" alt="" /></button>
          </div>
        </div>
      </div>
      {isPlaying && currentSong && (
        <div className="audio-bar-container">
          {currentSong.fileUrl ? (
            <AudioBar
              url={currentSong.fileUrl}
              songImg={currentSong.songCoverUrl || currentSong.imageUrl}
              artistName={currentSong.artist}
              songName={currentSong.name}
              isPlaying={isPlaying}
              togglePlayPause={() => setIsPlaying(false)}
            />
          ) : currentSong.spotifyLink && (
            <SpotifySongs spotifyUrl={currentSong.spotifyLink} />
          )}
        </div>
      )}
    </div>
  );
}

export default HomeNominees;
