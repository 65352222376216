import React, { useState, useEffect } from 'react';
import ThreeStepIndicator from '../Utility/ThreeStepIndicator';
import './NoAwardsScreen.css'; // Import CSS

const NoAwardsScreen = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 140); // Setzen Sie die Zeit auf 200 Millisekunden

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="tier-system">
      <div className="tier-header-top">
        <ThreeStepIndicator />
      </div>
      {isLoading ? (
        <div className="skeleton-loader-tiers"></div>
      ) : (
        <div className="tiers">
          <div className="tier">
            <img src="../img/Platte.webp" alt="Plate" />
            <div className="tier-info">
              <h2>Successfully submitted.</h2>
              <h5>Your music is in review.</h5>
              <p>Once your song gets nominated or awarded, you will receive an email notification. Please note that this process can take up to one or two weeks, depending on the number of submissions we are reviewing.</p>
            </div>
          </div>
        </div>
      )}
         <div className="tier-header">
            <p>In the Triple A Community, we have a three-tier success system. Whenever you upload a track, you can get playlisted, nominated, or awarded. Your future nominations and awards will be displayed here.</p>
          </div>
    </div>
  );
};

export default NoAwardsScreen;
