import React, { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';  // Importiere die notwendigen Firebase-Funktionen
import { auth, database } from '../../firebaseConfig';  // Importiere auth und database aus deiner Firebase-Konfiguration
import { toggleFavorite } from '../../Utility/ToggleFavorite';
import './SaveArtistButton.css';
import CollabLoginPopup from '../../Pages/CollabLoginPopup';

function SaveFavoritesButton({ artistId, pageId }) {
  const [isFavorite, setIsFavorite] = useState(false);
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false); // State für das Login-Popup

  useEffect(() => {
    const checkFavorite = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      const favRef = ref(database, `/Artists/${currentUser.uid}/favorites/${pageId}`);
      const snapshot = await get(favRef);
      setIsFavorite(snapshot.exists());
    };

    checkFavorite();
  }, [pageId]);

  const handleToggleFavorite = async () => {
    const currentUser = auth.currentUser;
    
    if (!currentUser) {
      setLoginPopupOpen(true); // Zeige das Login-Popup, wenn der Benutzer nicht eingeloggt ist
      return;
    }

    try {
      const newFavoriteStatus = await toggleFavorite(pageId, artistId);
      setIsFavorite(newFavoriteStatus);
    } catch (error) {
      console.error("Failed to toggle favorite:", error);
    }
  };

  return (
    <>
      <button className={`fav-save-button ${isFavorite ? 'active' : ''}`} onClick={handleToggleFavorite}>
        <img className="fav-save-button-img" src="../../img/love.svg" alt="Save Artist" />
      </button>
      {isLoginPopupOpen && (
        <CollabLoginPopup
          isOpen={isLoginPopupOpen}
          onClose={() => setLoginPopupOpen(false)}
        />
      )}
    </>
  );
}

export default SaveFavoritesButton;
