// HideEquipment.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HideEquipment.css'; // Stellen Sie sicher, dass Sie den Pfad zur CSS-Datei anpassen

const HideEquipment = () => {
  return (
    <div className="hide-equipment">
        <div className="hide-equipment-container">
      <img src="../img/lock.webp" alt="Equipment placeholder" className="equipment-image"/>
      <h3 className="equipment-text-1">Studio Equipment</h3>
      <p className="equipment-text">Please log in to see the equipment used in this song.</p>
     <Link style={{textDecoration: 'none'}} to="/signup">
      <div className="equipment-button">View Equipment</div>
      </Link>
      </div>

    </div>
  );
};

export default HideEquipment;
