import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Firebase Auth importieren
import { getDatabase, ref, push } from 'firebase/database'; // Firebase Database importieren
import './AwardsCtaPopup.css';

// Importing lodash to throttle the scroll event
import { throttle } from 'lodash';

const textVariants = [
  {
    header: "FREE UPLOAD",
    title: "Submit Your Song Today!",
    description: "Upload your music to AAA and win.",
    buttonText: "APPLY NOW",
    googleTag: "Variant_1",
  },
  {
    header: "JOIN NOW",
    title: "Submit Your Track Today!",
    description: "Upload your song and get noticed.",
    buttonText: "JOIN NOW",
    googleTag: "Variant_2",
  },
  {
    header: "EXCLUSIVE ACCESS",
    title: "Join the Top 1% of Musicians",
    description: "Share your music with AAA.",
    buttonText: "JOIN NOW",
    googleTag: "Variant_3",
  },
];

const AwardsCtaPopup = () => {
  const [visible, setVisible] = useState(false);
  const [popupClosed, setPopupClosed] = useState(false);
  const [textVariant, setTextVariant] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0); // Track the last scroll position

  useEffect(() => {
    const auth = getAuth();

    // Check if the user is logged in
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (isLoggedIn || popupClosed) return;

    // Throttle scroll event
    const handleScroll = throttle(() => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;


      // Show the popup when scrolling down more than 500px
      if (scrollY > 4000 && scrollY > lastScrollY) {
        if (!textVariant) {
          const randomVariant = textVariants[Math.floor(Math.random() * textVariants.length)];
          setTextVariant(randomVariant);
        }
        setVisible(true);
      }

      // Hide the popup when scrolling up
      if (scrollY < lastScrollY && visible) {
        setVisible(false);
      }

      // Hide the popup when the user is within 500px from the bottom of the page
      if (documentHeight - (scrollY + windowHeight) < 500) {
        setVisible(false);
        console.log("Popup is hidden as the user is near the end of the page");
      }

      setLastScrollY(scrollY);
    }, 200);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoggedIn, popupClosed, textVariant, visible, lastScrollY]);

  const handleClose = () => {
    setVisible(false);
    setPopupClosed(true);
    sessionStorage.setItem('cta_popup_closed', 'true');
  };

  const handleClick = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'cta_awardspage_popup_click',
        variant: textVariant.googleTag,
      });

      setTimeout(() => {
        window.open('/signup', '_blank');
      }, 300);
    }

    const db = getDatabase();
    const analyticsRef = ref(db, 'Analytics/awardsCtaPopup');
    push(analyticsRef, {
      variant: textVariant.googleTag,
      timestamp: Date.now(),
    });
  };

  if (isLoggedIn || popupClosed || !textVariant) {
    return null;
  }

  return (
    <div className="awards-cta-popup">
      <AnimatePresence>
        {visible && (
          <motion.div
            className="cta-popup-content"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{
              duration: 0.2,
              ease: [0.6, -0.05, 0.01, 0.99],
            }}
          >
            <div className="cta-popup-header">
              <span className="free-for-artists">{textVariant.header}</span>
              <button className="cta-close-btn" onClick={handleClose}>
                <img src="./img/delete.svg" alt="Close" />
              </button>
            </div>
            <div className="cta-popup-centre">
              <div className="cta-popup-body">
                <h4>{textVariant.title}</h4>
                <p>{textVariant.description}</p>
              </div>
              <div className="cta-popup-action">
                <button className="cta-apply-btn" onClick={handleClick}>
                  {textVariant.buttonText}
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AwardsCtaPopup;
