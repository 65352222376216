import React, { useState, useEffect } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { database } from '../../firebaseConfig';
import SettingsSingleInput from '../PageUtility/SettingsSingleInput';
import SettingsSingleTextarea from '../PageUtility/SettingsSingleTextarea';
import InputNumberField from '../PageUtility/InputNumberField';
import ServiceFeaturesSelector from '../PageUtility/ServiceFeatureSelector';
import HeaderLabel from '../PageUtility/HeaderLabel';
import CategoryLabel from '../PageUtility/CategoryLabel';
import PriceInputField from '../PageUtility/PriceInputField';
import SavePaymentOptions from '../PageUtility/SavePaymentOptions'; // Import the component
import './TheGigSettings.css';

function TheGigSettings({ pageId, gigData, pageData }) {
    const [gigTitle, setGigTitle] = useState(gigData?.gigTitle || '');
    const [gigText, setGigText] = useState(gigData?.gigText || '');
    const [earnAmount, setEarnAmount] = useState(gigData?.earnAmount || 0);
    const [selectedService, setSelectedService] = useState('');
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [gigRevisions, setGigRevisions] = useState(gigData?.gigRevisions || '');
    const [gigTime, setGigTime] = useState(gigData?.gigTime || '');
    const [changesMade, setChangesMade] = useState(false);
    const [payoutOption, setPayoutOption] = useState(pageData?.payoutOption || 'earnMoney');

    // State for SavePaymentOptions
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [moneyBackGuarantee, setMoneyBackGuarantee] = useState(false);

    useEffect(() => {
        if (pageId) {
            const serviceRef = ref(database, `Pages/${pageId}/pageData/service`);
            const featuresRef = ref(database, `Pages/${pageId}/pageData/offers/0/features`);

            onValue(serviceRef, (snapshot) => {
                setSelectedService(snapshot.val() || '');
            });

            onValue(featuresRef, (snapshot) => {
                setSelectedFeatures(snapshot.val() || []);
            });

            const paymentOptionsRef = ref(database, `Pages/${pageId}/pageData`);
            onValue(paymentOptionsRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setSelectedOptions(data.selectedPaymentOptions || []);
                    setMoneyBackGuarantee(data.moneyBackGuarantee || false);
                }
            });
        }
    }, [pageId]);

    const handleSave = () => {
        const updates = {};
        updates[`Pages/${pageId}/pageData/offers/0/gigTitle`] = gigTitle;
        updates[`Pages/${pageId}/pageData/offers/0/gigText`] = gigText;
        if (payoutOption !== 'collaborate') {
            updates[`Pages/${pageId}/pageData/offers/0/earnAmount`] = earnAmount;
        }
        updates[`Pages/${pageId}/pageData/offers/0/gigRevisions`] = gigRevisions;
        updates[`Pages/${pageId}/pageData/offers/0/gigTime`] = gigTime;
        updates[`Pages/${pageId}/pageData/offers/0/features`] = selectedFeatures;

        // Save payment options and money back guarantee
        updates[`Pages/${pageId}/pageData/selectedPaymentOptions`] = selectedOptions;
        updates[`Pages/${pageId}/pageData/moneyBackGuarantee`] = moneyBackGuarantee;

        update(ref(database), updates).then(() => {
            setChangesMade(false); // Reset changes indicator after saving
        }).catch(error => {
            console.error('Failed to update database:', error);
        });
    };

    const handleChange = (setter) => (event) => {
        setter(event.target.value);
        setChangesMade(true); // Set changes made to true
    };

    return (
        <div style={{ marginBottom: '60px' }}>
            <HeaderLabel text="Your Offer" />
            <CategoryLabel text="Basic Settings" />
            {payoutOption !== 'collaborate' && (
                payoutOption === 'earnRoyalties' ? (
                    <InputNumberField
                        value={earnAmount}
                        onChange={handleChange(setEarnAmount)}
                        placeholder="Enter Percentage"
                        labelText="Percentage"
                        imgSrc="../../img/prozent.svg"
                        max={100}
                        min={1}
                    />
                ) : (
                    <PriceInputField
                        value={earnAmount}
                        onChange={handleChange(setEarnAmount)}
                        placeholder="Enter Price"
                        labelText="Price"
                        imgSrc="../../img/dollar.svg"
                    />
                )
            )}
            <InputNumberField
                value={gigRevisions}
                onChange={handleChange(setGigRevisions)}
                placeholder="Number of Revisions"
                labelText="Revisions"
                imgSrc="../../img/infinity.svg"
                min={1}
            />
            <InputNumberField
                value={gigTime}
                onChange={handleChange(setGigTime)}
                placeholder="Estimated Time (in days)"
                labelText="Delivery Time"
                imgSrc="../../img/rush.svg"
                min={1}
            />
            <SettingsSingleInput
                value={gigTitle}
                onChange={handleChange(setGigTitle)}
                placeholder="Enter Gig Title"
                labelText="What do you offer?"
            />
            <SettingsSingleTextarea
                value={gigText}
                onChange={handleChange(setGigText)}
                placeholder="Enter Gig Text"
                labelText="Describe the offer:"
                maxLength={120}
            />
            <CategoryLabel text="Special Features" />
            <ServiceFeaturesSelector
                serviceType={selectedService}
                selectedFeatures={selectedFeatures}
                onFeatureToggle={(feature) => {
                    const newSelectedFeatures = selectedFeatures.includes(feature)
                        ? selectedFeatures.filter(f => f !== feature)
                        : [...selectedFeatures, feature];
                    setSelectedFeatures(newSelectedFeatures);
                    setChangesMade(true); // Update changes on toggle features
                }}
            />
            {/* SavePaymentOptions component */}
            <SavePaymentOptions
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                moneyBackGuarantee={moneyBackGuarantee}
                setMoneyBackGuarantee={setMoneyBackGuarantee}
                setChangesMade={setChangesMade} // Pass the setChangesMade function
            />
            {changesMade && <button className="gig-settings-save-button" style={{ position: 'fixed' }} onClick={handleSave}>Save Settings</button>}
        </div>
    );
}

export default TheGigSettings;
