import React, { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import CreateRoute from './CreateRoute';
import ExperienceAndGenreSelection from './ExperienceAndGenreSelection';
import ServiceSelection from './ServiceSelection';
import SelectPayout from './SelectPayout';
import EarnMoneySettings from './EarnMoneySettings';
import EarnRoyalties from './EarnRoyalties';
import AutoGigFill from './AutoGigFill';
import './CreatePage.css';
import { useNavigate, useParams, Link } from 'react-router-dom';
import MoodSelection from './MoodsSelection';
import SelectContactSettings from './SelectContactSettings';
import GenerativeOrbAnimation2 from '../../Utility/GenerativeOrbAnimation2';
import GenerativeOrbAnimation5 from '../../Utility/GenerativeOrbAnimation5';
import IndustryMoods from './IndustryMoods'; // Stellen Sie sicher, dass Sie diesen Import hinzufügen
import ThankYouStep from './ThankYouStep';

function CreatePage() {
    const { pageId: urlPageId } = useParams(); // pageId aus der URL holen, wenn vorhanden
    const [pageId, setPageId] = useState(urlPageId); // Zustand für pageId
    const [artistID, setArtistID] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [step, setStep] = useState(pageId ? 2 : 1); // Starten bei Schritt 2, wenn pageId existiert
    const [selectedService, setSelectedService] = useState('');
    const [selectedServiceType, setSelectedServiceType] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setArtistID(user.uid);
                const artistRef = ref(database, `Artists/${user.uid}`);
                get(artistRef).then((snapshot) => {
                    if (snapshot.exists()) {
                        const artistData = snapshot.val();
                        if (!artistData.hasSettings && !artistData.hasExpertSettings) {
                            navigate('/dashboard'); // Weiterleitung zu /dashboard, wenn keine Berechtigungen vorhanden sind
                        }
                    } else {
                        navigate('/dashboard'); // Weiterleitung zu /dashboard, wenn keine Daten gefunden wurden
                    }
                }).catch(error => {
                    console.error('Failed to fetch artist data:', error);
                    navigate('/dashboard'); // Weiterleitung zu /dashboard bei Fehler
                });
            } else {
                setErrorMessage("No user is signed in.");
                setArtistID(null);
                navigate('/dashboard'); // Weiterleitung zu /dashboard, wenn kein Benutzer angemeldet ist
            }
        });

        return () => unsubscribe(); // Cleanup the subscription
    }, [navigate]);

    
    const handleSuccess = (newPageId) => {
        setPageId(newPageId); // pageId im Zustand speichern
        setStep(2); // Zum zweiten Schritt weitergehen
        navigate(`/create-page/${newPageId}`); // Navigation zur Seite mit pageId
      };
    

    const handleGoBack = () => {
        if (step > 2 && step < 9) {
            setStep(step - 1);
        }
    };

    document.addEventListener("DOMContentLoaded", function() {
        var createPageWindow = document.querySelector(".create-page-window");
        createPageWindow.classList.add("fade-in");
    });
    

    const handleError = (message) => {
        setErrorMessage(message);
      };
    
      const handleSelectedService = (serviceName, serviceType) => {
        setSelectedService(serviceName);  // Speichern des Dienstnamens
        setSelectedServiceType(serviceType);  // Speichern des Diensttyps
    };
      
    useEffect(() => {
        if (pageId) {
            const pageDataRef = ref(database, `Pages/${pageId}/pageData`);
            get(pageDataRef).then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setSelectedServiceType(data.serviceType);  // Setze den ServiceType basierend auf der Datenbank
                }
            }).catch(error => {
                console.error('Failed to fetch page data:', error);
                setErrorMessage('Failed to fetch page data');
            });
        }
    }, [pageId]);

      const handleStepCompletion = () => {
        if (!isNextDisabled) {
          setStep(step + 1);
          setErrorMessage(''); // Fehlermeldung zurücksetzen beim Fortschreiten
        }
      };

    const handleAutoGigFillSuccess = () => {
        setStep(9); // Gehe zu Schritt 7 (Dankesseite)
    };
    

    const handleDisableNextButton = (disable) => {
        setIsNextDisabled(disable);
      };


    useEffect(() => {
        if (step === 9) {
            const timer = setTimeout(() => {
                navigate(`/setting/page/${pageId}`); // Weiterleitung zu /settings/page/:pageId
            }, 1000); // Warte 3 Sekunden, bevor weitergeleitet wird

            return () => clearTimeout(timer); // Cleanup der Timer-Ressource
        }
    }, [step, pageId, navigate]);

    return (
        <div className="create-page-container">
                              <GenerativeOrbAnimation5 />
                              <div className="song-stepper-header">
            <img src="../../img/logo.svg" alt="" />
            <Link to="/pages">
            <div>
                <p style={{fontSize: '14px', marginTop: '17px'}}></p>
                    <img src="../../img/delete.svg" alt="" />
                    </div>
                </Link>
                        </div>
            <div className="create-page-window">
                { /* Deine Schritte hier */ }
                {step === 1 && <CreateRoute onError={handleError} onSuccess={handleSuccess} />}
                {step === 2 && <ServiceSelection pageId={pageId} onSuccess={handleStepCompletion}     onServiceSelected={handleSelectedService}  onDisableNext={handleDisableNextButton} onError={handleError} />}
                {step === 3 && <ExperienceAndGenreSelection pageId={pageId} onError={handleError} onSuccess={handleStepCompletion} onDisableNext={handleDisableNextButton} />}
                {step === 4 && (
    selectedServiceType === 'Music Industry' ? 
    <IndustryMoods pageId={pageId} onSuccess={handleStepCompletion} onError={handleError} onDisableNext={handleDisableNextButton} /> : 
    <MoodSelection pageId={pageId} onSuccess={handleStepCompletion} onError={handleError} onDisableNext={handleDisableNextButton} />
)}
 {step === 5 && <SelectPayout pageId={pageId} onSuccess={handleStepCompletion} onError={handleError} onDisableNext={handleDisableNextButton}  />}
                {step === 6 && <EarnMoneySettings pageId={pageId} onSuccess={handleStepCompletion} onError={handleError} onDisableNext={handleDisableNextButton}  />}
                {step === 7 && <SelectContactSettings pageId={pageId} onSuccess={handleStepCompletion} onError={handleError} onDisableNext={handleDisableNextButton}  />}
                {step === 8 && <AutoGigFill artistID={artistID} pageId={pageId} onSuccess={handleAutoGigFillSuccess}  />}
                {step === 9 && <ThankYouStep />}
                <div className="next-button-container">
                {step > 1 && step < 8 &&   (
                    <button onClick={handleStepCompletion} className="create-page-next-button" disabled={isNextDisabled}>Next</button>
                )}                
  {step > 2 && step < 9 && (
                    <button onClick={handleGoBack} className="gig-settings-back-button">Back</button>
                )}
                <div className="create-bottom-gradient"></div>
          </div>
          
            </div>
        </div>
    );
}

export default CreatePage;
