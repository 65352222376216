import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { ref as databaseRef, onValue, get, remove } from 'firebase/database';
import { database } from "../firebaseConfig";
import './ListSongs.css';
import AddPublicationModal from './AddPublicationModal';
import AddEditorModal from './AddEditorModal';
import PlaylistStatus from './PlaylistStatus';
import ChartlistAndAwardManager from './ChartlistAndAwardManager';
import RetargetingModal from './RetargetingModal';
import checkIcon from '../img/check.svg';
import reviewIcon from '../img/right.svg';

function ListSongs() {
  const [songs, setSongs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSongId, setCurrentSongId] = useState(null);
  const [isRetargetingModalOpen, setIsRetargetingModalOpen] = useState(false);
  const [isPublicationModalOpen, setIsPublicationModalOpen] = useState(false);
  const [selectedSongId, setSelectedSongId] = useState(null);
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(30);
  const [retargetingStatuses, setRetargetingStatuses] = useState({});
  
  
  useEffect(() => {
    const fetchSongs = async () => {
      const songsRef = databaseRef(database, 'Songs');
      const unsubscribe = onValue(songsRef, (snapshot) => {
        const songsData = snapshot.val();
        const songsList = songsData
          ? Object.keys(songsData).map((key) => ({
              id: key,
              ...songsData[key],
            }))
          : [];
        songsList.sort((a, b) => new Date(b.releaseTimestamp) - new Date(a.releaseTimestamp));
        setSongs(songsList);
      });

      return () => unsubscribe();
    };

    fetchSongs();
  }, []);

  // Stabilisierung der Pagination-Logik mit useMemo
  const currentSongs = useMemo(() => {
    const indexOfLastSong = Math.min(currentPage * itemsPerPage, songs.length);
    const indexOfFirstSong = Math.max(indexOfLastSong - itemsPerPage, 0);
    return songs.slice(indexOfFirstSong, indexOfLastSong);
  }, [songs, currentPage, itemsPerPage]);

  // Stabilisierung von fetchRetargetingStatuses mit useCallback
  const fetchRetargetingStatuses = useCallback(async () => {
    const newStatuses = {};
    try {
      for (const song of currentSongs) {
        if (!song.id || !song.artistID) continue;

        const artistRef = databaseRef(database, `Artists/${song.artistID}/RetargetingButtonPressed`);
        const artistSnapshot = await get(artistRef);
        newStatuses[song.artistID] = artistSnapshot.exists() && artistSnapshot.val() === true;
      }
      setRetargetingStatuses(newStatuses);
    } catch (error) {
      console.error("Fehler beim Abrufen des Retargeting-Status:", error);
    }
  }, [currentSongs]);

  // Retargeting-Status nur bei Änderungen von currentSongs laden
  useEffect(() => {
    if (currentSongs.length > 0) {
      fetchRetargetingStatuses();
    } else {
      setRetargetingStatuses({});
    }
  }, [fetchRetargetingStatuses]);

  const paginate = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > Math.ceil(songs.length / itemsPerPage)) {
      console.warn("Ungültige Seitenzahl:", pageNumber);
      return;
    }
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(songs.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }


  const openModal = (songId) => {
    setCurrentSongId(songId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentSongId(null);
  };

  const openRetargetingModal = (songId) => {
    setCurrentSongId(songId);
    setIsRetargetingModalOpen(true);
  };

  const closeRetargetingModal = () => {
    setIsRetargetingModalOpen(false);
    setCurrentSongId(null);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('caam-modal-overlay')) {
      closeModal();
      closeRetargetingModal();
    }
  };

  const openPublicationModal = (songId) => {
    setSelectedSongId(songId);
    setIsPublicationModalOpen(true);
  };

  const closePublicationModal = () => {
    setIsPublicationModalOpen(false);
  };

  const openEditorModal = (songId) => {
    setSelectedSongId(songId);
    setIsEditorModalOpen(true);
  };

  const closeEditorModal = () => {
    setIsEditorModalOpen(false);
  };

  const deleteSong = (songId) => {
    const isConfirmed = window.confirm(
      'Sind Sie sicher, dass Sie diesen Song löschen möchten?'
    );
    if (isConfirmed) {
      const songRef = databaseRef(database, `Songs/${songId}`);
      remove(songRef)
        .then(() => {
          console.log(`Song ${songId} erfolgreich gelöscht.`);
        })
        .catch((error) => {
          console.error('Fehler beim Löschen des Songs: ', error);
        });
    }
  };

  return (
    <div className="list-songs-container">
      <h1>Songliste</h1>
      <p>Gesamtanzahl der Einträge: {songs.length}</p>
      <div className="table-scroll-container">
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li key={number} className="page-item">
              <button onClick={() => paginate(number)} className="page-link">
                {number}
              </button>
            </li>
          ))}
        </ul>
        <AddPublicationModal
          isOpen={isPublicationModalOpen}
          onClose={closePublicationModal}
          songId={selectedSongId}
        />
        <AddEditorModal
          isOpen={isEditorModalOpen}
          onClose={closeEditorModal}
          songId={selectedSongId}
        />
        <table className="list-songs-table">
          <thead>
            <tr>
              <th className="list-songs-name-field">Name</th>
              <th>Künstler</th>
              <th>Genre</th>
              <th>Timestamp</th>
              <th>Chartlist</th>
              <th>Manage Chartlist & Award</th>
              <th>Retargeting</th>
              <th>Song-Link</th>
              <th>Add Magazine</th>
              <th>Add Editor Quote</th>
              <th>Löschen</th>
            </tr>
          </thead>
          <tbody>
            {currentSongs.map((song) => {
              const artistID = song.artistID;
              const isRetargetingPressed = retargetingStatuses[artistID] || false;

              return (
                <tr key={song.id}>
                  <td>{song.name}</td>
                  <td>{song.artist}</td>
                  <td>{song.genre}</td>
                  <td>{new Date(song.releaseTimestamp).toLocaleDateString('de-DE')}</td>
                  <td>
                    <PlaylistStatus chartlists={song.chartlists || {}} />
                  </td>
                  <td>
                    {song.chartlists && Object.keys(song.chartlists).length > 0 ? (
                      <button onClick={() => openModal(song.id)} className="manage-chartlist-button-review">
                        Reviewed <img src={checkIcon} alt="Reviewed Icon" />
                      </button>
                    ) : (
                      <button onClick={() => openModal(song.id)} className="manage-chartlist-button">
                        Review Song <img src={reviewIcon} alt="Review Icon" />
                      </button>
                    )}
                    {isModalOpen && currentSongId === song.id && (
                      <div className="caam-modal-overlay" onClick={handleOverlayClick}>
                        <div className="caam-modal-content" onClick={(e) => e.stopPropagation()}>
                          <ChartlistAndAwardManager
                            songId={song.id}
                            chartlists={song.chartlists}
                            isNominated={song.isNominated}
                            hasAward={song.hasAward}
                            closeModal={closeModal}
                          />
                        </div>
                      </div>
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => openRetargetingModal(song.id)}
                      className={`retargeting-button ${isRetargetingPressed ? 'disabled' : ''}`}
                      disabled={isRetargetingPressed}
                    >
                      {isRetargetingPressed ? 'Retargeted' : 'Add Retargeting'}
                    </button>
                    {isRetargetingModalOpen && currentSongId === song.id && (
                      <RetargetingModal
                        isOpen={isRetargetingModalOpen}
                        isNominated={song.isNominated}
                        hasAward={song.hasAward}
                        onClose={closeRetargetingModal}
                        songId={song.id}
                        handleOverlayClick={handleOverlayClick}
                      />
                    )}
                  </td>
                  <td>
                    <a href={`/song/${song.id}`} target="_blank" rel="noopener noreferrer">
                      Song ansehen
                    </a>
                  </td>
                  <td>
                    <button id="add-table-button-2" onClick={() => openPublicationModal(song.id)}>
                      Magazines
                    </button>
                  </td>
                  <td>
                    <button id="add-table-button-3" onClick={() => openEditorModal(song.id)}>
                      Editor Quote
                    </button>
                  </td>
                  <td>
                    <button id="delete-table-button" onClick={() => deleteSong(song.id)}>
                      Löschen
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ListSongs;
