import { ref, set, remove, get } from 'firebase/database';
import { auth, database } from '../firebaseConfig'; // Pfad anpassen

export const toggleFavorite = async (pageId, artistId) => {
  const currentUser = auth.currentUser;
  if (!currentUser) throw new Error("User is not authenticated");

  const favRef = ref(database, `/Artists/${currentUser.uid}/favorites/${pageId}`);

  const snapshot = await get(favRef);
  if (snapshot.exists()) {
    await remove(favRef);
    return false; // Favorit wurde entfernt
  } else {
    const newFavorite = {
      artistId: artistId,
      pageId: pageId,
      timestamp: new Date().toISOString(),
      type: 'market',
    };
    await set(favRef, newFavorite);
    return true; // Favorit wurde hinzugefügt
  }
};
