import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import ArtistOTD from './ArtistOTD';
import HomeAwards from './HomeAwards';
import HomeBanner from './HomeBanner';
import HomeCharts from './HomeCharts';
import HomeCounter from './HomeCounter';
import HomeNewHeader from './HomeNewHeader';
import HomeInfo from './HomeInfo';
import HomeNominees from './HomeNominees';
import Footer from './Footer';
import HomeEditor from './HomeEditor';
import DashboardMobileNav from '../UserBackend/DashboardMobileNav';
import NewMobileHeader from './NewMobileHeader';
import HomeInternational from './HomeInternational';
import SongUploadAlert from './SongUploadAlert';
import HomePromoBanner1 from './HomePromoBanner1';
import AwardsCtaPopup from './AwardsCtaPopup';
import LastChancePopup from './LastChancePopup'; // Popup importieren
import FAQPage from './FAQPage';
import HomeBestVocals from './HomeBestVocals';
import HomeBestProduction from './HomeBestProduction';
import HomePromoBannerMini from './HomePromoBannerMini';
import HomePromoBanner2 from './HomePromoBanner2';
import ListTikTokVideos from './ListTiktokVideos';
import JuryInfo from './JuryInfo';

function HomePage() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      // Prüfe, ob die Maus sich in der linken oberen Ecke befindet
      if (e.clientX < 50 && e.clientY < 50) {
        setShowPopup(true);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      <HomeNewHeader />
      <NewMobileHeader />
      <DashboardMobileNav />
      <HomeCounter />
      <HomeBanner />
      <ArtistOTD />
      <HomeNominees />
      <HomeAwards />
      <HomeCharts />
      <HomeEditor />
      <HomePromoBanner1 />
      <HomeInternational />
      <HomeInfo />
      <SongUploadAlert />
      <AwardsCtaPopup />
      <HomeBestVocals />
      <HomeBestProduction />
      <JuryInfo />
      <FAQPage />
      <HomePromoBannerMini />
      <Footer />

      {showPopup && <LastChancePopup onClose={handleClosePopup} />}
    </div>
  );
}

export default HomePage;
