import React, { useEffect, useState } from 'react';
import { ref as databaseRef, onValue, remove } from 'firebase/database';
import { database } from "../firebaseConfig";
import './ListArtists.css'; // Stellen Sie sicher, dass der Pfad korrekt ist
import EditArtistModal from './EditArtistModal';
import ArtistStats from './ArtistStats';

function ListExperts() {
  const [artists, setArtists] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentArtistId, setCurrentArtistId] = useState(null);
  
  const openEditModal = (artistId) => {
    setCurrentArtistId(artistId);
    setIsEditModalOpen(true);
  };

  const deleteArtist = (artistId) => {
    // Bestätigungsdialog anzeigen, um versehentliches Löschen zu verhindern
    const isConfirmed = window.confirm("Sind Sie sicher, dass Sie diesen Künstler löschen möchten?");
    if (isConfirmed) {
      const artistRef = databaseRef(database, `Artists/${artistId}`);
      remove(artistRef)
        .then(() => {
          console.log(`Künstler ${artistId} erfolgreich gelöscht.`);
          // Optional: Benachrichtigen Sie den Benutzer über den erfolgreichen Löschvorgang
        })
        .catch((error) => {
          console.error("Fehler beim Löschen des Künstlers: ", error);
          // Optional: Fehlerbehandlung
        });
    }
  };
  
  
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentArtistId(null); // Optional: Setzen Sie die aktuelle Künstler-ID zurück
  };

  
  useEffect(() => {
    const artistsRef = databaseRef(database, 'Artists');
    onValue(artistsRef, (snapshot) => {
      const artistsData = snapshot.val();
      let artistsList = artistsData ? Object.keys(artistsData).map(key => ({
        id: key,
        ...artistsData[key],
      })) : [];
  
      // Filtere die Künstler, die als Experten markiert sind
      artistsList = artistsList.filter(artist => artist.isExpert === true);
  
      // Sortiere die Künstler nach dem signupTimestamp in absteigender Reihenfolge
      artistsList.sort((a, b) => {
        return new Date(b.signupTimestamp) - new Date(a.signupTimestamp);
      });
  
      setArtists(artistsList);
    });
  }, []);
  
  
  
  
  return (
    <div className="list-artists-container">
      <h1>Experten Liste</h1>
      <div className="table-scroll-container">
      <table className="list-artists-table">
        <thead>
          <tr>
          {isEditModalOpen && <EditArtistModal artistId={currentArtistId} onClose={closeEditModal} />}
            <th>Edit Artist</th>
            <th>Artist Image</th>
            <th>Logo Image</th>
            <th>Firmenname</th>
            <th>Name</th>
            <th>Land</th>
            <th>Email</th>
            <th>Approved</th>
            <th>Premium</th>
            <th>Letzter Zugriff</th>
            <th>Letztes Login</th>
            <th>Experience</th>
            <th>Type</th>
            <th>Discover Talent</th>
            <th>Paid Services</th>
            <th>Review Artists</th>
            <th>Mentorship</th>
            <th>Sitzungen</th>
            <th>Registrierungsdatum</th>
            <th>ID</th> {/* Hinzugefügte Spalte für die ID */}
            <th>Löschen</th>
          </tr>
        </thead>
        <tbody>
          {artists.map((artist) => (
            
            <tr key={artist.id}>
                <td>
    <button onClick={() => openEditModal(artist.id)}>Edit Artist</button>
  </td>
  <td><img src={artist.artistImageUrl} alt="" className="expert-mini-img"/></td>
  <td><img src={artist.companyLogoUrl} alt="" className="expert-mini-img"/></td>
              <td>{artist.artistName}</td>
              <td>{artist.name}</td>
              <td>{artist.country}</td>
              <td>{artist.email}</td>
              <td>{artist.isApproved ? 'Ja' : 'Nein'}</td>
              <td>{artist.isPremium ? 'Ja' : 'Nein'}</td>
              <td>{artist.lastAccess}</td>
              <td>{artist.lastLogin}</td>
              <td>{artist.experience}</td>
              <td>{artist.expertType}</td>
              <td>{artist.discoverTalent ? 'Ja' : 'Nein'}</td>
              <td>{artist.paidServices ? 'Ja' : 'Nein'}</td>
              <td>{artist.reviewArtists ? 'Ja' : 'Nein'}</td>
              <td>{artist.offerMentorship ? 'Ja' : 'Nein'}</td>
              <td>{artist.sessionCount}</td>
              <td>{artist.signupTimestamp}</td>
              <td>{artist.id}</td> {/* Anzeige der Künstler-ID */}
              <td><button onClick={() => deleteArtist(artist.id)} style={{ marginLeft: "10px"}}>Löschen</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
  
}

export default ListExperts;
