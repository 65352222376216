import React from 'react';
import './UtilitySettings.css'

function SettingsSingleInput({ value, onChange, placeholder, labelText }) {
    return (
        <div className="settings-single-input-container">
            {labelText && <label className="settings-single-input-label">{labelText}</label>}
            <input
                type="text"
                className="settings-single-input-field"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        </div>
    );
}

export default SettingsSingleInput;
