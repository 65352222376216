import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { database } from "../../firebaseConfig";
import { ref, onValue, push } from "firebase/database";
import CoinTables from "./CoinTables";
import "./CoinAnalysis.css";

const TokenStats = ({ coinData, tokenAddress }) => (
  <div className="coin-analysis-dashboard-card">
    <h2>Token Stats</h2>
    <div className="coin-analysis-data-grid">
      <div>
        <strong>Token Name:</strong> {coinData.tokenName}
      </div>
      <div>
        <strong>Market Cap:</strong> ${coinData.marketCap.toLocaleString()}
      </div>
      <div>
        <strong>24h Volume:</strong> ${coinData.volume.toLocaleString()}
      </div>
      <div>
        <strong>Holders:</strong> {coinData.holders.toLocaleString()}
      </div>
      <div>
        <strong>Current Score:</strong> {coinData.score}
      </div>
      <div>
        <strong>Dexscreener Link:</strong>{" "}
        <a
          href={`https://dexscreener.com/solana/${tokenAddress}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Dexscreener
        </a>
      </div>
    </div>
  </div>
);

const DetailedEntries = ({ analysisHistory }) => (
  <div className="coin-analysis-dashboard-card">
    <h2>Detailed Analysis Entries</h2>
    {analysisHistory.length > 0 ? (
      <div className="coin-analysis-data-grid">
        {analysisHistory.map(({ timestamp, analysis, holders }, index) => (
          <div key={index} className="coin-analysis-entry">
            <p>
              <strong>Time:</strong>{" "}
              {new Date(timestamp).toLocaleTimeString("en-US")}
            </p>
            <p>
              <strong>Transaction Change:</strong> {analysis.transactionChange}
            </p>
            <p>
              <strong>Average Value Per Holder:</strong>{" "}
              {analysis.averageValuePerHolder}
            </p>
            <p>
              <strong>Liquidity to Volume Ratio:</strong>{" "}
              {analysis.liquidityToVolumeRatio}
            </p>
            <p>
              <strong>Holders:</strong> {holders.toLocaleString()}
            </p>
          </div>
        ))}
      </div>
    ) : (
      <p>No entries available.</p>
    )}
  </div>
);

const CoinAnalysis = () => {
  const { tokenAddress } = useParams();
  const [analysisHistory, setAnalysisHistory] = useState([]);
  const [coinData, setCoinData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!tokenAddress) {
      console.error("Token address is missing in the URL!");
      return;
    }

    const coinRef = ref(database, `IA/coins/${tokenAddress}/coinAnalysis`);
    onValue(coinRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const analyses = Object.entries(data)
          .map(([timestamp, entry]) => ({
            timestamp: parseInt(timestamp),
            analysis: entry.analysis
              ? {
                  transactionChange: Math.round(entry.analysis.transactionChange || 0),
                  averageValuePerHolder: Math.round(entry.analysis.averageValuePerHolder || 0),
                  liquidityToVolumeRatio: parseFloat(
                    (entry.analysis.liquidityToVolumeRatio || 0).toFixed(3)
                  ),
                  score: entry.analysis.score || 0,
                }
              : null,
            dexscreener: entry.dexscreener,
            solscan: entry.solscan,
            holders: entry.solscan?.total_holders || 0, // Anzahl der Holder
          }))
          .filter((entry) => entry.analysis !== null);

        const sortedAnalyses = analyses.sort((a, b) => b.timestamp - a.timestamp);
        setAnalysisHistory(sortedAnalyses);

        if (sortedAnalyses.length > 0) {
          const latestData = sortedAnalyses[0];
          setCoinData({
            tokenName: latestData.dexscreener?.base_token || "Unknown Token",
            marketCap: latestData.dexscreener?.dex_market_cap || 0,
            volume: latestData.dexscreener?.dex_volume?.h24 || 0,
            holders: latestData.solscan?.total_holders || 0,
            score: latestData.analysis.score || 0,
          });
        }
      }
    });
  }, [tokenAddress]);

  const handleAnalyze = async () => {
    setLoading(true);
    try {
      const tokenName = coinData?.tokenName || "Unknown Token";

      const response = await fetch(
        "https://us-central1-aaartistsdev.cloudfunctions.net/analyzeToken",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ tokenAddress, tokenName }),
        }
      );

      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      const data = await response.json();

      if (data.analysis && data.dexscreener && data.solscan) {
        const timestamp = Date.now();
        const newAnalysisRef = ref(database, `IA/coins/${tokenAddress}/coinAnalysis/${timestamp}`);
        await push(newAnalysisRef, {
          analysis: data.analysis,
          dexscreener: data.dexscreener,
          solscan: data.solscan,
        });

        setAnalysisHistory((prev) => [
          {
            timestamp,
            analysis: data.analysis,
            dexscreener: data.dexscreener,
            solscan: data.solscan,
            holders: data.solscan?.total_holders || 0,
          },
          ...prev,
        ]);
      }
    } catch (error) {
      console.error("Error during analysis:", error);
    } finally {
      setLoading(false);
    }
  };

  const labels = analysisHistory
    .map(({ timestamp }) => new Date(timestamp).toLocaleTimeString("en-US"))
    .reverse();

  const dataSets = [
    {
      label: "Transaction Change",
      data: analysisHistory.map(({ analysis }) => analysis?.transactionChange || 0).reverse(),
      color: "#0056b3",
    },
    {
      label: "Average Value Per Holder",
      data: analysisHistory.map(({ analysis }) => analysis?.averageValuePerHolder || 0).reverse(),
      color: "#28a745",
    },
    {
      label: "Liquidity to Volume Ratio",
      data: analysisHistory.map(({ analysis }) => analysis?.liquidityToVolumeRatio || 0).reverse(),
      color: "#ffc107",
    },
  ];

  return (
    <div className="coin-analysis-dashboard">
      <div className="coin-analysis-analyze-button-wrapper">
        <button
          className="coin-analysis-analyze-button"
          onClick={handleAnalyze}
          disabled={loading}
        >
          {loading ? "Analyzing..." : "Run Analysis"}
        </button>
      </div>
      {coinData && <TokenStats coinData={coinData} tokenAddress={tokenAddress} />}
      <div className="coin-analysis-dashboard-header">
        <h1>Coin Analysis Dashboard</h1>
        <p>Token Address: {tokenAddress}</p>
      </div>

      <div className="coin-analysis-dashboard-card">
        <h2>Trend Analysis</h2>
        <CoinTables labels={labels} dataSets={dataSets} />
      </div>

      <DetailedEntries analysisHistory={analysisHistory} />
    </div>
  );
};

export default CoinAnalysis;
