import React, { useEffect, useState } from 'react';
import { database } from '../../firebaseConfig';
import { ref, get } from 'firebase/database';
import { usePageDetails } from '../../Utility/PageDetailsContext';
import './GigSellerInfos.css';

function GigSellerInfos({ pageId, onToggleSettings, active, settingsRoute, isLivePage }) {
  const { pageDetails } = usePageDetails();
  const [artistCountry, setArtistCountry] = useState('');
  const [isIndustry, setIsIndustry] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (pageId) {
      const pageArtistRef = ref(database, `/Pages/${pageId}/artistId`);
      get(pageArtistRef).then(snapshot => {
        if (snapshot.exists()) {
          const artistId = snapshot.val();
          const artistRef = ref(database, `/Artists/${artistId}/country`);
          get(artistRef).then(countrySnapshot => {
            if (countrySnapshot.exists()) {
              setArtistCountry(countrySnapshot.val());
            } else {
              console.error('Country not found for artistId:', artistId);
            }
            setLoading(false);
          }).catch(error => {
            console.error('Failed to fetch country:', error);
            setLoading(false);
          });
        } else {
          console.error('Artist ID not found for pageId:', pageId);
          setLoading(false);
        }
      }).catch(error => {
        console.error('Failed to fetch artist ID:', error);
        setLoading(false);
      });

      // Check the serviceType to determine display data
      const serviceTypeRef = ref(database, `/Pages/${pageId}/pageData/serviceType`);
      get(serviceTypeRef).then(snapshot => {
        if (snapshot.exists()) {
          setIsIndustry(snapshot.val() === 'Music Industry');
        }
      });
    }
  }, [pageId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const { experience, genres, moods, companyType } = pageDetails || {};
  const displayData = isIndustry ? companyType : moods;
  const dataTitle = isIndustry ? 'Type' : 'Moods';
  const className = `gig-seller-infos ${settingsRoute ? 'settings-route' : ''} ${active ? 'is-active' : ''}`;

  return (
    <div className={className} onClick={onToggleSettings}>
      {settingsRoute && (
        <div className="edit-overlay">
          <img src="../../img/edit.webp" alt="Edit" />
          <span>Edit</span>
        </div>
      )}
      <div className="gig-seller-info-pair">
        <p className="gig-seller-info-title">Experience:</p>
        <p className="gig-seller-info-value">{experience || 'No Experience Provided'}</p>
      </div>
      
      <div className="gig-seller-info-pair">
        <p className="gig-seller-info-title">Genres:</p>
        <p className="gig-seller-info-value">{genres ? genres.join(', ') : 'No Genres Provided'}</p>
      </div>
      
      <div className="gig-seller-info-pair">
        <p className="gig-seller-info-title">{dataTitle}:</p>
        <p className="gig-seller-info-value">{displayData ? displayData.join(', ') : `No ${dataTitle} Provided`}</p>
      </div>
      
      {artistCountry && (
        <div className="gig-seller-info-pair">
          <p className="gig-seller-info-title">Country:</p>
          <p className="gig-seller-info-value">{artistCountry}</p>
        </div>
      )}
    </div>
  );
}

export default GigSellerInfos;
