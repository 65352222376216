const contentMap = {
    "Mix & Master": [
      { 
        "name": "Analog Mastering", 
        "text": "I use analog gear to warm up and enhance your song.", 
        "img": "../../feature/analog.svg" 
      },
      { 
        "name": "Digital Mastering", 
        "text": "I use digital tools for clear and precise sound.", 
        "img": "../../feature/digital.svg" 
      },
      { 
        "name": "Loudness Optimization", 
        "text": "I make your track loud and clear, ready for radio and streaming.", 
        "img": "../../feature/loud.svg" 
      },
      { 
        "name": "Stereo Enhancement", 
        "text": "I widen your track’s sound for a better listening experience.", 
        "img": "../../feature/stereo.svg" 
      },
      { 
        "name": "Stem Mastering", 
        "text": "I master up to 8 separate parts of your mix for balance.", 
        "img": "../../feature/stem.svg" 
      },
      { 
        "name": "Surround Mastering", 
        "text": "I prepare your track for surround sound systems.", 
        "img": "../../feature/surround.svg" 
      },
      { 
        "name": "EQ Adjustments", 
        "text": "I adjust frequencies to make your track sound its best.", 
        "img": "../../feature/eq.svg"
      },
      { 
        "name": "Vocal Clarity Enhancement", 
        "text": "I make the vocals in your track stand out and sound clear.", 
        "img": "../../feature/clarity.svg"
      },
      { 
        "name": "MP3 & WAV File",
        "text": "I provide three versions of your track for different uses.", 
        "img": "../../feature/music-file.svg"
      },
      { 
        "name": "2 Different Versions", 
        "text": "I provide two versions of your track for different uses.", 
        "img": "../../feature/clean.svg"
      },
    ],
    "Song Production": [
      {
        "name": "Full Track Production",
        "text": "I create your song from start to finish.",
        "img": "../../feature/studio.svg"
      },
      {
        "name": "Including Lyrics",
        "text": "I write lyrics for your song.",
        "img": "../../feature/sing.svg"
      },
      {
        "name": "Mixing & Mastering",
        "text": "I mix and master your song for a polished sound.",
        "img": "../../feature/subwoofer.svg"
      },
      {
        "name": "Song Editing",
        "text": "I edit your song to perfection.",
        "img": "../../feature/audio.svg"
      },
      {
        "name": "MP3 & WAV File",
        "text": "You get your song in both MP3 and WAV formats.",
        "img": "../../feature/music-file.svg"
      },
      {
        "name": "Project File Included",
        "text": "You receive the complete project file for your song.",
        "img": "../../feature/computer.svg"
      },
      {
        "name": "Instrumental Tracks",
        "text": "I provide instrumental tracks for your song.",
        "img": "../../feature/live-music.svg"
      },
      {
        "name": "Vocal Tuning",
        "text": "I tune the vocals to ensure they sound pitch perfect.",
        "img": "../../feature/clean.svg"
      },
      {
        "name": "Custom Sound Design",
        "text": "I create custom sounds specifically for your track.",
        "img": "../../feature/music-note.svg"
      },
      {
        "name": "Arrangement Assistance",
        "text": "I help arrange your song to enhance its structure and flow.",
        "img": "../../feature/sounds.svg"
      }
    ],    
    "Vocal Feature": [
      {
        "name": "Lead Vocals",
        "text": "I provide the main vocal performance for your track.",
        "img": "../../feature/musical-note.svg"
      },
      {
        "name": "Background Vocals",
        "text": "I add backing vocals to enrich your song's sound.",
        "img": "../../feature/choir.svg"
      },
      {
        "name": "One Verse",
        "text": "I provide a compelling vocal performance for one verse of your song.",
        "img": "../../feature/verse.svg"
      },
      {
        "name": "Full Song",
        "text": "I deliver complete vocal coverage for your entire song, from start to finish.",
        "img": "../../feature/full-song.svg"
      },
      {
        "name": "Vocal Tuning",
        "text": "I fine-tune my vocals for pitch-perfect clarity.",
        "img": "../../feature/vocal-tuning.svg"
      },
      {
        "name": "Vocal Layering",
        "text": "I layer multiple vocal tracks for a fuller sound.",
        "img": "../../feature/vocal-layering.svg"
      },
      {
        "name": "Harmony Arrangement",
        "text": "I create and arrange vocal harmonies and melodies.",
        "img": "../../feature/clean.svg"
      },
      {
        "name": "Ad-lib Recording",
        "text": "I record ad-lib vocals to enhance the expressive quality.",
        "img": "../../feature/ad-lib.svg"
      },
      {
        "name": "Vocal Comping",
        "text": "I compile the best vocal takes to create the perfect performance.",
        "img": "../../feature/vocal-comping.svg"
      },
      {
        "name": "Vocal Effects",
        "text": "I apply effects like reverb, delay, and auto-tune.",
        "img": "../../feature/vocal-effects.svg"
      },
      {
        "name": "Pitch Correction",
        "text": "I correct any pitch issues to ensure the vocals are in tune.",
        "img": "../../feature/sine.svg"
      },
      {
        "name": "High-End Vocal Processing",
        "text": "I use high-end plugins and gear to process my vocals.",
        "img": "../../feature/amplifier.svg"
      }
    ],  
    
    

    "Rap Feature": [
      {
        "name": "Lead Vocals",
        "text": "I provide the main vocal performance for your track.",
        "img": "../../feature/musical-note.svg"
      },
      {
        "name": "Background Vocals",
        "text": "I add backing vocals to enrich your song's sound.",
        "img": "../../feature/choir.svg"
      },
      {
        "name": "One Verse",
        "text": "I provide a compelling vocal performance for one verse of your song.",
        "img": "../../feature/verse.svg"
      },
      {
        "name": "Full Song",
        "text": "I deliver complete vocal coverage for your entire song, from start to finish.",
        "img": "../../feature/full-song.svg"
      },
      {
        "name": "Vocal Tuning",
        "text": "I fine-tune my vocals for pitch-perfect clarity.",
        "img": "../../feature/vocal-tuning.svg"
      },
      {
        "name": "Vocal Layering",
        "text": "I layer multiple vocal tracks for a fuller sound.",
        "img": "../../feature/vocal-layering.svg"
      },
      {
        "name": "Harmony Arrangement",
        "text": "I create and arrange vocal harmonies and melodies.",
        "img": "../../feature/clean.svg"
      },
      {
        "name": "Ad-lib Recording",
        "text": "I record ad-lib vocals to enhance the expressive quality.",
        "img": "../../feature/ad-lib.svg"
      },
      {
        "name": "Vocal Comping",
        "text": "I compile the best vocal takes to create the perfect performance.",
        "img": "../../feature/vocal-comping.svg"
      },
      {
        "name": "Vocal Effects",
        "text": "I apply effects like reverb, delay, and auto-tune.",
        "img": "../../feature/vocal-effects.svg"
      },
      {
        "name": "Pitch Correction",
        "text": "I correct any pitch issues to ensure the vocals are in tune.",
        "img": "../../feature/sine.svg"
      },
      {
        "name": "High-End Vocal Processing",
        "text": "I use high-end plugins and gear to process my vocals.",
        "img": "../../feature/amplifier.svg"
      }
    ],
    "Sell Song Licenses": [
      {
        "name": "Exclusive Licensing",
        "text": "Sell song to one person or company. They are the only ones who can use it.",
        "img": "../../feature/gem.svg"
      },
      {
        "name": "Non-exclusive Licenses",
        "text": "Sell song to multiple owners. They can all use it.",
        "img": "../../feature/community.svg"
      },
      {
        "name": "Media Licensing",
        "text": "Use my song in TV shows, movies.",
        "img": "../../feature/sync.svg"
      },
      {
        "name": "Bulk Licensing",
        "text": "Offer deals on many songs at once to businesses.",
        "img": "../../feature/bulk-deals.svg"
      },
      {
        "name": "Online Ads License",
        "text": "Use song in online ads and campaigns.",
        "img": "../../feature/ads.svg"
      },
      {
        "name": "Just Instrumentals",
        "text": "Instrumental music of my song without vocals.",
        "img": "../../feature/instrumentals.svg"
      },
      {
        "name": "Custom Offer",
        "text": "Let's discuss a custom deal that perfectly suits your specific project needs.",
        "img": "../../feature/custom.svg"
      },
    ],    

    "Beat Production": [
      {
        "name": "Custom Beat Creation",
        "text": "I craft original beats tailored to your music style and project needs.",
        "img": "../../feature/drum.svg"
      },
      {
        "name": "30-60 Seconds Loop",
        "text": "I create short loopable beats perfect for jingles, ads, or background music.",
        "img": "../../feature/repeat.svg"
      },
      {
        "name": "Full Beat Production",
        "text": "I develop a complete beat from start to finish.",
        "img": "../../feature/full-song.svg"
      },
      {
        "name": "Pro Sound Design",
        "text": "I create unique sounds and textures.",
        "img": "../../feature/music-note.svg"
      },
      {
        "name": "Sample Clearance",
        "text": "I handle the legal work to clear samples used in your beats.",
        "img": "../../feature/right.svg"
      },
      {
        "name": "Mixing & Mastering",
        "text": "I fine-tune your beats to achieve professional sound quality.",
        "img": "../../feature/subwoofer.svg"
      },
      {
        "name": "Beat Stems",
        "text": "I provide individual track stems for your beats.",
        "img": "../../feature/stem.svg"
      },
      {
        "name": "Exclusive Beats",
        "text": "I offer exclusive beats that will only be sold to one artist.",
        "img": "../../feature/gem.svg"
      },
      {
        "name": "Beat Restoration",
        "text": "I restore old or low-quality beats to modern production standards.",
        "img": "../../feature/vinyl.svg"
      },
      {
        "name": "MP3 & WAV File",
        "text": "You get your song in both MP3 and WAV formats.",
        "img": "../../feature/music-file.svg"
      },
      {
        "name": "Project File Included",
        "text": "You receive the complete project file for your song.",
        "img": "../../feature/computer.svg"
      },
    ],    
    
    "Instrument Recordings": [
      {
        "name": "Electric Guitar Recording",
        "text": "I record high-quality audio of my guitar.",
        "img": "../../feature/electric-guitar.svg"
      },
      {
        "name": "Acoustic Guitar Recording",
        "text": "I record high-quality audio of my guitar.",
        "img": "../../feature/guitar.svg"
      },
        {
          "name": "Drum Recording",
          "text": "I record high-quality audio of my drums.",
          "img": "../../feature/drums.svg"
        },
        {
          "name": "Percussion Recording",
          "text": "I record high-quality audio of my percussion instruments.",
          "img": "../../feature/percussion.svg"
        },
        {
          "name": "Synthesizer Recording",
          "text": "I record high-quality audio of my synthesizer.",
          "img": "../../feature/synthesizer.svg"
        },
        {
          "name": "Digital Piano Recording",
          "text": "I record high-quality audio of my digital piano.",
          "img": "../../feature/digital-piano.svg"
        },
        {
          "name": "Piano Recording",
          "text": "I record high-quality audio of my piano.",
          "img": "../../feature/piano.svg"
        },
        {
          "name": "Bass Guitar Recording",
          "text": "I record high-quality audio of my bass guitar.",
          "img": "../../feature/bass-guitar.svg"
        },
        {
          "name": "Saxophone Recording",
          "text": "I record high-quality audio of my saxophone.",
          "img": "../../feature/saxophone.svg"
        },      

      {
        "name": "Multi-track Recording",
        "text": "I record different instruments on separate tracks for detailed editing.",
        "img": "../../feature/vocal-layering.svg"
      },

      {
        "name": "Audio Editing",
        "text": "I edit your tracks to correct timing and enhance sound quality.",
        "img": "../../feature/music-edition.svg"
      },
      {
        "name": "EQ Adjustments",
        "text": "I mix your tracks to balance the sound and prepare them for mastering.",
        "img": "../../feature/eq.svg"
      },
      {
        "name": "Track Layering",
        "text": "I layer multiple takes to create a full, complex sound.",
        "img": "../../feature/sounds.svg"
      },
        {
          "name": "Direct Input Recording",
          "text": "I record high-quality audio directly from the instrument's output.",
          "img": "../../feature/cable.svg"
        },
        {
          "name": "Recording with Microphones",
          "text": "I record high-quality audio using microphones to capture the natural sound of the instrument.",
          "img": "../../feature/recording-mic.svg"
        },
        {
          "name": "MP3 & WAV File",
          "text": "You get your song in both MP3 and WAV formats.",
          "img": "../../feature/music-file.svg"
        },
        {
          "name": "Project File Included",
          "text": "You receive the complete project file for your song.",
          "img": "../../feature/computer.svg"
        },
    ],     

    "Music Mentoring": [
      {
        "name": "One-on-One Coaching",
        "text": "I provide tailored coaching to develop your unique musical style and skills.",
        "img": "../../feature/hand.svg"
      },
      {
        "name": "Music Business Hacks",
        "text": "I guide you through the complexities of the music industry.",
        "img": "../../feature/music-business.svg"
      },
      {
        "name": "Feedback Session",
        "text": "I provide detailed feedback on your music, offering constructive criticism and guidance.",
        "img": "../../feature/song-review.svg"
      },
      {
        "name": "Marketing Plan",
        "text": "I help you develop a comprehensive marketing plan to reach your target audience.",
        "img": "../../feature/strategy.svg"
      },
      {
        "name": "Promotion Strategy",
        "text": "I devise strategies to promote your music, enhancing your visibility and fan engagement.",
        "img": "../../feature/marketing-automation.svg"
      },
      {
        "name": "Artist Branding",
        "text": "I assist in developing your artist brand to create a lasting image and connection with fans.",
        "img": "../../feature/corporate-identity.svg"
      },
    ],

    "Ghostwriting": [
      {
        "name": "Lyric Writing",
        "text": "I write custom lyrics tailored to your musical style and themes.",
        "img": "../../feature/composing.svg"
      },
      {
        "name": "Melody Composition",
        "text": "I compose memorable melodies to complement your lyrics.",
        "img": "../../feature/music-note.svg"
      },
      {
        "name": "Song Structuring",
        "text": "I help structure your song for optimal flow and impact.",
        "img": "../../feature/stem.svg"
      },
      {
        "name": "Chorus Creation",
        "text": "I craft catchy choruses that resonate with listeners.",
        "img": "../../feature/sing.svg"
      },
      {
        "name": "Bridge and Hook Development",
        "text": "I develop engaging bridges and hooks to enhance your song's appeal.",
        "img": "../../feature/choir.svg"
      },
      {
        "name": "Full Songwriting",
        "text": "I provide complete songwriting services from lyrics to final arrangement.",
        "img": "../../feature/full-song.svg"
      },
      {
        "name": "Co-Writing",
        "text": "I collaborate with you to co-write and refine your musical ideas.",
        "img": "../../feature/community.svg"
      },
      {
        "name": "Lyric Editing",
        "text": "I edit and refine your existing lyrics to perfect the song.",
        "img": "../../feature/music-edition.svg"
      },
      {
        "name": "Concept Development",
        "text": "I assist in developing the overarching theme or concept for your songs.",
        "img": "../../feature/sounds.svg"
      },
    ],    

    "Artist Management": [
      {
        "name": "Release Management",
        "text": "I coordinate your music releases, ensuring they hit the market at the perfect time.",
        "img": "../../feature/spotify.svg"
      },
      {
        "name": "Playlist Pitching",
        "text": "I pitch your tracks to popular playlists to increase streams and visibility.",
        "img": "../../feature/playlist.svg"
      },
      {
        "name": "Tour Management",
        "text": "I manage all aspects of your tours, from scheduling to logistics.",
        "img": "../../feature/tickets.svg"
      },
      {
        "name": "Contract Negotiation",
        "text": "I negotiate contracts on your behalf to secure favorable terms.",
        "img": "../../feature/contract.svg"
      },
      {
        "name": "Brand Partnerships",
        "text": "I secure lucrative brand partnerships to enhance your visibility and income.",
        "img": "../../feature/flyers.svg"
      },
      {
        "name": "Public Relations",
        "text": "I handle your public relations, maintaining a positive public image.",
        "img": "../../feature/media-relation.svg"
      },
      {
        "name": "Social Media Strategy",
        "text": "I develop and implement strategies to grow your social media presence.",
        "img": "../../feature/socials.svg"
      },
      {
        "name": "Financial Planning",
        "text": "I oversee your financial planning to maximize earnings and manage expenses.",
        "img": "../../feature/money.svg"
      },
      {
        "name": "Merchandising",
        "text": "I manage the development and sale of merchandise to boost revenue.",
        "img": "../../feature/merchandise.svg"
      },
      {
        "name": "Fan Engagement",
        "text": "I create and execute strategies to increase fan engagement across platforms.",
        "img": "../../feature/fans.svg"
      },
    ],
    
    "YouTube Promotion": [
      {
        "name": "Channel Optimization",
        "text": "I optimize your YouTube channel to improve visibility and attract subscribers.",
        "img": "../../feature/channel.svg"
      },
      {
        "name": "Video SEO",
        "text": "I apply SEO techniques to your videos to enhance discoverability and rankings.",
        "img": "../../feature/seo.svg"
      },
      {
        "name": "Thumbnail Creation",
        "text": "I design eye-catching thumbnails that increase click-through rates.",
        "img": "../../feature/thumbnail.svg"
      },
      {
        "name": "Content Strategy",
        "text": "I develop a content strategy that aligns with your artistic identity and audience interests.",
        "img": "../../feature/strategy.svg"
      },
      {
        "name": "Collaboration Opportunities",
        "text": "I facilitate collaborations to expand your audience.",
        "img": "../../feature/collab.svg"
      },
      {
        "name": "Targeted Video Ads",
        "text": "I deploy video ads to reach specific demographics, increasing exposure.",
        "img": "../../feature/marketing-automation.svg"
      },
      {
        "name": "Ad Campaign Management",
        "text": "I manage YouTube ad campaigns to target potential fans.",
        "img": "../../feature/campaign.svg"
      },
      {
        "name": "Analytics Monitoring",
        "text": "I monitor your channel analytics to guide strategy adjustments and improve performance.",
        "img": "../../feature/analytics.svg"
      },
      {
        "name": "Video Series Development",
        "text": "I help develop and produce engaging video series.",
        "img": "../../feature/movie.svg"
      },
      {
        "name": "Live Streaming Assistance",
        "text": "I assist in setting up and promoting live streaming events.",
        "img": "../../feature/live-streaming.svg"
      }
    ],
    
    "Spotify Promotion": [
      {
        "name": "Playlist Pitching",
        "text": "I pitch your music to popular and niche Spotify playlists to increase streams and exposure.",
        "img": "../../feature/playlist.svg"
      },
      {
        "name": "Profile Optimization",
        "text": "I optimize your Spotify artist profile to enhance its appeal and discoverability.",
        "img": "../../feature/clean.svg"
      },
      {
        "name": "Stream Boosting",
        "text": "I implement strategies to boost your streaming numbers through targeted promotions.",
        "img": "../../feature/rocket.svg"
      },
      {
        "name": "Data Analytics",
        "text": "I analyze your streaming data to tailor marketing strategies that resonate with your listener base.",
        "img": "../../feature/analytics.svg"
      },
      {
        "name": "Release Strategy",
        "text": "I develop release strategies that maximize impact and engagement.",
        "img": "../../feature/marketing-automation.svg"
      },
      {
        "name": "Sponsored Songs",
        "text": "I manage sponsored song campaigns to elevate your tracks to a wider audience.",
        "img": "../../feature/ads.svg"
      },
      {
        "name": "Fan Engagement",
        "text": "I create campaigns to increase fan engagement and loyalty.",
        "img": "../../feature/fans.svg"
      },
      {
        "name": "Artist Radios",
        "text": "I promote inclusion in Spotify radio stations to continuously engage listeners and drive plays.",
        "img": "../../feature/radio.svg"
      },
      {
        "name": "Pre-Save Campaigns",
        "text": "I manage pre-save campaigns to build anticipation and drive engagement upon release.",
        "img": "../../feature/like.svg"
      }
    ],
    
    "Blog/Magazine Promotion": [
      {
        "name": "Spotlight Feature",
        "text": "Gain a prime spot in a music blog, showcasing your sound and story.",
        "img": "../../feature/gem.svg"
      },
      
      {
        "name": "Sponsored Post",
        "text": "Secure a paid post on a music blog for targeted exposure.",
        "img": "../../feature/ads.svg"
      },
  
      {
        "name": "Article Writing",
        "text": "Craft custom articles to highlight your musical journey and insights.",
        "img": "../../feature/writer.svg"
      },
      {
        "name": "Highlight Section Placement",
        "text": "Feature your music in a blog's highlight section.",
        "img": "../../feature/highlight.svg"
      },
      {
        "name": "Submit Song for Review",
        "text": "Get your tracks reviewed to enhance your profile among enthusiasts.",
        "img": "../../feature/song-review.svg"
      },
      {
        "name": "Google News Approved",
        "text": "Boost SEO and visibility on Google News approved sites.",
        "img": "../../feature/news.svg"
      },
      {
        "name": "10k+ Monthly Visits",
        "text": "Feature on sites with over 10k monthly visitors for high reach.",
        "img": "../../feature/visibility.svg"
      },
    ],

    "TikTok Influencer": [
      {
        "name": "Song in Background",
        "text": "Feature your song in the background of my TikTok videos.",
        "img": "../../feature/musical-note.svg"
      },
      {
        "name": "Custom TikTok Dance",
        "text": "Create a custom dance to your song to spark a trending dance challenge.",
        "img": "../../feature/dancemove.svg"
      },
      {
        "name": "Tiktok Challenge",
        "text": "Engage with your promotional challenge to help it go viral.",
        "img": "../../feature/challenge.svg"
      },
      {
        "name": "Dedicated Shoutout",
        "text": "Give a dedicated shoutout to promote your music or brand in my video.",
        "img": "../../feature/mega.svg"
      },
      {
        "name": "Sponsored Post",
        "text": "Create a sponsored post that integrates your music or brand naturally.",
        "img": "../../feature/followers.svg"
      },
      {
        "name": "Product Placement",
        "text": "Incorporate your product seamlessly into my TikTok content.",
        "img": "../../feature/merch.svg"
      },
      {
        "name": "10k+ Followers",
        "text": "Leverage my 10k+ followers to increase your song's or brand's visibility.",
        "img": "../../feature/verified.svg"
      },
      {
        "name": "100k+ Followers",
        "text": "Utilize my 100k+ follower base to massively spread your music or product.",
        "img": "../../feature/verified.svg"
      },
      {
        "name": "500k+ Followers",
        "text": "Take advantage of my 500k+ followers for maximum exposure and impact.",
        "img": "../../feature/verified.svg"
      }
    ],
    
    "Graphic Design": [
      {
        "name": "Album Cover Art",
        "text": "Design stunning album cover art that captures the essence of your music.",
        "img": "../../feature/album-cover-design.svg"
      },
      {
        "name": "Custom Logo Creation",
        "text": "Design a unique and memorable logo that represents your artist identity.",
        "img": "../../feature/logo-design.svg"
      },
      {
        "name": "Single Cover Art",
        "text": "Create eye-catching single cover art to make your track stand out.",
        "img": "../../feature/single-cover.svg"
      },
      {
        "name": "Merchandise Design",
        "text": "Design custom merchandise like t-shirts and posters that resonate with your fans.",
        "img": "../../feature/merchandising.svg"
      },
      {
        "name": "Tour Posters",
        "text": "Create compelling tour posters that attract attention and boost ticket sales.",
        "img": "../../feature/poster.svg"
      },
      {
        "name": "Social Media Banners",
        "text": "Design professional banners for your social media profiles to enhance your online presence.",
        "img": "../../feature/social-banner.svg"
      },
      {
        "name": "YouTube Thumbnails",
        "text": "Design engaging YouTube thumbnails that increase your video click-through rate.",
        "img": "../../feature/youtube-thumbnail.svg"
      },
      {
        "name": "EPK (Electronic Press Kit) Design",
        "text": "Craft a professional EPK that showcases your music and brand to industry professionals.",
        "img": "../../feature/press.svg"
      },
      {
        "name": "Free Preview",
        "text": "Receive a free preview of your design before finalizing.",
        "img": "../../feature/eye.svg"
      },
      {
        "name": "Monthly Packages Available",
        "text": "Subscribe to monthly packages for ongoing design needs.",
        "img": "../../feature/monthly.svg"
      },
      {
        "name": "High Resolution Export",
        "text": "Get your designs exported in high resolution for top-quality printing and digital use.",
        "img": "../../feature/resolution.svg"
      },
      {
        "name": "Multiple File Types",
        "text": "Receive your designs in various file formats to suit your specific needs.",
        "img": "../../feature/file-types.svg"
      },
      {
        "name": "Vector Files",
        "text": "Ensure your vector files are optimized for high-quality printing in any size.",
        "img": "../../feature/print-ready.svg"
      },
      {
        "name": "5 Concepts",
        "text": "Receive five unique concepts to choose from for your project.",
        "img": "../../feature/number-5.svg"
      },
      {
        "name": "3 Concepts",
        "text": "Receive three unique concepts to choose from for your project.",
        "img": "../../feature/number-3.svg"
      },
      {
        "name": "2 Concepts",
        "text": "Get two creative concepts, tailored to your brief, to select from.",
        "img": "../../feature/number-2.svg"
      },
      {
        "name": "1 Concept",
        "text": "Receive one refined concept that perfectly aligns with your vision.",
        "img": "../../feature/number-1.svg"
      },
    ],
    "Video Editing": [
      {
        "name": "Music Video Editing",
        "text": "Edit your music video to create a visually stunning narrative that complements your song.",
        "img": "../../feature/video-editor.svg"
      },
      {
        "name": "Lyric Video Creation",
        "text": "Produce dynamic lyric videos that bring your lyrics to life.",
        "img": "../../feature/lyrics.svg"
      },
 
      {
        "name": "Social Media Video Clips",
        "text": "Create short, impactful video clips for Instagram, TikTok, and more to promote your music.",
        "img": "../../feature/new-post.svg"
      },
      {
        "name": "YouTube Video Editing",
        "text": "Edit your YouTube videos to increase viewer engagement and subscriber growth.",
        "img": "../../feature/youtube.svg"
      },
      {
        "name": "Visual Effects (VFX)",
        "text": "Enhance your music videos with professional visual effects that add a unique touch.",
        "img": "../../feature/animation.svg"
      },
      {
        "name": "Color Grading",
        "text": "Apply expert color grading to give your videos a polished, cinematic look.",
        "img": "../../feature/paint.svg"
      },
      {
        "name": "Free Preview",
        "text": "Receive a free preview of your design before finalizing.",
        "img": "../../feature/eye.svg"
      },
      {
        "name": "Monthly Packages Available",
        "text": "Subscribe to monthly packages for ongoing design needs.",
        "img": "../../feature/monthly.svg"
      },
      {
        "name": "High Resolution Export",
        "text": "Get your designs exported in high resolution for top-quality printing and digital use.",
        "img": "../../feature/resolution.svg"
      },
      {
        "name": "Multiple File Types",
        "text": "Receive your designs in various file formats to suit your specific needs.",
        "img": "../../feature/file-types.svg"
      }
    ]    
     ,    
    "Instagram Influencer": [
      {
        "name": "Song in Background",
        "text": "Feature your song in the background of my Instagram videos.",
        "img": "../../feature/musical-note.svg"
      },
      {
        "name": "Dance to your Song",
        "text": "Create a custom dance to your song to spark a trending dance challenge.",
        "img": "../../feature/dancemove.svg"
      },
      {
        "name": "Viral Challenge",
        "text": "Engage with your promotional challenge to help it go viral.",
        "img": "../../feature/challenge.svg"
      },
      {
        "name": "Dedicated Shoutout",
        "text": "Give a dedicated shoutout to promote your music or brand in my video.",
        "img": "../../feature/mega.svg"
      },
      {
        "name": "Sponsored Post",
        "text": "Create a sponsored post that integrates your music or brand naturally.",
        "img": "../../feature/followers.svg"
      },
      {
        "name": "Product Placement",
        "text": "Incorporate your product seamlessly into my Instagram content.",
        "img": "../../feature/merch.svg"
      },
      {
        "name": "10k+ Followers",
        "text": "Leverage my 10k+ followers to increase your song's or brand's visibility.",
        "img": "../../feature/verified.svg"
      },
      {
        "name": "100k+ Followers",
        "text": "Utilize my 100k+ follower base to massively spread your music or product.",
        "img": "../../feature/verified.svg"
      },
      {
        "name": "500k+ Followers",
        "text": "Take advantage of my 500k+ followers for maximum exposure and impact.",
        "img": "../../feature/verified.svg"
      }
    ],





    defaultService: [
      { name: 'No Service Available', img: '' }
    ]
  };
  export default contentMap;
