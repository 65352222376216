import React, { useState, useEffect } from 'react';
import './ChatList.css';
import { ref as databaseRef, get, onValue, off } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import DashboardNav from '../DashboardNav';
import ChatWindow from './ChatWindow';
import OpositeInfo from './OpositeInfo';
import LoadingScreen from '../../Utility/LoadingScreen';
import { useChat } from '../../Utility/ChatContext';

const ChatList = () => {
    const [chats, setChats] = useState([]);
    const [activeChatId, setActiveChatId] = useState(null);
    const [isTyping, setIsTyping] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const { unreadCounts, markMessagesAsRead } = useChat();

    useEffect(() => {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            setIsLoading(false);
            return;
        }

        const fetchChats = async () => {
            try {
                const chatsRef = databaseRef(database, 'Chats');
                const snapshot = await get(chatsRef);
                if (snapshot.exists()) {
                    const chatData = snapshot.val();
                    const filteredKeys = Object.keys(chatData).filter(
                        (key) => chatData[key].initiatorId === currentUser.uid || chatData[key].recipientId === currentUser.uid
                    );

                    const chatPromises = filteredKeys.map(async (key) => {
                        const chat = chatData[key];
                        const partnerId = chat.initiatorId === currentUser.uid ? chat.recipientId : chat.initiatorId;
                        const artistRef = databaseRef(database, `Artists/${partnerId}`);
                        const artistSnapshot = await get(artistRef);
                        const artistData = artistSnapshot.val() || {};

                        const messagesRef = databaseRef(database, `Chats/${key}/messages`);
                        const messagesSnapshot = await get(messagesRef);
                        const typingRef = databaseRef(database, `Chats/${key}/typing/${partnerId}`);
                        
                        // Typing status
                        onValue(typingRef, (typingSnapshot) => {
                            setIsTyping((prevStatus) => ({
                                ...prevStatus,
                                [key]: typingSnapshot.val() === true,
                            }));
                        });

                        let unreadCount = 0;
                        if (messagesSnapshot.exists()) {
                            const messages = messagesSnapshot.val();
                            unreadCount = Object.values(messages).reduce(
                                (acc, message) => acc + (!message.readStatus && message.senderId !== currentUser.uid ? 1 : 0),
                                0
                            );
                        }

                        return {
                            id: key,
                            partnerId,
                            name: artistData.name || artistData.artistName,
                            type: artistData.expertType || artistData.artistType,
                            imageUrl: artistData.artistImageUrl || 'https://example.com/default-profile.jpg',
                            unreadCount,
                            lastMessageAt: chat.lastMessageAt || '1970-01-01T00:00:00.000Z',
                        };
                    });

                    const chatsList = await Promise.all(chatPromises);
                    
                    // Sort by lastMessageAt (latest message at the top)
                    chatsList.sort((a, b) => new Date(b.lastMessageAt) - new Date(a.lastMessageAt));
                    
                    setChats(chatsList);

                    if (chatsList.length > 0) {
                        setActiveChatId(chatsList[0].id); // Set the first chat as active
                    }
                }
            } catch (error) {
                console.error("Error fetching chats:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchChats();

        return () => {
            // Clean-up typing listeners
            chats.forEach((chat) => {
                const typingRef = databaseRef(database, `Chats/${chat.id}/typing/${chat.partnerId}`);
                off(typingRef);
            });
        };
    }, [chats.length]); // Update when chats length changes to prevent stale references

    useEffect(() => {
        return () => {
            // Remove listener when chat changes or component unmounts
            if (activeChatId) {
                const messagesRef = databaseRef(database, `Chats/${activeChatId}/messages`);
                off(messagesRef);
            }
        };
    }, [activeChatId]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleChatClick = (chatId) => {
        if (activeChatId !== chatId) {
            if (activeChatId) {
                const prevMessagesRef = databaseRef(database, `Chats/${activeChatId}/messages`);
                off(prevMessagesRef);
            }

            setActiveChatId(chatId);
            markMessagesAsRead(chatId);
        }
    };

    if (isLoading) {
        return (
            <div className="loading-spinner-3">
                <DashboardNav />
                <LoadingScreen />
            </div>
        );
    }

    return (
        <div className="chat-list-container">
            <DashboardNav unreadCounts={unreadCounts} />
            {chats.length > 0 ? (
                <>
                    <div className="chat-sidebar-toggle-wrapper">
                        <button className="chat-sidebar-toggle-button" onClick={(e) => {
                            e.stopPropagation();
                            toggleSidebar();
                        }}>
                            <img src={`../../img/down.webp`} alt="Toggle Menu" style={{ transform: isSidebarOpen ? 'rotate(90deg)' : 'rotate(-90deg)' }} />
                        </button>
                        <div className={`dashboard-chat-sidebar ${isSidebarOpen ? 'open' : 'closed'}`} onClick={(e) => e.stopPropagation()}>
                            <h2>Your Chats</h2>
                            <a href="/dashboard" className="mobile-chat-back-button"><img src="../../img/down.webp" alt="" /></a>
                            {chats.map(chat => (
                                <div
                                    key={chat.id}
                                    className={`chat-entry ${chat.id === activeChatId ? 'active-chat-entry' : ''}`}
                                    onClick={() => handleChatClick(chat.id)}
                                >
                                    <img src={chat.imageUrl} alt={chat.name} className="chat-profile-image-3" />
                                    <div className="chat-sidebar-info">
                                        <div>
                                            {isSidebarOpen && (
                                                <div className="chat-sidebar-info">
                                                    <p className="chat-sidebar-name">{chat.name}</p>
                                                    <p className="chat-sidebar-name" style={{ fontSize: '11px', color: 'gray' }}>{chat.type || 'Unknown Type'}</p>
                                                </div>
                                            )}
                                            {unreadCounts[chat.id] > 0 && (
                                                <span><p>{unreadCounts[chat.id]}</p></span>
                                            )}
                                        </div>
                                        {isTyping[chat.id] && <span><p className="typing-indicator">typing</p></span>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="chat-messages">
                        {activeChatId ? (
                            <ChatWindow chatId={activeChatId} onMessagesRead={() => markMessagesAsRead(activeChatId)} />
                        ) : (
                            <div>Please select a chat to view messages.</div>
                        )}
                    </div>
                    <div className="oposite-info">
                        {activeChatId ? <OpositeInfo partnerId={chats.find(chat => chat.id === activeChatId)?.partnerId} /> : <div>No active chat selected.</div>}
                    </div>
                </>
            ) : (
                <div className="no-chats-message">
                    <img style={{ width: '100px' }} src="../../img/big_chat.webp" alt="" />
                    <h2>Seems like your Inbox is empty.</h2>
                    <p>Your inbox is empty. Check back later for new messages.</p>
                    <a href="/market"><p>Connect with other Artists</p></a>
                </div>
            )}
        </div>
    );
};

export default ChatList;
