import React, { useState, useEffect } from 'react';
import { ref as databaseRef, set, get } from 'firebase/database';
import { database } from "../firebaseConfig";
import './AwardCreator.css';

function AwardCreator() {
  const [awardName, setAwardName] = useState('');
  const [awardDate, setAwardDate] = useState('');
  const [textOne, setTextOne] = useState('');
  const [textTwo, setTextTwo] = useState('');

  // Daten beim Start laden
  useEffect(() => {
    const loadCurrentAward = async () => {
      const awardRef = databaseRef(database, 'Awards/uniqueAward');
      const snapshot = await get(awardRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setAwardName(data.name);
        setAwardDate(data.date);
        setTextOne(data.textOne);
        setTextTwo(data.textTwo);
      }
    };

    loadCurrentAward();
  }, []);

  const handleAwardCreation = async (e) => {
    e.preventDefault(); // Verhindert das Neuladen der Seite bei Formular-Submit

    const awardData = {
      name: awardName,
      date: awardDate,
      textOne,
      textTwo
    };

    // Verwenden Sie eine feste ID 'uniqueAward' für den Award
    set(databaseRef(database, 'Awards/uniqueAward'), awardData)
      .then(() => {
        alert("Award erfolgreich erstellt!");
      })
      .catch((error) => {
        console.error("Fehler beim Erstellen des Awards: ", error);
        alert('Fehler beim Speichern des Awards');
      });
  };

  return (
    <div className="award-creator">
      <form onSubmit={handleAwardCreation}>
        <label>
          Award Name:
          <input
            type="text"
            value={awardName}
            onChange={(e) => setAwardName(e.target.value)}
          />
        </label>
        <label>
          Award Datum:
          <input
            type="date"
            value={awardDate}
            onChange={(e) => setAwardDate(e.target.value)}
          />
        </label>
        <label>
          Text Eins:
          <textarea
            value={textOne}
            onChange={(e) => setTextOne(e.target.value)}
          />
        </label>
        <label>
          Text Zwei:
          <textarea
            value={textTwo}
            onChange={(e) => setTextTwo(e.target.value)}
          />
        </label>
        <button type="submit">Award Erstellen</button>
      </form>
    </div>
  );
}

export default AwardCreator;
