import React, { useState, useEffect } from 'react';
import { ref as databaseRef, push } from 'firebase/database'; // Firebase importieren
import { database } from '../firebaseConfig'; // Firebase-Konfiguration
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Firebase Authentifizierung
import './LastChancePopup.css'; // CSS für das Popup

const variants = [
  {
    variantId: 'variant1',
    h2: "FREE UPLOADS",
    description: "Meet the game-changers, the trendsetters, the artists who paint our sonic landscape with bold strokes and fearless beats.",
    buttonText: "Apply with my Song"
  },
  {
    variantId: 'variant2',
    h2: "LIMITED UPLOADS",
    description: "Join the trendsetters and visionaries, who are shaping the future of sound with groundbreaking beats.",
    buttonText: "Submit your Track"
  },
  {
    variantId: 'variant3',
    h2: "EXCLUSIVE UPLOADS",
    description: "Collaborate with bold artists, shaping the future of music through fearless sonic innovation.",
    buttonText: "Join the Revolution"
  }
];

const LastChancePopup = ({ onClose }) => {
  const [countdownValue, setCountdownValue] = useState(300);
  const [isFinalPhase, setIsFinalPhase] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isGlowActive, setIsGlowActive] = useState(false);
  const [isFloating, setIsFloating] = useState(false);
  const [currentVariant, setCurrentVariant] = useState(variants[0]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasPopupShown, setHasPopupShown] = useState(false);
  const [autoPopupTriggered, setAutoPopupTriggered] = useState(false);
  const [isReadyToRender, setIsReadyToRender] = useState(false); // Zustand, der das Rendern kontrolliert

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = auth.onAuthStateChanged(user => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    const popupShownInSession = sessionStorage.getItem('popupShown');
    if (popupShownInSession === 'true') {
      setHasPopupShown(true);
    }

    return () => checkAuth();
  }, []);

  useEffect(() => {
    if (isLoggedIn || hasPopupShown || autoPopupTriggered) {
      setIsReadyToRender(false);
      return;
    }

    const randomVariant = variants[Math.floor(Math.random() * variants.length)];
    setCurrentVariant(randomVariant);

    const countdownDuration = 450;
    const startValue = 200;
    const endValue = 42;
    const fastPhaseEnd = 55;
    const fastPhaseDuration = 200;
    const slowPhaseDuration = 70;

    setTimeout(() => {
      setIsGlowActive(true);
      setIsFloating(true);
    }, 2000);

    const totalDuration = 1500;
    const progressBar = document.querySelector('.progressora-bar-inner');

    setTimeout(() => {
      if (progressBar) {
        progressBar.style.transition = `width ${totalDuration / 1000}s linear`;
        progressBar.style.width = '92%';
      }
    }, 100);

    const fastStepTime = fastPhaseDuration / (startValue - fastPhaseEnd);
    const interval = setInterval(() => {
      setCountdownValue((prev) => {
        if (prev > fastPhaseEnd) {
          return prev - 1;
        } else {
          clearInterval(interval);
          setIsFinalPhase(true);
          return fastPhaseEnd;
        }
      });
    }, fastStepTime);

    if (isFinalPhase) {
      const slowInterval = setInterval(() => {
        setCountdownValue((prev) => {
          if (prev > endValue) {
            return prev - 1;
          } else {
            clearInterval(slowInterval);
            setIsButtonEnabled(true);
            return endValue;
          }
        });
      }, slowPhaseDuration);
    }

    const autoPopupTimeout = setTimeout(() => {
      setAutoPopupTriggered(true);
      setHasPopupShown(false);
    }, 60000);

    setIsReadyToRender(true); // Wenn alle Überprüfungen abgeschlossen sind, setze den Zustand zum Rendern

    return () => {
      clearInterval(interval);
      clearTimeout(autoPopupTimeout);
    };
  }, [isFinalPhase, isLoggedIn, hasPopupShown, autoPopupTriggered]);

  const handleApplyClick = () => {
    if (isButtonEnabled) {
      const analyticsRef = databaseRef(database, '/Analytics/AwardsFullPopup');
      push(analyticsRef, {
        variantId: currentVariant.variantId,
        h2: currentVariant.h2,
        description: currentVariant.description,
        buttonText: currentVariant.buttonText,
        timestamp: Date.now(),
      });

      sessionStorage.setItem('popupShown', 'true');
      navigate('/signup');
    }
  };

  const handleClosePopup = () => {
    sessionStorage.setItem('popupShown', 'true');
    onClose();
  };

  if (!isReadyToRender) {
    return null; // Zeige das Popup nicht an, bis alles bereit ist
  }

  if (isLoggedIn || hasPopupShown) {
    return null;
  }

  return (
    <div className="last-chance-popup-overlay" onClick={handleClosePopup}>
      <button className="closett-button" onClick={handleClosePopup}>
        <img src="./img/delete.svg" alt="Close" />
      </button>
      <button className="logett-button" onClick={handleClosePopup}>
        <img src="./img/logo.svg" alt="Close" />
      </button>

      <div className="last-chance-popup" onClick={(e) => e.stopPropagation()}>
        <div className={`last-chance-popup-header ${isFloating ? 'subtle-float' : ''}`}>
          <h1 className={`countdown-value ${isGlowActive ? 'glow-effect' : ''} ${isFinalPhase ? 'final-phase' : ''}`}>{countdownValue}</h1>
          <h2 className={isGlowActive ? 'glow-effect' : ''}>{currentVariant.h2}</h2>
        </div>
        <div className="last-chance-popup-body">
          <p>{currentVariant.description}</p>
          <button
            className="last-chance-popup-button"
            onClick={handleApplyClick}
            disabled={!isButtonEnabled}
            style={{
              cursor: isButtonEnabled ? 'pointer' : 'not-allowed',
              backgroundColor: isButtonEnabled ? 'var(--red)' : 'black',
              textDecoration: 'none'
            }}
          >
            {currentVariant.buttonText}
          </button>
        </div>
      </div>
      <div className="last-chance-popup-footer">
        <p><span>{countdownValue}</span> FREE UPLOADS LEFT</p>
        <div className="last-chance-bottom-right">
          <img src="./img/mini_avatar_preview.webp" alt="" />
          <span>Uploads 500/{countdownValue}</span>
        </div>
      </div>
      <div className="progressora-bar">
        <div className="progressora-bar-inner"></div>
      </div>
    </div>
  );
};

export default LastChancePopup;
