import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HomeInfo.css'; // Importiere das CSS
import { ref as databaseRef, push } from 'firebase/database';
import { database } from '../firebaseConfig'; // Deine Firebase-Konfiguration

const buttonVariants = [
  { id: 1, text: "Apply as Artist" },
  { id: 2, text: "Join the Awards" },
  { id: 3, text: "Submit Your Demo" }
];

const HomeInfo = () => {
  const [buttonVariant, setButtonVariant] = useState(buttonVariants[0]);

  // Zufällige Auswahl eines Button-Textes beim Laden der Komponente
  useEffect(() => {
    const randomVariantIndex = Math.floor(Math.random() * buttonVariants.length);
    setButtonVariant(buttonVariants[randomVariantIndex]);
  }, []);

  // Funktion, um die Daten bei Klick in die Firebase-Datenbank zu pushen
  const handleClick = () => {
    const clickData = {
      variantId: buttonVariant.id,
      buttonText: buttonVariant.text,
      timestamp: Date.now()
    };

    const analyticsRef = databaseRef(database, '/Analytics/BottomAwardsDescButton');
    push(analyticsRef, clickData); // Pusht die Daten in die Firebase-Realtime-Datenbank

    console.log('Data pushed to Firebase:', clickData);
  };

  return (
    <div className="home-info-container">
      <div className="home-info-content">
        <img src="./img/platte_1.webp" alt="Bildbeschreibung" className="home-info-image" />
        <h1>The AAA Music Awards</h1>
        <h2>Aaarists are the Music Awards that recognize and promote the talent and effort of the best new producers, rappers and songwriters in the world.</h2>
        <h3>
          At AAA, our mission is crystal-clear: to celebrate exceptional talent and foster connections among the best newcomers in the game. No room for shortcuts or amateur attempts – only high-quality, professional songs make the cut. Meet the eyes and ears of over 500 music industry experts, thanks to our partnership with DemoCharts. Submit your demo and claim your place among world-class artists.
        </h3>
        <Link className="home-info-link" to="/signup">
          <button className="outline-button-2" onClick={handleClick}>
            {buttonVariant.text}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default HomeInfo;
