import React, { useState, useRef, useEffect } from 'react';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ref as databaseRef, onValue, get, set } from 'firebase/database';
import { storage, database } from '../../firebaseConfig';
import './UtilitySettings.css';
import Resizer from 'react-image-file-resizer';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function ImageUploader({ pageId }) {
    const [images, setImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const inputFileRef = useRef(null);

    useEffect(() => {
        const imagesRef = databaseRef(database, `Pages/${pageId}/pageData/gallery`);
        const unsubscribe = onValue(imagesRef, (snapshot) => {
            if (snapshot.exists()) {
                const imageList = Object.values(snapshot.val());
                setImages(imageList);
            } else {
                setImages([]);
            }
        });

        return () => unsubscribe();
    }, [pageId]);

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        if (files.length === 0) {
            console.error('No files selected.');
            return;
        }

        const fileSizeLimit = 10 * 1024 * 1024; // 10 MB
        const validFiles = files.filter(file => file.size <= fileSizeLimit);

        if (validFiles.length !== files.length) {
            alert('Some files were too big and were not uploaded. The limit is 10 MB per file.');
        }

        setUploading(true);

        // Sequentially upload each valid image
        for (const file of validFiles) {
            await resizeAndUploadImage(file);
        }

        setUploading(false);
    };

    const resizeAndUploadImage = (file) => {
        return new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
                file,
                1300,
                1300,
                'JPEG',
                80,
                0,
                uri => {
                    uploadImage(uri).then(resolve).catch(reject);
                },
                'blob'
            );
        });
    };

    const uploadImage = (blob) => {
        return new Promise((resolve, reject) => {
            const fileRef = storageRef(storage, `gallery/${pageId}/${new Date().getTime()}.jpeg`);
            const uploadTask = uploadBytesResumable(fileRef, blob);

            uploadTask.on('state_changed',
                snapshot => {},
                error => {
                    console.error('Upload failed:', error);
                    reject(error);
                },
                async () => {
                    try {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        console.log('Uploaded image URL:', downloadURL);
                        const galleryRef = databaseRef(database, `Pages/${pageId}/pageData/gallery`);
                        const snapshot = await get(galleryRef);
                        const newImageList = snapshot.exists() ? [...snapshot.val(), downloadURL] : [downloadURL];
                        await set(galleryRef, newImageList);
                        setImages(newImageList);
                        resolve();
                    } catch (error) {
                        console.error('Failed to update database:', error);
                        reject(error);
                    }
                }
            );
        });
    };

    const deleteImage = (imageToDelete) => {
        if (images.length > 1) { // Only allow deletion if there's more than one image
            const filteredImages = images.filter(image => image !== imageToDelete);
            const galleryRef = databaseRef(database, `Pages/${pageId}/pageData/gallery`);
            set(galleryRef, filteredImages);
            setImages(filteredImages);
        } else {
            alert('Cannot delete the last image. Please upload another image before deleting this one.');
        }
    };

    const moveImage = (dragIndex, hoverIndex) => {
        const updatedImages = [...images];
        const [draggedImage] = updatedImages.splice(dragIndex, 1);
        updatedImages.splice(hoverIndex, 0, draggedImage);
        setImages(updatedImages);

        const galleryRef = databaseRef(database, `Pages/${pageId}/pageData/gallery`);
        set(galleryRef, updatedImages);
    };

    const ImageItem = ({ image, index }) => {
        const ref = useRef(null);
        const [, drop] = useDrop({
            accept: 'image',
            hover(item) {
                if (item.index !== index) {
                    moveImage(item.index, index);
                    item.index = index;
                }
            },
        });

        const [{ isDragging }, drag] = useDrag({
            type: 'image',
            item: { index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        drag(drop(ref));

        return (
            <div ref={ref} className="unique-image-display" style={{ opacity: isDragging ? 0.5 : 1 }}>
                <img src={image} alt={`Uploaded ${index}`} />
                <div 
                    className={`unique-image-delete-button ${images.length === 1 ? 'disabled' : ''}`} 
                    onClick={() => deleteImage(image)}
                >
                    <img src="../../img/delete.svg" alt="Delete" className="unique-image-delete-icon" />
                </div>
            </div>
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="unique-image-uploader-container">
                {images.map((image, index) => (
                    <ImageItem key={index} image={image} index={index} />
                ))}
                {uploading && (
                    <div className="unique-image-display">
                        <div className="unique-image-placeholder">
                            <img style={{ width: '30px' }} className="uploading-image" src="../../img/icon.png" alt="" />
                            <div className="spinner-3"></div>
                        </div>
                    </div>
                )}
                <button className="uploader-file-input-2" onClick={() => inputFileRef.current.click()}>
                    <div>
                        <h4>Upload Images</h4>
                        <input
                            ref={inputFileRef}
                            type="file"
                            onChange={handleFileChange}
                            accept="image/*"
                            multiple
                            style={{ display: 'none' }}
                        />
                        <img src="../../img/image.svg" alt="" />
                    </div>
                </button>
            </div>
        </DndProvider>
    );
}

export default ImageUploader;
