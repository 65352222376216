import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get, query, orderByChild, equalTo, limitToFirst } from 'firebase/database';
import { database } from "../firebaseConfig";
import './HomeCharts.css';
import playIcon from '../img/play.svg';
import pauseIcon from '../img/pause.svg';
import AudioBar from '../Audio/AudioBar';
import { Link } from 'react-router-dom';
import SpotifySongs from '../Audio/SpotifySongs';
import CountryFlagSVG from '../Utility/CountryFlagEmoji';
import GenerativeOrbAnimation from '../Utility/GenerativeOrbAnimation';

function HomeCharts() {
  const [songs, setSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
// Zustandsinitialisierung mit einem Objekt
const [chartList, setChartList] = useState({ current: 'Newcomers', alternateName: 'Newcomers' });

useEffect(() => {
  const fetchSongs = async () => {
    const songsRef = query(
      databaseRef(database, 'Songs'), 
      orderByChild(`chartlists/${chartList.current}`),
      equalTo(true)
      // Entferne vorerst das Limit, um sicherzustellen, dass wir alle relevanten Daten erhalten
    );
    const snapshot = await get(songsRef);
    if (snapshot.exists()) {
      const songsData = snapshot.val();
      let songsArray = Object.entries(songsData)
        .map(([id, data]) => ({
          id,
          ...data,
        }))
        .filter(song => song.releaseTimestamp) // Filtere nur Songs, die einen releaseTimestamp haben
        .sort((a, b) => new Date(b.releaseTimestamp) - new Date(a.releaseTimestamp)); // Sortiere absteigend nach releaseTimestamp als Date-Objekte

      // Nachdem wir alle relevanten Songs erhalten und sortiert haben, schränken wir die Liste auf die Top 10 ein
      songsArray = songsArray.slice(0, 15);
      setSongs(songsArray);
    } else {
      setSongs([]);
    }
  };
  fetchSongs();
}, [chartList.current]);




const togglePlayPause = (song) => {
  if (currentSong && currentSong.id === song.id && isPlaying) {
    setIsPlaying(false);
  } else {
    setCurrentSong(song);
    setIsPlaying(true);
  }
};



  // Funktion zum Umschalten der Chartliste
  const toggleChartList = () => {
    switch (chartList.current) {
        case 'Hiphop': // Achte auf die korrekte Schreibweise
            setChartList({ current: 'PopRadio', alternateName: 'Pop/Radio' });
            break;
        case 'PopRadio':
            setChartList({ current: 'Nominees', alternateName: 'Nominees' });
            break;
        case 'Nominees':
            setChartList({ current: 'Newcomers', alternateName: 'Newcomers' });
            break;
        case 'Newcomers':
            setChartList({ current: 'Hiphop', alternateName: 'Hip Hop' }); // Achte auch hier auf die korrekte Schreibweise
            break;
        default:
            setChartList({ current: 'Hiphop', alternateName: 'Hip Hop' }); // Und hier ebenfalls
    }
};

const categories = [
  { current: 'Hiphop', alternateName: 'Hip Hop' },
  { current: 'PopRadio', alternateName: 'Pop/Radio' },
  { current: 'Nominees', alternateName: 'Nominees' },
  { current: 'Newcomers', alternateName: 'Newcomers' }
];

const nextCategory = () => {
  const currentIndex = categories.findIndex(cat => cat.current === chartList.current);
  const nextIndex = (currentIndex + 1) % categories.length; // Gehe zum nächsten Index, kehre zum Anfang zurück, wenn das Ende erreicht ist
  setChartList(categories[nextIndex]);
};

const prevCategory = () => {
  const currentIndex = categories.findIndex(cat => cat.current === chartList.current);
  const prevIndex = (currentIndex - 1 + categories.length) % categories.length; // Gehe zum vorherigen Index, kehre zum Ende zurück, wenn der Anfang erreicht ist
  setChartList(categories[prevIndex]);
};



  return (
    <div className="home-charts">
        <div className="orb-animation-container">
        <GenerativeOrbAnimation />
      </div>
 <div className="category-navigation-2">
   <div className="category-nav-buttonbar">
   <button onClick={prevCategory}> 
      <img className="nav-arrow-2" src="../img/left.svg" alt=""/>
      </button> 
      <button onClick={nextCategory}><img className="nav-arrow-2" src="../img/right.svg" alt="" /></button> {/* Nächste Kategorie */}
   </div>

      <h2>{chartList.alternateName}</h2>
    </div>
<p className="home-charts-bottom-text">Discover the top 10 {chartList.alternateName.toLowerCase()} tracks of the month.</p>
<div className="home-charts-list">
  {songs.map((song, index) => (
    <div key={song.id} className="chart-song">
      <div className="chart-song-left">
        <span className="chart-position">{index + 1}.</span>
        <Link to={`/song/${song.id}`} style={{ textDecoration: 'none' }}>
          <img src={song.songCoverUrl} alt={song.name} className="chart-song-img" />
        </Link>
      </div>
      <div className="chart-song-middle">
  <Link style={{ textDecoration: 'none' }} className="chart-title-link" to={`/song/${song.id}`}>
    <h4>{song.name}</h4>
  </Link>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {song.artistID ? (
      <Link style={{ textDecoration: 'none' }} className="chart-title-link" to={`/artist/${song.artistID}`}>
        <p>{song.artist}</p>
      </Link>
    ) : (
      <p className="chart-title-link" style={{ textDecoration: 'none' }}>{song.artist}</p>
    )}
    {song.selectedCountry && <CountryFlagSVG country={song.selectedCountry}/>}
  </div>
</div>

      <div className="chart-song-right">
        <button className="play-pause-button" onClick={() => togglePlayPause(song)}>
          {currentSong && currentSong.id === song.id && isPlaying ? 
            <img className="pause-icon" src={pauseIcon} alt="Pause" /> : 
            <img className="play-icon" src={playIcon} alt="Play" />}
        </button>
      </div>
    </div>
  ))}
</div>

{isPlaying && currentSong && (
        currentSong.fileUrl ? (
          <AudioBar
            url={currentSong.fileUrl}
            songImg={currentSong.songCoverUrl || currentSong.imageUrl}
            artistName={currentSong.artist}
            songName={currentSong.name}
            isPlaying={isPlaying}
            togglePlayPause={() => setIsPlaying(false)}
          />
        ) : (
          <SpotifySongs spotifyUrl={currentSong.spotifyLink} />
        )
      )}
    </div>
  );
}

export default HomeCharts;
