import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { ref, get, set } from 'firebase/database';
import { database } from '../../firebaseConfig';
import PagePreview from './PagePreview';
import { PageDetailsProvider } from '../../Utility/PageDetailsContext';
import './LivePage.css'
import DashboardNav from '../DashboardNav';
import HomeHeader from '../../Pages/HomeHeader';
import HomeNewHeader from '../../Pages/HomeNewHeader';
import Footer from '../../Pages/Footer';
import NewMobileHeader from '../../Pages/NewMobileHeader';

function LivePage() {
  const { customPath } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [pageId, setPageId] = useState(null);
  const [isWhiteMode, setIsWhiteMode] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(0);
  const [pageIsLive, setPageIsLive] = useState(false);
  const [dataExists, setDataExists] = useState({
    songs: false,
    longText: false,
    projects: false,
    equipment: false,
    questionsAnswers: false
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const routeRef = ref(database, `PageRoutes/${customPath}`);

    get(routeRef).then(snapshot => {
      if (snapshot.exists()) {
        const { pageId } = snapshot.val();
        setPageId(pageId);

        const pageDataRef = ref(database, `Pages/${pageId}/pageData`);
        get(pageDataRef).then(pageDataSnapshot => {
          if (pageDataSnapshot.exists()) {
            const pageData = pageDataSnapshot.val();
            setPageIsLive(pageData.pageIsLive || false);

            if (location.pathname.startsWith('/market/')) {
              setIsWhiteMode(false);
              setSelectedStyle(0);
            } else {
              setIsWhiteMode(pageData.isWhiteMode || false);
              setSelectedStyle(pageData.selectedStyle || 0);
            }
          }
          setLoading(false);
        });
      } else {
        setError(`No routes found for ${customPath}`);
        setLoading(false);
      }
    }).catch(error => {
      console.error('Error fetching route details:', error);
      setError('Failed to fetch route details.');
      setLoading(false);
    });
  }, [customPath, location.pathname]);

  useEffect(() => {
    if (pageId) {
      const clickCountRef = ref(database, `Pages/${pageId}/pageData/clickCount`);
      get(clickCountRef).then((snapshot) => {
        const currentCount = snapshot.val() || 0;
        set(clickCountRef, currentCount + 1);
      }).catch((error) => {
        console.error('Error updating click count:', error);
      });
    }
  }, [pageId]);  // Depend on pageId to ensure it's loaded
  


  useEffect(() => {
    const root = document.documentElement;
    if (isWhiteMode) {
      root.setAttribute('data-theme', 'white');
    } else {
      root.setAttribute('data-theme', 'black');
    }
  }, [isWhiteMode, selectedStyle]);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!pageId || !pageIsLive) {
    return <div className="page-offline-screen">
        <div>
            <img src="../../img/logo.svg" alt="" />
        <h2>Ups, seems there's nothing...</h2>
        <Link style={{ backgroundColor: 'var(--blue)' , textDecoration: 'none', fontSize: '24px', padding: '10px 30px', borderRadius: '100px', color:'white'}} to="/">Home</Link>
        </div>
        </div>;
  }

  return (
    <div className="live-page-container">
        <HomeNewHeader />
        <NewMobileHeader />
      <PageDetailsProvider pageId={pageId}>
        <PagePreview pageId={pageId} isLivePage={true} dataExists={dataExists} isWhiteMode={isWhiteMode} selectedStyle={selectedStyle} />
   <Footer />
      </PageDetailsProvider>
      
    </div>
  );
}

export default LivePage;
