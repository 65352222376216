import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get } from 'firebase/database';
import { database } from "../firebaseConfig";
import './HomeCounter.css';
import { Link } from 'react-router-dom';
import LoadingScreen from '../Utility/LoadingScreen';

function HomeCounter() {
  const [awardName, setAwardName] = useState('');
  const [textOne, setTextOne] = useState('');
  const [textTwo, setTextTwo] = useState('');
  const [days, setDays] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const [isFutureDate, setIsFutureDate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const loadAwardData = async () => {
      const awardRef = databaseRef(database, 'Awards/uniqueAward');
      const snapshot = await get(awardRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setAwardName(data.name);
        setTextOne(data.textOne);
        setTextTwo(data.textTwo);

        const now = new Date().getTime();
        const awardDate = new Date(data.date).getTime();

        if (awardDate > now) {
          setIsFutureDate(true);
          updateCounter(data.date);
        } else {
          setIsFutureDate(false);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    const updateCounter = (toDate) => {
      const countDownDate = new Date(toDate).getTime();

      const x = setInterval(() => {
        const now = new Date().getTime();
        const distance = countDownDate - now;

        if (distance > 0) {
          setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
          setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
          setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
          setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
        } else {
          clearInterval(x);
          setIsFutureDate(false);
        }
      }, 1000);
    };

    loadAwardData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrolledDown = window.scrollY > 200;
      const nearBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 200;
  
      if (scrolledDown && !nearBottom) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isFutureDate || !isVisible) {
    return null;
  }



  
  return (
    <div className="home-counter-container">
        <div className="home-counter-section_1">
        <Link to="/signup" style={{textDecoration: 'none', color: 'white'}}>
            <p>{awardName}</p>
            </Link>

            </div>
      <div className="home-counter-section_2">
        <div className="home-counter-middle">
            {/* <img src="./img/award_3.webp" alt="" /> */}
          <div className="home-counter-counter">
            <span className="counter-value">{days}<span className="counter-unit">Days</span></span>:
            <span className="counter-value">{hours}<span className="counter-unit">Hours</span></span>:
            <span className="counter-value">{minutes}<span className="counter-unit">Minutes</span></span>:
            <span className="counter-value">{seconds}<span className="counter-unit">Seconds</span></span>
          </div>
        </div>
      </div>
      <div className="home-counter-section_3"><p>{textOne}</p></div>
    </div>
  );
}

export default HomeCounter;
