import React, { useState, useEffect } from 'react';
import { ref, onValue, update, set } from 'firebase/database';
import { database } from '../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import './UtilitySettings.css';  // Stelle sicher, dass der Pfad zur CSS-Datei korrekt ist.

function PageLiveToggle({ pageId }) {
  const [isLive, setIsLive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contentOwnershipConfirmed, setContentOwnershipConfirmed] = useState(false);
  const [addToMarketplaceConfirmed, setAddToMarketplaceConfirmed] = useState(true); // Initialwert auf true gesetzt
  const [routeName, setRouteName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const liveRef = ref(database, `Pages/${pageId}/pageData/pageIsLive`);
    const unsubscribe = onValue(liveRef, (snapshot) => {
      if (snapshot.exists()) {
        setIsLive(snapshot.val());
      }
    });

    const routeRef = ref(database, `Pages/${pageId}/route`);
    const unsubscribeRoute = onValue(routeRef, (snapshot) => {
      if (snapshot.exists()) {
        setRouteName(snapshot.val());
      }
    });

    return () => {
      unsubscribe();
      unsubscribeRoute();
    };
  }, [pageId]);

  const handleToggleClick = () => {
    if (!isLive) {
      setShowModal(true);
    } else {
      toggleLiveStatusDirectly(false);
    }
  };

  const toggleLiveStatusDirectly = (newStatus) => {
    const liveRef = ref(database, `Pages/${pageId}/pageData/pageIsLive`);
    set(liveRef, newStatus).then(() => {
      setIsLive(newStatus);
      setShowModal(false);
    }).catch(error => {
      console.error('Failed to update page live status:', error);
    });
  };

  const confirmToggleLiveStatus = () => {
    if (contentOwnershipConfirmed) {
      const timestamp = new Date().toISOString();
      const updates = {
        pageIsLive: true,
        contentOwnership: { confirmed: true, timestamp },
        addToMarketplace: { confirmed: addToMarketplaceConfirmed, timestamp }
      };
      update(ref(database, `Pages/${pageId}/pageData`), updates).then(() => {
        setIsLive(true);
        setShowModal(false);
        setContentOwnershipConfirmed(false);
        setAddToMarketplaceConfirmed(true); // Reset auf den ursprünglichen Zustand
        navigate(`/${routeName}`); // Weiterleitung auf die Route der Seite
      }).catch(error => {
        console.error('Failed to update page live status:', error);
      });
    } else {
      alert("Please confirm all conditions before going live.");
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="page-live-toggle-container">
      <span style={{ marginRight: '10px' }} className="page-live-status-text">{isLive ? 'Live' : 'Draft'}</span>
      <label className="page-live-toggle-switch">
        <input type="checkbox" checked={isLive} onChange={handleToggleClick} />
        <span className="page-live-slider round"></span>
      </label>
      {showModal && (
        <div className="gig-modal-overlay" onClick={() => setShowModal(false)}>
          <div className="gig-confirmation-modal" onClick={e => e.stopPropagation()}>
            <img className="gig-delete-topper" src="../../img/delete.svg" alt="Close" onClick={() => setShowModal(false)} />
            <h2>Publish Your Link</h2>
            <p>Publish your link and let others discover your page.</p>
            <div className={addToMarketplaceConfirmed ? "custom-checkbox checked" : "custom-checkbox"}
                 onClick={() => setAddToMarketplaceConfirmed(!addToMarketplaceConfirmed)}>
              <img src={addToMarketplaceConfirmed ? "../../img/check.webp" : "../../img/check_3.webp"} alt="" />
              <div>
                <h4>Apply for Backstage</h4>
                <p>Apply today and start offering your service to our community.</p>
              </div>
              <img className="dollar-wallet-top" src="../../img/dollar.webp" alt="" />
            </div>
            <div className={contentOwnershipConfirmed ? "custom-checkbox checked" : "custom-checkbox"}
                 onClick={() => setContentOwnershipConfirmed(!contentOwnershipConfirmed)}>
              <img src={contentOwnershipConfirmed ? "../../img/check.webp" : "../../img/check_3.webp"} alt="" />
             <h5> I confirm that the content I am providing is owned by me, and I take full responsibility for the posted gig.</h5>
            </div>
            <div className="add-gig-live-button-bar">
              <button onClick={() => setShowModal(false)}>Cancel</button>
              <button onClick={confirmToggleLiveStatus} disabled={!contentOwnershipConfirmed} className={contentOwnershipConfirmed ? "active" : "inactive"}>Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PageLiveToggle;
