import React, { useState, useEffect, useRef } from 'react';
import { ref as databaseRef, get } from 'firebase/database';
import { database } from "../firebaseConfig";  // Stellen Sie sicher, dass der richtige Pfad verwendet wird
import './ReviewGeneratorSong.css';

function extractSpotifyId(spotifyUrl) {
  if (spotifyUrl.startsWith('https')) {
    const match = spotifyUrl.match(/track\/([a-zA-Z0-9]{22})/);
    return match ? match[1] : null;
  }
  return spotifyUrl; // Rückgabe der ID, falls die URL keine vollständige URL war
}

function ReviewGeneratorSong({ songId }) {
  const [fileUrl, setFileUrl] = useState(null);
  const [spotifyLink, setSpotifyLink] = useState(null);
  const [songCoverUrl, setSongCoverUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);  // Ref für das Audio-Element

  useEffect(() => {
    // Fetch song data from the database
    const fetchSongData = async () => {
      const songRef = databaseRef(database, `Songs/${songId}`);
      try {
        const snapshot = await get(songRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          setFileUrl(data.fileUrl || null);
          setSpotifyLink(data.spotifyLink || null);
          setSongCoverUrl(data.songCoverUrl || null);
        }
      } catch (error) {
        console.error('Error fetching song data:', error);
      }
    };

    fetchSongData();
  }, [songId]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();  // Audio pausieren
    } else {
      audioRef.current.play();   // Audio abspielen
    }
    setIsPlaying(!isPlaying);  // Zustand umschalten
  };

  const spotifyId = spotifyLink ? extractSpotifyId(spotifyLink) : null;

  return (
    <div className="review-generator-song">
      {/* Falls ein Song-Cover vorhanden ist, anzeigen */}
      {songCoverUrl && <img src={songCoverUrl} alt="Song Cover" className="song-cover-teamdashboard" />}

      {/* Falls die fileUrl vorhanden ist, Play/Pause-Button anzeigen */}
      {fileUrl ? (
        <div className="generator-audio-player-container">
          <audio ref={audioRef} src={fileUrl} type="audio/mpeg" />
          <button className={`generator-play-pause-button ${isPlaying ? 'pause' : 'play'}`} onClick={togglePlayPause}>
            {isPlaying ? '❚❚' : '►'}
          </button>
        </div>
      ) : (
        // Falls fileUrl nicht vorhanden ist, Spotify-Widget anzeigen, falls Spotify-Link vorhanden ist
        spotifyId && (
          <div className="spotify-embeddo-container">
            <iframe
              src={`https://open.spotify.com/embed/track/${spotifyId}?utm_source=generator`}
              width="270"
              height="80"
              frameBorder="0"
              allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
        )
      )}
    </div>
  );
}

export default ReviewGeneratorSong;
