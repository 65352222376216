import React, { useState, useEffect, useRef } from 'react';
import './AudioBar.css'; // Ihre CSS-Datei
import playIcon from '../img/play.svg';
import pauseIcon from '../img/pause.svg';

function AudioBar({url, songImg, artistName, songName, isPlaying, togglePlayPause, className}) {
    const [volume, setVolume] = useState(0.5);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(null);
  
    useEffect(() => {
        const playAudio = async () => {
          if (isPlaying) {
            try {
              await audioRef.current.play();
            } catch (error) {
              console.error('Fehler beim Abspielen des Audios', error);
            }
          } else {
            audioRef.current.pause();
          }
        };
  
        playAudio();
      }, [isPlaying, url]);
    
      useEffect(() => {
        audioRef.current.volume = volume;
      }, [volume]);
  
    useEffect(() => {
      audioRef.current.volume = volume;
    }, [volume]);

    const handleVolumeChange = (e) => {
      setVolume(e.target.value);
    };

    const handleProgressChange = (e) => {
      const newTime = (e.target.value / 100) * duration;
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    };

    useEffect(() => {
      const audio = audioRef.current;

      const setAudioData = () => {
        setDuration(audio.duration);
      };

      const updateProgress = () => {
        setCurrentTime(audio.currentTime);
      };

      audio.addEventListener('loadedmetadata', setAudioData);
      audio.addEventListener('timeupdate', updateProgress);

      return () => {
        audio.removeEventListener('loadedmetadata', setAudioData);
        audio.removeEventListener('timeupdate', updateProgress);
      };
    }, []);

    // Hilfsfunktion zur Formatierung der Zeit in MM:SS
    const formatTime = time => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className={`audio-bar ${className}`}>
          <audio ref={audioRef} src={url} onEnded={() => togglePlayPause(false)} />
          <div className="song-info">
            <img src={songImg} alt="Song" className="song-img" />
            <div>
              <p style={{fontWeight: '500', fontSize: '14px',marginBottom: '-10px' }}>{songName}</p>
              <p style={{fontWeight: '500', fontSize: '10px', color: 'lightgray'}}>{artistName}</p>
            </div>
          </div>
          <div className="song-player-middle">
            <button className="play-pause-button" onClick={togglePlayPause}>
              {isPlaying ? <img src={pauseIcon} style={{marginLeft: '1px'}}className="pause-icon" alt="Pause" /> : <img src={playIcon} className="play-icon" alt="Play" />}
            </button>

            <div className="time-display">
            <p>{formatTime(currentTime)}</p>

            <input
              type="range"
              min="0"
              max="100"
              value={duration ? (currentTime / duration) * 100 : 0}
              onChange={handleProgressChange}
              className="progress-bar"
            />
          <p>{formatTime(duration)}</p>
          </div>
          </div>

          <div className="song-player-end">
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
              className="volume-control"
            />
            <img src="../img/speakers.svg" alt="" className="speakers-icon"/>
          </div>
        </div>
    );
}

export default AudioBar;
