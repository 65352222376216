import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../Utility/AppContext'; // Stelle sicher, dass der Pfad korrekt ist
import './DashboardNav.css'; // Stelle sicher, dass du eine entsprechende CSS-Datei erstellst
import { getAuth, signOut } from 'firebase/auth';
import { useChat } from '../Utility/ChatContext';

function DashboardNav() {
  const { userData, isUserDataLoading } = useAppContext();
  const location = useLocation(); // Zugriff auf den aktuellen Pfad
  const auth = getAuth();
  const navigate = useNavigate();
  const { totalUnread } = useChat();

  // Ersetze 'PfadZumStandardAvatar' mit dem tatsächlichen Pfad zu deinem Standardavatar
  const avatarImage = userData.artistImageUrl || './img/avatar.svg'; 
  const authId = auth.currentUser ? auth.currentUser.uid : null;

  // Überprüfen, ob der Benutzer sich auf der /my-songs Route befindet
  const onMySongsPage = location.pathname === '/my-songs';
  const onMyDashboardPage = location.pathname === '/dashboard';
  const onlyMyChatsPage = location.pathname === '/chats';
  const onlyMyBusinessPage = location.pathname === '/pages';
  const onlyMySavedPage = location.pathname === '/favorites';

  const handleLogout = () => {
    signOut(auth).then(() => {
      // Logout erfolgreich, Weiterleitung zur Startseite
      navigate('/');
    }).catch((error) => {
      // Ein Fehler ist aufgetreten
      console.error("Logout failed", error);
    });
  };

  if (isUserDataLoading || !userData) {
    return <div>Loading...</div>;  // Zeigen Sie eine Ladeanzeige an, während die Daten geladen werden
  }

  return (
    <div className="dashboardNav">
      <img src="/img/curve.svg" className="dashboardNav-curve top" alt="Curve Top" />
      <div className="profileSection">
        <Link to="/" className="userSettings">
          <div><img src="./img/left.svg" alt="User Avatar" className="profile_back_logo_icon" /></div>
          <img src="./img/logo.svg" alt="User Avatar" className="profile_logo_img" />
        </Link> 
      </div>
      <div className="profile-nav-label"> 
        <h4>Backstage</h4>
      </div>
      <div className="profile-nav-item-box">
        {!userData.isExpert && (
          <Link to="/dashboard" className={`profile-nav-item-link-box ${onMyDashboardPage ? 'hasRoute' : ''}`}>
            <div className={`profile-nav-item-image-box ${onMyDashboardPage ? 'song_dark' : 'song_red'}`}>
              <img src={`./img/${onMyDashboardPage ? 'award_red.webp' : 'award_dark.webp'}`} alt="" />
            </div>
            <p className={`profile-nav-item ${onMyDashboardPage ? 'hasRoute' : ''}`}>My Awards</p>
            <img src="./img/curve.svg" className="menu-triangle top" alt="Curve Top" />
            <img src="./img/curve.svg" className="menu-triangle bottom" alt="Curve Bottom" />
          </Link>
        )}
        <Link to="/pages" className={`profile-nav-item-link-box ${onlyMyBusinessPage ? 'hasRoute' : ''}`}>
          <div className={`profile-nav-item-image-box ${onlyMyBusinessPage ? 'hasRoute' : ''}`}>
            <img src={`./img/${onlyMyBusinessPage ? 'business_red.webp' : 'business_dark.webp'}`} alt="" />
          </div>
          <p className={`profile-nav-item ${onlyMyBusinessPage ? 'hasRoute' : ''}`}>Art Link <span>NEW</span></p>
          <img src="./img/curve.svg" className="menu-triangle top" alt="Curve Top" />
          <img src="./img/curve.svg" className="menu-triangle bottom" alt="Curve Bottom" />
        </Link>
        <Link to="/chats" className={`profile-nav-item-link-box ${onlyMyChatsPage ? 'hasRoute' : ''}`}>
          <div className={`profile-nav-item-image-box ${onlyMyChatsPage ? 'hasRoute' : ''}`}>
            <img src={`./img/${onlyMyChatsPage ? 'bubble_red.webp' : 'bubble_dark.webp'}`} alt="" />
          </div>
          <p className={`profile-nav-item ${onlyMyChatsPage ? 'hasRoute' : ''}`}>Messages</p>
          {totalUnread > 0 && (
            <div className="total-unread-messages-menu-1">{totalUnread}</div>
          )}
          <img src="./img/curve.svg" className="menu-triangle top" alt="Curve Top" />
          <img src="./img/curve.svg" className="menu-triangle bottom" alt="Curve Bottom" />
        </Link>
        <Link to="/favorites" className={`profile-nav-item-link-box ${onlyMySavedPage ? 'hasRoute' : ''}`}>
          <div className={`profile-nav-item-image-box ${onlyMySavedPage ? 'hasRoute' : ''}`}>
            <img src={`./img/${onlyMySavedPage ? 'heart_red.webp' : 'heart_dark.webp'}`} alt="" />
          </div>
          <p className={`profile-nav-item ${onlyMySavedPage ? 'hasRoute' : ''}`}>Favorites</p>
          <img src="./img/curve.svg" className="menu-triangle top" alt="Curve Top" />
          <img src="./img/curve.svg" className="menu-triangle bottom" alt="Curve Bottom" />
        </Link>
      </div>
<div className="profileSection-2">
  {/* Avatar-Bild als Link, der einen neuen Tab öffnet */}
  <a 
    href={`/artist/${authId}`} // Nutze die artistId aus userData
    target="_blank" 
    rel="noopener noreferrer"
    style={{ display: 'inline-block', textDecoration: 'none'}}  // So bleibt das Bild anklickbar
  >
    <img src={avatarImage} alt="User Avatar" className="userAvatar" />

  <div className="profile-section-header-text">
    <h2 className="userName">{userData.artistName || ''}</h2>
  </div>
  </a>

  <Link style={{color: 'white', textDecoration: 'none'}} className="nav-down-settings-button" to="/settings">
    Settings
    <img src="../../img/settings.svg" alt="" />

    </Link>

</div>

      <img src="/img/curve.svg" className="dashboardNav-curve bottom" alt="Curve Bottom" />
    </div>
  );
}

export default DashboardNav;
