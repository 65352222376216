import React, { useEffect, useRef, useState } from 'react';
import './JuryInfo.css';

function JuryInfo() {
  const [scrollY, setScrollY] = useState(0); // Aktuelle Scroll-Position
  const [rotation, setRotation] = useState(0); // Aktueller Rotationswinkel
  const prevScrollY = useRef(0); // Vorherige Scroll-Position

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Berechnung der Drehung basierend auf der Scroll-Richtung
      if (currentScrollY > prevScrollY.current) {
        // Nach unten scrollen
        setRotation((prev) => prev - 0.2); // Nach links drehen
      } else if (currentScrollY < prevScrollY.current) {
        // Nach oben scrollen
        setRotation((prev) => prev + 0.2); // Nach rechts drehen
      }

      prevScrollY.current = currentScrollY; // Update der vorherigen Scroll-Position
    };

    // Scroll-Listener hinzufügen
    window.addEventListener('scroll', handleScroll);

    // Cleanup-Funktion
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="jury-info-container">
      <div className="jury-info-left">
        <h5>HOW THE VOTING WORKS</h5>
        <h2><span>500+</span> Top Tier Jury Members</h2>
        <p>
At Triple A Music Awards, our mission is to democratize the voting process, ensuring it is fair and just for every artist who applies. Our jury members evaluate music based on creativity, quality, and additional metrics that help our system select the most deserving artists.</p>
        <div className="jury-info-container-boxen">
        <div className="jury-info-container-boxen">
  <a href="/signup" target="_blank" rel="noopener noreferrer" className="jury-info-container-box">
    <img src="../../img/glare.webp" alt="" />
    <p>Submit Your Best Song</p>
    <img id="jury-info-container-next" src="../../img/down.webp" alt="" />
  </a>
  <a href="/signup" target="_blank" rel="noopener noreferrer" className="jury-info-container-box">
    <img src="../../img/verified_2.webp" alt="" />
    <p>Get Your Song Evaluated by up to 500+ Experts</p>
    <img id="jury-info-container-next" src="../../img/down.webp" alt="" />
  </a>
  <a href="/signup" target="_blank" rel="noopener noreferrer" className="jury-info-container-box">
    <img src="../../img/grant.webp" alt="" />
    <img id="jury-info-container-next" src="../../img/down.webp" alt="" />
    <p>Win Your First Music Award or Award Nomination</p>
  </a>
</div>

        </div>
      </div>
      <div className="jury-info-right">
        <div className="jury-info-right-image-box">
          <img src="../../img/jury_pic.webp" alt="" />
          <img
            src="../../img/jury_pros.webp"
            id="jury-info-rondo"
            style={{ transform: `rotate(${rotation}deg)` }} // Dynamische Rotation
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default JuryInfo;
