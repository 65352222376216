import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ref as databaseRef, push } from 'firebase/database';
import { database } from '../firebaseConfig'; // Firebase-Konfiguration
import './Footer.css'; // Stelle sicher, dass du den Pfad zu deiner CSS-Datei entsprechend anpasst

const buttonVariants = [
  { id: 1, text: "Sign Up Now" },
  { id: 2, text: "Apply Here" },
  { id: 3, text: "Get Started" }
];

function Footer() {
  const [signupButtonVariant, setSignupButtonVariant] = useState(buttonVariants[0]);

  // Zufällige Auswahl eines Button-Textes beim Laden der Komponente
  useEffect(() => {
    const randomVariantIndex = Math.floor(Math.random() * buttonVariants.length);
    setSignupButtonVariant(buttonVariants[randomVariantIndex]);
  }, []);

  // Funktion zum Tracken des Klicks
  const handleSignupClick = () => {
    const clickData = {
      variantId: signupButtonVariant.id,
      buttonText: signupButtonVariant.text,
      timestamp: Date.now()
    };

    // Speichern der Daten in Firebase
    const analyticsRef = databaseRef(database, '/Analytics/FooterSignupButton');
    push(analyticsRef, clickData);

    console.log('Data pushed to Firebase:', clickData);
  };

  // Funktion zum Öffnen der E-Mail
  const handleContactClick = () => {
    window.location.href = "mailto:hey@aaartists.com";
  };

  return (
    <footer className="footer">
      <div className="footer-upper">
        <div className="footer-logo">
          <img src="/img/logo.svg" alt="Logo" />
        </div>
        <div className="footer-links">
          <Link to="/login">
            <button className="outline-button-3">LOG IN</button>
          </Link>
          <Link to="/signup">
            <button className="outline-button-3" onClick={handleSignupClick}>
              {signupButtonVariant.text}
            </button>
          </Link>
        </div>
      </div>

      {/* Neuer Mittelteil */}
      <div className="footer-middle">
        <div className="footer-middle-left">
          <p>
            Triple A Music is a revolutionary music industry platform designed to empower emerging artists by connecting them with exclusive opportunities and industry networks. Focused on breaking down traditional music industry barriers, AAA offers artists the exposure and connections needed to succeed in today’s competitive landscape. From gaining millions of Spotify streams to landing high-profile placements in TV, film, and advertising, AAA ensures that every artist’s talent is recognized and elevated to its full potential.
          </p>
          <div className="footer-help">
            <button className="footer-help-button" onClick={handleContactClick}>
              <img src="../../img/mail.svg" alt="" />
              <p>Need help?</p>
            </button>
          </div>
        </div>
        <div className="footer-middle-right">
          <Link style={{textDecoration: 'none'}} to="/collaborations">
            <button id="footer-award-button" className="footer-button">
              <div>
                <img style={{ height: '25px' }} className="fotter-button-img" src="../../img/link-to-device.svg" alt="link icon" />
              </div>
              Connect with Pro's
            </button>
          </Link>
          <Link style={{textDecoration: 'none'}} to="/market">
            <button id="footer-market-button" className="footer-button">
              <div>
                <img className="fotter-button-img" src="../../img/bag.svg" alt="market icon" />
              </div>
              AAA Music Marketplace
            </button>
          </Link>
          <a style={{textDecoration: 'none'}} href="https://www.tiktok.com/@aaartists" target="_blank" rel="noopener noreferrer">
            <button id="footer-tiktok-button" className="footer-button">
              <div>
                <img className="fotter-button-img" src="../../img/tiktok.svg" alt="tiktok icon" />
              </div>
              Follow us on Tiktok
            </button>
          </a>
        </div>
      </div>

      <div className="footer-lower">
        <p>&copy; 2024 Aaartists.com</p>
        <a target="_blank" href="/terms-of-use">Terms of use</a>
        <a target="_blank" href="/privacy-policy">Privacy Policy</a>
        <a target="_blank" href="https://instagram.com/wavyy.magazine">Instagram</a>
        <a target="_blank" href="https://tiktok.com/@aaartists">Tiktok</a>
      </div>
    </footer>
  );
}

export default Footer;
