import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import GenerativeOrbAnimation4 from '../Utility/GenerativeOrbAnimation4';
import '../Pages/SubmissionPage.css';

const WelcomeScreen = () => {
    let navigate = useNavigate(); // useNavigate Hook initialisieren

    useEffect(() => {
        const utmData = sessionStorage.getItem('utm_data');

        // Prüfen, ob das Event bereits gepusht wurde
        const eventPushed = sessionStorage.getItem('subscribed_event_pushed');

        if (utmData && !eventPushed) {
            const parsedUtmData = JSON.parse(utmData);

            // Pushe das "subscribed"-Event in den DataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'subscribed',
                method: 'Page View', // Geben wir den Auslöser an
                page: '/welcome',
                ...parsedUtmData,
            });

            console.log('UTM-Daten gefunden und "subscribed"-Event gepusht:', {
                event: 'subscribed',
                method: 'Page View',
                page: '/welcome',
                ...parsedUtmData,
            });

            // Markiere das Event als gepusht
            sessionStorage.setItem('subscribed_event_pushed', 'true');
        } else if (eventPushed) {
            console.log('Event wurde bereits gepusht. Kein erneuter Push.');
        } else {
            console.log('Keine UTM-Daten gefunden. Kein Event getriggert.');
        }
    }, []);

    const handleApplicationBoxClick = (path) => {
        navigate(path);
    };

    return (
        <div className="submission-page">
            <GenerativeOrbAnimation4 />
            <Link to="/">
                <img src="./img/logo.svg" alt="Logo" style={{ width: '200px', marginBottom: '15px' }} />
            </Link>
            <div className="application-box" onClick={() => handleApplicationBoxClick('/artists')}>
                <h2>Music Artists</h2>
                <p>Unlock your potential! Create an account and complete our application form to showcase your talent.</p>
                <button className="outline-button">Apply as Artist</button>
            </div>
            <div className="application-box" id="application-box-blue" onClick={() => handleApplicationBoxClick('/experts')}>
                <h2>Industry & Business</h2>
                <p>Discover the next big thing! Please complete our application form to access fresh talent.</p>
                <button className="outline-button">Apply as Label</button>
            </div>
        </div>
    );
};

export default WelcomeScreen;
