import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { database } from '../firebaseConfig';
import ArtistTop from './ArtistTop';
import HomeNewHeader from './HomeNewHeader';
import NewMobileHeader2 from './NewMobileHeader2';
import ArtistMore from './ArtistMore';
import ArtistEditorQuote from './ArtistEditorQuote';
import AllArtistGigs from './AllArtistGigs';
import SellArt from './SellArt';
import ArtistSongs from './ArtistSongs';
import CollabSongs from './CollabSongs';
import AudioBar from '../Audio/AudioBar';
import SpotifySongs from '../Audio/SpotifySongs';
import Footer from './Footer';
import LoadingScreen from '../Utility/LoadingScreen';
import './ArtistDetailPage.css';

// Importieren der benötigten Modale und Firebase-Funktionen
import CreateCollabModal from './CreateCollabModal';
import BuyProModal from './BuyProModal';
import CollabLoginPopup from './CollabLoginPopup';
import FinishSettingsModal from './FinishSettingsModal';
import CollabSuccessModal from './CollabSuccessModal';
import { ref as databaseRef, get } from 'firebase/database';

const ArtistDetailPage = () => {
  const { artistID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [artistDetails, setArtistDetails] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [filteredSongIds, setFilteredSongIds] = useState([]);
  const [filteredGenres, setFilteredGenres] = useState([]);
  const [genresLoaded, setGenresLoaded] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showActionButtons, setShowActionButtons] = useState(false);

  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  // Zustände für die Modale und Benutzerdaten
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showCollabModal, setShowCollabModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showFinishSettingsModal, setShowFinishSettingsModal] = useState(false);
  const [showCollabLoginPopup, setShowCollabLoginPopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [userCredits, setUserCredits] = useState(0);
  const [isPremium, setIsPremium] = useState(false);
  const [hasSettings, setHasSettings] = useState(null);
  const [hasExpertSettings, setHasExpertSettings] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isChatExists, setIsChatExists] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const artistRef = ref(database, `Artists/${artistID}`);
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        setCurrentUserId(user.uid);

        const userRef = databaseRef(database, `Artists/${user.uid}`);
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          setUserCredits(userData.collabCredits || 0);
          setIsPremium(userData.isPremium || false);
          setHasSettings(userData.hasSettings || false);
          setHasExpertSettings(userData.hasExpertSettings || false);
        }

        // Überprüfen, ob ein Chat bereits existiert
        checkChatExists(user.uid, artistID);
      } else {
        setIsLoggedIn(false);
        setCurrentUserId(null);
      }
    });

    onValue(artistRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setArtistDetails(data);
        setBackgroundImage(data.artistImageUrl);
      }
    });

    // Überprüfe, ob es eine vorherige Seite in der Historie gibt
    if (window.history.state && window.history.length > 2 && location.key !== 'default') {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }

    // Scroll-Listener für Action Buttons
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 100 && scrollPosition < 2000) {
        setShowActionButtons(true);
      } else {
        setShowActionButtons(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      unsubscribe();
    };
  }, [artistID, location.key]);

  const checkChatExists = async (currentUserId, artistID) => {
    const chatId1 = `${currentUserId}_${artistID}`;
    const chatId2 = `${artistID}_${currentUserId}`;

    const chatRef1 = databaseRef(database, `Chats/${chatId1}`);
    const chatRef2 = databaseRef(database, `Chats/${chatId2}`);

    const chatSnapshot1 = await get(chatRef1);
    const chatSnapshot2 = await get(chatRef2);

    if (chatSnapshot1.exists() || chatSnapshot2.exists()) {
      setIsChatExists(true);
    }
  };

  const handleSendMessage = () => {
    if (isChatExists) return;

    if (!isLoggedIn) {
      setShowCollabLoginPopup(true);
      return;
    }

    if (!hasSettings && !hasExpertSettings) {
      setShowFinishSettingsModal(true);
      return;
    }

    if (isPremium || userCredits > 0) {
      setShowCollabModal(true);
    } else {
      setShowUpgradeModal(true);
    }
  };

  const handleOverlayClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowCollabModal(false);
      setShowUpgradeModal(false);
      setShowFinishSettingsModal(false);
      setShowCollabLoginPopup(false);
    }
  };

  const handleSuccessMessage = async () => {
    setShowSuccessModal(true);
    await checkChatExists(currentUserId, artistID);
    setTimeout(() => {
      setShowSuccessModal(false);
    }, 3000);
  };

  // Filtere Songs erst, wenn die Genres geladen sind
  useEffect(() => {
    if (genresLoaded && filteredGenres.length > 0) {
      filterSongsByGenres(filteredGenres);
    }
  }, [genresLoaded, filteredGenres]);

  const filterSongsByGenres = (genres) => {
    const songsRef = ref(database, 'Songs');
    const matchingSongIds = [];

    onValue(songsRef, (snapshot) => {
      const songsData = snapshot.val();
      if (songsData) {
        Object.keys(songsData).forEach((songId) => {
          const song = songsData[songId];

          // Exaktes Matching: Prüfen, ob das Genre des Songs exakt mit den Genres des Künstlers übereinstimmt
          if (song.genre && genres.includes(song.genre.trim())) {
            matchingSongIds.push(songId);
          }
        });

        // Setze die gefilterten Song-IDs in den Zustand
        setFilteredSongIds(matchingSongIds);
      }
    });
  };

  const togglePlayPause = (song) => {
    if (currentSong && song.id === currentSong.id) {
      setIsPlaying(!isPlaying); // Song pausieren oder abspielen
    } else {
      setCurrentSong(song);
      setIsPlaying(true); // Wiedergabe starten
    }
  };

  if (!artistDetails) {
    return <LoadingScreen />;
  }

  return (
    <div className="artist-detail-page-container" onClick={handleOverlayClick}>
      {/* Modale */}
      {showCollabModal && currentUserId && (
        <div ref={modalRef}>
          <CreateCollabModal
            senderId={currentUserId}
            receiverId={artistID}
            receiverImageUrl={artistDetails.artistImageUrl}
            onClose={() => setShowCollabModal(false)}
            onMessageSent={(receiverId) => {
              setShowCollabModal(false);
              handleSuccessMessage();
            }}
          />
        </div>
      )}
      {showUpgradeModal && (
        <div ref={modalRef}>
          <BuyProModal onClose={() => setShowUpgradeModal(false)} />
        </div>
      )}
      {showFinishSettingsModal && (
        <div ref={modalRef}>
          <FinishSettingsModal onClose={() => setShowFinishSettingsModal(false)} />
        </div>
      )}
      {showCollabLoginPopup && (
        <div ref={modalRef}>
          <CollabLoginPopup onClose={() => setShowCollabLoginPopup(false)} />
        </div>
      )}
      {showSuccessModal && (
        <CollabSuccessModal onClose={() => setShowSuccessModal(false)} />
      )}

      {/* Hintergrundbild */}
      {backgroundImage && (
        <div
          className="artist-background-blur"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        />
      )}
      <div
        className="artist-mobile-action-buttons"
        style={{
          opacity: showActionButtons ? 1 : 0,
          visibility: showActionButtons ? 'visible' : 'hidden',
          transition: 'opacity 0.2s ease-in-out, visibility 0.2s ease-in-out',
        }}
      >
        <button
          onClick={handleSendMessage}
          disabled={isChatExists}
          style={{ cursor: isChatExists ? 'not-allowed' : 'pointer' }}
        >
          <p>{isChatExists ? 'Chat Exists' : 'Connect'}</p>
          <img src="../../img/link-to-device.svg" alt="Connect" />
        </button>
      </div>
      {/* Inhalt */}
      <div className="artist-detail-content">
        <HomeNewHeader />
        <div className="mobile-header-2-wrapper">
          <NewMobileHeader2 />
        </div>

        <div className="artist-detail-wrapper">
          <ArtistTop
            artistDetails={artistDetails}
            artistID={artistID}
            showBackButton={showBackButton}
            onConnectClick={handleSendMessage} // Übergibt die Funktion an ArtistTop
            isChatExists={isChatExists} // Übergibt den Zustand an ArtistTop
          />
          <ArtistMore artistDetails={artistDetails} artistID={artistID} />
          <ArtistEditorQuote artistID={artistID} />

          {artistDetails && artistID && <SellArt artistId={artistID} />}
          <AllArtistGigs artistId={artistID} />

          <ArtistSongs
            artistID={artistID}
            currentSong={currentSong}
            isPlaying={isPlaying}
            togglePlayPause={togglePlayPause}
          />
          <div
            className="collab-songs-wrappe"
            style={{ marginLeft: '40px', marginRight: '40px', marginBottom: '40px' }}
          >
            <h3
              style={{
                color: 'grey',
                textAlign: 'left',
                marginLeft: '30px',
                fontFamily: 'Wide',
                marginTop: '30px',
              }}
            >
              {filteredGenres.length > 0 ? `More ${filteredGenres.join(', ')}` : 'More'}
            </h3>
            <CollabSongs filteredSongIds={filteredSongIds} />
          </div>

          <Footer />
          {isPlaying && currentSong && (
            <div className="audio-bar-container-5">
              {currentSong.fileUrl ? (
                <AudioBar
                  url={currentSong.fileUrl}
                  songImg={currentSong.songCoverUrl || currentSong.imageUrl}
                  artistName={currentSong.artist}
                  songName={currentSong.name}
                  isPlaying={isPlaying}
                  togglePlayPause={() => setIsPlaying(false)}
                  className="audio-bar"
                />
              ) : (
                <SpotifySongs spotifyUrl={currentSong.spotifyLink} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArtistDetailPage;
