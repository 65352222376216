import React, { useEffect, useState } from 'react';
import { ref as databaseRef, get, set } from 'firebase/database';
import { database } from "../firebaseConfig";
import './RetargetingModal.css';

function RetargetingModal({ isOpen, onClose, songId, handleOverlayClick, isNominated, hasAward }) {
  const [taskData, setTaskData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpen && songId) {
      fetchRetargetingData(songId);
    }
  }, [isOpen, songId]);

  // Function to fetch retargeting data for the selected song
// Function to fetch retargeting data for the selected song
const fetchRetargetingData = async (songId) => {
  try {
    // Fetch the artist ID from the Songs database
    const songRef = databaseRef(database, `Songs/${songId}/artistID`);
    const songSnapshot = await get(songRef);

    if (songSnapshot.exists()) {
      const artistID = songSnapshot.val();

      // Fetch retargeting data for all relevant tasks
      const taskRefs = [
        'ValerieRetargeting1',
        'ValerieRetargeting2',
        'ValerieRetargeting3',
        'JulianAwardMessage',
        'JulianCreditMessage',
      ].map((task) => databaseRef(database, `Tasks/${task}`));
      const taskSnapshots = await Promise.all(taskRefs.map((ref) => get(ref)));

      // Extract data and store in taskData, filtering by isActive status
      const tasksData = taskSnapshots.map((snapshot, index) => {
        if (snapshot.exists()) {
          const taskData = snapshot.val();
          const isActive = taskData.isActive;
          const taskName =
            index < 3 ? `ValerieRetargeting${index + 1}` : index === 3 ? 'JulianAwardMessage' : 'JulianCreditMessage';

          // Only proceed if the task is active
          if (isActive) {
            // Skip JulianAwardMessage if both isNominated and hasAward are false
            if (taskName === 'JulianAwardMessage' && !isNominated && !hasAward) {
              return null;
            }

            // Skip JulianCreditMessage if either isNominated or hasAward is true
            if (taskName === 'JulianCreditMessage' && (isNominated || hasAward)) {
              return null;
            }

            const messages = taskData.messages || [];
            const message = messages[Math.floor(Math.random() * messages.length)];
            const { waitTimeInHours, senderUserId } = taskData;

            return {
              taskName,
              artistID,
              message,
              waitTimeInHours,
              senderUserId,
            };
          }
        }
        return null;
      }).filter(Boolean); // Filter out any null values (inactive or missing data)

      setTaskData(tasksData);
      setLoading(false);
    } else {
      console.error('artistID not found.');
      setLoading(false);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    setLoading(false);
  }
};


  // Function to trigger the retargeting Cloud Function for each task
  const triggerCloudFunction = async () => {
    if (!taskData) {
      console.error('No task data available.');
      return;
    }

    // Close the modal immediately before sending the request
    onClose();

    const { artistID } = taskData[0]; // Use the artistID from any task
    const artistRef = databaseRef(database, `Artists/${artistID}/RetargetingButtonPressed`);
    set(artistRef, true).catch((error) => {
      console.error('Error setting RetargetingButtonPressed:', error);
    });

    // Create tasks for each retargeting action
    taskData.forEach(async ({ taskName, artistID, message, waitTimeInHours, senderUserId }) => {
      try {
        // Trigger the Cloud Function
        const response = await fetch('https://us-central1-awards-13a46.cloudfunctions.net/retargetMessageScheduler', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            reviewedUserId: artistID,
            senderUserId,
            message,
            waitTimeInHours,
            taskName,
            isNominated,
            hasAward,
          }),
        });

        if (!response.ok) {
          throw new Error(`Error executing Cloud Function for ${taskName}`);
        }

        const result = await response.json();
        console.log(`Cloud Function result for ${taskName}:`, result);
      } catch (error) {
        console.error(`Error triggering Cloud Function for ${taskName}:`, error);
      }
    });
  };

  if (!isOpen) return null;

  return (
    <div className="rtt-modal-overlay" onClick={handleOverlayClick}>
      <div className="rtt-modal-content" onClick={(e) => e.stopPropagation()}>
        {loading ? (
          <p>Loading retargeting data...</p>
        ) : (
          <>
            <h2>Retargeting for Song ID: {songId}</h2>
            <p>Nominated: {isNominated ? 'Yes' : 'No'}</p>
            <p>Awarded: {hasAward ? 'Yes' : 'No'}</p>
            {taskData.map((task, index) => (
              <div key={index}>
                <p>Task: {task.taskName}</p>
                <p>Artist ID: {task.artistID}</p>
                <p>Message: {task.message}</p>
                <p>Wait Time (Hours): {task.waitTimeInHours}</p>
              </div>
            ))}
            <button onClick={triggerCloudFunction}>Send Messages</button>
            <button onClick={onClose}>Close</button>
          </>
        )}
      </div>
    </div>
  );
}

export default RetargetingModal;
