import React, { useState, useEffect } from 'react';
import { ref as databaseRef, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';
import CountryFlagEmoji from '../Utility/CountryFlagEmoji';
import './ArtistMore.css';

const ArtistMore = ({ artistID, artistDetails }) => {
  const [genres, setGenres] = useState('');
  const [artistType, setArtistType] = useState('');
  const [website, setWebsite] = useState('');
  const [experience, setExperience] = useState('');

  useEffect(() => {
    // Referenz zum Genre-Pfad in Firebase
    const genreRef = databaseRef(database, `Artists/${artistID}/genre`);
    onValue(genreRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const genreList = typeof data === 'string' ? data.split(',') : Object.values(data);
        setGenres(genreList.map((genre) => genre.trim()).join(', '));
      }
    });

    // Referenz für artistType oder expertType
    const artistTypeRef = databaseRef(database, `Artists/${artistID}`);
    onValue(artistTypeRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setArtistType(data.expertType || data.artistType || 'Unknown Type');

        // Überprüfen, ob hasExpertSettings true ist, und Website sowie Erfahrung laden
        if (data.hasExpertSettings) {
          if (data.website) {
            setWebsite(data.website);
          }
          if (data.experience) {
            setExperience(data.experience); // Anzahl der Jahre Erfahrung
          }
        }
      }
    });
  }, [artistID]);

  return (
    <div className="artist-more-wrapper">
      {/* Location Sektion */}
      <div className="artist-genres-horizontal">
        <p className="artist-genres-title" style={{marginBottom: '5px'}}>Type</p>
        <p className="artist-genres-item">{artistType}</p>
      </div>
      <div className="artist-more-location">
        <div className="artist-more-location-title">
          <p>Location</p>
        </div>
        <div className="artist-location-content">
          <p className="artist-country-name">{artistDetails.country}</p>
          <CountryFlagEmoji country={artistDetails.country} />
        </div>
      </div>

      {/* Genres Sektion */}
      <div className="artist-genres-horizontal">
        <p className="artist-genres-title">Genres</p>
        <p className="artist-genres-item">{genres.length > 0 ? genres : 'No genres selected'}</p>
      </div>

      {/* Website und Erfahrung Sektion, nur wenn hasExpertSettings true ist */}
      {website && (
        <div className="artist-genres-horizontal">
          <p className="artist-genres-title">Website</p>
          <a href={website} target="_blank" rel="noopener noreferrer" className="artist-genres-item">
            {website}
          </a>
        </div>
      )}

      {experience && (
        <div className="artist-genres-horizontal">
          <p className="artist-genres-title">Experience</p>
          <p className="artist-genres-item">{experience}</p>
        </div>
      )}
    </div>
  );
};

export default ArtistMore;
