import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { ref as databaseRef, onValue, off, update } from 'firebase/database';
import { database, auth } from '../firebaseConfig';

const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
    const [unreadCounts, setUnreadCounts] = useState({});
    const [totalUnread, setTotalUnread] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);
    const [isAuthLoading, setIsAuthLoading] = useState(true);

    // Überwachung der Authentifizierung
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setIsAuthLoading(false);
        });
        return () => unsubscribe();
    }, []);

    // Funktion zur Berechnung der ungelesenen Nachrichten
    const calculateUnreadCounts = useCallback((chatData, currentUser) => {
        const unreadCountsTemp = {};

        Object.keys(chatData).forEach(key => {
            const chat = chatData[key];
            if (chat.initiatorId === currentUser.uid || chat.recipientId === currentUser.uid) {
                const messages = chat.messages || {};
                const unreadCount = Object.values(messages).reduce((acc, message) => (
                    acc + (!message.readStatus && message.senderId !== currentUser.uid ? 1 : 0)
                ), 0);
                unreadCountsTemp[key] = unreadCount;
            }
        });

        return unreadCountsTemp;
    }, []);

    // Listener für Chats und ungelesene Nachrichten
    useEffect(() => {
        if (isAuthLoading || !currentUser) return;

        const chatsRef = databaseRef(database, 'Chats');
        const listener = onValue(chatsRef, snapshot => {
            if (snapshot.exists()) {
                const chatData = snapshot.val();
                const newUnreadCounts = calculateUnreadCounts(chatData, currentUser);

                setUnreadCounts(prevCounts => {
                    if (JSON.stringify(prevCounts) !== JSON.stringify(newUnreadCounts)) {
                        return newUnreadCounts;
                    }
                    return prevCounts;
                });

                const total = Object.values(newUnreadCounts).reduce((acc, count) => acc + count, 0);
                setTotalUnread(prevTotal => (prevTotal !== total ? total : prevTotal));
            }
        });

        return () => off(chatsRef, listener);
    }, [currentUser, isAuthLoading, calculateUnreadCounts]);

    // Funktion zum Zurücksetzen der ungelesenen Nachrichten für einen Chat
    const markMessagesAsRead = useCallback((chatId) => {
        const currentUser = auth.currentUser;
        if (!currentUser || !unreadCounts[chatId]) return;

        const messagesRef = databaseRef(database, `Chats/${chatId}/messages`);
        onValue(messagesRef, (snapshot) => {
            if (snapshot.exists()) {
                const messages = snapshot.val();
                const updates = {};

                Object.keys(messages).forEach(messageId => {
                    const message = messages[messageId];
                    if (!message.readStatus && message.senderId !== currentUser.uid) {
                        updates[`Chats/${chatId}/messages/${messageId}/readStatus`] = true;
                    }
                });

                update(databaseRef(database), updates).then(() => {
                    setUnreadCounts(prevCounts => ({
                        ...prevCounts,
                        [chatId]: 0  // Setze ungelesene Nachrichten für diesen Chat auf 0
                    }));
                });
            }
        });
    }, [unreadCounts]);

    const chatContextValue = useMemo(() => ({
        unreadCounts,
        setUnreadCounts,
        totalUnread,
        markMessagesAsRead  // Neue Funktion zum Zurücksetzen
    }), [unreadCounts, totalUnread, markMessagesAsRead]);

    return (
        <ChatContext.Provider value={chatContextValue}>
            {children}
        </ChatContext.Provider>
    );
};
