import React, { useState, useEffect } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './PageEquipment.css';

function PageEquipment({ onToggleSettings, pageId, widgetKey, active, settingsRoute }) {
    const [equipment, setEquipment] = useState([]);

    useEffect(() => {
        const equipmentRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/equipment`);
        const unsubscribe = onValue(equipmentRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setEquipment(data ? Object.values(data) : []);
            } else {
                setEquipment([]);
            }
        }, (error) => {
            console.error('Failed to fetch equipment data:', error);
        });

        return () => off(equipmentRef, 'value', unsubscribe);
    }, [pageId, widgetKey]);

    const className = `page-equipment ${settingsRoute ? 'settings-route' : ''} ${active ? 'is-active' : ''}`;

    const handleAddEquipmentClick = () => {
        onToggleSettings('Equipment');
    };

    const equipmentImages = {
        "Audio Interface": "../../equipment/audio-interface.svg",
        "Microphone": "../../equipment/microphone.svg",
        "Monitors": "../../equipment/monitor.svg",
        "DAW": "../../equipment/daw.svg",
        "Headphone": "../../equipment/headphone.svg",
        "MIDI Controller": "../../equipment/midi-controller.svg",
        "Synthesizer": "../../equipment/synthesizer.svg",
        "Compressor": "../../equipment/compressor.svg",
        "EQ": "../../equipment/eq.svg",
        "Preamp": "../../equipment/preamp.svg",
        "Effect Processor": "../../equipment/effect-processor.svg",
        "Audio Mixer": "../../equipment/audio-mixer.svg",
        "Drum Machine": "../../equipment/drum-machine.svg",
        "Camera": "../../equipment/camera.svg",
        "Lighting": "../../equipment/lighting.svg",
        "Computer": "../../equipment/computer.svg",
        "Software": "../../equipment/software.svg",
        "Other": "../../equipment/other.svg"
    };

    return (
        <div className={className} onClick={onToggleSettings}>
            {settingsRoute && (
                <div className="edit-overlay">
                    <img src="../../img/edit.webp" alt="Edit" />
                    <span>Edit</span>
                </div>
            )}

            {equipment.length > 0 ? (
                <div className="equipment-grid-2">
                    <h2 style={{ width: '100%', textAlign: 'left' }}>Studio Equipment</h2>
                    {equipment.map((item, index) => (
                        <div className="equipment-item-2" key={index}>
                            <div className="equipment-image-container">
                                <img src={equipmentImages[item.type] || '../../equipment/default.svg'} alt={item.type} className="equipment-image-2" />
                            </div>
                            <div className="equipment-type-2">{item.type}</div>
                            <div className="equipment-name-2">{item.name}</div>
                        </div>
                    ))}
                </div>
            ) : (
                settingsRoute && (
                    <div className="gig-upload-song-button" onClick={handleAddEquipmentClick}>
                        <h5>Studio Equipment</h5>
                        <img src="../../img/plus.svg" alt="Add" />
                    </div>
                )
            )}
        </div>
    );
}

export default PageEquipment;
