import React, { useState, useEffect } from 'react';
import { ref as databaseRef, update, get } from 'firebase/database';
import { database } from "../firebaseConfig";
import { uploadFile } from '../Utility/UploadUtlility';
import './AddPublicationModal.css'

function AddPublicationModal({ songId, onClose, isOpen }) {
  const [pubLink, setPubLink] = useState('');
  const [pubText, setPubText] = useState('');
  const [pubFile, setPubFile] = useState(null);
  const [publications, setPublications] = useState([]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchPublications = async () => {
      const songRef = databaseRef(database, `Songs/${songId}`);
      const snapshot = await get(songRef);
      const song = snapshot.val() || {};
      setPublications(song.publications || []);
    };

    if (isOpen) {
      fetchPublications();
    }
  }, [isOpen, songId]);

  if (!isOpen) {
    return null;
  }
  

  const handleAddPublicationClick = async () => {
    if (!songId || !pubLink.trim() || !pubText.trim() || !pubFile) {
      alert("Please fill out all the fields.");
      return;
    }
  
    const fileExtension = pubFile.name.split('.').pop().toLowerCase();
    if (!['png', 'jpeg', 'jpg', 'webp'].includes(fileExtension)) {
      alert("Please upload files only in PNG, JPEG, or WEBP format.");
      return;
    }
  
    setUploading(true);
    try {
      const pubImgUrl = await uploadFile(pubFile, 'publicationImages');
      const publicationData = { pubLink, pubText, pubImg: pubImgUrl };
      const newPublications = [...publications, publicationData];
      setPublications(newPublications);
      await savePublications(newPublications);
    } catch (error) {
      console.error('Fehler beim Hochladen des Publikationsbildes: ', error);
      alert("Fehler beim Hochladen des Bildes. Bitte versuchen Sie es erneut.");
    } finally {
      setUploading(false);
      setPubLink('');
      setPubText('');
      setPubFile(null);
    }
  };


  const deletePublication = async (indexToDelete) => {
    const updatedPublications = publications.filter((_, index) => index !== indexToDelete);
    setPublications(updatedPublications);

    // Aktualisieren Sie die Publikationen in der Datenbank
    const songRef = databaseRef(database, `Songs/${songId}`);
    await update(songRef, { publications: updatedPublications });
  };

  const savePublications = async (newPublications = publications) => {
    const songRef = databaseRef(database, `Songs/${songId}`);
    await update(songRef, { publications: newPublications })
      .then(() => console.log('Publikationen erfolgreich gespeichert'))
      .catch((error) => console.error('Fehler beim Speichern der Publikationen: ', error));
  };



  return (
    <div className="edit-modal-overlay">
      <div className="edit-modal-content">

      <h2>Edit Publications</h2>
        {uploading && <p>Upload läuft...</p>}
        <label htmlFor="pubLink">Publikationslink:</label>
        <input
          id="pubLink"
          type="text"
          value={pubLink}
          onChange={(e) => setPubLink(e.target.value)}
          placeholder="URL der Publikation"
        />
        <label htmlFor="pubText">Beschreibung:</label>
        <textarea
          id="pubText"
          value={pubText}
          onChange={(e) => setPubText(e.target.value)}
          placeholder="Beschreibung der Publikation"
        ></textarea>
        <label htmlFor="pubFile">Bild der Publikation:</label>
        <input
          id="pubFile"
          type="file"
          onChange={(e) => setPubFile(e.target.files[0])}
        />
        <button onClick={handleAddPublicationClick} disabled={uploading}>
          Publikation hinzufügen
        </button>
        <button onClick={onClose} className="close-button">
          Schließen
        </button>
        <hr />
        <div className="publications-display">
          {publications.map((pub, index) => (
            <div key={index} className="publication-item">
              <img src={pub.pubImg} alt="Publikationsbild" className="publication-img" />
              <p className="publication-text">{pub.pubText}</p>
              <p>{pub.pubLink}</p>
              <button className="mini-button" onClick={() => deletePublication(index)}>
              X
              </button>
            
            </div>
          ))}
        </div>
      </div>
    </div>
  );
          }  

export default AddPublicationModal;
