import React from 'react';
import './SavePaymentOptions.css';

function SavePaymentOptions({ selectedOptions, setSelectedOptions, moneyBackGuarantee, setMoneyBackGuarantee, setChangesMade }) {
    const paymentOptions = [
        { name: 'Paypal', icon: '../../img/paypal.webp' },
        { name: 'Stripe', icon: '../../img/stripe.webp' },
        { name: 'Wise', icon: '../../img/wise.webp' },
        { name: 'Venmo', icon: '../../img/venmo.webp' },
        { name: 'Credit Card', icon: '../../img/visa-master.webp' },
    ];

    const toggleOption = (option) => {
        setSelectedOptions((prevSelected) =>
            prevSelected.includes(option)
                ? prevSelected.filter((item) => item !== option)
                : prevSelected.length < 4
                ? [...prevSelected, option]
                : prevSelected
        );
        setChangesMade(true); // Trigger changes made flag
    };

    const toggleMoneyBackGuarantee = () => {
        setMoneyBackGuarantee((prev) => !prev);
        setChangesMade(true); // Trigger changes made flag
    };

    return (
        <div className="save-payment-options-container">
            <h3>Select Payment Methods</h3>
            <div className="payment-options">
                {paymentOptions.map((option) => (
                    <div
                        key={option.name}
                        className={`payment-option ${selectedOptions.includes(option.name) ? 'selected' : ''}`}
                        onClick={() => toggleOption(option.name)}
                    >
                        <img src={option.icon} alt={option.name} className="payment-option-icon" />
                        <span>{option.name}</span>
                    </div>
                ))}
            </div>

            <div className="money-back-guarantee">
                <label className="toggle-switch">
                    <input type="checkbox" checked={moneyBackGuarantee} onChange={toggleMoneyBackGuarantee} />
                    <span className="slider"></span>
                </label>
                <div className="guarantee-text">
                    <p>30 Day Money Back Guarantee</p>
                    <small>
                        By enabling this option, you agree to offer a 30-day money-back guarantee for your service.
                    </small>
                </div>
            </div>
        </div>
    );
}

export default SavePaymentOptions;