import React, { useState, useEffect, useRef } from 'react';
import { ref, onValue, remove, update } from 'firebase/database';
import { database } from '../../firebaseConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import './PagePreview.css';

// Widget-Komponenten
import GigSongs from '../PageComps/GigSongs';
import PageTextEdit from '../PageComps/PageTextEdit';
import AddProject from '../PageComps/AddProject';
import PageEquipment from '../PageComps/PageEquipment';
import PageQa from '../PageComps/PageQa';
import GigIntro from '../PageComps/GigIntro';
import PageHeader from '../PageComps/PageHeader';
import PageSocials from '../PageComps/PageSocials';
import TheGig from '../PageComps/TheGig';
import GigSellerInfos from '../PageComps/GigSellerInfos';
import GigHighlights from '../PageComps/GigHighlights';
import GigReviewWidget from './GigReviewWidget';
import LivePaymentOptions from '../PageComps/LivePaymentOptions';

const widgetComponents = {
    GigSongs,
    PageTextEdit,
    AddProject,
    PageEquipment,
    PageQa,
};

function PagePreview({ onToggleSettings, pageId, activeSettingsType, isWhiteMode, selectedStyle, isLivePage, setWidgetKey }) {
    const location = useLocation();
    const navigate = useNavigate();
    const isSettingsRoute = location.pathname.startsWith('/setting/');
    const isLiveRoute = !isSettingsRoute;
    const [artistId, setArtistId] = useState(null);
    const [widgets, setWidgets] = useState([]);
    const rightPreviewRef = useRef(null);
    const [isScrolledPast400, setIsScrolledPast400] = useState(false); // Zustand für das Scrollen ab 400px

    useEffect(() => {
        if (!isSettingsRoute) {
            const route = location.pathname.split('/')[1];
            const routesRef = ref(database, `/PageRoutes/${route}`);
            onValue(routesRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setArtistId(data.artistId);
                }
            });
        }
    }, [location.pathname, isSettingsRoute]);

    useEffect(() => {
        const widgetsRef = ref(database, `Pages/${pageId}/pageData/widgets`);
        onValue(widgetsRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                const sortedWidgets = Object.keys(data).map(key => ({
                    ...data[key],
                    key
                })).sort((a, b) => a.position - b.position);
                setWidgets(sortedWidgets);
            } else {
                setWidgets([]);
            }
        });
    }, [pageId]);

    const handleToggleSettings = (type, widgetKey) => {
        if (onToggleSettings) {
            onToggleSettings(type, widgetKey);
        }
        if (setWidgetKey) {
            setWidgetKey(widgetKey);
        }
    };

    const handleRemoveWidget = (widgetKey) => {
        const widgetRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}`);
        remove(widgetRef)
            .then(() => {
                console.log('Widget removed successfully');
                updateWidgetPositions();
            })
            .catch((error) => {
                console.error('Error removing widget:', error);
            });
    };

    const updateWidgetPositions = () => {
        const widgetsRef = ref(database, `Pages/${pageId}/pageData/widgets`);
        onValue(widgetsRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                const widgetsArray = Object.keys(data).map(key => ({
                    ...data[key],
                    key
                })).sort((a, b) => a.position - b.position);

                const updates = {};
                widgetsArray.forEach((widget, index) => {
                    updates[`/Pages/${pageId}/pageData/widgets/${widget.key}/position`] = index;
                });

                update(ref(database), updates)
                    .then(() => {
                        console.log('Widget positions updated successfully');
                    })
                    .catch((error) => {
                        console.error('Error updating widget positions:', error);
                    });
            }
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            const rightPreviewEl = rightPreviewRef.current;

            // Überprüfen, ob das Element existiert, bevor wir darauf zugreifen
            if (!rightPreviewEl) {
                return;
            }

            const maxScroll = rightPreviewEl.scrollHeight - rightPreviewEl.clientHeight + 1000;
            const scrollPosition = window.scrollY;
            setIsScrolledPast400(scrollPosition >= 950); // Zustand ändern, wenn über 400px gescrollt wird

            // Logik, um das Scrolling bei maximalem Scrollbereich zu stoppen
            if (window.scrollY <= maxScroll) {
                rightPreviewEl.style.position = 'absolute';
                rightPreviewEl.style.top = `${maxScroll - 400}px`; // Anpassung der Top-Position
            } else {
                rightPreviewEl.style.position = 'absolute';
                rightPreviewEl.style.top = '100px';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className={`page-preview ${isLiveRoute ? 'live' : 'settings'}`}>
            <div className="page-preview-header">
            </div>
            <div className={`page-preview-main ${isLiveRoute ? 'live' : 'settings'}`}>
      
                <div className={`page-preview-left ${isLiveRoute ? 'live' : 'settings'}`}>
          
                    <div className={`page-socials-top-mobile ${isLiveRoute ? 'live' : 'settings'}`}>
                        <PageSocials isLiveRoute={isSettingsRoute} pageId={pageId} isWhiteMode={isWhiteMode} selectedStyle={selectedStyle} />
                    </div>
                    <div>
                    {isLiveRoute && (
    <button onClick={() => navigate('/market')} className="going-back-button" style={{ border: 'none', cursor: 'pointer' }}>
        <img src="../../img/left.svg" alt="Back" />
        All Gigs
    </button>
)}

                    <PageHeader
                        pageId={pageId}
                        onToggleSettings={() => handleToggleSettings('PageHeader')}
                        active={activeSettingsType === 'PageHeader'}
                        settingsRoute={isSettingsRoute}
                        isWhiteMode={isWhiteMode}
                        selectedStyle={selectedStyle}
                        isLivePage={isLivePage}
                    />
                     <GigHighlights
                        pageId={pageId}
                    />
                    <GigIntro
                        pageId={pageId}
                        onToggleSettings={() => handleToggleSettings('GigIntro')}
                        active={activeSettingsType === 'GigIntro'}
                        settingsRoute={isSettingsRoute}
                        isWhiteMode={isWhiteMode}
                        selectedStyle={selectedStyle}
                        isLivePage={isLivePage}
                    />
                    <hr className="widget-splitter-hr" />
                    <div className={`page-preview-bottom-mobile ${isLiveRoute ? 'live' : 'settings'}`}>
                        <TheGig
                            pageId={pageId}
                            onToggleSettings={() => handleToggleSettings('TheGig')}
                            active={activeSettingsType === 'TheGig'}
                            settingsRoute={isSettingsRoute}
                            isWhiteMode={isWhiteMode}
                            selectedStyle={selectedStyle}
                            isLivePage={isLivePage}
                        />
                    </div>
                    </div>

                    <GigSellerInfos
                        pageId={pageId}
                        onToggleSettings={() => handleToggleSettings('GigSellerInfos')}
                        active={activeSettingsType === 'GigSellerInfos'}
                        settingsRoute={isSettingsRoute}
                        isWhiteMode={isWhiteMode}
                        selectedStyle={selectedStyle}
                    />
                    <hr className="widget-splitter-hr" />

                    {widgets.map((widget, index) => {
                        const WidgetComponent = widgetComponents[widget.widget];
                        return WidgetComponent ? (
                            <div key={index} className="widget-container">
                                <WidgetComponent
                                    pageId={pageId}
                                    widgetKey={widget.key}
                                    onToggleSettings={() => handleToggleSettings(widget.widgetSettingsClick, widget.key)}
                                    active={activeSettingsType === widget.widgetSettingsClick}
                                    settingsRoute={isSettingsRoute}
                                    isWhiteMode={isWhiteMode}
                                    selectedStyle={selectedStyle}
                                    isLivePage={isLivePage}
                                />
                                {isSettingsRoute && (
                                    <button
                                        onClick={() => handleRemoveWidget(widget.key)}
                                        className="remove-widget-button"
                                    >
                                        <img src="../../img/delete.webp" alt=""/>
                                    </button>
                                )}
                            </div>
                        ) : null;
                    })}

                    {isSettingsRoute && widgets.length < 3 && (
                        <div className={`empty-widget-message ${activeSettingsType === 'SelectWidgetSettings' ? 'selected' : ''}`} onClick={() => handleToggleSettings('SelectWidgetSettings')}>
                            <img src="../../img/plus_icon.webp" alt="" />
                            <p>Add More</p>
                        </div>
                    )}
                    <GigReviewWidget pageId={pageId} /> {/* Füge die neue statische Komponente hinzu */}

                </div>
                <div ref={rightPreviewRef} className={`page-preview-right ${isLiveRoute ? 'live' : 'settings'}`}>
                {!isScrolledPast400 && (
                        <>
                            <PageSocials pageId={pageId} isWhiteMode={isWhiteMode} selectedStyle={selectedStyle} />
                        </>
                    )}
                                       <div>

                    <TheGig
                        pageId={pageId}
                        onToggleSettings={() => handleToggleSettings('TheGig')}
                        active={activeSettingsType === 'TheGig'}
                        settingsRoute={isSettingsRoute}
                        isWhiteMode={isWhiteMode}
                        selectedStyle={selectedStyle}
                        isLivePage={isLivePage}
                    />
               
                    {!isScrolledPast400 && (
                       <>
                     <LivePaymentOptions 
                    pageId={pageId}
                    />                       
                     </>
                    )}
                   </div>
        
                </div>
            </div>

        </div>
    );
}

export default PagePreview;
