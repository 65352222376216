import React, { useState, useEffect } from 'react';
import { ref as databaseRef, onValue, update } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { database, storage } from "../firebaseConfig";
import './EditArtistModal.css';
import SubGenreList from '../Utility/SubGenreList';

function EditArtistModal({ artistId, onClose }) {
  const [formData, setFormData] = useState({
    city: '',
    genre: '',
    hasLabel: false,
    openForCollab: false,
    sellBeats: false,
    sellVerse: false,
    sellLicense: false,
    artistBioTitle: '',
    artistBioText: '',
    socialMediaLink: '', // Neues Feld für Social-Media-Link
  });
  const [image, setImage] = useState(null);

  const countries = [
    'China', 'India', 'United States', 'Indonesia', 'Pakistan',
    'Brazil', 'Nigeria', 'Bangladesh', 'Russia', 'Mexico',
    'Japan', 'Ethiopia', 'Philippines', 'Egypt', 'Vietnam',
    'Turkey', 'Iran', 'Germany', 'Thailand', 'United Kingdom',
    'France', 'Italy', 'Tanzania', 'South Africa', 'Myanmar',
    'Kenya', 'South Korea', 'Colombia', 'Spain', 'Uganda',
    'Argentina', 'Algeria', 'Sudan', 'Ukraine', 'Poland',
    'Canada', 'Morocco', 'Austria', 'Belgium', 'Croatia',
    'Denmark', 'Estonia', 'Finland', 'Greece', 'Hungary',
    'Ireland', 'Latvia', 'Lithuania', 'Luxembourg', 'Netherlands',
    'Norway', 'Portugal', 'Romania', 'Slovakia', 'Slovenia',
    'Sweden', 'Switzerland', 'Iceland', 'Costa Rica'
  ];
  

  useEffect(() => {
    if (!artistId) return;

    const artistRef = databaseRef(database, `Artists/${artistId}`);
    onValue(artistRef, (snapshot) => {
      const data = snapshot.val() || {};
      setFormData(prev => ({ ...prev, ...data }));
    });
  }, [artistId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Bild zuerst hochladen, falls vorhanden
    let imageUrl = formData.artistImageUrl || ''; // Verwenden Sie den bestehenden URL-Wert, falls kein neues Bild hochgeladen wird
    if (image) {
      const imageRef = storageRef(storage, `artistImages/${artistId}`);
      const uploadResult = await uploadBytes(imageRef, image);
      imageUrl = await getDownloadURL(uploadResult.ref);
    }

    const updates = { ...formData, artistImageUrl: imageUrl };
    if (imageUrl) {
      updates.artistImageUrl = imageUrl; // Fügen Sie imageUrl nur hinzu, wenn ein Bild hochgeladen wurde
    }

    update(databaseRef(database, `Artists/${artistId}`), updates)
      .then(() => {
        alert('Künstlerinformationen erfolgreich aktualisiert.');
        onClose(); // Schließen des Modals
      })
      .catch((error) => {
        console.error('Fehler beim Aktualisieren der Künstlerinformationen: ', error);
      });
  };

  return (
    <div className="modal-backdrop">
    <div className="edit-artist-modal">
      <form onSubmit={handleSubmit} className="add-song-form">
        <div className="input-section">
        <select name="country" value={formData.country || ''} onChange={handleChange} className="text-input">
    <option value="">Land auswählen</option>
    {countries.map((country, index) => (
      <option key={index} value={country}>{country}</option>
    ))}
  </select>
          <input type="text" name="city" placeholder="Stadt" value={formData.city} onChange={handleChange} className="text-input" />
         
         <SubGenreList onChange={(e) => handleChange({ ...e, target: { ...e.target, name: 'genre' } })} value={formData.genre} />
         <input type="file" onChange={handleChange} name="artistImage" className="file-input" />
          <input type="text" name="artistBioTitle" placeholder="Künstler Bio Titel" value={formData.artistBioTitle} onChange={handleChange} className="text-input" /> {/* Neues Feld */}
            <textarea name="artistBioText" placeholder="Künstler Bio Text" value={formData.artistBioText} onChange={handleChange} className="text-input" /> {/* Neues Feld */}
            <input type="text" name="socialMediaLink" placeholder="Social Media Link" value={formData.socialMediaLink} onChange={handleChange} className="text-input" />
  </div>
        <div className="awarded-boxes">
          <label className="checkbox-label">
            Has Label:
            <input type="checkbox" name="hasLabel" checked={formData.hasLabel} onChange={handleChange} className="pupu-checkbox" />
          </label>
          <label className="checkbox-label">
            Open für Collab:
            <input type="checkbox" name="openForCollab" checked={formData.openForCollab} onChange={handleChange} className="pupu-checkbox" />
          </label>
          <label className="checkbox-label">
            Sell Beats:
            <input type="checkbox" name="sellBeats" checked={formData.sellBeats} onChange={handleChange} className="pupu-checkbox" />
          </label>
          <label className="checkbox-label">
            Sell Verses:
            <input type="checkbox" name="sellVerse" checked={formData.sellVerse} onChange={handleChange} className="pupu-checkbox" />
          </label>
          <label className="checkbox-label">
            Sell Lizences:
            <input type="checkbox" name="sellLicense" checked={formData.sellLicense} onChange={handleChange} className="pupu-checkbox" />
          </label>
        </div>
        <button type="submit" className="submit-button">Speichern</button>
        <button type="button" onClick={onClose} className="cancel-button">Abbrechen</button>
      </form>
    </div>  
    </div>
  );
}

export default EditArtistModal;
