// EditorQuote.js
import React from 'react';
import './EditorQuote.css'

const EditorQuote = ({ quote }) => {
  if (!quote) return null; // Wenn kein Zitat vorhanden ist, rendere nichts

  return (
    <div className="editor-quote">
        <img className="img-editor-quote" src="../img/quotes.svg" alt="" />
      <h2>{quote}</h2>
      <div className="valerie-quote">
          <div className="valerie-quote-text">
            <h5>Valerie W.</h5>
            <h6>Wavy Music Magazine</h6>
          </div>
      <img className="img-valerie-quote" src="../img/valerie_wavy.jpg" alt="" />
      </div>
    </div>
  );
};

export default EditorQuote;
