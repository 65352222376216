import React from 'react';
import './UtilitySettings.css';

function PriceInputField({ value, onChange, placeholder, imgSrc, labelText }) {
    const handleChange = (event) => {
        let newValue = parseInt(event.target.value, 10);

        // Setze sicher, dass newValue in Schritten von 5 bleibt, beginnend bei 4
        newValue = Math.floor((Math.max(newValue, 4) - 4) / 5) * 5 + 4;
        // Setze das Maximum auf 499
        newValue = Math.min(newValue, 499);

        // Rufe onChange nur auf, wenn sich der Wert tatsächlich ändert, um unnötige Updates zu vermeiden
        if (newValue !== value) {
            onChange({ target: { value: newValue } });
        }
    };

    return (
        <div className="input-number-field-container">
            {labelText && <label className="input-number-field-label">{labelText}</label>}
            <div className="input-number-field-wrapper">
                <span className="input-number-field-prefix">{imgSrc && <img src={imgSrc} alt="Prefix Icon" className="input-number-field-img" />}</span>
                <input
                    className="input-number-field"
                    type="number"
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    min={4}
                    max={499}
                    step={5}
                />
            </div>
        </div>
    );
}

export default PriceInputField;
