import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';
import './ArtistBio.css'; // Pfad zur CSS-Datei anpassen
import LoadingScreen from '../Utility/LoadingScreen';

const ArtistBio = ({ artistId }) => {
  const [artistInfo, setArtistInfo] = useState(null);

  useEffect(() => {
    if (!artistId) return;

    const artistRef = ref(database, `Artists/${artistId}`);
    onValue(artistRef, (snapshot) => {
      const data = snapshot.val();
      setArtistInfo(data);
    });
  }, [artistId]);

  if (!artistInfo || !artistInfo.artistBioTitle || !artistInfo.artistBioText) {
    return null; // Wenn artistInfo nicht vorhanden ist oder artistBioTitle und artistBioText fehlen, wird nichts gerendert
  }

  return (
    <div className="artist-bio-2">
      <h3>About the Artist</h3>
      <div className="artist-bio-container">
        <div className="artist-bio-text">
          <h1 className="artist-bio-title">{artistInfo.artistBioTitle}</h1>
          <p className="artist-bio-description">{artistInfo.artistBioText}</p>
        </div>
        <div className="artist-bio-image">
          <img src={artistInfo.artistImageUrl} alt="Artist" />
        </div>
      </div>
    </div>
  );
};

export default ArtistBio;
