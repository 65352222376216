import React, { useState, useEffect } from 'react';
import { ref as databaseRef, onValue, off } from 'firebase/database';
import { database } from '../firebaseConfig';
import './PreviewVerifiedBadge.css';

const PreviewVerifiedBadge = ({ gigId }) => {
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        const verifiedRef = databaseRef(database, `/Pages/${gigId}/pageData/isVerified`);

        const fetchVerifiedStatus = () => {
            onValue(verifiedRef, (snapshot) => {
                if (snapshot.exists()) {
                    setIsVerified(snapshot.val());
                } else {
                    setIsVerified(false);
                }
            });

            return () => {
                off(verifiedRef);
            };
        };

        fetchVerifiedStatus();
    }, [gigId]);

    if (!isVerified) return null;

    return (
        <div className="verified-badge-container">
            <img src="../../img/verified_2.webp" alt="Verified Badge" className="verified-icon" />
            <p className="verified-text">Id Verified</p>
        </div>
    );
};

export default PreviewVerifiedBadge;
