import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, database } from '../firebaseConfig';
import { ref as databaseRef, get } from 'firebase/database';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
    const [avatar, setAvatar] = useState('');
    const [userData, setUserData] = useState({});
    const [isUserDataLoading, setIsUserDataLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setIsUserDataLoading(true);
            if (user) {
                const userId = user.uid;
                const artistRef = databaseRef(database, `/Artists/${userId}`);
                get(artistRef).then((snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.val();
                        if (data.artistImageUrl) {
                            setAvatar(data.artistImageUrl);
                        }
                        setUserData({
                            ...data,
                            isExpert: data.isExpert || false,
                            isPremium: data.isPremium || false // Add isPremium check
                        });
                    }
                    setIsUserDataLoading(false);
                }).catch((error) => {
                    console.error("Error fetching user data:", error);
                    setIsUserDataLoading(false);
                });
            } else {
                setAvatar('');
                setUserData({});
                setIsUserDataLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    const value = { userData, isUserDataLoading, setUserData }; // Nur userData und setUserData

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
};
