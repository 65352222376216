import React, { useState, useEffect } from "react";
import { database } from "../../firebaseConfig";
import { ref, onValue, set, remove } from "firebase/database";
import "./InvestHolders.css";
import GetInvestorCoins from "./GetInvestorCoins";
import NewCoins from "./NewCoins";

function InvestHolders() {
  const [holders, setHolders] = useState([]);
  const [walletID, setWalletID] = useState("");
  const [name, setName] = useState("");
  const [group, setGroup] = useState(""); // State for group
  const [showModal, setShowModal] = useState(false);
  const [expandedHolder, setExpandedHolder] = useState(null);

  // Fetch holders from Firebase
  useEffect(() => {
    const holdersRef = ref(database, "IA/wallets");
    onValue(holdersRef, (snapshot) => {
      const data = snapshot.val();
      const holdersList = data
        ? Object.entries(data).map(([key, value]) => ({
            id: key, // walletID as key
            ...value,
          }))
        : [];
      setHolders(holdersList);
    });
  }, []);

  // Add a new holder
  const addHolder = async () => {
    if (walletID && name && group) {
      const holderRef = ref(database, `IA/wallets/${walletID}`);
      try {
        await set(holderRef, { walletID, name, group }); // Save group along with walletID and name
        setWalletID("");
        setName("");
        setGroup(""); // Reset group input
        setShowModal(false); // Close modal after adding
      } catch (error) {
        console.error("Error adding holder:", error);
      }
    } else {
      alert("Please enter Wallet ID, Name, and Group.");
    }
  };

  // Delete a holder
  const deleteHolder = async (holderID) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this holder and all associated data?"
    );
    if (!confirmDelete) return;

    try {
      const holderRef = ref(database, `IA/wallets/${holderID}`);
      await remove(holderRef);
      alert("Holder deleted successfully!");
    } catch (error) {
      console.error("Error deleting holder:", error);
      alert("Failed to delete holder. Please try again.");
    }
  };

  // Toggle visibility of coins
  const toggleCoins = (holderID) => {
    setExpandedHolder((prev) => (prev === holderID ? null : holderID));
  };

  // Funktion zur Berechnung des Status
  const calculateStatus = (inactivityCounter) => {
    if (inactivityCounter === 0) return "Active";
    if (inactivityCounter < 15) return "Medium";
    return "Inactive";
  };

  return (
    <div className="invest-holders-container">
      <div className="invest-holders-header">
        <h2 className="invest-holders-title">Investors</h2>
        <button className="invest-holders-add-button" onClick={() => setShowModal(true)}>
          +
        </button>
        {/* Add NewCoins Component */}
        <NewCoins wallets={holders} />
      </div>

      <div className="invest-holders-list-container">
        {holders.length > 0 ? (
          holders.map((holder) => (
            <div key={holder.id} className="invest-holders-item-container">
              {/* Holder Info */}
              <div
                className="invest-holders-item"
                onClick={() => toggleCoins(holder.id)}
                style={{ cursor: "pointer" }}
              >
                <div className="invest-holders-info">
                  <span className="invest-holders-name">{holder.name}</span>
                  <span className="invest-holders-wallet">{holder.walletID}</span>
                  <span className="invest-holders-group">
                    <strong>Group:</strong> {holder.group || "None"}
                  </span>
                  <span className="invest-holders-status">
                    <strong>Status:</strong> {calculateStatus(holder.status?.inactivityCounter || 0)}
                  </span>
                </div>
                <div className="invest-holders-actions">
                  <GetInvestorCoins tokenAddress={holder.walletID} />
                  <button
                    className="invest-holders-delete-button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent toggling when clicking delete
                      deleteHolder(holder.id);
                    }}
                  >
                    🗑️
                  </button>
                </div>
              </div>

              {/* Coins Section */}
              {expandedHolder === holder.id && (
                <div className="invest-holders-coins-container">
                  {holder.coins && Object.keys(holder.coins).length > 0 ? (
                    <ul className="invest-holders-coins-list">
                      {Object.values(holder.coins).map((coin, index) => (
                        <li
                          key={index}
                          className="invest-holders-coin"
                          onClick={() =>
                            window.open(
                              `https://dexscreener.com/solana/${coin.tokenAddress}`,
                              "_blank"
                            )
                          }
                        >
                          <div className="coin-details">
                            <img
                              src={coin.icon || "https://via.placeholder.com/40"}
                              alt={`${coin.symbol || "Unknown"} Icon`}
                              className="coin-icon"
                            />
                            <div>
                              <span className="coin-name">{coin.name || "Unknown"}</span>
                              <span className="coin-symbol"> ({coin.symbol || "Unknown"})</span>
                            </div>
                            <span className="coin-amount">{coin.amount.toFixed(2)} Tokens</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="no-coins-message">This wallet does not contain coins.</p>
                  )}
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="invest-holders-no-data">No holders available.</p>
        )}
      </div>

      {showModal && (
        <div className="invest-holders-modal">
          <div className="invest-holders-modal-content">
            <h3>Add Holder</h3>
            <input
              type="text"
              placeholder="Wallet ID"
              value={walletID}
              onChange={(e) => setWalletID(e.target.value)}
              className="invest-holders-modal-input"
            />
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="invest-holders-modal-input"
            />
            <input
              type="text"
              placeholder="Group"
              value={group}
              onChange={(e) => setGroup(e.target.value)}
              className="invest-holders-modal-input"
            />
            <div className="invest-holders-modal-actions">
              <button onClick={addHolder} className="invest-holders-modal-button">
                Add
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="invest-holders-modal-button cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InvestHolders;
