import React, { useState, useEffect } from 'react';
import { ref as databaseRef, set, get } from 'firebase/database';
import { database, storage } from "../firebaseConfig";
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './SpotlightArtist.css'
import { compressImage } from '../Utility/ImageCompressor'; // Pfad ggf. anpassen


function SpotlightArtist() {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonLink, setButtonLink] = useState('');
  const [coverImageFile, setCoverImageFile] = useState(null);
  const [coverImageMobileFile, setCoverImageMobileFile] = useState(null);
  const [coverImageUrl, setCoverImageUrl] = useState('');
  const [coverImageMobileUrl, setCoverImageMobileUrl] = useState('');
  const [spotifyLink, setSpotifyLink] = useState('');
  const [songCoverFile, setSongCoverFile] = useState(null);
  const [songCoverUrl, setSongCoverUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [coverImagePreview, setCoverImagePreview] = useState('');
  const [coverImageMobilePreview, setCoverImageMobilePreview] = useState('');
const [imageUpdateKey, setImageUpdateKey] = useState(0);

  useEffect(() => {
    const fetchSpotlightData = async () => {
      const spotlightRef = databaseRef(database, 'Spotlight');
      const snapshot = await get(spotlightRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setTitle(data.title || '');
        setText(data.text || '');
        setButtonText(data.buttonText || '');
        setButtonLink(data.buttonLink || '');
        setCoverImageUrl(data.coverImageUrl || '');
        setCoverImageMobileUrl(data.coverImageMobileUrl || '');
        setSpotifyLink(data.spotifyLink || '');
      } else {
        console.log('Keine Spotlight-Daten gefunden');
      }
    };

    fetchSpotlightData();
  }, []);

  const handleImageChange = async (e, setImageFile, setPreview) => {
    const file = e.target.files[0];
    if (file) {
      setPreview(''); // Zustand zurücksetzen
      const previewUrl = URL.createObjectURL(file);
      setImageFile(file);
      setPreview(previewUrl); // Setze den neuen Vorschaustatus
    }
  };
  
  
  const uploadImage = async (imageFile, path) => {
    if (!imageFile) {
      return '';
    }
    
    // Verwende das originale ImageFile direkt für den Upload
    const fileRef = storageRef(storage, `${path}/${imageFile.name}`);
    const uploadTask = uploadBytesResumable(fileRef, imageFile);
    await uploadTask;
    return await getDownloadURL(fileRef);
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      const newCoverImageUrl = coverImageFile ? await uploadImage(coverImageFile, 'SpotlightCoverImages') : coverImageUrl;
      const newCoverImageMobileUrl = coverImageMobileFile ? await uploadImage(coverImageMobileFile, 'SpotlightCoverImagesMobile') : coverImageMobileUrl;
      const newSongCoverUrl = songCoverFile ? await uploadImage(songCoverFile, 'SpotlightSongCovers') : songCoverUrl;
      // Hier die Logik zum Hochladen des Audiofiles implementieren (wenn erforderlich)

      const updatedData = {
        title,
        text,
        buttonText,
        buttonLink,
        coverImageUrl: newCoverImageUrl,
        coverImageMobileUrl: newCoverImageMobileUrl,
        spotifyLink,
        // Weitere Felder hier hinzufügen
      };

      const spotlightRef = databaseRef(database, 'Spotlight');
      await set(spotlightRef, updatedData);

      alert('Spotlight Artist erfolgreich aktualisiert!');
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Spotlight Artists: ', error);
      alert('Fehler beim Speichern der Spotlight Artist Informationen');
    } finally {
      setUploading(false);
    }
  };

  
  return (
    <div className="spotlight-modal-overlay">
      <div className="spotlight-modal">
        <h1 className="spotlight-modal-title">Spotlight Artist aktualisieren</h1>
        <form className="spotlight-modal-form" onSubmit={handleSubmit}>
  <input type="text" placeholder="Artist Title" value={title} onChange={(e) => setTitle(e.target.value)} />
  <textarea placeholder="Description" value={text} onChange={(e) => setText(e.target.value)} />
  <input type="text" placeholder="Button-Text" value={buttonText} onChange={(e) => setButtonText(e.target.value)} />
  <input type="text" placeholder="Button-Link" value={buttonLink} onChange={(e) => setButtonLink(e.target.value)} />

  <div className="image-upload-boxes">
  <div className="image-upload-box" style={{ backgroundImage: `url(${coverImageMobilePreview || coverImageMobileUrl})` }}>
    <input type="file" onChange={(e) => handleImageChange(e, setCoverImageMobileFile, setCoverImageMobilePreview)} />
    <span>Mobiles Cover-Bild</span>
  </div>
  <div className="image-upload-box" id="image-box-2" style={{ backgroundImage: `url(${coverImagePreview || coverImageUrl})` }}>
    <input type="file" onChange={(e) => handleImageChange(e, setCoverImageFile, setCoverImagePreview)} />
    <span>Cover-Bild</span>
  </div>
</div>

  <input type="text" placeholder="Spotify Link" value={spotifyLink} onChange={(e) => setSpotifyLink(e.target.value)} />
  <button type="submit" disabled={uploading}>Aktualisieren</button>
</form>
{uploading && <p className="spotlight-modal-uploading">Upload läuft...</p>}

      </div>
    </div>
  );
  }

export default SpotlightArtist;
