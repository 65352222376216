import React, { useEffect, useState } from 'react';
import { ref as databaseRef, get, set, remove } from 'firebase/database';
import { database } from "../firebaseConfig";
import './RSSGetter.css';

const TwitterRSSGetter = () => {
  const [twitterProfile, setTwitterProfile] = useState(""); 
  const [twitterName, setTwitterName] = useState(""); 
  const [twitterGroup, setTwitterGroup] = useState("music"); 
  const [twitterTargets, setTwitterTargets] = useState([]); 
  const [csvFile, setCsvFile] = useState(null); // State to hold the uploaded CSV file

  useEffect(() => {
    fetchTwitterTargets(); 
  }, []);

  // Fetches existing Twitter targets from Firebase
  const fetchTwitterTargets = async () => {
    const twitterRef = databaseRef(database, 'Rss/twitter');
    const snapshot = await get(twitterRef);
    if (snapshot.exists()) {
      const twitterData = snapshot.val();
      setTwitterTargets(Object.keys(twitterData).map(id => ({ id, ...twitterData[id] })));
    }
  };

  // Adds a new Twitter target to Firebase
  const handleAddTwitterTarget = async () => {
    if (twitterProfile && twitterName) {
      const twitterLink = `https://x.com/${twitterProfile}`;
      const targetId = `twitter_${Date.now()}_${Math.floor(Math.random() * 1000000)}`;
      const newTarget = { 
        name: twitterName,
        link: twitterLink,
        group: twitterGroup,
        type: "Twitter Profile" 
      };
      const twitterRef = databaseRef(database, `Rss/twitter/${targetId}`);
      await set(twitterRef, newTarget);
      setTwitterTargets([...twitterTargets, { id: targetId, ...newTarget }]);
      setTwitterProfile("");
      setTwitterName("");
    } else {
      alert("Please enter a valid Twitter profile and name.");
    }
  };

  // Deletes a Twitter target from Firebase
  const handleDeleteTwitterTarget = async (id) => {
    const twitterRef = databaseRef(database, `Rss/twitter/${id}`);
    await remove(twitterRef);
    setTwitterTargets(twitterTargets.filter(target => target.id !== id));
  };

  // Trigger the Cloud Function to fetch latest tweets
  const handleFetchTwitterFeeds = async () => {
    const urls = twitterTargets.map(target => target.link);
    const group = twitterGroup;

    try {
      const response = await fetch("https://us-central1-aaartistsdev.cloudfunctions.net/twitterFetchRSSFeeds", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ urls, group }),
      });

      if (response.ok) {
        const data = await response.json();
        alert("Twitter profiles fetched and updated successfully!");
        console.log("Fetched Twitter entries:", data.newEntries);
      } else {
        const errorData = await response.json();
        console.error("Error fetching Twitter profiles:", errorData.error);
        alert("Error fetching Twitter profiles.");
      }
    } catch (error) {
      console.error("Error calling Cloud Function:", error);
      alert("Error calling Cloud Function.");
    }
  };

  // Handle CSV file selection
  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
  };

  // Upload CSV and add each entry to Firebase
  const handleCSVUpload = async () => {
    if (!csvFile) {
      alert("Please select a CSV file first.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const rows = text.trim().split("\n").slice(1); // Skip the header row

      for (const row of rows) {
        const [name, link, group] = row.split(",").map(cell => cell.trim());
        const targetId = `twitter_${Date.now()}_${Math.floor(Math.random() * 1000000)}`;
        const newTarget = { name, link, group, type: "Twitter Profile" };

        const twitterRef = databaseRef(database, `Rss/twitter/${targetId}`);
        await set(twitterRef, newTarget);
      }

      // Refresh the target list after upload
      fetchTwitterTargets();
      alert("CSV uploaded and profiles added successfully!");
      setCsvFile(null); // Clear the file input after upload
    };
    reader.readAsText(csvFile);
  };

  return (
    <div className="rss-container">
      <h2 className="rss-header">Twitter RSS Getter</h2>

      <div className="rss-input-container">
        <input
          type="text"
          placeholder="Enter Twitter profile"
          value={twitterProfile}
          onChange={(e) => setTwitterProfile(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter Twitter target name"
          value={twitterName}
          onChange={(e) => setTwitterName(e.target.value)}
        />
        <select value={twitterGroup} onChange={(e) => setTwitterGroup(e.target.value)}>
          <option value="music">Music</option>
          <option value="crypto">Crypto</option>
        </select>
        <button className="rss-save-button" onClick={handleAddTwitterTarget}>Save Twitter Profile</button>
      </div>

      <button className="rss-fetch-button" onClick={handleFetchTwitterFeeds}>Fetch Latest Tweets</button>

      <div className="rss-upload-container">
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="rss-upload-input"
        />
        {csvFile && <p>Selected File: {csvFile.name}</p>}
        <button className="rss-upload-button" onClick={handleCSVUpload}>Confirm Upload</button>
      </div>

      <h3 className="rss-header">Saved Twitter Profiles</h3>
      <ul className="rss-target-list">
        {twitterTargets.map((target) => (
          <li className="rss-target-item" key={target.id}>
            <span>
              {target.name ? `${target.name} - ` : ""} 
              {target.group} - {target.type}
            </span>
            <a href={target.link} target="_blank" rel="noopener noreferrer" className="rss-watch-button">
              View Profile
            </a>
            <button
              className="rss-delete-button"
              onClick={() => handleDeleteTwitterTarget(target.id)}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TwitterRSSGetter;
