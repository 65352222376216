import React, { useState, useEffect } from 'react';
import { ref, set } from 'firebase/database';
import { database } from '../../firebaseConfig';
import SettingsSingleInput from '../PageUtility/SettingsSingleInput';
import SettingsSingleTextarea from '../PageUtility/SettingsSingleTextarea';
import HeaderLabel from '../PageUtility/HeaderLabel';
import CategoryLabel from '../PageUtility/CategoryLabel';
import './TheGigSettings.css'


function GigIntroSettings({ pageId, pageData }) {
    const [introTitle, setIntroTitle] = useState(pageData.introTitle || '');
    const [introText, setIntroText] = useState(pageData.introText || '');
    const [changesMade, setChangesMade] = useState(false); // Zustand um zu überwachen, ob Änderungen gemacht wurden

    useEffect(() => {
        setIntroTitle(pageData.introTitle || '');
        setIntroText(pageData.introText || '');
    }, [pageData]);

    const updateDatabase = () => {
        if (changesMade) {
            const updateRefTitle = ref(database, `Pages/${pageId}/pageData/introTitle`);
            const updateRefText = ref(database, `Pages/${pageId}/pageData/introText`);
            set(updateRefTitle, introTitle).catch(error => {
                console.error('Failed to update intro title:', error);
            });
            set(updateRefText, introText).catch(error => {
                console.error('Failed to update intro text:', error);
            });
            setChangesMade(false); // Reset der Änderungsüberwachung nach dem Speichern
        }
    };

    const handleChange = (value, setter) => {
        setter(value);
        setChangesMade(true); // Markiere, dass Änderungen gemacht wurden
    };

    return (
        <div>
            <HeaderLabel text="Introduction" />
            <CategoryLabel text="Quick Intro"/>
            <SettingsSingleInput
                type="text"
                value={introTitle}
                onChange={(e) => handleChange(e.target.value, setIntroTitle)}
                placeholder="Enter Intro Title"
                labelText="Welcome Sentence"
            />
            <SettingsSingleTextarea
                type="textarea"
                value={introText}
                onChange={(e) => handleChange(e.target.value, setIntroText)}
                placeholder="Enter Intro Text"
                labelText="More About You"
                maxLength={200}
            />
            {changesMade && <button className="gig-settings-save-button" style={{position: 'fixed'}} onClick={updateDatabase}>Save Settings</button>}
        </div>
    );
}

export default GigIntroSettings;
