import React, { useEffect, useState } from 'react';
import { ref as databaseRef, set, get } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './UtilitySettings.css';

const styleGradients = [
    'linear-gradient(to bottom, var(--black), #121212)', // Stil 1
    'linear-gradient(to bottom, var(--blue), #121212)', // Stil 1
    'linear-gradient(to bottom, #5d2677d3, #000000)', // Stil 2
    'linear-gradient(to bottom, #EA925C, #0f0f0f)', // Stil 3
    'linear-gradient(to bottom, #ad1648, #020202)', // Stil 4
    'linear-gradient(to bottom, #967b32, #000000)', // Stil 5
    // Weitere Stil-Gradienten hier hinzufügen
];


function StyleSelector({ pageId }) {
    const [selectedStyle, setSelectedStyle] = useState(null);

    useEffect(() => {
        const styleRef = databaseRef(database, `Pages/${pageId}/pageData/selectedStyle`);
        get(styleRef).then((snapshot) => {
            if (snapshot.exists()) {
                setSelectedStyle(snapshot.val());
            }
        }).catch((error) => {
            console.error('Error fetching selected style:', error);
        });
    }, [pageId]);

    const selectStyle = (style) => {
        const styleRef = databaseRef(database, `Pages/${pageId}/pageData/selectedStyle`);
        set(styleRef, style).then(() => {
            setSelectedStyle(style);
        }).catch((error) => {
            console.error('Failed to update style:', error);
        });
    };

    return (
        <div className="style-grid">
            {styleGradients.map((gradient, index) => (
                <div
                    key={index}
                    className={`style-cell ${selectedStyle === index ? 'active' : ''}`}
                    onClick={() => selectStyle(index)}>
                    <div className="circle-3" style={{ backgroundImage: gradient }}></div>
                    <p>Style {index + 1}</p>
                </div>
            ))}
        </div>
    );
}

export default StyleSelector;
