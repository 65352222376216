import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './LivePaymentOptions.css';

function LivePaymentOptions({ pageId }) {
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [moneyBackGuarantee, setMoneyBackGuarantee] = useState(false);

    const paymentIcons = {
        'Paypal': '../../img/paypal.webp',
        'Stripe': '../../img/stripe.webp',
        'Wise': '../../img/wise.webp',
        'Venmo': '../../img/venmo.webp',
        'Credit Card': '../../img/visa-master.webp'
    };

    useEffect(() => {
        if (pageId) {
            const paymentRef = ref(database, `Pages/${pageId}/pageData`);
            onValue(paymentRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setPaymentOptions(data.selectedPaymentOptions || []);
                    setMoneyBackGuarantee(data.moneyBackGuarantee || false);
                }
            });
        }
    }, [pageId]);

    const hasPaymentOptions = paymentOptions.length > 0;
    const showPlatformFee = hasPaymentOptions && moneyBackGuarantee;

    return (
        <div className="live-payment-options">
            <div>
                {/* Payment Options Text und Icons anzeigen, wenn vorhanden */}
                {hasPaymentOptions && (
                    <div>
                            <div className="payment-icons-container">
                            {paymentOptions.map((option, index) => (
                                <div key={index} className="payment-icon-wrapper">
                                    <img
                                        src={paymentIcons[option] || '../../img/default-payment-icon.webp'}
                                        alt={option}
                                        className="payment-option-icon"
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="platform-fees-info">
                            <p className="platform-fees">Secure Payment Available</p>
                            <p className="platform-description">
                                Pay directly to the Artist with {paymentOptions.join(', ')}.
                            </p>
                        </div>
                    
                    </div>
                )}

                {/* Money Back Guarantee anzeigen, wenn vorhanden */}
                {moneyBackGuarantee && (
                    <div className="money-back-guaranteed">
                        <div className="payment-icon-wrapper-2">
                            <img
                                src='../../img/dollar.webp'
                                className="payment-option-icon"
                            />
                        </div>
                        <p className="guarantee-title">Money Back Guarantee</p>
                        <p className="guarantee-description">
                            Not happy with your delivery? I offer a 30 Day Money Back Guarantee!
                        </p>
                    </div>
                )}

                {/* 0% Fee anzeigen, wenn Payment Options und Money Back Guarantee vorhanden sind */}
                {showPlatformFee && (
                    <div id="money_backy" className="money-back-guaranteed" style={{ marginTop: '0px' }}>
                        <div className="payment-icon-wrapper-2">
                            <img
                                src='../../img/dollar_2.webp'
                                className="payment-option-icon"
                            />
                        </div>
                        <p className="guarantee-title">0% Platform Fee</p>
                        <p className="guarantee-description">
                            We take 0% commission fees.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default LivePaymentOptions;


