import React, { useEffect, useState } from 'react';
import { ref as databaseRef, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import HomeNewHeader from '../Pages/HomeNewHeader';
import AllGigs from '../Pages/AllGigs';
import './Marketplace.css';
import LoadingScreen from '../Utility/LoadingScreen';
import NewMobileHeader from '../Pages/NewMobileHeader';
import DashboardMobileNav from '../UserBackend/DashboardMobileNav';
import GigFilterComponent from './GigFilterComponent';  // Importiere die Filterkomponente
import Footer from '../Pages/Footer';

const Marketplace = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [filteredGigs, setFilteredGigs] = useState([]);
    const [allGigs, setAllGigs] = useState([]); // Alle Gigs

    const services = [
        "Artist Management",
        "Blog/Magazine Promotion",
        "Beat Production",
        "Ghostwriting",
        "Vocal Feature",
        "Song Production",
        "Rap Feature",
        "Instrument Recordings",
        "Mix & Master",
        "Sell Song Licenses",
        "YouTube Promotion",
        "Spotify Promotion",
        "Graphic Design",
        "Video Editing",
        "Music Mentoring",
        "TikTok Influencer",
        "Instagram Influencer"
    ];

    useEffect(() => {
        const pagesRef = databaseRef(database, `/Pages`);
        get(pagesRef).then(pageSnapshot => {
            if (pageSnapshot.exists()) {
                const pages = pageSnapshot.val();
                const gigs = Object.values(pages);
                setAllGigs(gigs); // Setze alle Gigs
                setFilteredGigs(gigs); // Initiale Anzeige aller Gigs
            }
            setIsLoading(false);
        });
    }, []);

    const handleFilterChange = (searchTerm, category) => {
        let filtered = allGigs;

        if (searchTerm) {
            filtered = filtered.filter(gig =>
                gig.gigTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
                gig.gigText.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (category) {
            filtered = filtered.filter(gig => gig.service === category);
        }

        setFilteredGigs(filtered);
    };

    if (isLoading) {
        return <div>
            <HomeNewHeader />
            <LoadingScreen />
        </div>;
    }

    return (
        <div className="marketplace-page">
            <HomeNewHeader />
            <NewMobileHeader />
            {/* Anzeige der gefilterten Gigs */}
            <AllGigs services={services} gigs={filteredGigs} />
            
            <DashboardMobileNav />
            <Footer />
        </div>
    );
};

export default Marketplace;
