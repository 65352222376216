import contentMap from "./ContentMap";
import './ServiceBasedContentSelector.css'

const ServiceBasedContentSelector = ({ serviceType, onServiceClick, selectedFeatures }) => {

    const serviceFeatures = contentMap[serviceType] || contentMap['defaultService'];
  
    return (
      <div className="service-based-content-selector">
     <h3>Includes: <span>Select 3-6 features</span></h3>
      <div className="create-earn-settings-features-container-2">
        {serviceFeatures.map((feature, index) => (
          <button 
          
          key={index} 
          className={`create-earn-settings-feature-button-2 ${selectedFeatures.includes(feature.name) ? 'selected' : ''}`}
          onClick={() => onServiceClick(feature)}>
            <img src={feature.img} alt={feature.name} />
            <h4>{feature.name}</h4>
            <p>{feature.text}</p>

          </button>
        ))}
      </div>
      </div>

    );
  };
  
  export default ServiceBasedContentSelector;
  