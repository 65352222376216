import React from 'react';
import './ListSongs.css'; // Stelle sicher, dass du auch die entsprechenden Styles definierst

function PlaylistStatus({ chartlists }) {
  const playlists = ["Newcomers", "Hiphop", "PopRadio", "Nominees", "AwardWinners", "Editor"];
  const activePlaylists = playlists.filter(playlist => chartlists[playlist]);

  return (
    <div className="playlist-status">
      {activePlaylists.length > 0 ? (
        activePlaylists.join(", ") // Zeigt aktive Playlists, getrennt durch Kommas
      ) : (
        <span className="red-dot-2"></span> // Zeigt einen roten Punkt, wenn keine Playlist aktiv ist
      )}
    </div>
  );
}

export default PlaylistStatus;
