import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

const CoinTables = ({ labels, dataSets }) => {
  return (
    <div className="coin-tables-container">
      {dataSets.map(({ label, data, color }, index) => (
        <div key={index} className="coin-tables-chart">
          <h3 className="chart-title">{label}</h3>
          <Line
            data={{
              labels,
              datasets: [
                {
                  label,
                  data,
                  borderColor: color,
                  backgroundColor: `${color}33`, // Add opacity for background
                  fill: true,
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              },
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default CoinTables;
