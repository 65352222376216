import React from "react";
import { database } from "../../firebaseConfig";
import { ref, set } from "firebase/database";

const SaveLogs = ({ filteredLogs }) => {
  const handleSaveLogs = async () => {
    if (!filteredLogs || filteredLogs.length === 0) {
      console.warn("No logs to save.");
      return;
    }

    const logsRef = ref(database, "/IA/logs");

    try {
      console.log("Saving filtered logs to database...");
      await set(logsRef, filteredLogs);
      console.log("Logs successfully saved.");
    } catch (error) {
      console.error("Error saving logs:", error);
    }
  };

  return (
    <button onClick={handleSaveLogs} className="save-logs-button">
      Save Logs
    </button>
  );
};

export default SaveLogs;
