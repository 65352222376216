import React, { useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './ArtistTop.css';
import CreateCollabModal from './CreateCollabModal';
import BuyProModal from './BuyProModal';
import CollabLoginPopup from './CollabLoginPopup';
import FinishSettingsModal from './FinishSettingsModal';
import Lottie from 'react-lottie-player';
import celebrateAnimation from '../img/celebra_red.json';
import CollabSuccessModal from './CollabSuccessModal';
import { ref as databaseRef, onValue, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import ArtistConnects from './ArtistConnects';
import { useNavigate } from 'react-router-dom';

const ArtistTop = ({ artistDetails, artistID, showBackButton, onConnectClick }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showCollabModal, setShowCollabModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showFinishSettingsModal, setShowFinishSettingsModal] = useState(false);
  const [showCollabLoginPopup, setShowCollabLoginPopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [userCredits, setUserCredits] = useState(0);
  const [isPremium, setIsPremium] = useState(false);
  const [hasSettings, setHasSettings] = useState(null);
  const [hasExpertSettings, setHasExpertSettings] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [showLottie, setShowLottie] = useState(false);
  const [isChatExists, setIsChatExists] = useState(false);
  const [socialLinks, setSocialLinks] = useState({
    spotifyAccount: '',
    youtubeAccount: '',
    instagramAccount: '',
    tiktokAccount: ''
  });
  const [badgeText, setBadgeText] = useState('');
  const [numberAwards, setNumberAwards] = useState(0);
  const [numberNominated, setNumberNominated] = useState(0);
  const modalRef = useRef(null);
  const navigate = useNavigate(); // Hook für Navigation

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        setCurrentUserId(user.uid);

        const userRef = databaseRef(database, `Artists/${user.uid}`);
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          setUserCredits(userData.collabCredits || 0);
          setIsPremium(userData.isPremium || false);
          setHasSettings(userData.hasSettings || false);
          setHasExpertSettings(userData.hasExpertSettings || false);
        }

        // Check if a chat already exists between the current user and the artist
        checkChatExists(user.uid, artistID);
      } else {
        setIsLoggedIn(false);
        setCurrentUserId(null);
      }
    });

    return () => unsubscribe();
  }, [artistID]);



  useEffect(() => {
    // Logik zur Ermittlung des Badge-Textes
    if (artistDetails.numberAwards > 0) {
      setBadgeText('Award Winner');
    } else if (artistDetails.numberNominated > 0) {
      setBadgeText('Nominee');
    } else if (artistDetails.hasSubmitted) {
      setBadgeText('Newcomer');
    }
  }, [artistDetails]);


  useEffect(() => {
    // Abrufen der Social Media Links
    const socialMediaRef = databaseRef(database, `Artists/${artistID}`);
    onValue(socialMediaRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setSocialLinks({
          spotifyAccount: data.spotifyAccount || '',
          youtubeAccount: data.youtubeAccount || '',
          instagramAccount: data.instagramAccount || '',
          tiktokAccount: data.tiktokAccount || '',
          totalAudience: data.totalAudience || ''
        });
      }
    });
  }, [artistID]);

  const checkChatExists = async (currentUserId, artistID) => {
    const chatId1 = `${currentUserId}_${artistID}`;
    const chatId2 = `${artistID}_${currentUserId}`;

    const chatRef1 = databaseRef(database, `Chats/${chatId1}`);
    const chatRef2 = databaseRef(database, `Chats/${chatId2}`);

    const chatSnapshot1 = await get(chatRef1);
    const chatSnapshot2 = await get(chatRef2);

    if (chatSnapshot1.exists() || chatSnapshot2.exists()) {
      setIsChatExists(true);
    }
  };

  const handleSendMessage = () => {
    if (isChatExists) return;

    if (!isLoggedIn) {
      setShowCollabLoginPopup(true);
      return;
    }

    if (!hasSettings && !hasExpertSettings) {
      setShowFinishSettingsModal(true);
      return;
    }

    if (isPremium || userCredits > 0) {
      setShowCollabModal(true);
    } else {
      setShowUpgradeModal(true);
    }
  };

  const handleLottieComplete = () => {
    setShowLottie(false);
  };

  const handleOverlayClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowCollabModal(false);
      setShowUpgradeModal(false);
      setShowFinishSettingsModal(false);
      setShowCollabLoginPopup(false);
    }
  };

  const handleSuccessMessage = async () => {
    setShowSuccessModal(true);
    await checkChatExists(currentUserId, artistID);
    setTimeout(() => {
      setShowSuccessModal(false);
    }, 3000);
  };


  useEffect(() => {
    const songsRef = databaseRef(database, 'Songs');

    onValue(songsRef, (snapshot) => {
      const songsData = snapshot.val();
      let awardsCount = 0;
      let nomineeCount = 0;

      Object.keys(songsData).forEach((songId) => {
        const song = songsData[songId];

        if (song.artistID === artistID) {
          if (song.hasAward) {
            awardsCount++;
          }
          if (song.isNominated) {
            nomineeCount++;
          }
        }
      });

      setNumberAwards(awardsCount);
      setNumberNominated(nomineeCount);

      if (awardsCount > 0) {
        setBadgeText('Award Winner');
      } else if (nomineeCount > 0) {
        setBadgeText('Nominee');
      } else if (artistDetails.hasSubmitted) {
        setBadgeText('Newcomer');
      }
    });
  }, [artistID, artistDetails]);

  return (
    <div className="artist-top" onClick={handleOverlayClick}>
  
      {showCollabModal && currentUserId && (
        <div ref={modalRef}>
          <CreateCollabModal
            senderId={currentUserId}
            receiverId={artistID}
            receiverImageUrl={artistDetails.artistImageUrl}
            onClose={() => setShowCollabModal(false)}
            onMessageSent={(receiverId) => {
              setShowCollabModal(false);
              handleSuccessMessage();
            }}
          />
        </div>
      )}
      {showUpgradeModal && (
        <div ref={modalRef}>
          <BuyProModal onClose={() => setShowUpgradeModal(false)} />
        </div>
      )}
      {showFinishSettingsModal && (
        <div ref={modalRef}>
          <FinishSettingsModal onClose={() => setShowFinishSettingsModal(false)} />
        </div>
      )}
      {showCollabLoginPopup && (
        <div ref={modalRef}>
          <CollabLoginPopup onClose={() => setShowCollabLoginPopup(false)} />
        </div>
      )}
      {showSuccessModal && (
        <CollabSuccessModal onClose={() => setShowSuccessModal(false)} />
      )}

<div className="awards-profile-banner">
        {numberAwards > 0 && (
          <div className="award-profile">
            <h2>{numberAwards}X</h2>
            <hr />
            <span className="award-label">
              <img className="award-img" src="../img/award.svg" alt="Award" />
              AAA Awards <p>Winner</p>
            </span>
          </div>
        )}
        {numberNominated > 0 && numberAwards === 0 && (
          <div className="award-profile">
            <h2>{numberNominated}X</h2>
            <hr />
            <span className="award-label">
              <img className="award-img" src="../img/award.svg" alt="Nominee" />
              AAA Awards <p>Nominee</p>
            </span>
          </div>
        )}
      </div>

      <div className="profile-top-wrapper">
      {showBackButton && (
        <button className="artist-page-back-button" onClick={() => navigate(-1)}>
          <img src="../../img/down.svg" alt="" />
        </button>
      )}

        <div className="profile-top-image-wrapper">
          <img 
            src={artistDetails.artistImageUrl} 
            alt={artistDetails.artistName} 
            className="artist-image-2" 
          />
        </div>

        <div className="artist-info">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h2 className="artist-name">{artistDetails.artistName}</h2> 
          </div>
          <p className="artist-real-name">{artistDetails.name}</p>

          {/* Social Media Icons */}
          <div className="artist-social-medias">
        
            <div>

            {socialLinks.spotifyAccount && (
              <a href={socialLinks.spotifyAccount} target="_blank" rel="noopener noreferrer">
                <img src="../../img/spotify_1.svg" alt="Spotify" className="artist-social-icon" />
              </a>
            )}
            {socialLinks.youtubeAccount && (
              <a href={socialLinks.youtubeAccount} target="_blank" rel="noopener noreferrer">
                <img src="../../img/youtube_1.svg" alt="YouTube" className="artist-social-icon" />
              </a>
            )}
            {socialLinks.instagramAccount && (
              <a href={socialLinks.instagramAccount} target="_blank" rel="noopener noreferrer">
                <img src="../../img/instagram_1.svg" alt="Instagram" className="artist-social-icon" />
              </a>
            )}
            {socialLinks.tiktokAccount && (
            
            <a href={socialLinks.tiktokAccount} target="_blank" rel="noopener noreferrer">
                <img src="../../img/tiktok_2.svg" alt="TikTok" className="artist-social-icon" />
              </a>
            )}
                        </div>
                        {socialLinks.totalAudience && socialLinks.totalAudience > 1 && (
  <div className="artist-audience-size">
    <p>
      Audience Size ~ <span>{socialLinks.totalAudience}</span>
    </p>
    <img src="../../img/user.svg" alt="" />
  </div>
)}

                          {artistDetails.isPremium && (
          <div className="artist-vip-badge">
          <p>
          <span>VIP</span>
              </p>
              {/* <img src="../../img/diamond.svg" alt="" /> */}
              </div>
            )}
                     {badgeText && (
            <div className={`artist-badge ${badgeText.toLowerCase()}`}>
              <p>{badgeText}</p>
            </div>
          )}
          </div>
          <div className="mobile-socials-artist">
          {socialLinks.totalAudience && socialLinks.totalAudience > 1 && (
  <p>
    Audience Size ~ <span>{socialLinks.totalAudience}</span>
  </p>
)}

<div>
{socialLinks.spotifyAccount && (
  <a href={socialLinks.spotifyAccount} target="_blank" rel="noopener noreferrer">
    <img src="../../img/spotify_1.svg" alt="Spotify" className="artist-social-icon" />
  </a>
)}
{socialLinks.youtubeAccount && (
  <a href={socialLinks.youtubeAccount} target="_blank" rel="noopener noreferrer">
    <img src="../../img/youtube_1.svg" alt="YouTube" className="artist-social-icon" />
  </a>
)}
{socialLinks.instagramAccount && (
  <a href={socialLinks.instagramAccount} target="_blank" rel="noopener noreferrer">
    <img src="../../img/instagram_1.svg" alt="Instagram" className="artist-social-icon" />
  </a>
)}
{socialLinks.tiktokAccount && (

<a href={socialLinks.tiktokAccount} target="_blank" rel="noopener noreferrer">
    <img src="../../img/tiktok_2.svg" alt="TikTok" className="artist-social-icon" />
  </a>
)}
</div>
            </div>
          <div className="artist-buttons">
            <button
              onClick={handleSendMessage}
              className="send-profile-message-button"
              disabled={isChatExists}
              style={{ background: isChatExists ? '#0a0a0a' : '', cursor: isChatExists ? 'not-allowed' : 'pointer' }}
            >
              <p>{isChatExists ? 'Chat Exists' : 'Connect'}</p>
              <img src="../img/link-to-device.svg" alt="" />
            </button>
                          <ArtistConnects artistID={artistID} />

          </div>
          <p className="artist-bio-1">{artistDetails.artistBioText}</p>
        </div>
      </div>
    </div>
  );
};

export default ArtistTop;
