import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import './App.css';
import ScrollToTop from './ScrollToTop';
import { auth } from './firebaseConfig';
import HomePage from './Pages/HomePage';
import TermsAndConditions from './Pages/TermsAndConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import SignUp from './SignUp/SignUp';
import ForgotPassword from './SignUp/ForgotPassword';
import DashboardPage from './UserBackend/DashboardPage';
import TeamDashboard from './Team/TeamDashboard';
import SongDetailPage from './Pages/SongDetailPage';
import ArtistPage from './Pages/ArtistDetailPage';
import ArtistDetailPage from './Pages/ArtistDetailPage';
import WelcomeStepper from './UserBackend/WelcomeStepper';
import SubmissionPage from './Pages/SubmissionPage';
import LoadingScreen from './Utility/LoadingScreen';
import SongStepper from './UserBackend/SongStepper';
import PageTransition from './Utility/PageTransitionWrapper';
import { motion, AnimatePresence } from 'framer-motion';
import MyAwards from './UserBackend/MyAwards';
import Settings from './UserBackend/Settings';
import WelcomeScreen from './UserBackend/WelcomeScreen';
import ExpertStepper from './UserBackend/ExpertStepper';
import AllPages from './UserBackend/Page/AllPages';
import CreatePage from './UserBackend/Page/CreatePage';
import PageSettings from './UserBackend/Page/PageSettings';
import { PageDetailsProvider } from './Utility/PageDetailsContext';  // Pfad entsprechend anpassen
import LivePage from './UserBackend/Page/LivePage';
import ChatList from './UserBackend/Chat/ChatList';
import MyFavorites from './UserBackend/Favorites/MyFavorites';
import Marketplace from './Market/Marketplace';
import ErrorBoundary from './Utility/ErrorBoundary';
import CollabPage from './Pages/CollabPage';
import useUserOnlineStatus from './Utility/useUserOnlineStatus';
import LoadingScreenFull from './Utility/LoadingScreenFull';
import ListTikTokVideos from './Pages/ListTiktokVideos';
import ViralVault from './Pages/ViralVault';
import MrTrack from './Utility/MrTrack';
import BuyPro from './Pages/BuyPro';
import useUTMParams from './Utility/useUTMParams';
import WalletTrades from './Team/Invest/WalletTrades';
import CoinAnalysis from './Team/Invest/CoinAnalysis';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false); // Hinzugefügt
  const [isAuthorized, setIsAuthorized] = useState(false); // Autorisierung für TeamDashboard
  const [songCredits, setSongCredits] = useState(0);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageId, setPageId] = useState(null);  // State to hold the pageId


  useUTMParams();


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      const allowedEmails = ['hardvorevibeandi@gmail.com', 'juliprop@hotmail.com', 'julian.democharts@gmail.com', 'valeriewavy@gmail.com'];
      setIsAuthenticated(!!user && user.emailVerified);
      setIsInitialized(true);
      setIsAuthorized(user ? allowedEmails.includes(user.email) && user.emailVerified : false);
    });

    return () => unsubscribe();
  }, []);
  
  const SettingsRouteWrapper = () => {
    const { pageId } = useParams(); // This will fetch pageId from URL parameter
    return (
      <PageDetailsProvider pageId={pageId}>
        <PageSettings />
      </PageDetailsProvider>
    );
  };
  

  return (
    <Router>
      <MrTrack />
      <ScrollToTop />
      <div className="App">
        {/* AnimatePresence für den Übergang des Loading Screens */}
        <AnimatePresence>
          {!isInitialized && <LoadingScreenFull />}
        </AnimatePresence>
        {isInitialized && (
           <ErrorBoundary>
        <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/market" element={<Marketplace />} />
        <Route path="/collaborations" element={<CollabPage />} />
          <Route path="/terms-of-use" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/submission" element={<SubmissionPage />} />
          <Route path="/signup" element={!isAuthenticated ? <SignUp setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/dashboard" replace />} />
          <Route path="/login" element={!isAuthenticated ? <SignUp setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/dashboard" replace />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/song/:songID" element={<SongDetailPage />} />
          <Route path="/dashboard" element={isAuthenticated ? <DashboardPage /> : <Navigate to="/signup" replace />} />
          <Route path="/viral" element={isAuthenticated ? <ViralVault /> : <Navigate to="/signup" replace />} />
          <Route path="/vip" element={isAuthenticated ? <BuyPro /> : <Navigate to="/signup" replace />} /> 
          <Route path="/favorites" element={isAuthenticated ? <MyFavorites /> : <Navigate to="/signup" replace />} />
          <Route path="/settings" element={isAuthenticated ? <Settings /> : <Navigate to="/signup" replace />} />
          <Route path="/chats" element={isAuthenticated ? <ChatList /> : <Navigate to="/signup" replace />} />
          <Route path="/myawards" element={isAuthenticated ? <MyAwards /> : <Navigate to="/signup" replace />} />
          <Route path="/create-page/:pageId" element={isAuthenticated ? <CreatePage /> : <Navigate to="/signup" replace />} />
          <Route path="/create-page" element={isAuthenticated ? <CreatePage /> : <Navigate to="/signup" replace />} />
          <Route path="/pages" element={isAuthenticated ? <AllPages /> : <Navigate to="/signup" replace />} />
          <Route path="/submit-song" element={isAuthenticated ? <SongStepper /> : <Navigate to="/signup" replace />} />
          <Route path="/artists" element={isAuthenticated ? <WelcomeStepper /> : <Navigate to="/signup" replace />} />
          <Route path="/welcome" element={isAuthenticated ? <WelcomeScreen /> : <Navigate to="/signup" replace />} />
          <Route path="/experts" element={isAuthenticated ? <ExpertStepper /> : <Navigate to="/signup" replace />} />
          <Route path="/setting/page/:pageId" element={isAuthenticated ? <SettingsRouteWrapper /> : <Navigate to="/signup" replace />} />
          <Route path="/market/:customPath" element={ <LivePage />} />
          <Route path="/:customPath" element={ <LivePage />} />
        {/* Zugriff auf TeamDashboard nur für autorisierte Benutzer */}
          <Route path="/teamdashboard" element={isAuthorized ? <TeamDashboard /> : <Navigate to="/" replace />} />
          <Route path="/ia/:walletID" element={isAuthorized ? <WalletTrades /> : <Navigate to="/" replace />} />
          <Route path="/coin/:tokenAddress" element={isAuthorized ? <CoinAnalysis /> : <Navigate to="/" replace />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/artist/:artistID" element={<ArtistDetailPage />} />
            </Routes>
            </ErrorBoundary>
                )}
      </div>
    </Router>
  );
}

export default App;
