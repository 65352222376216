import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get } from 'firebase/database';
import { database } from '../../firebaseConfig';
import CountryFlagSVG from '../../Utility/CountryFlagEmoji';
import './CountryCounter.css'


function CountryCounter({ setFilteredSongIds }) {
  const [countries, setCountries] = useState([]);
  const [countryToIdsMap, setCountryToIdsMap] = useState({}); // Mapping von Ländern zu Song-IDs
  const [selectedCountry, setSelectedCountry] = useState(null); // Für das Hervorheben des ausgewählten Landes

  useEffect(() => {
    const fetchCountries = async () => {
      const allSongsSnapshot = await get(databaseRef(database, 'Songs'));
      if (allSongsSnapshot.exists()) {
        const allSongsData = allSongsSnapshot.val();
        
        const newCountryToIdsMap = {}; // Neues Mapping-Objekt erstellen

        // Durchlaufen aller Songs und Erstellung des Mappings
        Object.keys(allSongsData).forEach(songId => {
          const song = allSongsData[songId];
          const country = song.selectedCountry;
          
          if (country) { // Nur Länder hinzufügen, die definiert sind
            if (!newCountryToIdsMap[country]) {
              newCountryToIdsMap[country] = [];
            }
            newCountryToIdsMap[country].push(songId);
          }
        });

        // Erstelle ein Array von Ländern und deren Songanzahl
        const countryWithCounts = Object.keys(newCountryToIdsMap).map((country) => ({
          country,
          count: newCountryToIdsMap[country].length,
        }));

        // Sortiere das Array nach der Anzahl der Songs in absteigender Reihenfolge
        countryWithCounts.sort((a, b) => b.count - a.count);

        // Extrahiere die sortierten Länder
        const sortedCountries = countryWithCounts.map((item) => item.country);

        setCountries(sortedCountries);
        setCountryToIdsMap(newCountryToIdsMap); // Das neue Mapping setzen
      }
    };

    fetchCountries();
  }, []);

  const handleCountryClick = (country) => {
    if (selectedCountry === country) {
      // Wenn dasselbe Land angeklickt wird, deselektiere es und zeige alle Songs an
      setSelectedCountry(null);
      setFilteredSongIds([]); // Leite eine leere Liste weiter, um alle Songs anzuzeigen
    } else {
      // Ansonsten das ausgewählte Land setzen und die gefilterten Song-IDs übergeben
      setSelectedCountry(country);
      setFilteredSongIds(countryToIdsMap[country] || []);
    }
  };

  return (
    <div className="country-counter" style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
      <ul style={{ display: 'flex', padding: 0, margin: 0, listStyleType: 'none' }}>
        {countries.map((country) => (
          <li
            key={country}
            className="country-counter-text"
            style={{
              cursor: 'pointer',
              fontWeight: selectedCountry === country ? '600' : 'normal',
              display: 'flex',
              backgroundColor: selectedCountry === country ? 'var(--blue)' : 'rgb(49, 49, 49)',
              alignItems: 'center',
              marginRight: '15px'
            }}
            onClick={() => handleCountryClick(country)}
          >
            <CountryFlagSVG country={country} /> {/* Füge das Flaggen-Icon hinzu */}
            <p>{country}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CountryCounter;
