import React from 'react';
import './ArtistAnalysis.css';

function ArtistAnalysis({ artists }) {
  const artistStats = {
    uploadedNoAwardOrNomination: 0,
    uploadedAndNominated: 0,
    uploadedAndAwarded: 0,
    totalSuperusers: 0,
    totalArtists: artists.length,
    incompleteSettings: 0,
    submittedArtists: 0,
    completedSettings: 0,
    totalPremiumUsers: 0, // New count for premium users
  };

  const artistPageVisits = {};

  // Array to store premium artists
  const premiumArtists = [];

  artists.forEach((artist) => {
    if (artist.hasSubmitted === 'Ja') {
      artistStats.submittedArtists += 1;

      if (artist.numberAwards > 0) {
        artistStats.uploadedAndAwarded += 1;
      } else if (artist.numberNominated > 0) {
        artistStats.uploadedAndNominated += 1;
      } else {
        artistStats.uploadedNoAwardOrNomination += 1;
      }
    }

    if (artist.sessionCounter >= 10) {
      artistStats.totalSuperusers += 1;
    }

    if (artist.hasSettings) {
      artistStats.completedSettings += 1;
    } else {
      artistStats.incompleteSettings += 1;
    }

    if (artist.isPremium) {
      artistStats.totalPremiumUsers += 1;
      premiumArtists.push(artist); // Add to premium artists list
    }

    if (artist.lastPageVisited) {
      if (artistPageVisits[artist.lastPageVisited]) {
        artistPageVisits[artist.lastPageVisited] += 1;
      } else {
        artistPageVisits[artist.lastPageVisited] = 1;
      }
    }
  });

  const successRate = artistStats.totalArtists > 0 
    ? ((artistStats.submittedArtists / artistStats.totalArtists) * 100).toFixed(2) 
    : 0;

  const completedSettingsRate = artistStats.totalArtists > 0 
    ? ((artistStats.completedSettings / artistStats.totalArtists) * 100).toFixed(2) 
    : 0;

  const sortedPageVisits = Object.entries(artistPageVisits).sort((a, b) => b[1] - a[1]);

  const topArtistsBySessions = [...artists]
    .sort((a, b) => b.sessionCounter - a.sessionCounter)
    .slice(0, 20);

  const formatDate = (timestamp) => {
    return timestamp ? new Date(timestamp).toLocaleDateString() : 'N/A';
  };

  return (
    <div className="analysis-artist-dashboard">
      <h2>Artist Analysis</h2>

      {/* Artist stats */}
      <div className="analysis-cards-container">
        <div className="analysis-card">
          <div className="analysis-number">{artistStats.uploadedNoAwardOrNomination}</div>
          <div className="analysis-description">No Awards/Nominations</div>
        </div>
        <div className="analysis-card analysis-blue">
          <div className="analysis-number">{artistStats.uploadedAndNominated}</div>
          <div className="analysis-description">Nominated Artists</div>
        </div>
        <div className="analysis-card analysis-red">
          <div className="analysis-number">{artistStats.uploadedAndAwarded}</div>
          <div className="analysis-description">Awarded Artists</div>
        </div>
      </div>

      {/* New statistics */}
      <div className="analysis-cards-container">
        <div className="analysis-card">
          <div className="analysis-number">{artistStats.totalSuperusers}</div>
          <div className="analysis-description">Total Superusers</div>
        </div>
        <div className="analysis-card analysis-blue">
          <div className="analysis-number">{artistStats.totalArtists}</div>
          <div className="analysis-description">Total Artists</div>
        </div>
        <div className="analysis-card analysis-red">
          <div className="analysis-number">{artistStats.incompleteSettings}</div>
          <div className="analysis-description">Incomplete Settings</div>
        </div>
        <div className="analysis-card analysis-success-rate">
          <div className="analysis-number">{successRate}%</div>
          <div className="analysis-description">Success Rate (Submitted)</div>
        </div>
        <div className="analysis-card analysis-completed-settings">
          <div className="analysis-number">{completedSettingsRate}%</div>
          <div className="analysis-description">Completed Settings Rate</div>
        </div>
        <div className="analysis-card analysis-premium-users">
          <div className="analysis-number">{artistStats.totalPremiumUsers}</div>
          <div className="analysis-description">Premium Users</div>
        </div>
      </div>

      {/* Scrollable container for page visits */}
      <div className="artist-page-visits-container">
        <h3>Last Page Visited by Artists</h3>
        <div className="artist-page-visits-list">
          {sortedPageVisits.length > 0 ? (
            sortedPageVisits.map(([page, frequency]) => (
              <div key={page} className="artist-page-visits-item">
                <span className="artist-page-name">{page}</span>
                <span className="artist-page-frequency">{frequency} visit(s)</span>
              </div>
            ))
          ) : (
            <p>No page visit data available.</p>
          )}
        </div>
      </div>

      {/* Scrollable container for top 20 artists by session count */}
      <div className="analysis-top-artists-sessions-container">
        <h3>Top 20 Artists by Session Count</h3>
        <div className="analysis-top-artists-sessions-list">
          {topArtistsBySessions.map((artist) => {
            const sessionTimestamps = Object.values(artist.sessionTimestamps || {});
            const firstSession = sessionTimestamps.length ? Math.min(...sessionTimestamps) : null;
            const lastSession = artist.lastSessionTimestamp || null;

            return (
              <div key={artist.id} className="analysis-top-artists-sessions-item">
                <span className="analysis-artist-name">{artist.artistName || 'Unknown'}</span>
                <span className="analysis-session-count">{artist.sessionCounter} sessions</span>
                <span className="analysis-first-session">First: {formatDate(firstSession)}</span>
                <span className="analysis-last-session">Last: {formatDate(lastSession)}</span>
              </div>
            );
          })}
        </div>
      </div>

      {/* Scrollable container for premium artists */}
      <div className="premium-artists-container">
        <h3>Premium Users</h3>
        <div className="premium-artists-list">
          {premiumArtists.length > 0 ? (
            premiumArtists.map((artist) => (
              <div key={artist.id} className="premium-artist-item">
                <span className="premium-artist-name">{artist.artistName || 'Unknown'}</span>
                <span className="premium-artist-email">{artist.email || 'No Email'}</span>
                <span className="premium-artist-country">{artist.country || 'No Country'}</span>
              </div>
            ))
          ) : (
            <p>No premium users found.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ArtistAnalysis;
