// HomeInfoBoxen.js
import React from 'react';
import '../Pages/HomePromoBanner1.css'; // assuming you're using the same CSS

function HomeInfoBoxen() {
  return (
    <div className="home-promo-info-boxen">
      <div className="home-promo-info-box-1">
        <img src="./img/song_dark.webp" alt="" />
        <p>Win <br /> Awards</p>
      </div>
      <div className="home-promo-info-box-1">
        <img src="./img/award_red.webp" alt="" />
        <p>1% <br /><span style={{ fontSize: '12px' }}>Acceptance Rate</span></p>
      </div>
      <div className="home-promo-info-box-1">
        <img src="./img/business_dark.webp" alt="" />
        <p style={{ marginTop: '20px' }}>Connect <br /> with Pro's</p>
      </div>
    </div>
  );
}

export default HomeInfoBoxen;
