import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get, set } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { database, storage } from '../firebaseConfig';
import './AutoMessages.css';

function AutoMessage({ path, messageType, specialFields = [] }) {
  const [senderUserId, setSenderUserId] = useState('');
  const [waitTimeInHours, setWaitTimeInHours] = useState('');
  const [messageInput, setMessageInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [htmlSnippet, setHtmlSnippet] = useState('');
  const [subject, setSubject] = useState('');
  const [images, setImages] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const loadData = async () => {
    const dataRef = databaseRef(database, path);
    try {
      const snapshot = await get(dataRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setSenderUserId(data.senderUserId || '');
        setWaitTimeInHours(data.waitTimeInHours || '');
        setIsActive(data.isActive !== undefined ? data.isActive : true);
        if (specialFields.includes('htmlSnippet')) setHtmlSnippet(data.htmlSnippet || '');
        if (specialFields.includes('subject')) setSubject(data.subject || '');
        if (!specialFields.length) setMessages(Array.isArray(data.messages) ? data.messages : []);
        if (data.images) setImages(data.images);
      } else {
        console.log(`No data available for ${messageType}`);
      }
    } catch (error) {
      console.error(`Error loading data from ${messageType}:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [path]);

  const handleSaveData = async () => {
    if (!senderUserId || !waitTimeInHours) {
      alert('Please fill in all required fields.');
      return;
    }

    const dataRef = databaseRef(database, path);
    const updatedData = {
      senderUserId,
      waitTimeInHours,
      isActive,
      ...(specialFields.includes('htmlSnippet') && { htmlSnippet }),
      ...(specialFields.includes('subject') && { subject }),
      ...(!specialFields.length && { messages }),
      images,
    };

    try {
      await set(dataRef, updatedData);
      alert(`${messageType} data saved successfully!`);
      setIsEditing(false);
    } catch (error) {
      console.error(`Error saving ${messageType} data:`, error);
      alert(`Failed to save ${messageType} data.`);
    }
  };

  const handleFileUpload = async (files) => {
    const imageUrls = [];
    for (const file of files) {
      const storageReference = storageRef(storage, `${path}/images/${file.name}`);
      try {
        const snapshot = await uploadBytes(storageReference, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        imageUrls.push(downloadURL);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
    setImages([...images, ...imageUrls]);
  };

  const deleteImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    handleSaveData(); // Save the updated images list to the database
  };

  const addMessage = () => {
    if (messageInput.trim() === '') return;
    setMessages([...messages, messageInput]);
    setMessageInput('');
  };

  const deleteMessage = (index) => {
    const updatedMessages = messages.filter((_, i) => i !== index);
    setMessages(updatedMessages);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const handleUpload = () => {
    if (selectedFiles.length > 0) {
      handleFileUpload(selectedFiles);
      setSelectedFiles([]);
    } else {
      alert('Please select files to upload.');
    }
  };

  if (isLoading) {
    return <p>Loading data for {messageType}...</p>;
  }

  return (
    <div className="auto-messages-wrapper">
      <button className="auto-messages-toggle-btn" onClick={() => setIsEditing(!isEditing)}>
        {isEditing ? 'Cancel Edit' : 'Edit'}
      </button>

      {isEditing ? (
        <div className="auto-messages-form">
          <label>Sender User ID</label>
          <input
            type="text"
            value={senderUserId}
            onChange={(e) => setSenderUserId(e.target.value)}
            placeholder="Enter sender user ID"
          />

          <label>Wait Time (in Hours)</label>
          <input
            type="number"
            step="0.1"
            value={waitTimeInHours}
            onChange={(e) => setWaitTimeInHours(e.target.value)}
            placeholder="Enter wait time in hours"
          />

          <label>
            <input 
              type="checkbox" 
              checked={isActive} 
              onChange={() => setIsActive(!isActive)} 
            />
            Active
          </label>

          {specialFields.includes('htmlSnippet') && (
            <>
              <label>HTML Snippet</label>
              <textarea
                value={htmlSnippet}
                onChange={(e) => setHtmlSnippet(e.target.value)}
                placeholder="Enter HTML Snippet"
              />
            </>
          )}

          {specialFields.includes('subject') && (
            <>
              <label>Subject</label>
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Enter subject"
              />
            </>
          )}

          {!specialFields.length && (
            <>
              <label>Add Message</label>
              <input
                type="text"
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                placeholder="Enter a message"
              />
              <button onClick={addMessage}>Add Message</button>

              <div className="auto-messages-list">
                <h3>Messages</h3>
                {messages.length > 0 ? (
                  messages.map((msg, index) => (
                    <div key={index} className="auto-message-item">
                      <p>{msg}</p>
                      <button className="auto-messages-delete-btn" onClick={() => deleteMessage(index)}>
                        Delete
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No messages yet.</p>
                )}
              </div>
            </>
          )}

          <div className="auto-messages-images">
            <label>Upload Images</label>
            <input type="file" multiple onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload Images</button>

            <div className="auto-uploaded-images">
              {images.map((url, index) => (
                <div key={index} className="auto-uploaded-image-container">
                  <img src={url} alt={`uploaded ${index}`} className="uploaded-image" />
                  <button className="auto-img-delete-image-button" onClick={() => deleteImage(index)}>X</button>
                </div>
              ))}
            </div>
          </div>

          <button onClick={handleSaveData}>Save {messageType}</button>
        </div>
      ) : (
        <div className="auto-messages-view">
          <p><strong>Sender User ID:</strong> {senderUserId || 'N/A'}</p>
          <p><strong>Wait Time (in Hours):</strong> {waitTimeInHours || 'N/A'}</p>
          <p><strong>Active:</strong> {isActive ? 'Yes' : 'No'}</p>

          {specialFields.includes('htmlSnippet') && (
            <p><strong>HTML Snippet:</strong> {htmlSnippet || 'N/A'}</p>
          )}

          {specialFields.includes('subject') && (
            <p><strong>Subject:</strong> {subject || 'N/A'}</p>
          )}

          <h3>Messages</h3>
          {messages.length > 0 ? (
            messages.map((msg, index) => <p key={index}>{msg}</p>)
          ) : (
            <p>No messages saved.</p>
          )}

          <h3>Images</h3>
          <div className="auto-uploaded-images">
            {images.map((url, index) => (
              <div key={index} className="auto-uploaded-image-container">
                <img src={url} alt={`uploaded ${index}`} className="auto-uploaded-image" />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default AutoMessage;
