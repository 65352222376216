import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FinishSettingsModal.css';

const FinishSettingsModal = ({ onClose }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/dashboard');  // Leitet nach 4 Sekunden auf /dashboard weiter
      if (onClose) onClose();
    }, 4000);

    return () => clearTimeout(timer);
  }, [navigate, onClose]);

  return (
    <div className="collab-success-modal-overlay" onClick={onClose}>
      <div className="collab-success-modal" onClick={(e) => e.stopPropagation()}>
        <div className="collab-success-header">
          <img src="../../img/next.webp" alt="warning" />
          <h2>Finish Your Settings</h2>
          <p>Please complete your profile settings before proceeding.</p>
        </div>
        <div className="start-over-button" style={{ textDecoration: 'none' }}>
          <button
            style={{
              backgroundColor: 'transparent',
              zIndex: '20',
              color: 'white',
              border: 'none',
              fontWeight: '600',
              textTransform: 'uppercase',
            }}
          >
            Go to Settings
          </button>
          <div className="completed-stepper-progress-container">
            <div className="completed-stepper-progress-bar"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinishSettingsModal;
