// src/Utility/VerticalText.js
import React from 'react';

function VerticalText({ text }) {
    const letters = text.split('').map((letter, index) => (
        <span key={index} className="vertical-letter">{letter}</span>
    ));

    return <div className="vertical-text">{letters}</div>;
}

export default VerticalText;
