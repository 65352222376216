import React from 'react';
import './SongAwardInfo.css'; // Stellen Sie sicher, dass Sie auch die entsprechende CSS-Datei erstellen

const SongAwardInfo = ({ hasAward, awardDate }) => {
  if (!hasAward) {
    return null; // Wenn hasAward false ist, wird nichts gerendert
  }
  
  // Funktion, um das Datum zu formatieren
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); // Führende Null hinzufügen, falls nötig
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Monate sind 0-basiert
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const formattedDate = awardDate ? formatDate(awardDate) : '';

  return (
    <div className="song-award-info-wrapper">
      <div className="song-award-info">
        <img src="../img/aaa_awards_small.svg" alt="" />
        <div>
          <h3>Award Winner</h3>
          <p>
            AAA Music Awards 
            {formattedDate && ` | ${formattedDate}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SongAwardInfo;
