// ArtistTypes.js
import React from 'react';

function ArtistTypes({ onChange, value, className}) {
  // List of artist types
  const artistTypes = [
    'Singer/Songwriter', 'Rapper', 'Beatmaker', 'Producer',
    'Instrumentalist', 'Band', 'DJ', 'Composer',  'MC'
  ];

  return (
    <select onChange={onChange} value={value} className={className}>
      <option value="">Select Artist Type...</option>
      {artistTypes.map((type, index) => (
        <option key={index} value={type}>{type}</option>
      ))}
    </select>
  );
}

export default ArtistTypes;
