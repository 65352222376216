import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get, update } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import './MoodSelection.css'
import {availableMoods } from '../PageUtility/AvailableInfos'

function MoodSelection({ pageId, onDisableNext, onError }) {
    const [moods, setMoods] = useState([]);


  useEffect(() => {
    if (pageId) {
      const pageDataRef = databaseRef(database, `Pages/${pageId}/pageData`);
      get(pageDataRef).then((snapshot) => {
        if (snapshot.exists() && snapshot.val().moods) {
          setMoods(snapshot.val().moods);
        }
      }).catch(error => {
        console.error('Failed to fetch page data:', error);
        onError && onError('Failed to fetch page data');
      });
    }
  }, [pageId, onError]);

  useEffect(() => {
    onDisableNext(!(moods.length > 0)); // Disable button unless at least one mood is selected
  }, [moods, onDisableNext]);


  const toggleSelection = (mood) => {
    const currentIndex = moods.indexOf(mood);
    const newList = [...moods];

    if (currentIndex === -1) {
        // Hinzufügen nur erlauben, wenn weniger als 4 Moods ausgewählt sind
        if (moods.length < 4) {
            newList.push(mood);
        }
    } else {
        // Entferne das Mood, wenn es bereits ausgewählt ist
        newList.splice(currentIndex, 1);
    }

    setMoods(newList);
    savePageData({moods: newList});
};



  const savePageData = (data) => {
    const userId = auth.currentUser?.uid;
    if (userId && pageId) {
      const pageDataRef = databaseRef(database, `Pages/${pageId}/pageData`);
      update(pageDataRef, data).catch(error => console.error('Failed to save page data:', error));
    } else {
      console.error('No pageId provided or user is not logged in');
    }
  };

  return (
    <div className="page-mood-selection">
      <div className="create-page-header-fonts">
        <h2>Sound Profile</h2>
        <p>Detail the core elements that define your unique sound.</p>
      </div>
      <p style={{ width: '100%', textAlign: 'center', fontSize: '14px', color: 'grey', marginTop: '0px' }}>Select 1-6 Sound-Types</p>
      <div className="mood-selection-container">
        {availableMoods.map((mood) => (
          <button key={mood} className={`mood-button-2 ${moods.includes(mood) ? "selected" : ""}`} onClick={() => toggleSelection(mood)}>
            {mood}
          </button>
        ))}
      </div>
    </div>
  );
}


export default MoodSelection;
