import React from 'react';
import './GenerativeOrbAnimation.css';

const GenerativeOrbAnimation5 = () => {
  // Dies ist nur eine Präsentationskomponente, die keine Logik enthält.
  return (
<div className="component-wrapper-2">
    <div className="orb2 orb2-1"></div>
    <div className="orb2 orb2-2"></div>
    <div className="orb2 orb2-3"></div>
    <div className="orb2 orb2-4"></div>
</div>

  );
};

export default GenerativeOrbAnimation5;
