import React from 'react';
import { motion } from 'framer-motion';
import './LoadingScreen.css';
import logo from '../img/logo.png';

const LoadingScreenFull = () => {
  return (
    <motion.div 
      initial={{ opacity: 1 }} // Startet sofort mit voller Opazität
      exit={{
        opacity: 0,
        transition: { delay: 1.2, duration: 0.2 } // Warte 1 Sekunde, dann schnelles Fade-Out in 200ms
      }}
      className="loddri-loading-screen-full"
    >
      <div className="loddri-spinner"></div>
      <img src={logo} alt="Logo" className="loddri-loading-logo" />
    </motion.div>
  );
};

export default LoadingScreenFull;
