import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from "../firebaseConfig";
import { compressImage } from '../Utility/ImageCompressor'; // Stellen Sie sicher, dass der Pfad korrekt ist

export const uploadFile = async (file, folder) => {
  let fileToUpload = file;

  // Prüfen, ob die Datei ein Bild ist und komprimiert werden soll
  if (file.type.startsWith('image/')) {
    const compressedBlob = await compressImage(file);
    fileToUpload = new File([compressedBlob], file.name, {
      type: file.type,
    });
  }

  const uniqueSuffix = `${Date.now()}-${Math.round(Math.random() * 1E9)}`;
  const fileName = `${uniqueSuffix}-${fileToUpload.name}`;
  const fileRef = storageRef(storage, `${folder}/${fileName}`);
  const uploadTask = uploadBytesResumable(fileRef, fileToUpload);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      () => {},
      (error) => {
        console.error('Fehler beim Hochladen der Datei:', error);
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('Datei erfolgreich hochgeladen. Download URL:', downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};
