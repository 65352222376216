import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './GigFilterComponent.css';
import GigFilterSideContainer from './GigFilterSideContainer'; // Importiere die ausgelagerte Komponente

const GigFilterComponent = ({ services, onFilterChange }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        onFilterChange(debouncedSearchTerm, selectedCategory);
    }, [debouncedSearchTerm, selectedCategory]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(prevCategory => (prevCategory === category ? '' : category));
    };

    return (
        <div className="gig-filter-component-container">
            <div className="gig-filter-content-area">
                <div className="gig-filter-search-container">
                    <p className="marketplace-gig-filter-text">Marketplace</p>
                    <img src="./img/democharts_boys.webp" alt="Democharts Boys" className="democharts_boys" />
                    <div>
                        <h2>The Music Marketplace.</h2>
                        <div style={{ position: 'relative', width: '100%', display: 'flex', alignItems: 'center' }}>
                            <input 
                                type="text" 
                                placeholder="Look for Services..." 
                                value={searchTerm} 
                                onChange={handleSearchChange} 
                                className="gig-filter-search-box"
                            />
                            <img className="search-input-icon" src="./img/search.svg" alt="Search Icon" />
                        </div>
                    </div>
                </div>
                <div className="gig-filter-right-side">
                    <div className="gig-filter-additional-container">
                        <div 
                            className={`gig-filter-upper-container ${selectedCategory === "Graphic Design" ? 'selected' : ''}`}
                            onClick={() => handleCategoryClick("Graphic Design")}
                        >
                            <h2>Graphic Design</h2>
                        </div>
                        <div 
                            className={`gig-filter-lower-container ${selectedCategory === "Music Influencer" ? 'selected' : ''}`}
                            onClick={() => handleCategoryClick("Music Influencer")}
                        >
                            <h2>Influencer Marketing</h2>
                        </div>
                        <div 
                            className={`gig-filter-lower-container ${selectedCategory === "Mix & Master" ? 'selected' : ''}`}
                            id="lowest-container"
                            onClick={() => handleCategoryClick("Mix & Master")}
                        >
                            <h2>Mix & Master</h2>
                        </div>
                    </div>

                    {/* Verwende die ausgelagerte Komponente */}
                    <GigFilterSideContainer />
                </div>
            </div>
            <div className="gig-filter-buttons-container">
                <p>Categories</p>
                {services.map(service => (
                    <button 
                        key={service} 
                        className={`gig-filter-button ${selectedCategory === service ? 'selected' : ''}`} 
                        onClick={() => handleCategoryClick(service)}
                    >
                        {service}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default GigFilterComponent;
