import React, { useState, useEffect } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './PageQa.css';

function PageQa({ onToggleSettings, pageId, widgetKey, active, settingsRoute }) {
    const [qaList, setQaList] = useState([]);
    const [selectedQa, setSelectedQa] = useState(null);

    useEffect(() => {
        const qaRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/questionsAnswers`);
        const unsubscribe = onValue(qaRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setQaList(data ? Object.values(data) : []);
            } else {
                setQaList([]);
            }
        }, (error) => {
            console.error('Failed to fetch Q&A data:', error);
        });

        return () => off(qaRef, 'value', unsubscribe);
    }, [pageId, widgetKey]);

    const className = `page-qa ${settingsRoute ? 'settings-route' : ''} ${active ? 'is-active' : ''}`;

    const toggleQa = (index) => {
        setSelectedQa(selectedQa === index ? null : index);
    };

    return (
        <div className={className} onClick={() => onToggleSettings('QA')}>
            {settingsRoute && (
                <div className="edit-overlay">
                    <img src="../../img/edit.webp" alt="Edit" />
                    <span>Edit</span>
                </div>
            )}

            {qaList.length > 0 ? (
                <div className="qa-list">
                    <h2 style={{ textAlign: 'left' }}>Frequently Asked Questions</h2>
                    {qaList.map((qa, index) => (
                        <div key={index} className="qa-item" onClick={() => toggleQa(index)}>
                            <div className="qa-question">
                                {qa.question}
                                <img src="../../img/down.svg" alt="Arrow" className={`arrow-icon ${selectedQa === index ? 'up' : 'down'}`} />
                            </div>
                            {selectedQa === index && (
                                <div className="qa-answer">
                                    {qa.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                settingsRoute && (
                    <div className="gig-upload-song-button" onClick={() => onToggleSettings('AddQa')}>
                        <h5>Questions & Answers</h5>
                        <img src="../../img/plus.svg" alt="Add" />
                    </div>
                )
            )}
        </div>
    );
}

export default PageQa;
