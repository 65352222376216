import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';
import OpenSingleChat from './OpenSingleChat'; // Import der Chat-Komponente
import './TeamDashboard.css'; // Import der CSS-Datei für das Modal-Design

function AllChats() {
  const [chats, setChats] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);

  useEffect(() => {
    const chatsRef = ref(database, 'Chats');
    onValue(chatsRef, (snapshot) => {
      const chatData = snapshot.val();
      if (chatData) {
        const chatEntries = Object.keys(chatData).map(async (chatId) => {
          const chat = chatData[chatId];
          const initiatorRef = ref(database, `Artists/${chat.initiatorId}/artistName`);
          const recipientRef = ref(database, `Artists/${chat.recipientId}/artistName`);

          const [initiatorSnapshot, recipientSnapshot] = await Promise.all([
            new Promise((resolve) => onValue(initiatorRef, resolve)),
            new Promise((resolve) => onValue(recipientRef, resolve)),
          ]);

          const initiatorName = initiatorSnapshot.val();
          const recipientName = recipientSnapshot.val();

          return {
            chatId,
            initiatorName,
            recipientName,
            lastMessageAt: chat.lastMessageAt,
          };
        });

        Promise.all(chatEntries).then((resolvedChats) => {
          setChats(resolvedChats);
        });
      }
    });
  }, []);

  const openChat = (chatId) => {
    setSelectedChatId(chatId);
  };

  const closeChat = () => {
    setSelectedChatId(null);
  };

  return (
    <div>
      <h2>All Chats</h2>
      <table className="woody-summary-table">
        <thead>
          <tr>
            <th>Chat ID</th>
            <th>Initiator</th>
            <th>Recipient</th>
            <th>Last Message At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {chats.map((chat, index) => (
            <tr key={index}>
              <td>{chat.chatId}</td>
              <td>{chat.initiatorName}</td>
              <td>{chat.recipientName}</td>
              <td>{new Date(chat.lastMessageAt).toLocaleString()}</td>
              <td>
                <button onClick={() => openChat(chat.chatId)}>Open Chat</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedChatId && (
        <div className="all-chats-modal">
          <div className="all-chats-modal-content">
            <span className="all-chats-close-button" onClick={closeChat}>&times;</span>
            <OpenSingleChat chatId={selectedChatId} onClose={closeChat} />
          </div>
        </div>
      )}
    </div>
  );
}

export default AllChats;
