import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref as databaseRef, get } from 'firebase/database';
import { database } from "../firebaseConfig";
import './DashboardMobileNav.css';

function DashboardMobileNav() {
    const location = useLocation();
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);
    const [navClass, setNavClass] = useState('nav-visible');
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Zustand für den Anmeldestatus
    const [hasExpertSettings, setHasExpertSettings] = useState(false); // Zustand für Expert-Einstellungen
    const [loading, setLoading] = useState(true); // Zustand für das Laden der Daten

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setIsLoggedIn(true);
                setLoading(true);

                // Abfrage in der Datenbank für `hasExpertSettings`
                const artistRef = databaseRef(database, `/Artists/${user.uid}/hasExpertSettings`);
                try {
                    const snapshot = await get(artistRef);
                    if (snapshot.exists()) {
                        setHasExpertSettings(snapshot.val() === true);
                    }
                } catch (error) {
                    console.error('Error fetching expert settings:', error);
                } finally {
                    setLoading(false); // Ladezustand beenden
                }
            } else {
                setIsLoggedIn(false);
                setLoading(false);
            }
        });

        return () => unsubscribe(); // Bereinigung des Auth-Listeners
    }, []);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > lastScrollY) {
            setNavClass('nav-hidden');
        } else {
            setNavClass('nav-visible');
        }
        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    // Menü für Benutzer, die nicht eingeloggt sind
    const guestNavItems = [
        { path: '/submit-song', label: 'Upload Song', icon: 'plus.webp', activeIcon: 'business_red.webp' },
        { path: '/dashboard', label: 'Dashboard', icon: 'award_dark.webp', activeIcon: 'award_red.webp' },
    ];

    // Menü für eingeloggt aber kein Experte
    const userNavItems = [
        { path: '/submit-song', label: 'Upload', icon: 'plus.webp', activeIcon: 'business_red.webp' },
        { path: '/dashboard', label: 'My Awards', icon: 'award_dark.webp', activeIcon: 'award_red.webp' },
        { path: '/pages', label: 'My Pages', icon: 'award_dark.webp', activeIcon: 'award_red.webp' },
        { path: '/chats', label: 'Chats', icon: 'bubble_dark.webp', activeIcon: 'bubble_red.webp' },
    ];

    // Menü für Experten
    const expertNavItems = [
        { path: '/pages', label: 'My Pages', icon: 'award_dark.webp', activeIcon: 'award_red.webp' },
        { path: '/chats', label: 'Chats', icon: 'bubble_dark.webp', activeIcon: 'bubble_red.webp' },
        { path: '/market', label: 'Market', icon: 'business_dark.webp', activeIcon: 'heart_red.webp' },
    ];

    // Auswahl des passenden Menüs basierend auf dem Benutzerstatus
    const getNavItems = () => {
        if (loading) {
            return []; // Zeige nichts während des Ladens
        }

        if (!isLoggedIn) {
            return guestNavItems;
        }

        if (hasExpertSettings) {
            return expertNavItems;
        }

        return userNavItems;
    };

    const navItems = getNavItems();

    return (
        <div className={`mobile-nav-container ${navClass}`}>
            {navItems.map(item => (
                <Link to={item.path} key={item.label} className={`mobile-nav-link ${location.pathname === item.path ? 'mobile-nav-link-active' : ''}`}>
                    <img 
                        src={`./img/${location.pathname === item.path ? item.activeIcon : item.icon}`} 
                        alt={item.label} 
                        className="mobile-nav-icon" 
                    />
                    <p className="mobile-nav-label">{item.label}</p>
                </Link>
            ))}
        </div>
    );
}

export default DashboardMobileNav;
