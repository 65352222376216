import React from 'react';
import './UtilitySettings.css'


function ButtonSelection({ options, value, onChange, labelText, multiSelect = false, className, maxSelections = Number.MAX_SAFE_INTEGER, minSelections = 0 }) {
    const toggleSelection = (option) => {
        if (multiSelect) {
            const currentIndex = value.indexOf(option);
            const newList = [...value];
            
            if (currentIndex === -1) {
                if (value.length < maxSelections) {
                    newList.push(option);
                }
            } else {
                // Erlaube das Entfernen nur, wenn es mehr als die Mindestanzahl gibt
                if (value.length > minSelections) {
                    newList.splice(currentIndex, 1);
                }
            }

            onChange(newList);
        } else {
            onChange(option);
        }
    };



    return (
        <div className="button-selection-container">
            <p>{labelText}</p>
            <div className={`${className}`}>
                {options.map(option => (
                    <button
                        key={option}
                        className={`mood-button-2 ${multiSelect ? (value.includes(option) ? 'selected' : '') : (value === option ? 'selected' : '')}`}
                        onClick={() => toggleSelection(option)}
                    >
                        {option}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default ButtonSelection;
