import React from 'react';
import BlackWhiteSwitch from '../PageUtility/BlackWhiteSwitch';
import CategoryLabel from '../PageUtility/CategoryLabel';
import HeaderLabel from '../PageUtility/HeaderLabel';
import StyleSelector from '../PageUtility/StyleSelector';
import './DefaultSettings.css'

function DefaultSettings({ pageId }) {
    return (
        <div className="default-settings">
            <div>
            <h2>Page Settings</h2>
            <p>Please select a component to edit its settings or start by reviewing general page settings.</p>
     </div>

     <div className="gig-default-settings">
     <BlackWhiteSwitch pageId={pageId} />
     <CategoryLabel text="Offer Style"/>
     <StyleSelector pageId={pageId}/>
     <div className="gig-default-banner">
     <p className="gig-pro-text">24 more styles with <span className="supercharge">VIP</span></p>
     <img src="../../img/lock.svg" alt="" />
     </div>
     </div>
        </div>
    );
}

export default DefaultSettings;
