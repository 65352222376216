import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './OpositeInfo.css';
import { Link } from 'react-router-dom';

const OpositeInfo = ({ partnerId }) => {
    const [partnerInfo, setPartnerInfo] = useState({});
    const [pages, setPages] = useState([]);
    const [loadingPages, setLoadingPages] = useState(true);

    useEffect(() => {
        const fetchPartnerInfo = async () => {
            const artistRef = databaseRef(database, `Artists/${partnerId}`);
            const snapshot = await get(artistRef);
            if (snapshot.exists()) {
                setPartnerInfo(snapshot.val());
            }
        };
    
        const fetchArtistPages = async () => {
            const pageRoutesRef = databaseRef(database, `PageRoutes`);
            const pageRoutesSnapshot = await get(pageRoutesRef);
            if (pageRoutesSnapshot.exists()) {
                const pageRoutes = pageRoutesSnapshot.val();
                const artistPages = Object.keys(pageRoutes)
                    .filter(key => pageRoutes[key].artistId === partnerId)
                    .map(key => ({
                        pageId: pageRoutes[key].pageId,
                        routeName: key  // Angenommen, `key` ist die Route-ID.
                    }));
    
                const pagesDataPromises = artistPages.map(async ({ pageId, routeName }) => {
                    const pageDataRef = databaseRef(database, `Pages/${pageId}/pageData`);
                    const pageDataSnapshot = await get(pageDataRef);
                    if (pageDataSnapshot.exists() && pageDataSnapshot.val().pageIsLive) {
                        const pageData = pageDataSnapshot.val();
                        const offers = pageData.offers ? pageData.offers[0] : null;
                        const firstGalleryImage = pageData.gallery && pageData.gallery.length > 0 ? pageData.gallery[0] : null;
                        return {
                            service: pageData.service,
                            introTitle: pageData.introTitle,
                            payoutOption: pageData.payoutOption,
                            gigTitle: offers ? offers.gigTitle : '',
                            earnAmount: offers ? offers.earnAmount : '',
                            gigText: offers ? offers.gigText : '',
                            firstGalleryImage,
                            routeName  // Speichere Route-Name zur späteren Verwendung im Link
                        };
                    }
                    return null;
                });
    
                const fetchedPages = await Promise.all(pagesDataPromises);
                const livePages = fetchedPages.filter(page => page !== null);
                if (livePages.length > 0) {
                    setPages(livePages);
                } else {
                    setPages(['No pages available']);
                }
            }
            setLoadingPages(false);
        };
    
        fetchPartnerInfo();
        fetchArtistPages();
    }, [partnerId]);
    
    return (
        <div className="opposite-info">
            <h2>About</h2>
            <div className="opposite-info-pic">
                <img src={partnerInfo.artistImageUrl || '../../img/default-image.webp'} alt={partnerInfo.artistName || 'Artist'} />
            </div>
            <Link style={{color: 'inherit'}} to={`/artist/${partnerId}`}> {/* Korrigierter Link zur Künstlerseite */}
                <h3>{partnerInfo.artistName || 'Unknown Artist'}</h3>
            </Link>
            <p>{partnerInfo.artistType || partnerInfo.expertType}</p>
            <p>{partnerInfo.country || 'No country provided'}</p>
            {partnerInfo.numberAwards > 0 && (
                <div className="awarded-nominated-div">
                    <img src="../../img/aaa_awards_small.svg" alt="Award" />
                    <p>Award Winner</p>
                </div>
            )}
            {partnerInfo.numberNominated > 0 && (
                <div className="awarded-nominated-div">
                    <img src="../../img/aaa_nominee_2_black.svg" alt="Nominee" />
                    <p>Award Nominee</p>
                </div>
            )}
            {loadingPages ? (
                <p>Loading pages...</p>
            ) : (
                pages.length > 0 && typeof pages[0] !== 'string' && (
                    <div className="inchat-gigs">
                        <hr/>
                        <h5 style={{marginTop: '20px', marginBottom: '20px'}}>Services</h5>
                        {pages.map((page, index) => (
                            <div className="inpage-chat-info-box" key={index}>
                                <Link to={`/${page.routeName}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <img src={page.firstGalleryImage || '../../img/background_2.jpg'} alt="Gallery Image" />
                                    <h5>{page.gigTitle}</h5>
                                    <div className="inchat-earn-amount">
                                        {page.payoutOption === "collaboration" ? "Free" :
                                        page.payoutOption === "earnMoney" ? `$${page.earnAmount}` :
                                        page.payoutOption === "earnRoyalties" ? `${page.earnAmount}%` : ""}
                                    </div>
                                    <p>{page.gigText}</p>
                                </Link>
                            </div>
                        ))}
                    </div>
                )
            )}
        </div>
    );
    
                                }    

export default OpositeInfo;
