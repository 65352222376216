import React, { useState, useEffect } from 'react';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  sendEmailVerification,
  signOut
} from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import './SignUp.css';
import { addUserEntry, completeUserRegistration } from "../firebaseConfig"; // Stellen Sie sicher, dass Sie den richtigen Pfad zu Ihrer Konfigurationsdatei angeben
import GenerativeOrbAnimation from '../Utility/GenerativeOrbAnimation';
import { motion } from 'framer-motion'; // Vergiss nicht, dies zu importieren, falls noch nicht geschehen
import { useLocation } from 'react-router-dom';



function SignUp({isAuthenticated, setIsAuthenticated }) {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showSignIn, setShowSignIn] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showVerificationScreen, setShowVerificationScreen] = useState(false);
  const auth = getAuth();
  const [isValid, setIsValid] = useState(false); 
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
const [passwordTouched, setPasswordTouched] = useState(false);
const [passwordConfirmation, setPasswordConfirmation] = useState('');
const [passwordConfirmationTouched, setPasswordConfirmationTouched] = useState(false);
const debouncedPassword = useDebounce(password, 600);
const debouncedPasswordConfirmation = useDebounce(passwordConfirmation, 600);
const [passwordError, setPasswordError] = useState(false);
const [passwordConfirmationError, setPasswordConfirmationError] = useState(false);
const [errorMessage, setErrorMessage] = useState("");
const debouncedEmail = useDebounce(email, 600);
const [showCheckPassword, setShowCheckPassword] = useState(false);
const [showCheckPasswordConfirmation, setShowCheckPasswordConfirmation] = useState(false);
const [emailErrorMessage, setEmailErrorMessage] = useState("");
const [showCheckEmail, setShowCheckEmail] = useState(false);
const [loginMessage, setLoginMessage] = useState("");
const location = useLocation();
const isFormValid = showCheckEmail && showCheckPassword && showCheckPasswordConfirmation;
const navigate = useNavigate(); // Richtig platziert innerhalb der Komponente
const [loading, setLoading] = useState(false); // Zustand für den Ladevorgang


useEffect(() => {
  // Setzt showSignIn auf true, wenn der Pfad "/login" ist, sonst auf false
  setShowSignIn(location.pathname === '/login');
}, [location]);


useEffect(() => {
  let errorMessage = "";
  let showCheckPasswordTemp = false;
  let showCheckPasswordConfirmationTemp = false;

  // Prüfung, ob das erste Passwort den Anforderungen entspricht
  if (debouncedPassword.length >= 8) {
    showCheckPasswordTemp = true;
  } else if (debouncedPassword.length > 0) {
    errorMessage = "The password should be at least 8 characters long.";
  }

  // Prüfung, ob das zweite Passwort mit dem ersten übereinstimmt
  if (debouncedPasswordConfirmation === debouncedPassword && debouncedPasswordConfirmation.length >= 8) {
    showCheckPasswordConfirmationTemp = true;
  } else if (debouncedPasswordConfirmation.length > 0) {
    // Nur eine Fehlermeldung setzen, wenn noch kein anderer Fehler vorliegt
    if (!errorMessage) {
      errorMessage = "Passwords do not match.";
    }
  }

  setShowCheckPassword(showCheckPasswordTemp);
  setShowCheckPasswordConfirmation(showCheckPasswordConfirmationTemp);
  setErrorMessage(errorMessage);

}, [debouncedPassword, debouncedPasswordConfirmation]);

useEffect(() => {
  // Setzt Fehlermeldung und Styling zurück, sobald der Nutzer tippt
  setEmailErrorMessage("");
  setShowCheckEmail(false);
}, [email]); 

useEffect(() => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  // Prüfen, ob das Eingabefeld leer ist
  if (debouncedEmail.length === 0) {
    setEmailErrorMessage(""); // Entfernt die Fehlermeldung
    setShowCheckEmail(false); // Setzt spezifisches Styling zurück
    // Frühe Rückkehr, um die restliche Logik zu vermeiden
    return; 
  }

  // Wenn das Feld nicht leer ist, aber der Inhalt nicht dem Muster entspricht
  if (!emailRegex.test(debouncedEmail)) {
    setEmailErrorMessage("Please type in a valid e-mail address.");
    setShowCheckEmail(false);
  } else {
    // Wenn das Feld nicht leer ist und der Inhalt dem Muster entspricht
    setEmailErrorMessage(""); // Entfernt die Fehlermeldung, falls zuvor gesetzt
    setShowCheckEmail(true); // Aktiviert spezifisches Styling oder Logik
  }
}, [debouncedEmail]);



function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}


const resetFormFields = () => {
  setEmail("");
  setPassword("");
  setPasswordConfirmation("");
  setEmailTouched(false);
  setPasswordTouched(false);
  setPasswordConfirmationTouched(false);
  // Setzen Sie hier weitere Zustände zurück, falls benötigt
};


// Direktes Setzen von passwordTouched beim Ändern des Passwortes
const handlePasswordChange = (e) => {
  setPassword(e.target.value);
  setPasswordTouched(true);
};

// Entsprechendes für die Passwortbestätigung
const handlePasswordConfirmationChange = (e) => {
  setPasswordConfirmation(e.target.value);
  setPasswordConfirmationTouched(true);
};


function getFriendlyErrorMessage(errorCode) {
  const firebaseErrorMapping = {
    'auth/invalid-email': 'This e-mail does not seem valid.',
    'auth/user-disabled': 'Your account has been deactivated.',
    'auth/user-not-found': 'No account found. Did you mean to sign up?',
    'auth/wrong-password': 'Sorry, the password is incorrect.',
    // Weitere Fehlercodes hinzufügen nach Bedarf
  };

  // Gibt eine benutzerfreundliche Fehlermeldung zurück oder einen Standardtext, falls der Fehlercode nicht gefunden wurde
  return firebaseErrorMapping[errorCode] || "An unexpected error occurred. Please try again.";
}

 
useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged(async (user) => {
    if (user) {
      await user.reload(); // Stellt sicher, dass der neueste Zustand des Benutzers abgerufen wird
      setIsAuthenticated(user.emailVerified); // Setzt den Authentifizierungsstatus abhängig von der E-Mail-Verifizierung
      if (user.emailVerified && user.email) { // Überprüft, ob die E-Mail verifiziert ist und ob eine E-Mail vorhanden ist
        // Die E-Mail des Benutzers wurde verifiziert, also vervollständige die Registrierung
        completeUserRegistration(user.uid, user.email)
          .then(() => {
            // Optional: Zeige eine Erfolgsmeldung oder navigiere zu einer anderen Seite
            navigate('/dashboard'); // Navigiere zum Dashboard, nachdem die Registrierung vervollständigt wurde
          })
          .catch((error) => {
            // Handle mögliche Fehler, die beim Update auftreten
            console.error("Fehler beim Vervollständigen der Registrierung: ", error);
          });
      }
    } else {
      setIsAuthenticated(false); // Setzt den Authentifizierungsstatus auf false, wenn kein Benutzer angemeldet ist
    }
  });

  // Bereinige den Listener, wenn die Komponente unmountet wird
  return () => unsubscribe();
}, [auth, navigate]); // Fügt 'auth' und 'navigate' zu den Abhängigkeiten des useEffects hinzu, um bei deren Änderung den Effekt erneut auszuführen



useEffect(() => {
  // Regex für E-Mail und Passwort Validierung
  const passwordRegex = /^.{8,}$/;

  const validateInputs = () => {
    let valid = true;
    let errors = [];


    // Prüfung des debounced Passworts gegen das Regex-Muster
    if (debouncedPassword.length > 0 && !passwordRegex.test(debouncedPassword)) {
      valid = false;
      errors.push("The password should be at least 8 characters long.");
    }

    // Prüfung, ob Passwort und Passwortbestätigung übereinstimmen
    if (debouncedPassword !== debouncedPasswordConfirmation && debouncedPassword.length > 0 && debouncedPasswordConfirmation.length > 0) {
      valid = false;
      errors.push("Passwords do not match.");
    }

    // Option zur Anzeige der ersten gefundenen Fehlermeldung
    setMessage(errors[0] || "");
    return valid;
  };

  setIsValid(validateInputs());
}, [debouncedEmail, debouncedPassword, debouncedPasswordConfirmation]);

  
const handleSignOut = () => {
  signOut(auth)
      .then(() => {
          console.log("User signed out");
      })
      .catch(error => {
          console.error("Error signing out: ", error);
      });
};
const handleSignUp = async (e) => {
  e.preventDefault();
  setLoading(true); // Starte den Ladevorgang

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    await addUserEntry(userCredential.user.uid);
    await sendEmailVerification(userCredential.user);

    // Erfolgsmeldung und Weiterleitung
    setMessage("Successfully signed up with Email!");
    navigate('/dashboard'); // Weiterleitung zum Dashboard
  } catch (error) {
    console.error('Fehler bei der Registrierung:', error);
    setMessage(getFriendlyErrorMessage(error));
    setLoading(false); // Beende den Ladevorgang im Fehlerfall
  }

  setLoading(false); // Beende den Ladevorgang nach erfolgreicher Registrierung
};

const handleSignIn = async (e) => {
  e.preventDefault();
  setLoading(true); // Starte den Ladevorgang
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    setLoading(false); // Beende den Ladevorgang bei Erfolg oder Fehler
    if (userCredential.user.emailVerified) {
      setLoginMessage(""); // Löscht vorherige Login-Nachrichten
      setIsAuthenticated(true); // Setzt den Benutzer als authentifiziert
    } else {
      setShowVerificationScreen(true);
    }
  } catch (error) {
    console.error("Login error:", error); // Für Debugging-Zwecke
    setLoading(false); // Beende den Ladevorgang bei Erfolg oder Fehler
    if (error.code === 'auth/invalid-credential') {
      // Fehlermeldung für ungültige Anmeldeinformationen
      setLoginMessage("Invalid email or password. Please check your credentials and try again.");
    } else if (error.code) {
      // Wenn ein Fehlercode vorhanden ist, aber nicht auth/invalid-credential ist, nutze ihn, um eine spezifische Nachricht zu generieren
      setLoginMessage(getFriendlyErrorMessage(error.code));
    } else {
      // Falls kein Fehlercode vorhanden ist, zeige eine allgemeine Fehlermeldung
      setLoginMessage("An unexpected error occurred. Please try again.");
    }
    // Fehlermeldung nach 5 Sekunden zurücksetzen
    setTimeout(() => {
      setLoginMessage("");
    }, 4000);
  }
  
};

const handleGoogleSignup = async () => {
  const provider = new GoogleAuthProvider();

  try {
    const userCredential = await signInWithPopup(auth, provider);
    console.log('Google Sign-Up erfolgreich:', userCredential);

    // Erfolgsmeldung und Weiterleitung
    setMessage("Successfully signed up with Google!");
    navigate('/dashboard'); // Weiterleitung zum Dashboard
  } catch (error) {
    console.error('Fehler beim Google Sign-Up:', error);
    setMessage(getFriendlyErrorMessage(error));
  }
};


  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset link sent to your email!");
    } catch (error) {
      setMessage(getFriendlyErrorMessage(error));
    }
  };

  const shakeAnimation = {
    x: [0, -5, 5, -5, 5, 0], // Reduzierte seitliche Bewegung
    y: [0, -2, 2, -2, 2, 0], // Subtile vertikale Bewegung
    rotate: [0, -1, 1, -1, 1, 0], // Sehr leichte Rotation
    transition: {
      duration: 0.5, // Halte die Dauer moderat für einen sanften Übergang
      ease: "easeInOut", // EaseInOut für sanfte Anfangs- und Endbewegungen
    },
  };

  

  return (
    <div className="login">
      <GenerativeOrbAnimation />
      <div>
        <Link to="/">
        <img className="header-about-icon" src="./img/logo.png" alt="" />
        </Link>
      </div>
        <div className="login-container">
            {auth.currentUser ? (
                <div className="verify_mail_container">
                  <div>
                    <img src="./img/mailer.svg" alt="" />
                  </div>
                    <h1>Make sure to verify your email!</h1>
                    <p>Make sure to check your spam or junk folder.</p>
                    <a style={{backgroundColor: 'var(--red)', color: 'white', textDecoration: 'none', borderRadius: '100px', width: '80%', height: '52px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}  onClick={handleSignOut} href="/login">Log In</a>
                </div>
            ) : showVerificationScreen ? (
                <div className="verification-screen">
                    Please verify your E-Mail
                </div>
            ) : showSignIn ? (
         
                <div className="signup-screen">
<div className="toggle-buttons">
    <div>
        <button 
            className={`toggle_button_1 ${showSignIn ? 'active' : ''}`}
            onClick={() => setShowSignIn(true)}
        >
            Log In
        </button>
        <button 
            className={`toggle_button_1 ${!showSignIn ? 'active' : ''}`}
            onClick={() => setShowSignIn(false)}
        >
             Apply
        </button>
    </div>
</div>
                <div className="login-header-fonts">
                <h2 className="login-header">Login</h2>
                    <p>Log in here.</p>
                </div>
           
                    <form className="login-form" onSubmit={handleSignIn}>
                        <div>
                            <label className="login-label">Email:</label>
                            <div className={`login-input-wrapper ${email ? "has-content" : "login-input-wrapper-passive"}`}>
                                <input className="login-input" type="email" placeholder='Your Email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                                <img className="mail-icon" src="./img/mail.svg" alt="" />
                            </div>
                        </div>
                        <div>
                            <label className="login-label">Password:</label>
                            <div className={`login-input-wrapper ${password ? "has-content" : "login-input-wrapper-passive"}`}>
                                <input className="login-input" type="password"  id="forgot-input-relative" placeholder='Your Password' value={password} onChange={(e) => setPassword(e.target.value)}  required />
                                <Link className="login-btn-4" to="/forgot-password">Forgot?</Link>
                                <img className="lock-icon" src="./img/padlock.svg" alt="" />
                            </div>


                        </div>

                        <button className={`login-btn-1 ${isValid ? "active-button-3" : ""}`} type="submit" disabled={!isValid} style={{marginTop: '92px'}}>
  {loading ? (<div className="minispinner"></div>) : (<span>Log In</span>)}
</button>

                        <button className="login-btn-2" onClick={handleGoogleSignup}>
                      <img className="google_logo" src="./img/google.webp" alt="" style={{marginRight: '10px'}}/>
                      <p>Continue with Google</p>
                    
                    </button>
                    </form>

                    {/* <button className="login-btn-2" onClick={() => setShowSignIn(false)}>Create new Account</button> */}
                    <div className="toggle-buttons">
                   
                    </div>
                </div>
            ) : showForgotPassword ? (
                <div className="signup-screen">
                    <h2 className="login-header">Forgot Password</h2>
                    <form onSubmit={handlePasswordReset}>
                        <div>
                            <label className="login-label">Email:</label>
                            <input className="login-input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <button className="login-btn" type="submit">Reset Password</button>
                    </form>
                    <button className="login-btn" onClick={() => setShowForgotPassword(false)}>Back to Sign In</button>
                </div>
            ) : (
                <div className="signup-screen">
                  <div className="toggle-buttons">
    <div>
    <button 
  className={`toggle_button_1 ${showSignIn ? 'active' : ''}`}
  onClick={() => {
    setShowSignIn(true);
    resetFormFields(); // Zurücksetzen beim Wechsel zu Log In
  }}
>
  Log In
</button>
<button 
  className={`toggle_button_1 ${!showSignIn ? 'active' : ''}`}
  onClick={() => {
    setShowSignIn(false);
    resetFormFields(); // Zurücksetzen beim Wechsel zu Sign Up
  }}
>
  Apply
</button>

    </div>
</div>
                    <h2 className="login-header">Apply</h2>
                    <p>To apply, start by creating your account first.</p>
                    <form className="login-form" onSubmit={handleSignUp}>
                        <div>
                            <label className="login-label">Email:</label>
                            <motion.div
  className={`login-input-wrapper ${showCheckEmail ? "valid" : ""} ${emailErrorMessage ? "error" : ""}`}
  animate={emailErrorMessage ?  shakeAnimation : {} }
  transition={{ duration: 0.5 }}
>
  <input
    className="login-input"
    type="email"
    placeholder="Set your E-Mail"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    required
  />
   {showCheckEmail ? (
    <img src="./img/check.svg" alt="Check" className="check-icon-1" />
  ) : (
    <img className="lock-icon" src="./img/mail.svg" alt="" />
  )}</motion.div>

                        </div>
                        <div>
    <label className="login-label">Password:</label>
    <motion.div
  className={`login-input-wrapper ${showCheckPassword ? "valid" : ""} ${!isValid ? "error" : ""}`}
  animate={debouncedPassword.length > 0 && debouncedPassword.length < 8 ?  shakeAnimation : {} }
  transition={{ duration: 0.5 }}
>
    <input
      className="login-input"
      type="password"
      placeholder="Create a password"
      value={password}
      onChange={handlePasswordChange} // Verwende die neue Funktion hier
      required
    />
         {showCheckPassword ? (
    <img src="./img/check.svg" alt="Check" className="check-icon-1" />
  ) : (
    <img className="lock-icon" src="./img/padlock.svg" alt="" />
  )}
</motion.div>

<motion.div
  animate={debouncedPasswordConfirmation !== debouncedPassword && debouncedPasswordConfirmation.length > 0 ?  shakeAnimation : {} }
  transition={{ duration: 0.5 }}
  >
<div className={`login-input-wrapper ${showCheckPasswordConfirmation ? "valid" : ""} ${!isValid && passwordConfirmationTouched ? "error" : ""}`}>
    <input
      className="login-input"
      type="password"
      placeholder="Confirm password"
      value={passwordConfirmation}
      onChange={handlePasswordConfirmationChange} // Verwende die neue Funktion hier
      required
    />
  {showCheckPasswordConfirmation ? (
    <img src="./img/check.svg" alt="Check" className="check-icon-1" />
  ) : (
    <img className="lock-icon" src="./img/padlock.svg" alt="" />
  )}</div>
</motion.div>
</div>

                        <button className={`login-btn-1 ${isFormValid ? "active-button-3" : ""}`} type="submit" disabled={!isFormValid}>
                        {loading ? <div className="minispinner"></div> : <p>Create Account</p>}
                        </button>
                        <button className="login-btn-2" onClick={handleGoogleSignup}>
                      <img className="google_logo" src="./img/google.webp" alt="" style={{marginRight: '10px'}}/>
                      <p>Continue with Google</p>
                    
                    </button>
                    </form>
                  
                </div>
            )}
                   {!showSignIn && (
  <p className="login-message">{message}</p>
)}

          
          {!showSignIn && emailErrorMessage && (
  <div className="login-message">{emailErrorMessage}</div>
  
)}
{loginMessage && (
  <div className="login-message">{loginMessage}</div>
)}
        </div>
        <div className="references">
          <a href="https://aaartists.com" target="_blank" />
          <hr />
<p>London</p>
<p>Paris</p>
<p>New York</p>
<p>Tokio</p>
<p>Vienna</p>
<p>Sydney</p>
<p>Los Angeles</p>
<p>Shanghai</p>
<p>Mexico City</p>
<p>Singapur</p>       
   <hr />



        </div>
    </div>
);
            }

export default SignUp;


