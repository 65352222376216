import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HomePromoBanner1.css'; // Gleiche CSS-Klassen wie vorher
import { ref as databaseRef, push } from 'firebase/database';
import { database } from '../firebaseConfig'; // Firebase-Konfiguration
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Firebase Auth

const ctaBigAwardsBannerMiniVariants = [
  {
    id: 1,
    header: "Ready to rumble?",
    highlightWord: "Ready", // Das Wort, das hervorgehoben werden soll
    description: "Collaborate with award-winning professionals directly on Triple A."
  },
  {
    id: 2,
    header: "Apply with your demo!",
    highlightWord: "Apply", // Das Wort, das hervorgehoben werden soll
    description: "This is your chance to connect with the best in the industry. Apply today and let your music be heard on Triple A"
  },
  {
    id: 3,
    header: "What are you waiting for?",
    highlightWord: "waiting for?", // Das Wort, das hervorgehoben werden soll
    description: "The doors to success are open. Join a network of top-notch professionals and take the next step in your career!"
  },
  {
    id: 4,
    header: "Ready to start?",
    highlightWord: "Ready", // Das Wort, das hervorgehoben werden soll
    description: "You’ve got the talent, now work with the industry's finest. Start your journey with Triple A today!"
  }
];

function HomePromoBannerMini() {
  const [currentVariant, setCurrentVariant] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Zustand für Login-Status

  // Überprüfe, ob der Benutzer eingeloggt ist
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true); // Benutzer ist eingeloggt
      } else {
        setIsLoggedIn(false); // Benutzer ist nicht eingeloggt
      }
    });

    // Zufällige Variante beim Laden der Seite auswählen
    const randomVariantIndex = Math.floor(Math.random() * ctaBigAwardsBannerMiniVariants.length);
    setCurrentVariant(ctaBigAwardsBannerMiniVariants[randomVariantIndex]);

    // Aufräumen, wenn die Komponente unmontiert wird
    return () => unsubscribe();
  }, []);

  // Funktion, um die Daten bei Klick in die Firebase-Datenbank zu pushen
  const handleClick = () => {
    if (currentVariant) {
      const clickData = {
        variantId: currentVariant.id,
        header: currentVariant.header,
        description: currentVariant.description,
        timestamp: Date.now()
      };

      const analyticsRef = databaseRef(database, '/Analytics/HomePromoMini');
      push(analyticsRef, clickData); // Pusht die Daten in die Firebase-Realtime-Datenbank

      console.log('Data pushed to Firebase:', clickData);
    }
  };

  if (!currentVariant || isLoggedIn) {
    return null; // Wenn keine Variante gesetzt ist oder der Benutzer eingeloggt ist, nichts rendern
  }

  // Funktion, um das highlightWord im Header hervorzuheben
  const renderHeaderWithHighlight = () => {
    const { header, highlightWord } = currentVariant;
    const parts = header.split(highlightWord); // Header in zwei Teile teilen

    return (
      <>
        {parts[0]}
        <span className="highlight">{highlightWord}</span>
        {parts[1]}
      </>
    );
  };

  return (
    <div className="home-promo-banner" style={{height: '500px'}}>
          <img id="promo-blur-banner" src="./img/Background_Blur.webp" alt="Collab & Connect" />

      <div className="home-promo-banner-wrapper-left" style={{marginTop: '0px'}}>
        <h2>{renderHeaderWithHighlight()}</h2>
        <p>{currentVariant.description}</p>

        <Link
          to="/signup"
          className="cta-promo-link"
          onClick={handleClick}
        >
          <p>Get Started</p>
        </Link>
      </div>
    </div>
  );
}

export default HomePromoBannerMini;
