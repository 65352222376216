import React, { useEffect, useState } from 'react';
import { ref as databaseRef, set, get } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './UtilitySettings.css';

function BlackWhiteSwitch({ pageId }) {
    const [isWhiteMode, setIsWhiteMode] = useState(false);

    useEffect(() => {
        const modeRef = databaseRef(database, `Pages/${pageId}/pageData/isWhiteMode`);
        get(modeRef).then((snapshot) => {
            if (snapshot.exists()) {
                setIsWhiteMode(snapshot.val());
            }
        }).catch((error) => {
            console.error('Error fetching color mode:', error);
        });
    }, [pageId]);

    const toggleMode = (targetMode) => {
        // Setze den Zustand basierend auf dem angeklickten Modus
        const newMode = targetMode === 'white';
        const modeRef = databaseRef(database, `Pages/${pageId}/pageData/isWhiteMode`);
        set(modeRef, newMode).then(() => {
            setIsWhiteMode(newMode);
        }).catch((error) => {
            console.error('Failed to update color mode:', error);
        });
    };

    return (
        <div className="mode-switch-container">
            <div className={`mode-option ${!isWhiteMode ? "active" : ""}`} onClick={() => toggleMode('black')}>
                Black
            </div>
            <div className={`mode-option ${isWhiteMode ? "active" : ""}`} onClick={() => toggleMode('white')}>
                White
            </div>
        </div>
    );
}

export default BlackWhiteSwitch;
