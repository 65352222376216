import React, { useEffect, useState } from 'react';
import { ref as databaseRef, query, orderByChild, limitToLast, get } from 'firebase/database';
import { database } from "../firebaseConfig";
import './HomeBanner.css'; // Stellen Sie sicher, dass der Pfad korrekt ist
import { Link } from 'react-router-dom';



function HomeBanner() {
  const [latestArtists, setLatestArtists] = useState([]);

  useEffect(() => {
    const fetchLatestArtists = async () => {
      // Stellen Sie sicher, dass 'signupTimestamp' in Ihren Firebase Security Rules als indizierter Schlüssel aufgeführt ist
      const artistsRef = query(
        databaseRef(database, 'Artists'),
        orderByChild('signupTimestamp'), // Sortieren nach 'signupTimestamp'
        limitToLast(20) // Holen Sie die letzten 20 Künstler, basierend auf dem Timestamp
      );

      try {
        const snapshot = await get(artistsRef);
        const artistsData = snapshot.val();
        if (artistsData) {
          const artistsArray = Object.keys(artistsData)
            .map(key => ({ id: key, ...artistsData[key] }))
            .reverse(); // Die neuesten zuerst, da Firebase die Daten in aufsteigender Reihenfolge liefert
          setLatestArtists(artistsArray);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der neuesten Künstler: ", error);
      }
    };

    fetchLatestArtists();
  }, []);


  return (
    <div className="home-banner">
      <h4 className="top-banner-font">
  AAA <span className="red-dot"></span>MUSIC AWARDS
</h4>

<div className="sub-container">
      <div className="fixed-text">New Aaarists</div>
      <ul className="artist-list">
      {latestArtists.length > 0 && latestArtists
  .slice(0, 20)
  .filter(artist => artist.artistName)
  .map((artist, index) => (
    <Link to={`/artist/${artist.id}`} className="artist-link" key={index} style={{textDecoration: 'none'}}>
      <li>
        <div className="artist-list-image-container">
          {artist.artistImageUrl && (
            <img src={artist.artistImageUrl} alt={artist.artistName} className="mini-image" />
          )}
        </div>
        @{artist.artistName}
      </li>
    </Link>
))}
</ul>


    </div>
    </div>
  );
}

export default HomeBanner;
