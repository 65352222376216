import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';

function OpenSingleChat({ chatId, onClose }) {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (chatId) {
      const messagesRef = ref(database, `Chats/${chatId}/messages`);
      onValue(messagesRef, (snapshot) => {
        const messagesData = snapshot.val();
        const messageList = messagesData ? Object.keys(messagesData).map(messageId => ({
          id: messageId,
          ...messagesData[messageId]
        })) : [];
        setMessages(messageList);
      });
    }
  }, [chatId]);

  return (
    <div className="open-single-chat">
      <button onClick={onClose}>Close Chat</button>
      <div className="messages-list">
        {messages.map((message, index) => (
          <div key={index} className="message-item">
            <strong>{message.senderId}:</strong> {message.message}
          </div>
        ))}
      </div>
    </div>
  );
}

export default OpenSingleChat;
