import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, ref } from 'firebase/database';
import { auth, database } from '../firebaseConfig';
import './DashboardPage.css';
import LoadingScreen from '../Utility/LoadingScreen';
import SubmissionScreen from './SubmissionScreen';
import ArtistDashboard from './ArtistDashboard';

function DashboardPage() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Ladezustand
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {

        try {
          // Warten, bis die Datenbankabfrage abgeschlossen ist
          const artistRef = ref(database, `/Artists/${currentUser.uid}`);
          const snapshot = await get(artistRef);
          
          if (snapshot.exists()) {
            const artistData = snapshot.val();
            
            if (artistData.hasSettings || artistData.hasExpertSettings) {
              setUser(artistData); // Setze die Benutzerdaten
            } else {
              navigate('/welcome'); // Navigiere zur Welcome-Seite
            }
          } else {
            navigate('/login'); // Navigiere zum Login, falls keine Daten vorhanden sind
          }
        } catch (error) {
          console.error("Error fetching artist data:", error);
        }
        
        setLoading(false); // Ladezustand beenden
      } else {
        setLoading(false); // Ladezustand beenden
        navigate('/signup'); // Navigiere zur Signup-Seite
      }
    });

    return () => {
      unsubscribe(); // Entferne den Auth-Listener, wenn die Komponente unmountet
    };
  }, [navigate]);

  if (loading) {
    return <div style={{ color: 'white' }} className="spinner"></div>; // Ladeanzeige, solange Daten geladen werden
  }

  if (!user) {
    return null; // Zeige nichts, falls kein Benutzer gefunden wurde (abgedeckt durch das Navigieren)
  }

  // Experten sollen den SubmissionScreen nicht sehen, wenn hasExpertSettings true ist.
  if (user.isExpert && user.hasExpertSettings) {
    return (
      <div className="dashboard-page">
        <ArtistDashboard user={user} />
      </div>
    );
  }

  // Normale Nutzer oder Experten ohne abgeschlossene Einstellungen
  return (
    <div className="dashboard-page">
      {user.hasSubmitted ? (
        <ArtistDashboard user={user} />
      ) : (
        <SubmissionScreen user={user} />
      )}
    </div>
  );
}

export default DashboardPage;
