import './DeleteConfirmationModal.css'

function DeleteConfirmationModal({ isOpen, onClose, onConfirm }) {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay-3">
        <div className="modal-content-3 unique-modal-class">
          <h2>Are you sure you want to delete this page?</h2>
          <button onClick={onClose}>Cancel</button>
          <button onClick={onConfirm}>Yes, Delete it</button>
        </div>
      </div>
    );
  }
  
  export default DeleteConfirmationModal