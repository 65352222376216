import React, { useState, useEffect } from 'react';
import { ref as databaseRef, set, push, update, get, remove } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import ImageUploadComponentChat2 from './ImageUploadComponentChat2';
import './PromoText.css';

const PromoText = ({ artistId, chatId, onClose }) => {
    const [promoTexts, setPromoTexts] = useState([]); // List of saved promo texts
    const [isPremium, setIsPremium] = useState(false); // State for premium status
    const [messageInfoTitle, setMessageInfoTitle] = useState('');
    const [promoMessage, setPromoMessage] = useState('');
    const [imageUploadUrl, setImageUploadUrl] = useState('');
    const [pdfUploadUrl, setPdfUploadUrl] = useState('');
    const [resetImageUpload, setResetImageUpload] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [showAddNew, setShowAddNew] = useState(false);

    useEffect(() => {
        const fetchArtistData = async () => {
            const premiumRef = databaseRef(database, `Artists/${artistId}/isPremium`);
            const snapshot = await get(premiumRef);
            setIsPremium(snapshot.exists() && snapshot.val() === true);

            // Fetch promo texts if the artist is a premium user
            if (snapshot.exists() && snapshot.val() === true) {
                await fetchPromoTexts();
            }
        };

        fetchArtistData();
    }, [artistId]);

    const fetchPromoTexts = async () => {
        const promoRef = databaseRef(database, `Artists/${artistId}/promoText`);
        const snapshot = await get(promoRef);
        if (snapshot.exists()) {
            const promoArray = [];
            snapshot.forEach(childSnapshot => {
                promoArray.push({ id: childSnapshot.key, ...childSnapshot.val() });
            });
            setPromoTexts(promoArray);
        }
    };

    const handleUploadSuccess = (url, fileType) => {
        if (fileType === 'pdf') {
            setPdfUploadUrl(url);
            setImageUploadUrl('');
        } else if (fileType === 'image') {
            setImageUploadUrl(url);
            setPdfUploadUrl('');
        }
    };

    const handleSavePromoText = async () => {
        if (!messageInfoTitle && !promoMessage && !imageUploadUrl && !pdfUploadUrl) {
            console.error('No title, promo text, image, or PDF to save');
            return;
        }

        const promoRef = databaseRef(database, `Artists/${artistId}/promoText`);
        const timestamp = new Date().toISOString();

        const promoData = {
            title: messageInfoTitle,
            message: promoMessage,
            imageUrl: imageUploadUrl,
            pdfUrl: pdfUploadUrl,
            senderId: artistId,
            type: "normal",
            timestamp
        };

        if (isEditing && editId) {
            const editPromoRef = databaseRef(database, `Artists/${artistId}/promoText/${editId}`);
            await update(editPromoRef, promoData);
        } else {
            const newPromoRef = push(promoRef);
            await set(newPromoRef, promoData);
        }

        handleCancel();
        await fetchPromoTexts();
    };

    const handleSend = async (promo) => {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.error('No user logged in');
            return;
        }

        const messagesRef = databaseRef(database, `Chats/${chatId}/messages`);
        const newMessageRef = push(messagesRef);
        const timestamp = new Date().toISOString();

        await set(newMessageRef, {
            title: promo.title,
            message: promo.message,
            imageUrl: promo.imageUrl || '',
            pdfUrl: promo.pdfUrl || '',
            senderId: currentUser.uid,
            receiverId: chatId,
            type: "normal",
            timestamp,
            readStatus: false
        });

        console.log("Promo text sent to chat.");
        onClose();
    };

    const handleEdit = (promo) => {
        setMessageInfoTitle(promo.title || '');
        setPromoMessage(promo.message);
        setImageUploadUrl(promo.imageUrl || '');
        setPdfUploadUrl(promo.pdfUrl || '');
        setIsEditing(true);
        setEditId(promo.id);
        setShowAddNew(true);
    };

    const handleAddNew = () => {
        setMessageInfoTitle('');
        setPromoMessage('');
        setImageUploadUrl('');
        setPdfUploadUrl('');
        setIsEditing(false);
        setEditId(null);
        setShowAddNew(true);
    };

    const handleDeletePromoText = async () => {
        if (editId) {
            const promoRef = databaseRef(database, `Artists/${artistId}/promoText/${editId}`);
            await remove(promoRef);
            console.log("Promo text deleted successfully.");
            handleCancel();
            await fetchPromoTexts();
        }
    };

    const handleCancel = () => {
        setMessageInfoTitle('');
        setPromoMessage('');
        setImageUploadUrl('');
        setPdfUploadUrl('');
        setResetImageUpload(prevState => !prevState);
        setIsEditing(false);
        setEditId(null);
        setShowAddNew(false);
    };

    return (
        <div className="promo-text-container">
            {isPremium ? (
                showAddNew ? (
                    <div className="promo-text-editor">
                        <input
                            type="text"
                            value={messageInfoTitle}
                            onChange={e => setMessageInfoTitle(e.target.value)}
                            placeholder="Promo text name (internal)"
                            className="promo-title-input"
                        />
                        <textarea
                            value={promoMessage}
                            onChange={e => setPromoMessage(e.target.value)}
                            placeholder="Enter your promo text here"
                            className="promo-text-input"
                        />

                        <ImageUploadComponentChat2 onUploadSuccess={handleUploadSuccess} resetTrigger={resetImageUpload} />

                        <div className="promo-text-editor-buttons">
                            <button onClick={handleCancel} className="promo-cancel-button">
                                Back
                            </button>
                            <button onClick={handleSavePromoText} className="promo-save-button">
                                {isEditing ? 'Update Promo Text' : 'Save Promo Text'}
                            </button>
                            {isEditing && (
                                <button onClick={handleDeletePromoText} className="promo-delete-button">
                                    <img src="../../img/delete.webp" alt="" />
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="promo-text-list">
                        {promoTexts.map(promo => (
                            <div key={promo.id} className="promo-text-item">
                                <div className="promo-text-preview">
                                    <h4 className="promo-text-title">{promo.title}</h4>
                                    {promo.imageUrl && <img src={promo.imageUrl} alt="Promo Image" className="promo-preview-image" />}
                                    {promo.pdfUrl && <img src="../../img/pdf.svg" alt="PDF Icon" className="promo-preview-pdf" />}
                                    <p className="promo-text-content">{promo.message}</p>
                                </div>
                                <button onClick={() => handleEdit(promo)} className="promo-edit-button">
                                    Edit
                                </button>
                                <button onClick={() => handleSend(promo)} className="promo-send-button">
                                    Send
                                    <img src="../../img/share.webp" alt="" />
                                </button>
                            </div>
                        ))}
                        <button onClick={handleAddNew} className="promo-add-new-button">
                            Add New Promo Text
                            <img src="../../img/plus.svg" alt="" />
                        </button>
                    </div>
                )
            ) : (
                <div className="premium-feature-message">
                    This is a VIP feature.
                </div>
            )}
        </div>
    );
};

export default PromoText;
