import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get, set, push } from 'firebase/database';
import { database } from '../../firebaseConfig';
import { getAuth } from 'firebase/auth';
import './CreateRoute.css'


function CreateRoute({ onSuccess, onError }) {
  const [route, setRoute] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const auth = getAuth();

  useEffect(() => {
    const handle = setTimeout(() => {
      setErrorMessage('');
    }, 5000); // Fehlermeldung nach 5 Sekunden ausblenden

    return () => clearTimeout(handle);
  }, [errorMessage]);

  const validateRoute = (input) => {
    const normalizedInput = input.toLowerCase();
    const validPattern = /^[a-z0-9]+[a-z0-9.-]*$/;
    if (!validPattern.test(normalizedInput)) {
      onError("Invalid route format. Only lowercase letters, numbers, '.', and '-' are allowed, and cannot start with '.' or '-'");
      return null;
    }
    return normalizedInput;
  };

  const handleRouteChange = (event) => {
    setRoute(event.target.value);
    setIsDisabled(event.target.value === ''); // Button deaktivieren, wenn das Eingabefeld leer ist
    if (errorMessage) {
      setErrorMessage(''); // Fehlermeldung zurücksetzen, wenn der Benutzer anfängt zu tippen
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true); // Button beim Senden deaktivieren
    const normalizedRoute = validateRoute(route);
    if (!normalizedRoute) {
      setIsDisabled(false);
      return;
    }

    const routeWithAt = `@${normalizedRoute}`; // Füge das @-Zeichen hinzu
    const routeRef = databaseRef(database, `PageRoutes/${routeWithAt}`);
    const snapshot = await get(routeRef);
    if (snapshot.exists()) {
      setErrorMessage('Sorry, Name already in use.');
      setIsDisabled(false); // Button wieder aktivieren, wenn der Name bereits verwendet wird
    } else {
      createRoute(routeWithAt); // Verwende den angepassten Namen mit @
    }
  };

  const createRoute = async (routeWithAt) => {
    const artistId = auth.currentUser?.uid;
    if (!artistId) {
      onError('No user logged in.');
      return;
    }

    const newPageRef = push(databaseRef(database, 'Pages'));
    const newPageData = {
      route: routeWithAt,
      artistId: artistId
    };

    await set(newPageRef, newPageData);

    const routeRef = databaseRef(database, `PageRoutes/${routeWithAt}`);
    await set(routeRef, {
      artistId: artistId,
      pageId: newPageRef.key
    });

    onSuccess(newPageRef.key); // Gebe die pageId zurück
    setIsDisabled(false);
  };

  return (
    <div className="create-route">
      <div className="create-page-header-fonts">
        <h2>Claim your Link</h2>
        <p>Reserve your custom domain and create your link.</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="create-route-input">
        <label htmlFor="route">aaartists.com/@</label>
        <input
          type="text"
          id="route"
          value={route}
          onChange={handleRouteChange}
          placeholder="link-name"
          required
        />
        </div>
        <button className="create-page-next-button-2" type="submit" disabled={isDisabled}>Next</button>
        {errorMessage && <p className="create-route-error-page">{errorMessage}</p>}
      </form>
    </div>
  );
}

export default CreateRoute;
