import React, { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import { database } from '../../firebaseConfig';
import SaveArtistButton from '../PageUtility/SaveArtistButton'; // Importiere die neue Komponente
import './PageSocials.css';

const socialIcons = {
  youtube: '../../img/youtube_1.svg',
  instagram: '../../img/instagram_1.svg',
  tiktok: '../../img/tiktok_1.svg',
  spotify: '../../img/spotify_1.svg'
};

function PageSocials({ pageId, isLiveRoute }) {
  const [socialLinks, setSocialLinks] = useState({});
  const [artistId, setArtistId] = useState(null); // Zustand für die Künstler-ID

  useEffect(() => {
    const pageRef = ref(database, `/Pages/${pageId}/artistId`);
    get(pageRef).then(pageSnapshot => {
      if (pageSnapshot.exists()) {
        const artistId = pageSnapshot.val();
        setArtistId(artistId); // Setze die artistId im Zustand

        const artistRef = ref(database, `/Artists/${artistId}`);
        get(artistRef).then(artistSnapshot => {
          if (artistSnapshot.exists()) {
            const data = artistSnapshot.val();
            setSocialLinks({
              youtube: data.youtubeAccount,
              instagram: data.instagramAccount,
              tiktok: data.tiktokAccount,
              spotify: data.spotifyAccount
            });
          } else {
            console.log("No artist data available");
          }
        });
      } else {
        console.log("No artist ID found on this page");
      }
    }).catch(error => {
      console.error("Error fetching artist ID:", error);
    });
  }, [pageId]);

  const formatLink = (link) => {
    // Entfernt 'http://', 'https://' und 'www.'
    let formattedLink = link.replace(/^(https?:\/\/)?(www\.)?/, '');
    // Fügt 'https://' vorne an
    return `https://${formattedLink}`;
  };

  return (
    <div className={`social-links-container ${isLiveRoute ? 'settings' : 'live'}`}>
      {Object.entries(socialLinks).map(([key, value]) => value && (
        <a key={key} href={formatLink(value)} target="_blank" rel="noopener noreferrer" className="social-link">
          <img src={socialIcons[key]} alt={`${key} icon`} />
        </a>
      ))}
      {artistId && <SaveArtistButton pageId={pageId} artistId={artistId} />}
    </div>
  );
}

export default PageSocials;
