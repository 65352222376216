import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get, update } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import './ExperienceAndGenreSelection.css';
import { availableGenres } from '../PageUtility/AvailableInfos';

function ExperienceAndGenreSelection({ pageId, onDisableNext, onError }) {
  const [experience, setExperience] = useState('');
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    if (pageId) {
      const pageDataRef = databaseRef(database, `Pages/${pageId}/pageData`);
      get(pageDataRef).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setExperience(data.experience || '');
          setGenres(data.genres || []);
        }
      }).catch(error => {
        console.error('Failed to fetch page data:', error);
        onError && onError('Failed to fetch page data');
      });
    }
  }, [pageId, onError]);

  useEffect(() => {
    // Aktiviere den "Next"-Button nur, wenn Erfahrung und mindestens ein Genre ausgewählt sind
    onDisableNext(!(experience && genres.length > 0));
  }, [experience, genres, onDisableNext]);

  const handleExperienceSelect = (years) => {
    setExperience(years);
    savePageData({ experience: years });
  };

  const toggleSelection = (genre) => {
    const currentIndex = genres.indexOf(genre);
    const newList = [...genres];

    if (currentIndex === -1 && genres.length < 4) {
      newList.push(genre);
    } else if (currentIndex !== -1) {
      newList.splice(currentIndex, 1);
    }

    setGenres(newList);
    savePageData({ genres: newList });
  };

  const savePageData = (data) => {
    const userId = auth.currentUser?.uid;
    if (userId && pageId) {
      const pageDataRef = databaseRef(database, `Pages/${pageId}/pageData`);
      update(pageDataRef, data).catch(error => console.error('Failed to save page data:', error));
    } else {
      console.error('No pageId provided or user is not logged in');
    }
  };

  return (
    <div className="page-settings-experience">
      <div className="create-page-header-fonts">
        <h2>Experience and Genres</h2>
        <p>Detail your experience and the genres to attract the right audience.</p>
      </div>
      <div style={{ marginBottom: '25px' }}>
        <p style={{ width: '100%', textAlign: 'center', fontSize: '16px', color: 'lightgrey', marginTop: '0px' }}>Experience</p>
        <div className="mood-selection-container">
          {Array.from({ length: 10 }, (_, i) => `${i + 1} Years`).concat("10+ Years").map((year) => (
            <button key={year} className={`mood-button-2 ${experience === year ? "selected" : ""}`} onClick={() => handleExperienceSelect(year)}>
              {year}
            </button>
          ))}
        </div>
      </div>
      <div style={{ marginBottom: '25px' }}>
        <p style={{ width: '100%', textAlign: 'center', fontSize: '16px', color: 'lightgrey', marginTop: '10px' }}>Genres (up to 4)</p>
        <div className="mood-selection-container">
          {availableGenres.map((genre) => (
            <button key={genre} className={`mood-button-2 ${genres.includes(genre) ? "selected" : ""}`} onClick={() => toggleSelection(genre)}>
              {genre}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ExperienceAndGenreSelection;
