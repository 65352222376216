import React from 'react';
import contentMap from '../Page/ContentMap';
import './UtilitySettings.css'


const ServiceFeaturesSelector = ({ serviceType, onFeatureToggle, selectedFeatures }) => {
    const serviceFeatures = contentMap[serviceType] || contentMap['defaultService'];

    const handleFeatureClick = (feature) => {
        const isSelected = selectedFeatures.includes(feature.name);
    
        if (isSelected) {
            // Wenn das Feature bereits ausgewählt ist und mehr als 3 Features ausgewählt sind, erlaube das Entfernen
            if (selectedFeatures.length > 3) {
                onFeatureToggle(feature.name);
            } else {
                // Optional: Feedback geben, dass nicht weniger als 3 Features ausgewählt sein müssen
            }
        } else if (!isSelected && selectedFeatures.length < 6) {
            // Wenn das Feature nicht ausgewählt ist und weniger als 6 Features ausgewählt sind, füge es hinzu
            onFeatureToggle(feature.name);
        } else {
            // Optional: Feedback geben, dass keine weiteren Features hinzugefügt werden können
        }
    };
    
    

    return (
        <div>
          <div className="create-earn-settings-features-container">
            {serviceFeatures.map((feature, index) => (
                <button 
                    key={index} 
                    className={`create-earn-settings-feature-button ${selectedFeatures.includes(feature.name) ? 'selected' : ''}`}
                    onClick={() => handleFeatureClick(feature)}
                >
                    <img src={feature.img} alt={feature.name} />
                    <h4>{feature.name}</h4>
                </button>
            ))}
        </div>
        </div>
    );
};

export default ServiceFeaturesSelector;
