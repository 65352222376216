import React, { useEffect, useState } from 'react';
import { ref as databaseRef, onValue } from 'firebase/database';
import { database } from "../firebaseConfig";
import { Bar } from 'react-chartjs-2';
import './ArtistStats.css'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ServiceStats() {
    const [serviceStats, setServiceStats] = useState({
        sellBeats: 0,
        sellLicense: 0,
        sellVerse: 0,
        openForCollabs: 0,
    });

    useEffect(() => {
        const artistsRef = databaseRef(database, 'Artists');
        onValue(artistsRef, (snapshot) => {
          const artistsData = snapshot.val();
          const counts = {
            sellBeats: 0,
            sellLicense: 0,
            sellVerse: 0,
            openForCollabs: 0,
          };

          if (artistsData) {
            Object.values(artistsData).forEach(artist => {
              if (artist.sellBeats) counts.sellBeats++;
              if (artist.sellLicense) counts.sellLicense++;
              if (artist.sellVerse) counts.sellVerse++;
              if (artist.openForCollabs) counts.openForCollabs++;
            });
          }

          setServiceStats(counts);
        });
    }, []); 

  const data = {
    labels: ['Sell Beats', 'Sell License', 'Sell Verse', 'Open for Collabs'],
    datasets: [
      {
        label: 'Services Offered by Artists', // Beschreibt, was im Datensatz dargestellt wird
        data: [serviceStats.sellBeats, serviceStats.sellLicense, serviceStats.sellVerse, serviceStats.openForCollabs],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        color: '#FFFFFF',
        anchor: 'center',
        align: 'center',
        font: {
          weight: 'bold',
        },
      }
    },
    maintainAspectRatio: false,
    responsive: true,
  };
  
  return (
    <div className="service-stats-container">
      <h2>Service Availability</h2>
      <div className="chart-container">
        <Bar data={data} options={options} plugins={[ChartDataLabels]} />
      </div>
    </div>
  );
}

export default ServiceStats;
