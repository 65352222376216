// availableInfos.js
export const availableGenres = [
    "Pop", "Rap", "Electronic", "Trap", "R&B", "Country", "Rock", "Punk", "Reggae",
    "EDM", "Classical", "Jazz", "Experimental", "Indie", "Alternative", "Other"
];

export const availableMoods = [
    "Smooth", "Edgy", "Acoustic", "Raw", "Heavy", "Melodic", "Harmonic", "Rhythmic",
    "Lyrical", "Ambient", "Distorted", "Vibrant", "Mellow", "Lo-Fi", "Energetic", "Dynamic"
];

export const availableTypes = [
    "Artist Manager", "Tour Manager", "Booking Agent", "Music Publisher",
    "Label A&R", "Music Journalist", "Concert Promoter", "Music Influencer",
    "Record Label Owner", "Playlist Curator", "Marketing Manager", "Content Creator",
    "Channel Manager", "Music Blogger", "Other"
];
