import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref as databaseRef, push } from 'firebase/database';
import { database } from '../firebaseConfig'; // Firebase-Konfiguration
import './CollabLoginPopup.css';

const CollabLoginPopup = ({ onClose }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Speichern der Daten in Firebase kurz vor dem Weiterleiten
    const logPopupEvent = () => {
      const clickData = {
        message: "User redirected from CollabLoginPopup",
        timestamp: Date.now()
      };

      const analyticsRef = databaseRef(database, '/Analytics/CollabLoginPopup');
      push(analyticsRef, clickData);

      console.log('Data pushed to Firebase:', clickData);
    };

    // Logge das Ereignis in Firebase und leite nach 2 Sekunden weiter
    const timer = setTimeout(() => {
      logPopupEvent();
      navigate('/dashboard');  // Leitet nach 2 Sekunden auf /dashboard weiter
      if (onClose) onClose();
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate, onClose]);

  return (
    <div className="collab-success-modal-overlay" onClick={onClose}>
      <div className="collab-success-modal" onClick={(e) => e.stopPropagation()}>
        <div className="collab-success-header">
          <img src="../../img/next.webp" alt="login" />
          <h2>Login Required</h2>
          <p>You need to log in to collaborate.</p>
        </div>
        <div className="start-over-button" style={{ textDecoration: 'none' }}>
          <button
            style={{
              backgroundColor: 'transparent',
              zIndex: '20',
              color: 'white',
              border: 'none',
              fontWeight: '600',
              textTransform: 'uppercase',
            }}
          >
            Login
          </button>
          <div className="completed-stepper-progress-container">
            <div className="completed-stepper-progress-bar"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollabLoginPopup;
