import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SongStepper.css';
import './CompletedStep5.css';

const CompletedStep5 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Automatische Weiterleitung nach 5 Sekunden
    const timer = setTimeout(() => {
      navigate('/dashboard');
    }, 3000);

    // Cleanup-Funktion, um den Timer zu löschen, falls der Benutzer vorher navigiert
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="confirmation-step">
      <div>
        <img src="./img/check.webp" alt="checkmark" />
        <h2>Completed</h2>
        <p>Your submission is complete. Thank you for sharing your music with us. We will get in touch with you shortly.</p>
      </div>
      <Link to="/dashboard" className="start-over-button" style={{ textDecoration: 'none' }}>
        <button
          style={{
            backgroundColor: 'transparent',
            zIndex: '20',
            color: 'white',
            border: 'none',
            fontWeight: '600',
            textTransform: 'uppercase',
          }}
        >
          Go to Dashboard
        </button>
        <div className="completed-step5-progress-container">
        <div className="completed-step5-progress-bar"></div>
      </div>
      </Link>
    </div>
  );
};

export default CompletedStep5;
