import React, { useState, useEffect } from 'react';
import { ref, push, set, get, remove } from 'firebase/database';
import { database } from '../../firebaseConfig';
import SettingsSingleInput from '../PageUtility/SettingsSingleInput';
import HeaderLabel from '../PageUtility/HeaderLabel';
import CategoryLabel from '../PageUtility/CategoryLabel';
import './AddEquipmentSettings.css'

function AddEquipmentSettings({ pageId, widgetKey }) {
    const [equipments, setEquipments] = useState([]);
    const [equipmentName, setEquipmentName] = useState('');
    const [equipmentType, setEquipmentType] = useState('');

    useEffect(() => {
        const equipmentRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/equipment`);
        get(equipmentRef).then((snapshot) => {
            if (snapshot.exists()) {
                setEquipments(Object.entries(snapshot.val()).map(([key, value]) => ({ id: key, ...value })));
            }
        });
    }, [pageId, widgetKey]);

    const handleAddEquipment = () => {
        if (!equipmentType || !equipmentName) {
            alert('Both equipment type and name must be filled out.');
            return;
        }

        const newEquipment = {
            name: equipmentName,
            type: equipmentType
        };
        const newRef = push(ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/equipment`));
        set(newRef, newEquipment).then(() => {
            setEquipments([...equipments, { id: newRef.key, ...newEquipment }]);
            setEquipmentName('');
            setEquipmentType('');
        }).catch(error => {
            console.error('Failed to add equipment:', error);
        });
    };

    const handleDeleteEquipment = (id) => {
        const equipmentRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/equipment/${id}`);
        remove(equipmentRef).then(() => {
            setEquipments(equipments.filter(equipment => equipment.id !== id));
        }).catch(error => {
            console.error('Failed to delete equipment:', error);
        });
    };

    return (
        <div>
            <HeaderLabel text="Studio Equipment" />
            <CategoryLabel text="Manage Your Equipment"/>
            {equipments.map((equipment) => (
                <div key={equipment.id}>
                    <div className="equipment-preview-settings">
                        <p>{equipment.type}</p>
                        <p>{equipment.name}</p>
                        <button onClick={() => handleDeleteEquipment(equipment.id)}><img src="../../img/delete.svg" alt="Delete Icon" /></button>
                    </div>
                </div>
            ))}
            <div className="gig-equipment-selector-wrapper">
                <select value={equipmentType} onChange={e => setEquipmentType(e.target.value)}>
                    <option value="">Select Equipment Type</option>
                    <option value="Audio Interface">Audio Interface</option>
                    <option value="Microphone">Microphone</option>
                    <option value="Monitors">Monitor (Speaker)</option>
                    <option value="DAW">DAW (Digital Audio Workstation)</option>
                    <option value="Headphone">Headphone</option>
                    <option value="MIDI Controller">MIDI Controller</option>
                    <option value="Synthesizer">Synthesizer</option>
                    <option value="Compressor">Compressor</option>
                    <option value="EQ">EQ (Equalizer)</option>
                    <option value="Preamp">Preamp</option>
                    <option value="Effect Processor">Effect Processor</option>
                    <option value="Audio Mixer">Audio Mixer</option>
                    <option value="Drum Machine">Drum Machine</option>
                    <option value="Camera">Camera</option>
                    <option value="Lighting">Lighting</option>
                    <option value="Computer">Computer</option>
                    <option value="Software">Software</option>
                    <option value="Other">Other</option>
                </select>
                <SettingsSingleInput
                    type="text"
                    value={equipmentName}
                    onChange={e => setEquipmentName(e.target.value)}
                    placeholder="Enter Equipment Name"
                    labelText="Equipment Name"
                />
                <button className="page-add-equipment-button" onClick={handleAddEquipment}>Add Equipment</button>
            </div>
        </div>
    );
}

export default AddEquipmentSettings;
