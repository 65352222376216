import React, { useState } from 'react';
import ImageUploader from '../PageUtility/ImageUploader';
import VideoUploader from '../PageUtility/VideoUploader';
import ProfileImageUploader from '../PageUtility/ProfileImageUploader';
import { database } from '../../firebaseConfig';
import CategoryLabel from '../PageUtility/CategoryLabel';
import HeaderLabel from '../PageUtility/HeaderLabel';

function TheHeaderSettings({ pageId }) {
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [galleryImages, setGalleryImages] = useState([]);
    const [videoGallery, setVideoGallery] = useState([]);

    // ProfileImageUploader onSave handler
    const saveProfileImage = (url) => {
        setProfileImageUrl(url);
        // Hier könnte man die URL im Realtime Database speichern
    };

    // ImageUploader onSave handler
    const saveGalleryImage = (url) => {
        const updatedImages = [...galleryImages, url];
        setGalleryImages(updatedImages);
        // Hier könnte man die URLs im Realtime Database speichern
    };

    // VideoUploader onSave handler
    const saveVideo = (url) => {
        const updatedVideos = [...videoGallery, url];
        setVideoGallery(updatedVideos);
        // Hier könnte man die URLs im Realtime Database speichern
    };

    return (
        <div>
             <HeaderLabel text="Header" />
            <CategoryLabel text="Profile Picture"/>
            <ProfileImageUploader 
                pageId={pageId} 
                initialImageUrl={profileImageUrl} 
                onSave={saveProfileImage} 
            />
             <CategoryLabel text="Image Gallery"/>
            <ImageUploader 
                pageId={pageId} 
                galleryImages={galleryImages} 
                onSave={saveGalleryImage} 
            />
           <CategoryLabel text="Gallery Video"/>
            <VideoUploader 
                pageId={pageId} 
                videoGallery={videoGallery} 
                onSave={saveVideo} 
            />
        </div>
    );
}

export default TheHeaderSettings;
