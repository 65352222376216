import React, { useState, useEffect } from 'react';
import { ref as databaseRef, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';
import './ArtistEditorQuote.css'; // Importiere das neue CSS

const ArtistEditorQuote = ({ artistID }) => {
  const [editorQuote, setEditorQuote] = useState(null);
  const [expertQuote, setExpertQuote] = useState(null);
  const [artistName, setArtistName] = useState(''); // Speichere den Künstlernamen

  useEffect(() => {
    // Funktion zum Abrufen der expertQuote und artistName aus /Artists
    const fetchArtistInfo = () => {
      const artistRef = databaseRef(database, `Artists/${artistID}`);
      onValue(artistRef, (snapshot) => {
        const artistData = snapshot.val();
        if (artistData) {
          setArtistName(artistData.name || artistData.artistName || 'Unknown Artist');
          if (artistData.expertQuote) {
            setExpertQuote(artistData.expertQuote);
          }
        }
      });
    };

    const songsRef = databaseRef(database, 'Songs');

    onValue(songsRef, (snapshot) => {
      const songsData = snapshot.val();
      if (songsData) {
        let latestSong = null;

        // Durch alle Songs iterieren, um den zuletzt hochgeladenen Song des Künstlers zu finden
        Object.keys(songsData).forEach((songId) => {
          const song = songsData[songId];
          
          if (song.artistID === artistID) {
            if (!latestSong || song.releaseTimestamp > latestSong.releaseTimestamp) {
              latestSong = song;
            }
          }
        });

        // Wenn wir einen Song mit einer editorQuote finden, diese setzen
        if (latestSong && latestSong.editorQuote) {
          setEditorQuote(latestSong.editorQuote);
        }

        // Wenn keine editorQuote gefunden wird, artist info abrufen
        fetchArtistInfo();
      } else {
        // Falls keine Songs gefunden werden, artist info abrufen
        fetchArtistInfo();
      }
    });
  }, [artistID]);

  // Wenn weder Editor Quote noch Expert Quote vorhanden ist, nichts anzeigen
  if (!editorQuote && !expertQuote) {
    return null;
  }

  return (
    <div className="artist-editor-quote-container">
      {editorQuote && (
        <div>
          <h3>Editor's Review</h3>
          <p className="artist-editor-quote">
            <span className="bold-first-word">{editorQuote.split(' ')[0]}</span>
            {editorQuote.substring(editorQuote.split(' ')[0].length)}
          </p>
        </div>
      )}
      {expertQuote && (
        <div>
          <h3>About {artistName}</h3>
          <p className="artist-editor-quote" style={{fontSize: '32px'}}>
            <span className="bold-first-word">{expertQuote.split(' ')[0]}</span>
            {expertQuote.substring(expertQuote.split(' ')[0].length)}
          </p>
        </div>
      )}
    </div>
  );
};

export default ArtistEditorQuote;
