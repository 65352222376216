import React, { useState, useEffect, useCallback } from 'react';
import { ref as databaseRef, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import SaveFavoritesButton from '../UserBackend/PageUtility/SaveFavoritesButton';
import GigDetailedPreview from './GigDetailedPreview';
import './AllArtistGigs.css'; // Assuming you're reusing the same CSS
import LoadingScreen from '../Utility/LoadingScreen';

const AllArtistGigs = ({ artistId }) => { 
    const [gigs, setGigs] = useState([]);
    const [filteredGigs, setFilteredGigs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [artistName, setArtistName] = useState('Unbekannt');
    const [artistImageUrl, setArtistImageUrl] = useState(''); // Setze das Artist-Image-URL

    useEffect(() => {
      const fetchArtistData = async () => {
          try {
              // Abfrage des Künstlers in der Artist-Datenbank
              const artistRef = databaseRef(database, `/Artists/${artistId}`);
              const artistSnapshot = await get(artistRef);

              let fetchedArtistName = 'Unbekannt';
              let fetchedArtistImageUrl = ''; // Fallback-Wert für Artist-Bild

              if (artistSnapshot.exists()) {
                  const artistData = artistSnapshot.val();
                  fetchedArtistName = artistData.artistName || 'Unbekannt';
                  fetchedArtistImageUrl = artistData.artistImageUrl || ''; // Fallback für das Bild
              }

              // Setze den Künstlernamen und das Bild
              setArtistName(fetchedArtistName);
              setArtistImageUrl(fetchedArtistImageUrl); // Speichere das Bild in den Zustand

              const gigsData = [];
              const pagesRef = databaseRef(database, '/Pages');
              const snapshot = await get(pagesRef);

              if (snapshot.exists()) {
                  const pages = snapshot.val();
                  for (const pageId in pages) {
                      const page = pages[pageId];

                      // Filtere nach artistId und live Gigs
                      if (page.artistId === artistId && page.pageData && page.pageData.offers && page.pageData.offers.length > 0 &&
                          page.pageData.pageIsLive === true && page.pageData.isApproved === true) {
                          
                          const offer = page.pageData.offers[0];

                          // Verwende das `artistImageUrl` aus den Pages oder das Fallback aus den Artists-Daten
                          const gigInfo = {
                              pageId,
                              artistId,
                              artistName: fetchedArtistName,  
                              artistImageUrl: page.artistImageUrl || fetchedArtistImageUrl || '../../img/placeholder.png', // Verwende hier das Fallback-Bild
                              artistType: page.artistType || 'Unbekannt',
                              country: page.country || 'Unbekannt',
                              route: page.route || '',
                              directMessageEnabled: page.pageData.directMessageEnabled || false,
                              service: page.pageData.service,
                              gigTitle: offer.gigTitle,
                              gigText: offer.gigText,
                              earnAmount: offer.earnAmount,
                              gigTime: offer.gigTime,
                              payoutOption: page.pageData.payoutOption,
                              gallery: page.pageData.gallery ? Object.values(page.pageData.gallery) : [],
                              isHighlight: page.pageData.isHighlight || false
                          };
                          gigsData.push(gigInfo);
                      }
                  }

                  // Sortiere die Gigs nach Hervorhebung
                  gigsData.sort((a, b) => b.isHighlight - a.isHighlight);
              }

              setGigs(gigsData);
              setFilteredGigs(gigsData); // Anfangs alle Gigs anzeigen
              setIsLoading(false);
          } catch (error) {
              console.error('Error fetching artist data:', error);
          }
      };

      fetchArtistData();
  }, [artistId]);


    const handleFilterChange = useCallback((searchTerm, category) => {
        let filtered = gigs;

        if (searchTerm) {
            filtered = filtered.filter(gig =>
                gig.gigTitle && gig.gigTitle.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (category) {
            filtered = filtered.filter(gig => gig.service === category);
        }

        setFilteredGigs(filtered);
    }, [gigs]);

    if (isLoading) {
        return <div>
            <LoadingScreen />
        </div>;
    }

    // If no gigs are found, return null and render nothing
    if (filteredGigs.length === 0) {
        return null;
    }

    return (
        <div className="gigs-basic-2-wrapper">
            <h2>{artistName}'s Gigs</h2>
            <div className="gigs-basic-grid-2">
                {filteredGigs.map((gig, index) => (
                    <div key={gig.pageId} className="basic-gig-item artistPage">
                        <SaveFavoritesButton artistId={gig.artistId} pageId={gig.pageId} className="save-button-container" />
                        <GigDetailedPreview gig={gig}/>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AllArtistGigs;
