import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get, set } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './SelectPayout.css';

function SelectPayout({ pageId, onDisableNext, onError }) {
  const [selectedOption, setSelectedOption] = useState('');
  const options = [
    { key: 'earnMoney', label: 'Charge Money', description: 'Earn money directly from your services.', img: '../../img/coin.svg' },
    { key: 'collaborate', label: 'For Free', description: 'Offer your services at no cost and get reviews.', img: '../../img/free_2.svg' },
    { key: 'earnRoyalties', label: 'Earn Royalties', description: 'Receive royalties based on the usage of your work.', img: '../../img/discount.svg' }
  ];

  useEffect(() => {
    const fetchPayoutOption = async () => {
      if (pageId) {
        const payoutRef = databaseRef(database, `Pages/${pageId}/pageData/payoutOption`);
        try {
          const snapshot = await get(payoutRef);
          if (snapshot.exists()) {
            setSelectedOption(snapshot.val());
          }
        } catch (error) {
          console.error('Failed to fetch payout option:', error);
          onError && onError('Failed to fetch payout option');
        }
      }
    };

    fetchPayoutOption();
  }, [pageId, onError]);

  useEffect(() => {
    onDisableNext(!selectedOption); // Aktiviere den "Next"-Button nur, wenn eine Option ausgewählt ist
  }, [selectedOption, onDisableNext]);

  const selectOption = (option) => {
    setSelectedOption(option.key);
    savePayoutOption(option.key);
  };

  const savePayoutOption = (option) => {
    if (pageId) {
      const payoutRef = databaseRef(database, `Pages/${pageId}/pageData/payoutOption`);
      set(payoutRef, option).catch(error => {
        console.error('Failed to save payout option:', error);
        onError && onError('Failed to save payout option');
      });
    } else {
      console.error('No pageId provided');
      onError && onError('No pageId provided');
    }
  };

  return (
    <div className="settings-payout-selection-container">
      <div className="create-page-header-fonts">
        <h2>Set your Pricing</h2>
        <p>Choose how you want to get compensated.</p>
      </div>
      {options.map((option) => (
        <div key={option.key} className={`payout-option ${selectedOption === option.key ? "selected" : ""}`} onClick={() => selectOption(option)}>
          <img src={option.img} alt={option.label} className="payout-option-image" />
          <div className="payout-option-text">
            <h3>{option.label}</h3>
            <p>{option.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SelectPayout;
