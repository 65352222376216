import React from 'react';
import AutoMessage from './AutoMessage';
import './AutoMessages.css';
import ReplyRateAnalytics from './ReplyRateAnalytics';

function AutoMessages() {
  return (
    <div className="auto-messages-container">
      {/* Section for ReviewTriggerInbox */}
      <div className="auto-analytics-container">
        <ReplyRateAnalytics userId="7wGWWa9XRYgJj0rJpOVYPBUBBa23" userName="Julian" />
        <ReplyRateAnalytics userId="HZwvbR107vXJvlnaIGwO8WHVB5V2" userName="Valerie" />
        <ReplyRateAnalytics userId="6meRLw4r83blUk5p6yuPkDGgCvV2" userName="Marius" />
      </div>
      <div className="auto-message-section">
        <h2>Julian's First Message</h2>
        <AutoMessage path="Tasks/ReviewTriggerInbox" messageType="ReviewTriggerInbox" />
      </div>
      <div className="auto-message-section">
        <h2>Julian's Award Message</h2>
        <AutoMessage path="Tasks/JulianAwardMessage" messageType="JulianAwardMessage" />
      </div>
      <div className="auto-message-section">
        <h2>Julian's Free Credit Message</h2>
        <AutoMessage path="Tasks/JulianCreditMessage" messageType="JulianCreditMessage" />
      </div>
      {/* Section for ValerieMessage */}
      <div className="auto-message-section">
        <h2>Valerie's First Message</h2>
        <AutoMessage path="Tasks/ValerieMessage" messageType="ValerieMessage" />
      </div>
          <div className="auto-message-section">
        <h2>ValerieRetargeting1</h2>
        <AutoMessage path="Tasks/ValerieRetargeting1" messageType="ValerieRetargeting1" />
      </div>
      <div className="auto-message-section">
        <h2>ValerieRetargeting2</h2>
        <AutoMessage path="Tasks/ValerieRetargeting2" messageType="ValerieRetargeting2" />
      </div>
      <div className="auto-message-section">
        <h2>ValerieRetargeting3</h2>
        <AutoMessage path="Tasks/ValerieRetargeting3" messageType="ValerieRetargeting3" />
      </div>
      {/* Section for MariusReview */}
      <div className="auto-message-section">
        <h2>Marius First Message</h2>
        <AutoMessage path="Tasks/MariusReview" messageType="MariusReview" />
      </div>

      {/* Section for AbdiMessage */}
      <div className="auto-message-section">
        <h2>Extra Message</h2>
        <AutoMessage path="Tasks/AbdiMessage" messageType="AbdiMessage" />
      </div>
      <div className="auto-message-section">
  <h2>AAA Team Email 1</h2>
  <AutoMessage 
    path="Tasks/MoesardMail" 
    messageType="MoesardMail" 
    specialFields={['htmlSnippet', 'subject']} // Neue Felder nur für MoesardMail
  />
</div>
<div className="auto-message-section">
  <h2>Valerie Team Email 1</h2>
  <AutoMessage 
    path="Tasks/LadyMail" 
    messageType="LadyMail" 
    specialFields={['htmlSnippet', 'subject']} // Neue Felder nur für MoesardMail
  />
</div>

    </div>
  );
}

export default AutoMessages;
