import React, { useState, useEffect } from 'react';
import { auth, database, storage } from '../firebaseConfig';
import { ref as databaseRef, get, remove, update } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import './GeneralSettings.css';
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

function GeneralSettings() {
    const [userData, setUserData] = useState({
        artistName: '',
        name: '',
        email: '',
        artistImageUrl: '',
        instagramAccount: '',
        tiktokAccount: '',
        youtubeAccount: '',
        totalAudience: '',  
        spotifyAccount: '',
        website: '',  // Hinzugefügt: Feld für die Website
        emailVisible: true,
        hasExpertSettings: false,
        expertQuote: '',
    });
    const [nameInput, setNameInput] = useState('');
    const [imgLoading, setImgLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState('');
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.currentUser) {
            const userId = auth.currentUser.uid;
            const userRef = databaseRef(database, `Artists/${userId}`);
            get(userRef).then(snapshot => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setUserData(data);
                    if (data.artistImageUrl) {
                        setImagePreview(data.artistImageUrl);
                    }
                }
            });
        }
    }, []);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleImageChange = async event => {
        const file = event.target.files[0];
        if (!file) return;

        setImgLoading(true);
        const fileRef = storageRef(storage, `artistImages/${auth.currentUser.uid}/${file.name}`);
        const uploadResult = await uploadBytes(fileRef, file);
        const imageUrl = await getDownloadURL(uploadResult.ref);
        setImagePreview(imageUrl);
        setUserData({ ...userData, artistImageUrl: imageUrl });
        setImgLoading(false);
    };

    const handleSave = async () => {
        const userId = auth.currentUser.uid;
        let isValid = true;
        let newUserData = { ...userData };
        let newErrors = {};
    
        Object.keys(userData).forEach(key => {
            if (!validateInput(key, userData[key]) && userData[key] !== '') {
                isValid = false;
                newUserData[key] = ''; // Ungültiges Feld zurücksetzen
                newErrors[key] = `Invalid input for ${key}`;
            }
        });
    
        setUserData(newUserData); // Aktualisiere den Zustand
        setErrors(newErrors); // Aktualisiere Fehlermeldungen
    
        if (isValid) {
            await update(databaseRef(database, `Artists/${userId}`), newUserData);
            setSaveStatus('Everything saved');
        } else {
            setSaveStatus('Settings partly saved - some values were invalid and deleted');
        }
    
        // Lösche Status und Fehler nach 4 Sekunden
        setTimeout(() => {
            setSaveStatus('');
            setErrors({});
        }, 4000);
    };

    function isValidURL(value) {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // Protokoll
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*))\\.?)+[a-z]{2,}'+ // Domainname und Erweiterung
        '(\\:\\d+)?(\\/[-a-z\\d%@_.~+&:]*)*'+ // Port und Pfad
        '(\\?[;&a-z\\d%@_.,~+&:=-]*)?'+ // Abfrageparameter
        '(\\#[-a-z\\d_]*)?$','i'); // Fragment
        return !!pattern.test(value);
    }

    function validateInput(key, value) {
        if (value === "") return true;
        switch (key) {
            case 'instagramAccount':
                return /^(https:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9._-]{1,30}$/.test(value);
            case 'tiktokAccount':
                return /^(https:\/\/)?(www\.)?tiktok\.com\/@?[a-zA-Z0-9._]{1,24}$/.test(value);
            case 'youtubeAccount':
                return /^(https:\/\/)?(www\.)?youtube\.com\/(channel\/|user\/)?([a-zA-Z0-9\-_]+)$/.test(value);
            case 'spotifyAccount':
                return /^(https:\/\/)?open\.spotify\.com\/(intl-[a-z]{2}\/)?artist\/[a-zA-Z0-9]{22}(\?si=[a-zA-Z0-9_-]+)?$/.test(value);
            case 'website':
                return isValidURL(value); // Validierung der Website-URL
            default:
                return true;
        }
    }

    function DeleteAccountModal({ isOpen, onClose, onDelete }) {
        const [passwordInput, setPasswordInput] = useState('');

        const handleDeleteClick = () => {
            onDelete(passwordInput);
            setPasswordInput('');
        };

        if (!isOpen) return null;

        return (
            <div className="modal-overlay-2">
                <div className="modal-content-2">
                    <h2>Confirm Delete Account</h2>
                    <input
                        type="password"
                        placeholder="Enter your password"
                        value={passwordInput}
                        onChange={(e) => setPasswordInput(e.target.value)}
                    />
                    <div>
                        <button style={{ backgroundColor: 'var(--blue)' }} type="button" onClick={onClose}>Cancel</button>
                        <button type="button" onClick={handleDeleteClick}>Delete Account</button>
                    </div>
                </div>
            </div>
        );
    }

    const handleDeleteAccount = async (password) => {
        const userId = auth.currentUser.uid;

        try {
            const userRef = databaseRef(database, `Artists/${userId}/email`);
            const userEmailSnapshot = await get(userRef);
            if (!userEmailSnapshot.exists()) {
                alert("Email not found in database.");
                return;
            }
            const userEmail = userEmailSnapshot.val();
            const credential = EmailAuthProvider.credential(userEmail, password);
            await reauthenticateWithCredential(auth.currentUser, credential);

            const songsRef = databaseRef(database, 'Songs');
            const songsSnapshot = await get(songsRef);
            if (songsSnapshot.exists()) {
                const songs = songsSnapshot.val();
                for (const songId in songs) {
                    if (songs[songId].artistID === userId) {
                        await remove(databaseRef(database, `Songs/${songId}`));
                    }
                }
            }

            await remove(databaseRef(database, `Artists/${userId}`));
            await auth.currentUser.delete();
            alert('Your account and all associated data have been permanently deleted.');
            navigate('/');
        } catch (error) {
            alert("Failed to delete account: " + error.message);
        }
    };

    return (
        <div className="general-settings">
            <div className="mySongsHeader" style={{ paddingTop: '20px' }}>
                <h1>My Settings</h1>
            </div>
            <form className="general-settings-form">
                <h2>General Settings</h2>
                <div className="profile-image-container" onClick={() => document.getElementById('imageUpload').click()}>
                    <img src={imagePreview || 'default-profile.jpg'} alt="Profile" className="profile-image-2" />
                    {imgLoading && <div className="img-loader"></div>}
                </div>
                <button className="profile-picture-change-button" type="button" onClick={() => document.getElementById('imageUpload').click()}>
                    Change Profile Picture
                </button>
                <input id="imageUpload" type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageChange} />
                <div className="general-settings-container">
                    <div className="general-input-box">
                        <label className="general-settings-label">Email:</label>
                        <input type="text" value={userData.email || ''} disabled className="general-settings-input" />
                    </div>
                    <div className="general-settings-double">
                        <div className="general-input-box-2" style={{ marginRight: '35px' }}>
                            <label id="rep-name-label" className="general-settings-label">Artist Name:</label>
                            <input type="text" value={userData.artistName} disabled className="general-settings-input" />
                        </div>
                        <div className="general-input-box-2" style={{ marginLeft: '35px' }}>
                            <label id="rep-name-label" className="general-settings-label">Representative Name:</label>
                            <input type="text" name="name" value={userData.name} onChange={handleChange} className="general-settings-input" />
                        </div>
                    </div>
            
                    {/* Expert Settings */}
                    <div className="general-input-box" style={{ marginTop: '20px' }}>
                        <label className="general-settings-label">About You:</label>
                        <textarea
                            name="expertQuote"
                            value={userData.expertQuote}
                            onChange={handleChange}
                            placeholder="Tell your story here"
                            className="general-settings-textarea"
                        />
                    </div>

                    <h2 style={{ marginTop: '30px' }}>Social Media</h2>
                    <div className="general-input-box" style={{ marginTop: '20px' }}>
                        <label className="general-settings-label">Followers across all Channels:</label>
                        <input
                            type="number"
                            name="totalAudience"
                            value={userData.totalAudience}
                            onChange={handleChange}
                            placeholder="Enter total followers count"
                            className="general-settings-input"
                        />
                    </div>
                    <div className="general-input-box" style={{ marginTop: '20px' }}>
                        <img className="general-input-img" src="./img/instagram_1.svg" alt="" />
                        <label className="general-settings-label">Instagram Link:</label>
                        <input
                            type="text"
                            name="instagramAccount"
                            value={userData.instagramAccount}
                            onChange={handleChange}
                            placeholder="e.g. www.instagram.com/username"
                            className="general-settings-input"
                        />
                    </div>
                    {errors.instagramAccount && <p style={{ color: 'red' }}>The Instagram link is not valid, please try again.</p>}
                    <div className="general-input-box" style={{ marginTop: '20px' }}>
                        <img className="general-input-img" src="./img/tiktok_1.svg" alt="" />
                        <label className="general-settings-label">TikTok Link:</label>
                        <input
                            type="text"
                            name="tiktokAccount"
                            value={userData.tiktokAccount}
                            placeholder="e.g. www.tiktok.com/@username"
                            onChange={handleChange}
                            className="general-settings-input"
                        />
                    </div>
                    {errors.tiktokAccount && <p style={{ color: 'red' }}>The TikTok link is not valid, please try again.</p>}
                    <div className="general-input-box" style={{ marginTop: '20px' }}>
                        <label className="general-settings-label">Youtube Link:</label>
                        <img className="general-input-img" src="./img/youtube_1.svg" alt="" />
                        <input
                            type="text"
                            name="youtubeAccount"
                            value={userData.youtubeAccount}
                            placeholder="e.g. www.youtube.com/username"
                            onChange={handleChange}
                            className="general-settings-input"
                        />
                    </div>
                    {errors.youtubeAccount && <p style={{ color: 'red' }}>The Youtube link is not valid, please try again.</p>}
                    <div className="general-input-box" style={{ marginTop: '20px' }}>
                        <label className="general-settings-label">Spotify Link:</label>
                        <img style={{ width: '25px', bottom: '13px', right: '-42px' }} className="general-input-img" src="./img/spotify_1.svg" alt="" />
                        <input
                            type="text"
                            name="spotifyAccount"
                            value={userData.spotifyAccount}
                            onChange={handleChange}
                            placeholder="e.g. https://open.spotify.com/intl/artist/artistID"
                            className="general-settings-input"
                        />
                    </div>
                    {errors.spotifyAccount && <p style={{ color: 'red' }}>The Spotify link is not valid, please try again.</p>}
                    
                    {/* Hinzugefügt: Website-Eingabefeld */}
                    <div className="general-input-box" style={{ marginTop: '20px' }}>
                        <label className="general-settings-label">Website Link:</label>
                        <input
                            type="text"
                            name="website"
                            value={userData.website}
                            onChange={handleChange}
                            placeholder="e.g. https://www.yourwebsite.com"
                            className="general-settings-input"
                        />
                    </div>
                    {errors.website && <p style={{ color: 'red' }}>The website link is not valid, please try again.</p>}
                </div>
                <div className="general-input-box" style={{ marginTop: '30px' }}>
                    <label className="general-settings-label">Hide public email:</label>
                    <label className="toggle-switch">
                        <input type="checkbox" checked={userData.emailVisible} name="emailVisible" onChange={handleChange} />
                        <span className="switch-slider"></span>
                    </label>
                </div>

                <h2 style={{ marginTop: '80px', color: 'red' }}>Danger Zone</h2>
                <div className="danger-zone">
                    <p>This action cannot be undone. This will permanently delete your account and all associated songs.</p>
                </div>

                <div className="settings-save-bar">
                    {saveStatus && <p className="save-status">{saveStatus}</p>}
                    <button className="settings-save-button" type="button" onClick={handleSave}>Save Settings</button>
                </div>
            </form>
            <button className="danger-button" onClick={() => setModalIsOpen(true)}>Delete Account</button>
            <DeleteAccountModal
                isOpen={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
                onDelete={handleDeleteAccount}
            />
        </div>
    );
}

export default GeneralSettings;
