import React, { useState, useEffect } from 'react';
import { auth, database } from '../firebaseConfig';
import { ref as databaseRef, get, query, orderByChild, equalTo } from 'firebase/database';
import './MySongs.css';  
import MySongs from './MySongs';  
import MyAwards from './MyAwards';  
import BuyProModal from '../Pages/BuyProModal';  
import ViralBuyModal from '../Pages/ViralBuyModal'; // Importiere das ViralBuyModal
import { useNavigate } from 'react-router-dom'; 

function SongsAndAwards() {
  const [activeTab, setActiveTab] = useState('songs');
  const [songs, setSongs] = useState([]);
  const [isBuyProModalOpen, setIsBuyProModalOpen] = useState(false);
  const [isViralBuyModalOpen, setIsViralBuyModalOpen] = useState(false); // Zustand für das ViralBuyModal
  const [songCredits, setSongCredits] = useState(0);
  const [isPremium, setIsPremium] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async (userId) => {
      const userRef = databaseRef(database, `Artists/${userId}`);
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        const { isPremium, songCredits } = snapshot.val();
        setSongCredits(songCredits || 0);
        setIsPremium(isPremium || false);
      }
    };

    const fetchSongs = async (userId) => {
      const songsRef = query(databaseRef(database, 'Songs'), orderByChild('artistID'), equalTo(userId));
      const snapshot = await get(songsRef);
      if (snapshot.exists()) {
        const songsData = snapshot.val();
        const songsArray = Object.entries(songsData).map(([id, data]) => ({
          id,
          ...data,
        }));
        setSongs(songsArray.reverse()); 
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserData(user.uid);
        fetchSongs(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleButtonClick = async () => {
    const user = auth.currentUser;
    if (user) {
      if (isPremium || songCredits > 0) {
        navigate('/submit-song');
      } else {
        const randomModal = Math.random() < 0.5 ? 'BuyProModal' : 'ViralBuyModal'; // Zufälliger Modalauswahl
        if (randomModal === 'BuyProModal') {
          setIsBuyProModalOpen(true);
        } else {
          setIsViralBuyModalOpen(true);
        }
      }
    }
  };

  const awardCount = songs ? songs.filter((song) => song.awardDate).length : 0;
  const nominationCount = songs ? songs.filter((song) => song.nominatedDate && !song.awardDate).length : 0;
  const inReviewCount = songs ? songs.filter((song) => !song.nominatedDate && !song.awardDate).length : 0;

  const filteredAwards = songs.filter(song => song.awardDate || song.nominatedDate);

  return (
    <div className="mySongs">
      <div className="mySongsHeader">
        <h1>Backstage</h1>
        <button className="upload-song-button" onClick={handleButtonClick}>
          <p>Upload new Song</p>
          <img className="plus-song-icon" src="./img/plus_icon.webp" alt="Plus icon" />
        </button>
      </div>
      <div className="mySongstoggleMenu">
        <button
          className={`songtoggleButton ${activeTab === 'songs' ? 'active' : ''}`}
          onClick={() => setActiveTab('songs')}
        >
          <img 
            src={activeTab === 'songs' ? "./img/song_red.webp" : "./img/song_dark.webp"} 
            alt="" 
          />
          My Submissions
        </button>
        <button
          className={`awardtoggleButton ${activeTab === 'awards' ? 'active' : ''}`}
          onClick={() => setActiveTab('awards')}
        >
          <img 
            src={activeTab === 'awards' ? "./img/award_red.webp" : "./img/award_dark.webp"} 
            alt="" 
          />
          My Awards
        </button>
      </div>

      {(awardCount > 0 || nominationCount > 0) && (
        <div className="subHeader">
          {awardCount > 0 && <p>Awards: {awardCount}</p>}
          {nominationCount > 0 && <p>Nominations: {nominationCount}</p>}
          {inReviewCount > 0 && <p>In Review: {inReviewCount}</p>}
        </div>
      )}

      <div className="content">
        {activeTab === 'songs' && <MySongs />}
        {activeTab === 'awards' && <MyAwards filteredAwards={filteredAwards} />}
      </div>

      {/* Modals */}
      {isBuyProModalOpen && <BuyProModal onClose={() => setIsBuyProModalOpen(false)} />}
      {isViralBuyModalOpen && <ViralBuyModal isOpen={isViralBuyModalOpen} onClose={() => setIsViralBuyModalOpen(false)} />}
    </div>
  );
}

export default SongsAndAwards;
