import React, { useState, useEffect } from 'react';
import { ref as databaseRef, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';
import { motion, AnimatePresence } from 'framer-motion';
import './SongUploadAlert.css';

const SongUploadAlert = () => {
  const [recentUploads, setRecentUploads] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const fetchRecentUploads = async () => {
      const songsRef = databaseRef(database, '/Songs');
      onValue(songsRef, (snapshot) => {
        const songsData = snapshot.val() || {};
        const recentUploadsData = [];

        const now = Date.now();
        const twentyFourHoursAgo = now - 24 * 60 * 60 * 1000;

        for (const songId in songsData) {
          const song = songsData[songId];
          const uploadTime = new Date(song.releaseTimestamp).getTime();

          if (uploadTime > twentyFourHoursAgo) {
            const artistRef = databaseRef(database, `/Artists/${song.artistID}`);
            onValue(artistRef, (artistSnapshot) => {
              const artistData = artistSnapshot.val() || {};
              recentUploadsData.push({
                songId,
                artistName: artistData.artistName,
                artistImageUrl: artistData.artistImageUrl,
                songTitle: song.title,
                releaseTimestamp: song.releaseTimestamp,
              });
              setRecentUploads([...recentUploadsData]);
            });
          }
        }
      });
    };

    fetchRecentUploads();
  }, []);

  useEffect(() => {
    if (recentUploads.length > 0) {
      const intervalId = setInterval(() => {
        setVisible(true);

        setTimeout(() => {
          setVisible(false);

          setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            if (newIndex >= recentUploads.length) {
              clearInterval(intervalId); // Stop cycle when all songs are displayed
              return prevIndex;
            }
            return newIndex;
          });
        }, 10000); // Display for 5 seconds
      }, 20000); // 5 seconds display, 5 seconds pause

      return () => clearInterval(intervalId);
    }
  }, [recentUploads]);

  if (recentUploads.length === 0 || currentIndex >= recentUploads.length) {
    return null; // Nothing to display
  }

  const currentUpload = recentUploads[currentIndex];

  const calculateTimeAgo = (timestamp) => {
    const now = Date.now();
    const uploadTime = new Date(timestamp).getTime();
    const differenceInMinutes = Math.floor((now - uploadTime) / (1000 * 60));

    if (differenceInMinutes < 60) {
      return `${differenceInMinutes} minute${differenceInMinutes === 1 ? '' : 's'} ago`;
    } else {
      const differenceInHours = Math.floor(differenceInMinutes / 60);
      return `${differenceInHours} hour${differenceInHours === 1 ? '' : 's'} ago`;
    }
  };

  const handleClick = () => {
    window.open(`/song/${currentUpload.songId}`, '_blank'); // Open in a new tab
  };

  return (
    <div className="song-upload-alerts">
      <AnimatePresence>
        {visible && (
          <motion.div
            className="song-upload-alert"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            transition={{
              duration: 0.5,
              ease: [0.6, -0.05, 0.01, 0.99], // Smooth easing function
            }}
            style={{ willChange: 'transform, opacity', cursor: 'pointer' }} // Add cursor pointer for better UX
            onClick={handleClick} // Add the click handler
          >
            <div className="song-upload-alert-content">
              <div className="song-upload-alert-text">
                <h4>New Upload</h4>
                <p>{currentUpload.songTitle}</p>
                <p>{currentUpload.artistName}</p>
                <p>{calculateTimeAgo(currentUpload.releaseTimestamp)}</p>
              </div>
              <div className="song-upload-alert-image">
                <img src={currentUpload.artistImageUrl} alt={currentUpload.artistName} />
            <img className="song-upload-alert-plus" src="../../img/plus_icon.webp" alt="" />
              </div>
            </div>
            <div className="progressorio-bar">
              <motion.div
                className="progressorio"
                initial={{ width: '0%' }}
                animate={{ width: '100%' }}
                transition={{ duration: 10 }} // Sync with 5-second display time
              ></motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SongUploadAlert;
