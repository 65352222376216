import React, { useEffect, useState } from 'react';
import { ref as databaseRef, get } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './CollabStartComponent.css';

const containerDefinitions = [
    { id: 1, type: 'Connect', title: "I want to connect", text: "I am here to connect and collaborate with other artists.", imgSrc: "../../service/unplugged.svg" },
    { id: 2, type: 'Vocals', title: "I will record vocals", text: "I will record vocals on your beat or instrumental.", imgSrc: "../../service/vocal.svg" },
    { id: 3, type: 'Create Beat', title: "I will create a beat.", text: "I will create a beat so you can record vocals.", imgSrc: "../../service/cd.svg" },
    { id: 4, type: 'Promote Music', title: "I will promote your music.", text: "I will promote your music via. my channels.", imgSrc: "../../service/management.svg" },
    { id: 5, type: 'Write Lyrics', title: "I will write lyrics.", text: "I will write lyrics for you to record.", imgSrc: "../../service/blog.svg" },
    { id: 6, type: 'Record Guitar', title: "I will record my guitar", text: "I will record guitar melodies and chords.", imgSrc: "../../service/instrument.svg" },
    { id: 7, type: 'Mix and Master', title: "I will mix and master your track", text: "I will mix and master your track to professional quality.", imgSrc: "../../service/studio.svg" },
    { id: 8, type: 'Remix', title: "I will remix your song", text: "I will remix your song and give it a new flavor.", imgSrc: "../../service/remix.svg" },
    { id: 9, type: 'Music Video', title: "I will create the music video", text: "I will create a music video for your track.", imgSrc: "../../service/video.svg" },
    { id: 10, type: 'Ghost Produce', title: "I will ghost produce a song", text: "I will ghost produce a track for you.", imgSrc: "../../service/ghost.svg" },
    { id: 11, type: 'Artwork', title: "I will create the artwork for a song", text: "I will create the artwork for your song or album.", imgSrc: "../../service/artwork.svg" },
    { id: 12, type: 'Management', title: "I will manage you", text: "I will manage your career and help you grow.", imgSrc: "../../service/manage.svg" }
];

const CollabStartComponent = ({ containers, initiatorId, message }) => {
    const [artistInfo, setArtistInfo] = useState({ artistName: '', artistImageUrl: '' });

    useEffect(() => {
        const fetchArtistInfo = async () => {
            if (initiatorId) {
                const artistRef = databaseRef(database, `Artists/${initiatorId}`);
                const artistSnapshot = await get(artistRef);
                if (artistSnapshot.exists()) {
                    const artistData = artistSnapshot.val();
                    setArtistInfo({
                        artistName: artistData.artistName || 'Unknown Artist',
                        artistImageUrl: artistData.artistImageUrl || 'https://example.com/default-profile.jpg',
                    });
                }
            }
        };

        fetchArtistInfo();
    }, [initiatorId]);

    const mapContainerToDetails = (containerType) => {
        const foundContainer = containerDefinitions.find(container => container.type === containerType);
        return foundContainer || { title: 'Unknown Service', imgSrc: 'https://example.com/default-image.jpg' };
    };

    return (
        <div className="collab-start-container">
            <div>
                <h3>Collaboration request from</h3>
                <div className="collab-chat-artist-info">
                    <img src={artistInfo.artistImageUrl} alt={artistInfo.artistName} className="collab-chat-artist-image" />
                    <span className="collab-chat-artist-name">{artistInfo.artistName}</span>
                </div>
            </div>

            <ul>
                {containers.map((containerType, index) => {
                    const { title, imgSrc } = mapContainerToDetails(containerType);
                    return (
                        <li key={index} className="collab-start-item">
                            <img src={imgSrc} alt={title} className="collab-start-item-image" />
                            <span>{title}</span>
                        </li>
                    );
                })}
            </ul>
            
            {/* Nachricht unter den Details anzeigen */}
            {message && (
                <div className="collab-start-message">
                    <p>{message}</p>
                </div>
            )}
        </div>
    );
};

export default CollabStartComponent;
