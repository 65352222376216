import React, { useState } from "react";
import { database } from "../../firebaseConfig";
import { ref, set, get, remove } from "firebase/database";
import "./NewCoins.css";

const NewCoins = ({ wallets }) => {
  const [loading, setLoading] = useState(false);

  const fetchActivities = async (wallet) => {
    const now = Math.floor(Date.now() / 1000); // Aktuelle Zeit in Sekunden (Unix-Timestamp)
    const oneHourAgo = now - 1 * 60 * 60; // 1 Stunde vorher

    const requestOptions = {
      method: "GET",
      headers: {
        token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE3MzIxODUwMDM3MjUsImVtYWlsIjoiaGFyZHZvcmV2aWJlYW5kaUBnbWFpbC5jb20iLCJhY3Rpb24iOiJ0b2tlbi1hcGkiLCJhcGlWZXJzaW9uIjoidjIiLCJpYXQiOjE3MzIxODUwMDN9.bE8JKbILy6DabOlJqUaQhutHI9jiL6Cni7KKiHVswdw", // Dein API-Key
      },
    };

    const walletRef = ref(database, `/IA/wallets/${wallet.walletID}/activities`);

    try {
      // 1. Vorhandene Aktivitäten löschen
      console.log(`Deleting existing activities for wallet ${wallet.walletID}...`);
      await remove(walletRef);
      console.log(`Existing activities for wallet ${wallet.walletID} deleted successfully.`);

      // 2. Neue Aktivitäten abrufen
      console.log(`Fetching activities for wallet: ${wallet.walletID}`);
      const response = await fetch(
        `https://pro-api.solscan.io/v2.0/account/defi/activities?address=${wallet.walletID}&block_time[]=${oneHourAgo}&block_time[]=${now}&page=1&page_size=10&sort_by=block_time&sort_order=desc`,
        requestOptions
      );

      const data = await response.json();

      if (data.success && Array.isArray(data.data)) {
        console.log(`Fetched activities for wallet ${wallet.walletID}:`, data.data);

        const tokenMetadata = data.metadata?.tokens || {};

        // Aktivitäten filtern und anreichern
        const enrichedActivities = data.data
          .filter((activity) =>
            ["ACTIVITY_TOKEN_SWAP", "ACTIVITY_SPL_MINT", "ACTIVITY_SPL_BURN"].includes(
              activity.activity_type
            )
          )
          .map((activity) => {
            const token1Name =
              tokenMetadata[activity.routers?.token1]?.token_name || "Unknown";
            const token2Name =
              tokenMetadata[activity.routers?.token2]?.token_name || "Unknown";

            const token1Address = activity.routers?.token1 || "Unknown Address";
            const token2Address = activity.routers?.token2 || "Unknown Address";

            const token1Amount =
              activity.routers?.amount1 /
              Math.pow(10, activity.routers?.token1_decimals || 0);
            const token2Amount =
              activity.routers?.amount2 /
              Math.pow(10, activity.routers?.token2_decimals || 0);

            return {
              trans_id: activity.trans_id,
              block_time: activity.block_time,
              activity_type: activity.activity_type,
              platform: activity.platform,
              from_address: activity.from_address,
              value: activity.value,
              token1_name: token1Name,
              token1_address: token1Address,
              token1_amount: isNaN(token1Amount) ? null : token1Amount,
              token2_name: token2Name,
              token2_address: token2Address,
              token2_amount: isNaN(token2Amount) ? null : token2Amount,
            };
          });

        // Aktivitäten speichern (überschreibt immer die alten)
        console.log(
          `Saving ${enrichedActivities.length} activities for wallet ${wallet.walletID}`
        );
        await set(walletRef, enrichedActivities);

        return enrichedActivities.length; // Anzahl der gespeicherten Aktivitäten zurückgeben
      } else {
        console.warn(`No activities found for wallet ${wallet.walletID}:`, data);
        return 0;
      }
    } catch (error) {
      console.error(`Error fetching activities for wallet ${wallet.walletID}:`, error);
      return 0;
    }
  };

  const handleCheckActivities = async () => {
    setLoading(true);

    try {
      const results = await Promise.all(
        wallets.map(async (wallet) => {
          const newCount = await fetchActivities(wallet);
          return { walletID: wallet.walletID, newCount };
        })
      );

      console.log("Activity updates completed:", results);
    } catch (error) {
      console.error("Error during activity updates:", error);
    }

    setLoading(false);
  };

  return (
    <div>
      <button className="new-coins-button" onClick={handleCheckActivities} disabled={loading}>
        {loading ? "Loading..." : "Check Activities"}
      </button>
      {loading && <div className="spinner"></div>}
    </div>
  );
};

export default NewCoins;
