import React from 'react';
import LoadingScreen from '../../Utility/LoadingScreen';
import './ThankYouStep.css'

const ThankYouStep = () => {
  return (
    <div className="thank-you-step">
            <div style={{marginTop: '-30px', position: 'relative', marginBottom: '40px'}} className="spinner-3"></div>
      <h2>Creating your Page</h2>
      <p>Your setup is complete and has been saved successfully.</p>
    </div>
  );
};

export default ThankYouStep;
