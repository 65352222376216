import React, { useRef, useEffect } from 'react';

function ScrollVideo() {
  const videoRef = useRef(); // Ref für das Video-Element

  // Diese Funktion aktualisiert die aktuelle Zeit des Videos basierend auf der Scroll-Position
  const handleScroll = () => {
    const video = videoRef.current;
    // Berechnen Sie den Scroll-Bereich, in dem das Video aktiv ist
    const startScroll = 1000; // Der Scroll-Beginn für das Video in Pixel
    const endScroll = document.body.scrollHeight - window.innerHeight; // Das Ende des Scroll-Bereichs
    const scrollRange = endScroll - startScroll;
  
    // Überprüfen Sie, ob der aktuelle Scroll über dem Startwert liegt
    if (window.scrollY > startScroll) {
      const scrollFraction = (window.scrollY - startScroll) / scrollRange;
      const videoTime = scrollFraction * video.duration;
      
      // Überprüfen, ob der berechnete Wert für die videoTime endlich ist
      if (!isNaN(videoTime) && isFinite(videoTime)) {
        video.currentTime = videoTime;
      }
    } else {
      // Optional: Setzen Sie das Video auf den Anfang zurück, wenn der Scroll-Wert unter dem Startwert liegt
      video.currentTime = 0;
    }
  };
  
  
  // Event-Listener für das Scroll-Event hinzufügen und beim Unmount entfernen
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <video ref={videoRef} width="100%"  style={{opacity: '1'}} preload="auto" muted>
      <source src="../img/animation.mp4" type="video/mp4" />
      Your Browser does not support Videos.
    </video>
  );
}

export default ScrollVideo;
