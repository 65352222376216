import React, { useState, useEffect } from 'react';
import './PlayTreasure1.css'; // CSS für den Schatz-Modus
import { ref, get, set } from 'firebase/database';
import { database, auth } from '../firebaseConfig'; // Importiere database und auth aus Firebase-Konfiguration

const PlayTreasure1 = ({ onClose }) => {
  const [stage, setStage] = useState(1); // Stadium 1 für die Testversion, Stadium 2 für den Congratulations-Screen
  const [loading, setLoading] = useState(false); // Ladezustand, während die Firebase-Funktion ausgeführt wird
  const [error, setError] = useState(null); // Fehlerzustand
  const [isTrialUsed, setIsTrialUsed] = useState(false); // Zustand, ob Trial bereits verwendet wurde
  const artistId = auth.currentUser?.uid; // Authentifizierter Benutzer (artistId)

  // Funktion, um das Trial zu aktivieren und die Cloud Scheduler API zu triggern
  const triggerFreeTrial = async ({ artistId, time, trialVariable }) => {
    try {
      const url = 'https://us-central1-awards-13a46.cloudfunctions.net/trialTurnoff';
  
      const payload = {
        artistId,
        time, // Anzahl der Tage
        trialVariable, // Variable, die geändert werden soll (z.B. PlayViral/status)
      };
  
      // HTTP POST an die Cloud Function
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const error = await response.json();
        console.error('Fehler beim Aufruf der Cloud Function:', error);
        return false;
      }
  
      const result = await response.json();
      return result.success; // Erfolg wird von der Funktion zurückgegeben
    } catch (error) {
      console.error('Fehler beim Aufruf des Google Cloud Scheduler:', error);
      return false;
    }
  };

  // Überprüfe, ob der Benutzer bereits einen PlayViral-Eintrag hat
  const checkIfTrialUsed = async () => {
    if (!artistId) return false;

    const trialRef = ref(database, `/Artists/${artistId}/PlayViral`); // Nutze die database-Instanz
    const snapshot = await get(trialRef);

    // Überprüfe, ob ein Eintrag vorhanden ist
    return snapshot.exists(); // Wenn ein Eintrag vorhanden ist, gib true zurück
  };

  // Überprüfe, ob der Benutzer das Trial bereits verwendet hat (beim Laden der Komponente)
  useEffect(() => {
    const checkTrialStatus = async () => {
      const trialUsed = await checkIfTrialUsed();
      setIsTrialUsed(trialUsed); // Setze den Zustand je nach Vorhandensein des Eintrags
    };

    checkTrialStatus();
  }, [artistId]);

  const handleAcceptFreeTrial = async () => {
    if (!artistId) {
      setError("Benutzer ist nicht angemeldet");
      return;
    }

    setLoading(true); // Setze den Ladezustand auf true, während die Firebase-Funktion ausgeführt wird
    setError(null); // Fehler zurücksetzen

    try {
      if (isTrialUsed) {
        setError("Sie haben das Gratis-Trial bereits verwendet.");
        setLoading(false);
        return;
      }

      // Rufe die Funktion triggerFreeTrial auf
      const success = await triggerFreeTrial({
        artistId,
        time: 7, // 7 Tage Testversion
        trialVariable: "PlayViral/status",
      });

      if (success) {
        // Setze den Status des Trials auf true und speichere den Zeitstempel
        const trialRef = ref(database, `/Artists/${artistId}/PlayViral`);
        await set(trialRef, {
          status: true,
          startTimestamp: new Date().toISOString(),
        });

        setStage(2); // Wechsle zu Stadium 2, wenn die Funktion erfolgreich war
      } else {
        setError("Fehler beim Starten des Trials. Bitte versuchen Sie es erneut.");
      }
    } catch (error) {
      console.error("Fehler beim Auslösen der Firebase-Funktion:", error);
      setError("Fehler beim Starten des Trials. Bitte versuchen Sie es erneut.");
    } finally {
      setLoading(false); // Ladezustand zurücksetzen
    }
  };

  return (
    <div className="play-treasure-overlay">
      <div className="play-treasure-modal">
        <button className="close-button" onClick={onClose}>✕</button>

        {error && <p className="error-message">{error}</p>}

        {stage === 1 && (
          <>
          <div>
              <img src="../../img/goaty.webp" alt="" />
          <h1>Wow, you just found Jimmy!</h1>
            <p>Do you want to see whats inside?</p>
          </div>
            <button className="play-treasure-modalo-button" onClick={handleAcceptFreeTrial} disabled={loading || isTrialUsed}>
              {loading ? (
                <>
                  <div className="spinneritto"></div> {/* Spinner */}
                  Checking...
                </>
              ) : isTrialUsed ? "Goat is dead" : "See whats inside"}
            </button>
          </>
        )}

        {stage === 2 && (
          <>
            <img src="../../img/goaty_2.webp" alt="" />
            <h1>7 Day Free Trial unlocked 🎉</h1>
            <p>You killed the goat.<br/> But unlocked yourself a 7 Day Free Trial!</p>
            <button onClick={onClose} className="play-treasure-modalo-button">Enjoy Free Trial</button>
          </>
        )}
      </div>
    </div>
  );
};

export default PlayTreasure1;
