import React, { useRef, useState, useEffect } from 'react';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebaseConfig';
import Resizer from 'react-image-file-resizer';
import './ChatWindow.css';
import './ImageUploadComponentChat.css';

const ImageUploadComponentChat2 = ({ onUploadSuccess, resetTrigger }) => {
    const [uploading, setUploading] = useState(false);
    const [imageUploadUrl, setImageUploadUrl] = useState(''); // URL für Bild
    const [pdfUploadUrl, setPdfUploadUrl] = useState(''); // URL für PDF
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (resetTrigger) {
            setImageUploadUrl(''); // Setzt das Bild-Vorschaubild zurück
            setPdfUploadUrl(''); // Setzt die PDF-Vorschaubild zurück
            if (fileInputRef.current) fileInputRef.current.value = ''; // Leert das Input-Feld
        }
    }, [resetTrigger]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) {
            console.error('No file selected.');
            return;
        }

        const fileSizeLimit = 10 * 1024 * 1024; // 10 MB Limit
        if (file.size > fileSizeLimit) {
            alert('File is too big. The limit is 10 MB.');
            return;
        }

        setUploading(true);

        // Separate Logik für PDF oder Bild
        if (file.type === 'application/pdf') {
            uploadPdf(file);
        } else if (file.type.startsWith('image/')) {
            resizeAndUploadImage(file);
        } else {
            alert('Invalid file type. Only images and PDFs are allowed.');
            setUploading(false);
        }
    };

    const resizeAndUploadImage = (file) => {
        Resizer.imageFileResizer(
            file,
            1300, // max width
            1300, // max height
            'JPEG', // format
            80, // quality
            0, // rotation
            (uri) => {
                const fileRef = storageRef(storage, `chatImages/${new Date().getTime()}.jpeg`);
                const uploadTask = uploadBytesResumable(fileRef, uri);

                uploadTask.on(
                    'state_changed',
                    () => {},
                    (error) => {
                        console.error('Image upload failed:', error);
                        setUploading(false);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setImageUploadUrl(downloadURL);
                            onUploadSuccess(downloadURL, 'image'); // Übergabe von URL und Typ
                            setUploading(false);
                        });
                    }
                );
            },
            'blob'
        );
    };

    const uploadPdf = (file) => {
        const fileRef = storageRef(storage, `chatFiles/${new Date().getTime()}.pdf`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on(
            'state_changed',
            () => {},
            (error) => {
                console.error('PDF upload failed:', error);
                setUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setPdfUploadUrl(downloadURL);
                    onUploadSuccess(downloadURL, 'pdf'); // Übergabe von URL und Typ
                    setUploading(false);
                });
            }
        );
    };

    const openFileDialog = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="image-upload-component-chat-3">
            <input
                ref={fileInputRef}
                type="file"
                accept="image/*,application/pdf"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            <button className="chat-image-upload-4" onClick={openFileDialog}>
                <img src='../../img/upload.svg' alt="Upload File" />
            </button>
            {uploading && <div style={{ bottom: '95px', left: '65px', width: '30px', height: '30px' }} className="spinner-4"></div>}
            
            {/* Bild-Upload Vorschau */}
            {imageUploadUrl && <img src={imageUploadUrl} className="chat-prev-uploaded-img-2" alt="Uploaded image preview" />}
            
            {/* PDF-Upload Vorschau mit Icon */}
            {pdfUploadUrl && (
                <a href={pdfUploadUrl} target="_blank" rel="noopener noreferrer">
                    <img src="../../img/pdf.svg" className="chat-prev-uploaded-img-2" alt="PDF file" />
                </a>
            )}
        </div>
    );
};

export default ImageUploadComponentChat2;
