import React, { useState, useRef } from 'react';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ref as databaseRef, set } from 'firebase/database';
import { database, storage, auth } from '../../firebaseConfig';
import Resizer from 'react-image-file-resizer';

function ReviewImport({ onClose }) {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [link, setLink] = useState('');
  const [text, setText] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);  // Zustand für den Upload-Status
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  const inputFileRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error('No file selected.');
      return;
    }

    const fileSizeLimit = 10 * 1024 * 1024; // 10 MB
    if (file.size > fileSizeLimit) {
      alert('File is too big. The limit is 10 MB.');
      return;
    }

    // Local preview of the image
    const reader = new FileReader();
    reader.onload = () => setPreviewUrl(reader.result);
    reader.readAsDataURL(file);

    // Resize the image for upload
    Resizer.imageFileResizer(
      file,
      1300,
      1300,
      'JPEG',
      80,
      0,
      blob => {
        // Store the resized image for later upload
        setFile(blob);
      },
      'blob'
    );
  };

  const handleSave = () => {
    if (!link || !text || !file) {
      alert('Please fill in all fields and upload an image.');
      return;
    }

    setUploading(true);
    const fileRef = storageRef(storage, `review_images/${userId}/${new Date().getTime()}.jpeg`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on('state_changed',
      snapshot => {},
      error => {
        console.error('Upload failed:', error);
        setUploading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          const data = { link, text, photoURL: downloadURL };
          const reviewsRef = databaseRef(database, `/ReviewImports/${userId}`);
          set(reviewsRef, data).then(() => {
            setUploading(false);
            setUploadComplete(true);  // Setzen des Upload-Status auf 'erfolgreich'
          }).catch(error => {
            console.error('Failed to update database:', error);
            setUploading(false);
          });
        });
      }
    );
  };

  return (
    <div className="prev-page-modal-overlay" onClick={onClose}>
      <div className="prev-page-modal-content" onClick={e => e.stopPropagation()}>
        {uploadComplete ? (
          <div className="prev-page-thank-you">
            <h2>Thank You!</h2>
            <p>Your data was uploaded and will be reviewed shortly.</p>
            <button onClick={onClose}>Close</button>
          </div>
        ) : (
          <>
            <h2>Import Reviews</h2>
            <p>Import your reviews from other platforms to Triple A. Simply upload a screenshot for verification along with the link to your sales page. We'll add the reviews to your account once approved.</p>
            <input type="text" placeholder="Link to Reviews" value={link} onChange={e => setLink(e.target.value)} />
            <textarea placeholder="Description (Optional)" value={text} onChange={e => setText(e.target.value)} />
            <input type="file" ref={inputFileRef} onChange={handleFileChange} style={{ display: 'none' }} />
            <div className="prev-upload-image-container" onClick={() => inputFileRef.current.click()}>
              <img src={previewUrl || "../../img/plus.svg"} 
                   alt="Upload Preview"
                   className={previewUrl ? "prev-preview-image" : "prev-default-image"} />
              <p>Upload Screenshot</p>
            </div>
            <button className="prev-rev-import-close-button" onClick={onClose}><img src="../../img/delete.svg" alt="" /></button>
            <button onClick={handleSave} disabled={uploading || !link} className={`prev-save-button ${link && !uploading ? 'active' : ''}`}>
              Save Review
            </button>
            {uploading && <div className="spinner-3"></div>}
          </>
        )}
      </div>
    </div>
  );
}

export default ReviewImport;
