import { ref as databaseRef, get, push, set } from 'firebase/database';
import { database, auth } from '../firebaseConfig';

export const saveMessage = async (senderId, recipientId, messageText, selectedContainers, containers) => {
  if (!auth.currentUser) {
    throw new Error('No user logged in');
  }

  const chatId = [senderId, recipientId].sort().join('_');
  const chatRef = databaseRef(database, `Chats/${chatId}`);
  const nowIso = new Date().toISOString();

  const snapshot = await get(chatRef);
  const containerTypes = selectedContainers.map(id => {
    const container = containers.find(c => c.id === id);
    return container ? container.type : null;
  }).filter(type => type !== null);

  if (snapshot.exists()) {
    // Chat exists, push a new message
    const messagesRef = databaseRef(database, `Chats/${chatId}/messages`);
    const newMessageRef = push(messagesRef);
    await set(newMessageRef, {
      message: messageText,
      senderId: senderId,
      type: "collab-start",
      containers: containerTypes, // Store the types of selected containers
      timestamp: nowIso
    });
    await set(databaseRef(database, `Chats/${chatId}/lastMessageAt`), nowIso);
  } else {
    // Chat does not exist, create it and add the first message
    await set(chatRef, {
      initiatorId: senderId,
      recipientId: recipientId,
      initiatorId_recipientId: chatId,
      createdAt: nowIso,
      lastMessageAt: nowIso
    });
    const messagesRef = databaseRef(database, `Chats/${chatId}/messages`);
    const newMessageRef = push(messagesRef);
    await set(newMessageRef, {
      message: messageText,
      senderId: senderId,
      type: "collab-start",
      containers: containerTypes, // Store the types of selected containers
      timestamp: nowIso
    });
  }
};
