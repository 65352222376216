import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ref as databaseRef, update, get } from 'firebase/database';
import { database } from '../../firebaseConfig';
import debounce from 'lodash.debounce';
import './EarnMoneySettings.css'; // Stellen Sie sicher, dass der Pfad korrekt ist
import FeatureSelector from './FeatureSelector';
import ServiceBasedContentSelector from './ServiceBasedContentSelector';
import LoadingScreen from '../../Utility/LoadingScreen';

const EarnMoneySettings = ({ pageId, onDisableNext, onError }) => {
  const [earnAmount, setEarnAmount] = useState(24);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [error, setError] = useState('');
  const [payoutOption, setPayoutOption] = useState('');
  const [licenseType, setLicenseType] = useState('standard'); // Standard or Exclusive
  const [serviceType, setServiceType] = useState(''); // Service type from the user's profile
  const standardLicenseImage = '/path/to/standard-license.png'; // Pfad zum Bild für Standard License
  const exclusiveLicenseImage = '/path/to/exclusive-license.png'; // Pfad zum Bild für Exclusive License
  const [loading, setLoading] = useState(true); // Added loading state
  const sliderRef = useRef(null); // Referenz für den Slider

  useEffect(() => {
    if (pageId) {
      const payoutOptionRef = databaseRef(database, `Pages/${pageId}/pageData/payoutOption`);
      get(payoutOptionRef).then(snapshot => {
        if (snapshot.exists()) {
          setPayoutOption(snapshot.val());
        }
      }).catch(error => {
        console.error('Failed to fetch payout option:', error);
        setError('Failed to load payout option.');
      });
    }
  }, [pageId]);

  useEffect(() => {
    const fetchData = async () => {
      if (!pageId) return;  // Frühe Rückkehr, wenn keine pageId vorhanden ist.
      try {
        const pageDataRef = databaseRef(database, `Pages/${pageId}/pageData`);
        const snapshot = await get(pageDataRef);
        if (snapshot.exists()) {
          const data = snapshot.val();

          setPayoutOption(data.payoutOption || 'earnMoney');

          // Überprüfen, ob `offers` und `offers[0]` vorhanden sind
          if (data.offers && data.offers.length > 0) {
            setEarnAmount(data.offers[0].earnAmount || 24); // Sicherer Zugriff auf `earnAmount`
            setSelectedFeatures(data.offers[0].features || []); // Sicherer Zugriff auf `features`
          } else {
            // Setze Standardwerte, falls keine Angebote vorhanden sind.
            setEarnAmount(24);
            setSelectedFeatures([]);
          }

          setServiceType(data.service || 'defaultService');
        } else {
          setError('No settings found.');
        }
      } catch (error) {
        console.error('Failed to fetch page data:', error);
        setError('Failed to load existing settings: ' + error.message);
        onError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pageId]);  // Abhängigkeit, um bei Änderung der `pageId` neu zu laden

  useEffect(() => {
    onDisableNext(selectedFeatures.length < 3); // Aktiviere oder deaktiviere den "Next"-Button basierend auf der Anzahl der ausgewählten Features
  }, [selectedFeatures, onDisableNext]);  // Abhängigkeiten nur auf selectedFeatures und onDisableNext beschränken

  const updateSliderBackground = () => {
    const slider = sliderRef.current;
    if (slider) {
      const value = (slider.value - slider.min) / (slider.max - slider.min) * 100;
      slider.style.background = `linear-gradient(to right, #000000 0%, #0000FF ${value}%, #ddd ${value}%, #ddd 100%)`;
    }
  };

  const handleServiceClick = (feature) => {
    const isSelected = selectedFeatures.includes(feature.name);
    if (!isSelected && selectedFeatures.length >= 6) {
      // Zeige eine Fehlermeldung, wenn mehr als 6 Features ausgewählt werden sollen
      return; // Verhindere das Hinzufügen neuer Features
    }
    const newSelectedFeatures = isSelected
      ? selectedFeatures.filter(f => f !== feature.name)  // Entferne das Feature, wenn es schon ausgewählt war
      : [...selectedFeatures, feature.name];  // Füge das neue Feature hinzu, wenn es noch nicht ausgewählt war
    setSelectedFeatures(newSelectedFeatures);
    savePageData(newSelectedFeatures, earnAmount);
  };

  const debouncedSaveEarnAmount = useCallback(debounce((amount) => {
    if (pageId) {
      const updates = {
        'offers/0/features': selectedFeatures,
        'offers/0/earnAmount': amount,  // Verwendet immer den gleichen Schlüssel
      };
      update(databaseRef(database, `Pages/${pageId}/pageData`), updates).catch(error => {
        console.error('Failed to save page data:', error);
        setError('Failed to save settings: ' + error.message);
      });
    }
  }, 1000), [pageId, selectedFeatures]); // Dependencies aktualisiert
  
  const handleEarnAmountChange = (event) => {
    let newAmount = parseInt(event.target.value, 10);
  
    if (payoutOption === 'earnMoney') {
      // Setze sicher, dass newAmount in Schritten von 5 bleibt, beginnend bei 4
      newAmount = Math.floor((Math.max(newAmount, 4) - 4) / 5) * 5 + 4;
      // Setze das Maximum auf 500
      newAmount = Math.min(newAmount, 500);
    } else if (payoutOption === 'License') {
      // Setze ein Minimum von 1 für License
      newAmount = Math.max(newAmount, 1);
    } else if (payoutOption === 'earnRoyalties') {
      // Setze ein Minimum von 1 für Royalties
      newAmount = Math.max(newAmount, 1);
    }
  
    setEarnAmount(newAmount);
    debouncedSaveEarnAmount(newAmount);
  
    // Print statements for debugging
  };
  
  

  const savePageData = (features, amount = earnAmount) => {
    if (pageId) {
      const updates = {
        'offers/0/features': features,
        'offers/0/earnAmount': amount,        
      };
      update(databaseRef(database, `Pages/${pageId}/pageData`), updates).catch(error => console.error('Failed to save page data:', error));
    }
  };

  const handleLicenseTypeChange = () => {
    setLicenseType(prevType => prevType === 'standard' ? 'exclusive' : 'standard');
    saveLicenseTypeData(licenseType === 'standard' ? 'exclusive' : 'standard');
  };

  const saveLicenseTypeData = (licenseType) => {
    if (pageId) {
      const updates = { 'offers/0/licenseType': licenseType };
      update(databaseRef(database, `Pages/${pageId}/pageData`), updates).catch(error => {
        console.error('Failed to save license type:', error);
        setError('Failed to save settings: ' + error.message);
      });
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      const updateBackground = () => {
        var value = (slider.value - slider.min) / (slider.max - slider.min) * 100;
        slider.style.background = `linear-gradient(to right, #000000 0%, #0000FF ${value}%, #ddd ${value}%, #ddd 100%)`;
      };

      updateBackground();  // Aktualisiere den Hintergrund sofort nach dem Rendern
      slider.addEventListener('input', updateBackground);

      // Bereinigungsfunktion, um den Event Listener zu entfernen
      return () => {
        slider.removeEventListener('input', updateBackground);
      };
    }
  }, [earnAmount]);  // Abhängigkeit zu earnAmount, um sicherzustellen, dass die Funktion bei Änderung des Werts erneut ausgeführt wird

  const renderLicenseTypeToggle = () => {
    if (payoutOption === 'License') {
      return (
        <div className="license-type-toggle">
          <button 
            className={`license-button ${licenseType === 'standard' ? 'active' : ''}`} 
            onClick={() => handleLicenseTypeChange('standard')}>
            <img src={standardLicenseImage} alt="Standard License" />
            <span>Standard License</span>
          </button>
          <button 
            className={`license-button ${licenseType === 'exclusive' ? 'active' : ''}`} 
            onClick={() => handleLicenseTypeChange('exclusive')}>
            <img src={exclusiveLicenseImage} alt="Exclusive License" />
            <span>Exclusive License</span>
          </button>
        </div>
      );
    }
    return null;
  };

  if (loading) {
    return <div className="loading"><LoadingScreen /></div>;
  }

  const renderPayoutInput = () => {
    if (['earnRoyalties', 'earnMoney', 'License'].includes(payoutOption)) {
      let label;
      let description;
      if (payoutOption === 'earnRoyalties') {
        label = 'Set Royalties';
        description = "Set the percentage of royalties you'll earn from collaborations.";
      } else if (payoutOption === 'earnMoney') {
        label = 'Set your Pricing';
        description = "Define how much you want to earn for your service";
      } else if (payoutOption === 'License') {
        label = 'Price of each License';
        description = "Set the price for licensing each of your songs or compositions.";
      }

      return (
        <>
    <div className="gig-settings-price-slider">
      <label htmlFor="earnAmount">{label}</label>
      <p>{description}</p>
      <div>
        <input
          type="range"
          id="earnAmount"
          max={payoutOption === 'earnRoyalties' ? 100 : 500}
          value={earnAmount}
          onChange={handleEarnAmountChange}
          ref={sliderRef}
          step={payoutOption === 'earnMoney' ? 5 : 1}
          className="custom-range-slider"
        />
        <span>{payoutOption === 'earnRoyalties' ? `${earnAmount}%` : `$${earnAmount}`}</span>
      </div>
    </div>
        </>
      );
    }
    return null;
  };

  return (
    <div>
      <div className="create-earn-settings-container">
        <div className="create-earn-settings-earn-amount">
          {renderPayoutInput()}
          {renderLicenseTypeToggle()}
        </div>
        <ServiceBasedContentSelector 
          serviceType={serviceType} 
          onServiceClick={handleServiceClick} 
          selectedFeatures={selectedFeatures}
        />
      </div>
    </div>
  );
};

export default EarnMoneySettings;
