import React from 'react';
import './SpotifySongs.css'; // Stellen Sie sicher, dass Sie den Pfad zu Ihrer CSS-Datei korrekt setzen

function extractSpotifyId(spotifyUrl) {
  if (spotifyUrl.startsWith('https')) {
    const match = spotifyUrl.match(/track\/([a-zA-Z0-9]{22})/);
    return match ? match[1] : null;
  }
  return spotifyUrl; // Rückgabe der ID, falls die URL keine vollständige URL war
}

function SpotifySongs({ spotifyUrl }) {
  const spotifyId = extractSpotifyId(spotifyUrl);

  return (
    <div className="spotify-embed-container">
      <iframe
        src={`https://open.spotify.com/embed/track/${spotifyId}?utm_source=generator`}
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </div>
  );
}

export default SpotifySongs;
