import React, { useState, useEffect } from 'react';
import { ref, push, set, get, remove } from 'firebase/database';
import { database } from '../../firebaseConfig';
import SettingsSingleInput from '../PageUtility/SettingsSingleInput';
import HeaderLabel from '../PageUtility/HeaderLabel';
import CategoryLabel from '../PageUtility/CategoryLabel';
import './PageQaSettings.css';

function PageQaSettings({ pageId, widgetKey }) {
    const [questionsAnswers, setQuestionsAnswers] = useState([]);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');

    useEffect(() => {
        const qaRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/questionsAnswers`);
        get(qaRef).then((snapshot) => {
            if (snapshot.exists()) {
                setQuestionsAnswers(Object.entries(snapshot.val()).map(([key, value]) => ({ id: key, ...value })));
            }
        });
    }, [pageId, widgetKey]);

    const handleAddQa = () => {
        if (question.trim() === '' || answer.trim() === '') {
            alert('Both question and answer must be provided.');
            return;
        }
        const newQa = { question, answer };
        const newRef = push(ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/questionsAnswers`));
        set(newRef, newQa).then(() => {
            setQuestionsAnswers([...questionsAnswers, { id: newRef.key, ...newQa }]);
            setQuestion('');
            setAnswer('');
        }).catch(error => {
            console.error('Failed to add Q&A:', error);
        });
    };

    const handleDeleteQa = (id) => {
        const qaRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/questionsAnswers/${id}`);
        remove(qaRef).then(() => {
            setQuestionsAnswers(questionsAnswers.filter(qa => qa.id !== id));
        }).catch(error => {
            console.error('Failed to delete Q&A:', error);
        });
    };

    return (
        <div>
            <HeaderLabel text="Questions & Answers" />
            <CategoryLabel text="Manage Your Questions and Answers"/>
            {questionsAnswers.map((qa) => (
                <div className="page-qa-settings-stored" key={qa.id}>
                    <div>Q: {qa.question}</div>
                    <div style={{color:'var(--red)'}}>A: {qa.answer}</div>
                    <button onClick={() => handleDeleteQa(qa.id)}><img src="../../img/delete.svg" alt="Delete" /></button>
                </div>
            ))}
            <div>
                <SettingsSingleInput
                    type="text"
                    value={question}
                    onChange={e => setQuestion(e.target.value)}
                    placeholder="Enter Question"
                    labelText="Question"
                />
                <SettingsSingleInput
                    type="text"
                    value={answer}
                    onChange={e => setAnswer(e.target.value)}
                    placeholder="Enter Answer"
                    labelText="Answer"
                />
                <button className="add-qa-button" onClick={handleAddQa}>Add Question and Answer</button>
            </div>
        </div>
    );
}

export default PageQaSettings;
