// ArtistTypes.js
import React from 'react';

function ExpertTypes({ onChange, value, className}) {
  // List of artist types
  const artistTypes = [
    'Record Label',    // Oversees the operations and strategy of a record label.
    'A&R',               // Scouts talent and oversees the artistic development of artists and bands.
    'Recording Engineer',        // Manages the technical aspects of recording music in a studio.
    'Mix Engineer',              // Specializes in mixing tracks to achieve the final sound of a recording.
    'Mastering Engineer',        // Performs final tweaks and prepares the final master for production.
    'Music Publisher',           // Manages copyrights and oversees the publication of music.
    'Booking Agent',             // Books artists for concerts, gigs, and tours.
    'Music Promotion',        // Manages promotional strategies for albums, singles, and artists.
    'Magazine/Blog',        // Oversees marketing campaigns and strategies for artists and music releases.
    'Influencer',      // Manages the distribution of music across various channels.
    'Sync Licensing',      // Secures placements of music in films, TV shows, commercials, and games.
    'Gig Promoter',
    'Music Manager',          // Organizes and promotes live music events.    // Organizes and promotes live music events.
    'Online Marketing',  // Manages online presence and digital marketing for music releases.
    'Merchandising Manager',     // Oversees the production and sale of artist merchandise.
    'Music Video Producer', 
    'Graphics and Visuals',      // Produces music videos for artists signed to the label.    // Produces music videos for artists signed to the label.
    'Legal Affairs',     // Manages legal matters for the label, dealing with contracts and rights issues.
];

  return (
    <select onChange={onChange} value={value} className={className}>
      <option value="">Select Expert Type...</option>
      {artistTypes.map((type, index) => (
        <option key={index} value={type}>{type}</option>
      ))}
    </select>
  );
}

export default ExpertTypes;
