import React, { useState, useEffect } from 'react';
import { ref as databaseRef, update, get, query, orderByChild, equalTo } from 'firebase/database';
import { database } from "../firebaseConfig";
import ChartlistAndAwardHelper from '../Utility/ChartlistAndAwardHelper';
import ReviewGeneratorSong from './ReviewGeneratorSong';

function ChartlistAndAwardManager({ songId, chartlists = {}, isNominated, hasAward, closeModal }) {
  const [localChartlists, setLocalChartlists] = useState({
    ...chartlists,
    International: chartlists.International || false,
  });
  const [localIsNominated, setLocalIsNominated] = useState(isNominated || false);
  const [localHasAward, setLocalHasAward] = useState(hasAward || false);
  const [editorQuote, setEditorQuote] = useState('');
  const [awardNominatedInfo, setAwardNominatedInfo] = useState('');
  const [selectedCompliments, setSelectedCompliments] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [artistName, setArtistName] = useState('');
  const [artistCountry, setArtistCountry] = useState('');
  const [songName, setSongName] = useState('');
  const [genre, setGenre] = useState('');
  const [artistType, setArtistType] = useState('');
  const [artistId, setArtistId] = useState('');
  const [randomMessage, setRandomMessage] = useState('');
  const [moesardEmail, setMoesardEmail] = useState('');
  const [ladyEmail, setLadyEmail] = useState('');

  // Separate variables for MariusReview data
  const [mariusSenderUserId, setMariusSenderUserId] = useState('');
  const [mariusWaitTimeInHours, setMariusWaitTimeInHours] = useState('');

  // Separate variables for ValerieMessage data
  const [valerieSenderUserId, setValerieSenderUserId] = useState('');
  const [valerieWaitTimeInHours, setValerieWaitTimeInHours] = useState('');
  const [valerieMessages, setValerieMessages] = useState([]);  // Store multiple messages
  const [valerieMessage, setValerieMessage] = useState('');    // Store random selected message

  // Separate variables for AbdiMessage data
  const [abdiSenderUserId, setAbdiSenderUserId] = useState('');
  const [abdiWaitTimeInHours, setAbdiWaitTimeInHours] = useState('');
  const [abdiMessages, setAbdiMessages] = useState([]);  // Store multiple messages
  const [abdiMessage, setAbdiMessage] = useState('');    // Store random selected message

  // Separate variables for ReviewTriggerInbox data
  const [senderUserId, setSenderUserId] = useState('');
  const [waitTimeInHours, setWaitTimeInHours] = useState('');

  const [generatedSongReview, setGeneratedSongReview] = useState('');
  const [generatedMariusReview, setGeneratedMariusReview] = useState('');

  const editorName = 'Valerie Wavy';
  const editorCompany = 'Wavy Music Magazine';
  const editorImgUrl = './img/valerie_wavy.jpg';

  useEffect(() => {
    // Fetch song data
    const fetchSongData = async () => {
      const songRef = databaseRef(database, `Songs/${songId}`);
      try {
        const snapshot = await get(songRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          setEditorQuote(data.editorQuote || '');
          setAwardNominatedInfo(data.awardNominatedInfo || '');
          setArtistName(data.artist || 'the artist');
          setArtistCountry(data.selectedCountry || '');
          setSongName(data.name || 'the song');
          setGenre(data.genre || 'unknown genre');
          setArtistType(data.selectedArtistType || 'musician');
          setArtistId(data.artistID);
          setSelectedCompliments(data.selectedCompliments || []); 
        }
      } catch (error) {
        console.error('Error fetching artist and song data:', error);
      }
    };

    // Fetch messages, senderUserId, and waitTimeInHours from ReviewTriggerInbox, MariusReview, ValerieMessage, and AbdiMessage
    const fetchReviewMariusValerieAndAbdiData = async () => {
      try {
        // ReviewTriggerInbox data
        const messagesRef = databaseRef(database, `Tasks/ReviewTriggerInbox/messages`);
        const isActiveRef = databaseRef(database, `Tasks/ReviewTriggerInbox/isActive`); // Neue Referenz
        const senderUserIdRef = databaseRef(database, `Tasks/ReviewTriggerInbox/senderUserId`);
        const waitTimeInHoursRef = databaseRef(database, `Tasks/ReviewTriggerInbox/waitTimeInHours`);
    
        // MariusReview data
        const mariusSenderUserIdRef = databaseRef(database, `Tasks/MariusReview/senderUserId`);
        const mariusWaitTimeInHoursRef = databaseRef(database, `Tasks/MariusReview/waitTimeInHours`);
        const mariusIsActiveRef = databaseRef(database, `Tasks/MariusReview/isActive`); // Neue Referenz
    
        // ValerieMessage data
        const valerieMessagesRef = databaseRef(database, `Tasks/ValerieMessage/messages`);
        const valerieSenderUserIdRef = databaseRef(database, `Tasks/ValerieMessage/senderUserId`);
        const valerieWaitTimeInHoursRef = databaseRef(database, `Tasks/ValerieMessage/waitTimeInHours`);
        const valerieIsActiveRef = databaseRef(database, `Tasks/ValerieMessage/isActive`); // Neue Referenz
    
        // AbdiMessage data
        const abdiMessagesRef = databaseRef(database, `Tasks/AbdiMessage/messages`);
        const abdiSenderUserIdRef = databaseRef(database, `Tasks/AbdiMessage/senderUserId`);
        const abdiWaitTimeInHoursRef = databaseRef(database, `Tasks/AbdiMessage/waitTimeInHours`);
        const abdiIsActiveRef = databaseRef(database, `Tasks/AbdiMessage/isActive`); // Neue Referenz
    
        const [
          messagesSnapshot,
          isActiveSnapshot,  // Überprüfung, ob der Task aktiv ist
          senderUserIdSnapshot,
          waitTimeInHoursSnapshot,
          mariusSenderUserIdSnapshot,
          mariusWaitTimeInHoursSnapshot,
          mariusIsActiveSnapshot,  // Überprüfung für Marius
          valerieMessagesSnapshot,
          valerieSenderUserIdSnapshot,
          valerieWaitTimeInHoursSnapshot,
          valerieIsActiveSnapshot,  // Überprüfung für Valerie
          abdiMessagesSnapshot,
          abdiSenderUserIdSnapshot,
          abdiWaitTimeInHoursSnapshot,
          abdiIsActiveSnapshot  // Überprüfung für Abdi
        ] = await Promise.all([
          get(messagesRef),
          get(isActiveRef),
          get(senderUserIdRef),
          get(waitTimeInHoursRef),
          get(mariusSenderUserIdRef),
          get(mariusWaitTimeInHoursRef),
          get(mariusIsActiveRef),
          get(valerieMessagesRef),
          get(valerieSenderUserIdRef),
          get(valerieWaitTimeInHoursRef),
          get(valerieIsActiveRef),
          get(abdiMessagesRef),
          get(abdiSenderUserIdRef),
          get(abdiWaitTimeInHoursRef),
          get(abdiIsActiveRef)
        ]);
    
        // Nur laden, wenn der Task aktiv ist
        if (isActiveSnapshot.exists() && isActiveSnapshot.val()) {
          // Daten für ReviewTriggerInbox setzen
          if (messagesSnapshot.exists()) {
            const messages = Object.values(messagesSnapshot.val());
            if (messages.length > 0) {
              const randomIndex = Math.floor(Math.random() * messages.length);
              setRandomMessage(messages[randomIndex]);
            }
          }
    
          if (senderUserIdSnapshot.exists()) {
            setSenderUserId(senderUserIdSnapshot.val());
          }
    
          if (waitTimeInHoursSnapshot.exists()) {
            setWaitTimeInHours(waitTimeInHoursSnapshot.val());
          }
        }
    
        // Ähnliches für MariusReview, ValerieMessage und AbdiMessage mit der entsprechenden isActive-Überprüfung
        if (mariusIsActiveSnapshot.exists() && mariusIsActiveSnapshot.val()) {
          if (mariusSenderUserIdSnapshot.exists()) {
            setMariusSenderUserId(mariusSenderUserIdSnapshot.val());
          }
          if (mariusWaitTimeInHoursSnapshot.exists()) {
            setMariusWaitTimeInHours(mariusWaitTimeInHoursSnapshot.val());
          }
        }
    
        if (valerieIsActiveSnapshot.exists() && valerieIsActiveSnapshot.val()) {
          if (valerieMessagesSnapshot.exists()) {
            const messages = Object.values(valerieMessagesSnapshot.val());
            if (messages.length > 0) {
              const randomIndex = Math.floor(Math.random() * messages.length);
              setValerieMessage(messages[randomIndex]);
            }
          }
          if (valerieSenderUserIdSnapshot.exists()) {
            setValerieSenderUserId(valerieSenderUserIdSnapshot.val());
          }
          if (valerieWaitTimeInHoursSnapshot.exists()) {
            setValerieWaitTimeInHours(valerieWaitTimeInHoursSnapshot.val());
          }
        }
    
        if (abdiIsActiveSnapshot.exists() && abdiIsActiveSnapshot.val()) {
          if (abdiMessagesSnapshot.exists()) {
            const messages = Object.values(abdiMessagesSnapshot.val());
            if (messages.length > 0) {
              const randomIndex = Math.floor(Math.random() * messages.length);
              setAbdiMessage(messages[randomIndex]);
            }
          }
          if (abdiSenderUserIdSnapshot.exists()) {
            setAbdiSenderUserId(abdiSenderUserIdSnapshot.val());
          }
          if (abdiWaitTimeInHoursSnapshot.exists()) {
            setAbdiWaitTimeInHours(abdiWaitTimeInHoursSnapshot.val());
          }
        }
    
      } catch (error) {
        console.error('Error fetching ReviewTriggerInbox, MariusReview, ValerieMessage or AbdiMessage data:', error);
      }
    };
    

    fetchSongData();
    fetchReviewMariusValerieAndAbdiData();
  }, [songId]);


  const saveSettings = async () => {
    let songUpdates = {
      isNominated: localIsNominated,
      hasAward: localHasAward,
      nominatedDate: localIsNominated ? new Date().toISOString() : null,
      awardDate: localHasAward ? new Date().toISOString() : null,
      editorName,
      editorCompany,
      editorQuote,
      editorImg: './img/valerie_wavy.jpg',
      selectedCompliments,
      chartlists: localChartlists,
    };
  
    if (localIsNominated || localHasAward) {
      songUpdates.awardNominatedInfo = awardNominatedInfo;
    } else {
      songUpdates.awardNominatedInfo = '';
    }
  
    const hasPlaylist = Object.values(localChartlists).some((value) => value === true);
  
    try {
      // Update song data in Firebase
      await update(databaseRef(database, `Songs/${songId}`), { ...songUpdates, hasPlaylist });
      setSuccessMessage('Settings saved successfully.');
  
      if (!artistId) {
        console.error('Error: artistId is not defined');
        return;
      }
  
      // Fetch the artist's email
      const artistRef = databaseRef(database, `Artists/${artistId}/email`);
      const artistSnapshot = await get(artistRef);
      let recipientEmail = null;
  
      if (artistSnapshot.exists()) {
        recipientEmail = artistSnapshot.val();
      } else {
        console.error('Artist email not found');
        return; // If there's no email, stop further actions
      }
  
      // Check if awardOfferEmail is false or doesn't exist
      const awardOfferEmailRef = databaseRef(database, `Artists/${artistId}/awardOfferEmail`);
      const awardOfferEmailSnapshot = await get(awardOfferEmailRef);
      let awardOfferEmailSent = false;
  
      if (awardOfferEmailSnapshot.exists()) {
        awardOfferEmailSent = awardOfferEmailSnapshot.val();
      }
  
      // Only send the email if:
      // - The artist is nominated or awarded
      // - The awardOfferEmail is either false or doesn't exist
      if ((localIsNominated || localHasAward) && !awardOfferEmailSent) {
        // First, update `awardOfferEmail` to true in the database to prevent duplicate emails
        await update(databaseRef(database, `Artists/${artistId}`), { awardOfferEmail: true });
  
        // Fetch the dynamic email details from the database (Tasks/MoesardMail)
        const moesardMailRef = databaseRef(database, 'Tasks/MoesardMail');
        const moesardMailSnapshot = await get(moesardMailRef);
        const moesardIsActiveRef = databaseRef(database, 'Tasks/MoesardMail/isActive'); // New isActive check
        const moesardIsActiveSnapshot = await get(moesardIsActiveRef);
        if (!moesardMailSnapshot.exists() || !moesardIsActiveSnapshot.exists() || !moesardIsActiveSnapshot.val()) {
          console.error('MoesardMail data not found in the database');
          return;
        }
  
        const { subject, htmlSnippet, waitTimeInHours } = moesardMailSnapshot.val();
  
        // Send the Moesard email if applicable
        if (moesardEmail) {  // Only trigger the function if moesardEmail exists
          await fetch('https://us-central1-awards-13a46.cloudfunctions.net/emailScheduler', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              moesardEmail,  // Keep the existing Moesard email content
              htmlSnippet,   // Add the new HTML snippet to the email
              recipientEmail,  // Pass the artist's email as the recipient
              waitTimeInHours,  // Use the dynamically fetched wait time
              subject,  // Pass the dynamically fetched subject
              songId,  
              artistId,  // Optionally pass the songId if needed
            }),
          });
          console.log('Moesard email sent successfully');
        }
      }
      const ladyOfferEmailRef = databaseRef(database, `Artists/${artistId}/ladyOfferEmail`);
      const ladyOfferEmailSnapshot = await get(ladyOfferEmailRef);
      let ladyOfferEmailSent = false;
  
      if (ladyOfferEmailSnapshot.exists()) {
        ladyOfferEmailSent = ladyOfferEmailSnapshot.val();
      }
  
      if (!localIsNominated && !localHasAward && !ladyOfferEmailSent) {
        await update(databaseRef(database, `Artists/${artistId}`), { ladyOfferEmail: true });
        const ladyMailRef = databaseRef(database, 'Tasks/LadyMail');
        const ladyMailSnapshot = await get(ladyMailRef);
const ladyIsActiveRef = databaseRef(database, 'Tasks/LadyMail/isActive'); // New isActive check
const ladyIsActiveSnapshot = await get(ladyIsActiveRef);

if (!ladyMailSnapshot.exists() || !ladyIsActiveSnapshot.exists() || !ladyIsActiveSnapshot.val()) {
  console.error('LadyMail data not found or task not active');
  return;
}

        const { subject, htmlSnippet, waitTimeInHours } = ladyMailSnapshot.val();

        if (ladyEmail) {
          await fetch('https://us-central1-awards-13a46.cloudfunctions.net/emailScheduler', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              ladyEmail,      // This would be the content related to LadyMail
              htmlSnippet,    // HTML snippet from the LadyMail task
              recipientEmail, // Artist email fetched earlier
              waitTimeInHours,// Wait time for sending
              subject,        // Subject from LadyMail data
              songId,    
              artistId,      // ID of the song if needed
            }),
          });
          console.log('LadyMail sent successfully');
        }
      }

  
      // Check if chat already exists before making API calls
      const chatExistsForReviewTriggerInbox = await checkIfChatExists(senderUserId, artistId);
      const chatExistsForMariusReview = await checkIfChatExists(mariusSenderUserId, artistId);
      const chatExistsForValerieMessage = await checkIfChatExists(valerieSenderUserId, artistId);
      const chatExistsForAbdiMessage = await checkIfChatExists(abdiSenderUserId, artistId);
  
      // First API call for ReviewTriggerInbox
      if (!chatExistsForReviewTriggerInbox) {
        await fetch('https://us-central1-awards-13a46.cloudfunctions.net/messageScheduler', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            reviewedUserId: artistId,
            senderUserId: senderUserId,
            message: randomMessage,
            waitTimeInHours: waitTimeInHours,
          }),
        });
        console.log('First message (ReviewTriggerInbox) sent successfully');
      }
  
      // Second API call for ReviewTriggerMarius (generated Marius review)
      if (!chatExistsForMariusReview) {
        await fetch('https://us-central1-awards-13a46.cloudfunctions.net/messageScheduler', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            reviewedUserId: artistId,
            senderUserId: mariusSenderUserId,
            message: generatedMariusReview,
            waitTimeInHours: mariusWaitTimeInHours,
          }),
        });
        console.log('Second message (ReviewTriggerMarius) sent successfully');
      }
  
      // Third API call for ValerieMessage
      if (!chatExistsForValerieMessage) {
        await fetch('https://us-central1-awards-13a46.cloudfunctions.net/messageScheduler', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            reviewedUserId: artistId,
            senderUserId: valerieSenderUserId,
            message: valerieMessage,
            waitTimeInHours: valerieWaitTimeInHours,
          }),
        });
        console.log('Third message (ValerieMessage) sent successfully');
      }
  
      // Fourth API call for AbdiMessage
      if (!chatExistsForAbdiMessage) {
        await fetch('https://us-central1-awards-13a46.cloudfunctions.net/messageScheduler', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            reviewedUserId: artistId,
            senderUserId: abdiSenderUserId,
            message: abdiMessage,
            waitTimeInHours: abdiWaitTimeInHours,
          }),
        });
        console.log('Fourth message (AbdiMessage) sent successfully');
      }
  
      // Close the modal after all API calls
      closeModal();
  
    } catch (error) {
      console.error('Error saving settings or sending messages:', error);
    }
  };
  


  const handleChartlistToggle = (chartlist, isChecked) => {
    setLocalChartlists((prev) => ({ ...prev, [chartlist.name]: isChecked }));
  };

  const checkIfChatExists = async (senderUserId, reviewedUserId) => {
    // Erstellen der beiden möglichen Chat-Pfade
    const chatKey1 = `${senderUserId}_${reviewedUserId}`;  // Pfad 1: senderId_recipientId
    const chatKey2 = `${reviewedUserId}_${senderUserId}`;  // Pfad 2: recipientId_senderId

    try {
        // Überprüfen, ob beide Benutzer (Sender und Empfänger) in der Artists-Datenbank existieren
        const senderRef = databaseRef(database, `Artists/${senderUserId}`);
        const recipientRef = databaseRef(database, `Artists/${reviewedUserId}`);
      
        const [senderSnapshot, recipientSnapshot] = await Promise.all([get(senderRef), get(recipientRef)]);
      
        if (!senderSnapshot.exists()) {
            console.log(`Skipping operation. Sender ${senderUserId} does not exist in the database.`);
            return false; // Abbrechen, wenn der Sender nicht existiert
        }
      
        if (!recipientSnapshot.exists()) {
            console.log(`Skipping operation. Recipient ${reviewedUserId} does not exist in the database.`);
            return false; // Abbrechen, wenn der Empfänger nicht existiert
        }

        // Referenzen zu beiden möglichen Chat-Pfaden in der Datenbank
        const chatRef1 = databaseRef(database, `Chats/${chatKey1}`);
        const chatRef2 = databaseRef(database, `Chats/${chatKey2}`);
      
        // Parallel prüfen, ob einer der beiden Pfade existiert
        const [chatSnapshot1, chatSnapshot2] = await Promise.all([
            get(chatRef1),
            get(chatRef2)
        ]);

        // Ausgabe von Logs zur Fehlersuche
        console.log(`Chat ${chatKey1} exists:`, chatSnapshot1.exists());
        console.log(`Chat ${chatKey2} exists:`, chatSnapshot2.exists());

        // Wenn einer der beiden Pfade existiert, gibt es den Chat
        return chatSnapshot1.exists() || chatSnapshot2.exists();
    } catch (error) {
        console.error('Error checking chat existence:', error);
        return false; // Bei einem Fehler annehmen, dass kein Chat existiert
    }
};

  
  
  
  const toggleOption = (option) => {
    if (option === "Nominated") {
      const newNominatedState = !localIsNominated;
      setLocalIsNominated(newNominatedState);
      if (!newNominatedState) {
        setLocalHasAward(false);
        setAwardNominatedInfo('');
      } else {
        setLocalChartlists((prev) => ({ ...prev, Nominees: true }));
      }
    } else if (option === "Awarded") {
      const newAwardState = !localHasAward;
      setLocalHasAward(newAwardState);
      if (!newAwardState) {
        setLocalIsNominated(false);
        setAwardNominatedInfo('');
      } else {
        setLocalChartlists((prev) => ({ ...prev, AwardWinners: true }));
      }
    }
  };

  const handleAwardNominatedInfoChange = (value) => {
    setAwardNominatedInfo(awardNominatedInfo === value ? '' : value);
  };

  const handleComplimentToggle = (title) => {
    setSelectedCompliments((prev) =>
      prev.includes(title) ? prev.filter((item) => item !== title) : [...prev, title]
    );
  };

  const generateSummary = () => {
    const chartlistDisplayNames = {
      Editor: 'Editor Picks',
      AwardWinners: 'Award Winners',
      International: 'Global 100 Chartlist',
      Newcomers: 'Newcomers',
      Hiphop: 'Hiphop',
      PopRadio: 'Pop Radio',
      Nominees: 'Nominees',
    };

    let summary = `${artistName}, a talented ${artistType} from ${artistCountry}, has created the song "${songName}".`;

    if (localIsNominated || localHasAward) {
      summary += ` ${artistName} has been ${localHasAward ? 'awarded' : 'nominated'} at the Triple A awards for their outstanding work in the ${genre} genre.`;

      if (awardNominatedInfo) {
        summary += ` They were recognized for ${awardNominatedInfo}. `;
      }
    }

    const selectedChartlists = Object.keys(localChartlists)
      .filter((key) => localChartlists[key])
      .map((key) => chartlistDisplayNames[key]);

    if (selectedChartlists.length > 0) {
      summary += `${artistName}'s song "${songName}" has been placed in the following chartlists: ${selectedChartlists.join(', ')}. `;
    }

    const selectedComplimentDescriptions = selectedCompliments.join(', ');

    if (selectedComplimentDescriptions.length > 0) {
      summary += `What stands out about the song is: ${selectedComplimentDescriptions}.`;
    }

    return summary;
  };

  

  return (
    <div style={{display: 'flex'}}>
      <div>
        <ReviewGeneratorSong songId={songId} />
      </div>
  <ChartlistAndAwardHelper
      localChartlists={localChartlists}
      localIsNominated={localIsNominated}
      localHasAward={localHasAward}
      awardNominatedInfo={awardNominatedInfo}
      selectedCompliments={selectedCompliments}
      toggleOption={toggleOption}
      handleChartlistToggle={handleChartlistToggle}
      handleAwardNominatedInfoChange={handleAwardNominatedInfoChange}
      handleComplimentToggle={handleComplimentToggle}
      saveSettings={saveSettings}
      successMessage={successMessage}
      setEditorQuote={setEditorQuote}
      generateSummary={generateSummary}
      editorQuote={editorQuote}
      artistName={artistName}
      songName={songName}
      editorImgUrl={editorImgUrl}
      generatedMariusReview={generatedMariusReview}        
      setGeneratedMariusReview={setGeneratedMariusReview}
      generatedSongReview={generatedSongReview}       
      setGeneratedSongReview={setGeneratedSongReview} 
      setMoesardEmail={setMoesardEmail} 
      setLadyEmail={setLadyEmail} 
      ladyEmail={ladyEmail}
       />
    </div>
  
  );
}

export default ChartlistAndAwardManager;
