import React, { useState, useEffect } from 'react';
import { auth, database, storage } from '../firebaseConfig';
import { ref as databaseRef, update, get } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import SubGenreList from '../Utility/SubGenreList';
import ArtistTypes from '../Utility/ArtistTypes';
import './WelcomeStepper.css'
import { Link, useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import { compressImage } from '../Utility/ImageCompressor';
import ExpertTypes from '../Utility/ExpertTypes';
import { motion } from 'framer-motion';
import ToggleSwitch from '../Utility/ToggleSwitch';
import './DashboardPage.css'
import GenerativeOrbAnimation from '../Utility/GenerativeOrbAnimation';
import GenerativeOrbAnimation2 from '../Utility/GenerativeOrbAnimation2';
import { useAppContext } from '../Utility/AppContext';  // Passe den Pfad entsprechend an

function ExpertStepper() {

  const initialServices = [
    { name: 'discoverTalent', title: 'Discover New Talent', checked: false },
    { name: 'paidServices', title: 'Offer Your Services', checked: false },
    { name: 'reviewArtists', title: 'Review Music', checked: false },
    { name: 'offerMentorship', title: 'Offer Mentorship', checked: false },
  ];
  const navigate = useNavigate(); // Initialisiere useNavigate hier
  const { setUserData } = useAppContext(); // Hole setAvatar und setUserData aus dem Kontext

  
  const [currentStep, setCurrentStep] = useState(1);
  const [artistData, setArtistData] = useState({
    artistName: '',
    name: '',
    genre: '',
    genre: [],
    experience: '',
    socialMedia: '', 
    website: '',
    expertType: '',
    artistImageUrl: '',
    companyLogoUrl: '',
    country: '',
    discoverTalent: false,
    paidServices: false,
    reviewArtists: false,
    offerMentorship: false,
    sendExpertWelcome: true,
  });
  const [uploading, setUploading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
const [agreedToPrivacy, setAgreedToPrivacy] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [services, setServices] = useState(initialServices);
  const [formErrors, setFormErrors] = useState({});
  const [logoPreview, setLogoPreview] = useState(null);
  const [websiteError, setWebsiteError] = useState('');
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const genres = [
    "Hiphop/Rap", "Pop", "Rock", "EDM", "Country", "R&B/Soul", "Reggae",
    "House", "Techno", "K-pop", "Latin", "Metal",
    "Folk", "Punk", "Indie", "Alternative",
    "Singer/Songwriter", "Funk", "Trap", "Jazz", "Other"
];

const validateWebsite = (url) => {
    if (url && !url.match(/^(https:\/\/|www\.).+/)) {
      setWebsiteError("Website must start with 'www.' or 'https://'.");
    } else {
      setWebsiteError('');
    }
  };
  
  

  const toggleGenre = (genre) => {
    setArtistData(prev => ({
      ...prev,
      genre: prev.genre.includes(genre) ?
        prev.genre.filter(m => m !== genre) : // Entferne den Mood, wenn er bereits ausgewählt war
        (prev.genre.length < 6 ? [...prev.genre, genre] : prev.genre) // Füge den Mood hinzu, nur wenn weniger als 4 ausgewählt sind
    }));
  };
  

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
  
    // Beispielvalidierung für das Künstlerbild
    if (!artistData.artistImageUrl) {
      errors.artistImageUrl = "Bitte laden Sie ein Bild hoch.";
      formIsValid = false;
    }
  
    // Fügen Sie hier weitere Validierungen hinzu...
  
    setFormErrors(errors);
    return formIsValid;
  };


// Ensure SubGenreList's onChange prop is correctly handled
const handleGenreChange = (event) => {
  const { value } = event.target;
  setArtistData(prevData => ({
    ...prevData,
    genre: value,
  }));
};

const toggleService = (index) => {
  const newServices = services.map((service, i) => {
    if (i === index) {
      return { ...service, checked: !service.checked };
    }
    return service;
  });

  setServices(newServices);
  // Also update the artistData state accordingly
  const updatedData = { ...artistData };
  newServices.forEach((service) => {
    updatedData[service.name] = service.checked;
  });
  setArtistData(updatedData);
};



// For artistType, directly use a select element with options mapped from artistTypes array
const handleArtistTypeChange = (event) => {
  const { value } = event.target;
  setArtistData(prevData => ({
    ...prevData,
    expertType: value,
  }));
};


// For country, directly use a select element with options mapped from countries array
const handleCountryChange = (event) => {
  const { value } = event.target;
  setArtistData(prevData => ({
    ...prevData,
    country: value,
  }));
};


useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged(user => {
    if (user) {
      setUserId(user.uid);
    } else {
      setUserId(null);
    }
  });

  return () => unsubscribe(); // Cleanup-Abonnement, wenn die Komponente demontiert wird
}, []);


useEffect(() => {
  if (auth.currentUser) {
    const userId = auth.currentUser.uid;
    // Pfad zur Überprüfung der Benutzereinstellungen in Firebase
    const settingsRef = databaseRef(database, `/Artists/${userId}/hasExpertSettings`);
    get(settingsRef).then((snapshot) => {
      if (snapshot.exists() && snapshot.val() === true) {
        // Benutzer hat die Einstellungen bereits konfiguriert
        navigate('/dashboard'); // Weiterleitung zum Dashboard
      }
      // Falls 'hasSettings' nicht true ist oder nicht existiert, bleibt der Benutzer im WelcomeStepper
    }).catch((error) => {
      console.error("Fehler beim Abrufen der Benutzereinstellungen:", error);
    });
  }
}, [navigate]);


  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setArtistData(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  };

  

  const handleImageChange = async (e, imageType) => {
    e.preventDefault();
    const file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];
  
    if (!file) {
      alert("Please select a file.");
      return;
    }
  
    const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!validTypes.includes(file.type)) {
      alert("Only JPG, PNG, and WEBP formats are allowed.");
      if (imageType === 'artistImage') {
        setUploading(false);
      } else if (imageType === 'companyLogo') {
        setUploadingLogo(false);
      }
      return;
    }
  
    if (file.size > 10 * 1024 * 1024) { // 10 MB
      alert("The file size exceeds the maximum limit of 10MB.");
      if (imageType === 'artistImage') {
        setUploading(false);
      } else if (imageType === 'companyLogo') {
        setUploadingLogo(false);
      }
      return;
    }
  
    if (imageType === 'artistImage') {
      setUploading(true);
    } else if (imageType === 'companyLogo') {
      setUploadingLogo(true);
    }
  
    const reader = new FileReader();
  
    reader.onloadend = () => {
      if (imageType === 'artistImage') {
        setImagePreview(reader.result);
      } else if (imageType === 'companyLogo') {
        setLogoPreview(reader.result);
      }
  
      // Komprimieren und Größe des Bildes ändern, Konvertierung in JPEG
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        'JPEG',
        50,
        0,
        async (uri) => {
          const byteString = atob(uri.split(',')[1]);
          const mimeString = uri.split(',')[0].split(':')[1].split(';')[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ab], { type: mimeString });
  
          const imagePath = imageType === 'artistImage' ? `artistImages/${auth.currentUser.uid}` : `companyLogos/${auth.currentUser.uid}`;
          const fileRef = storageRef(storage, `${imagePath}/${new Date().getTime()}.jpeg`);
          
          try {
            const uploadResult = await uploadBytes(fileRef, blob);
            const imageUrl = await getDownloadURL(uploadResult.ref);
            if (imageType === 'artistImage') {
              setArtistData(prevData => ({ ...prevData, artistImageUrl: imageUrl }));
            } else if (imageType === 'companyLogo') {
              setArtistData(prevData => ({ ...prevData, companyLogoUrl: imageUrl }));
            }
          } catch (error) {
            console.error("Fehler beim Hochladen des Bildes: ", error);
            alert("An error occurred while uploading the image.");
          } finally {
            if (imageType === 'artistImage') {
              setUploading(false);
            } else if (imageType === 'companyLogo') {
              setUploadingLogo(false);
            }
          }
        },
        'base64'
      );
    };
    reader.readAsDataURL(file);
  };
  

  const handleDragOver = (e) => e.preventDefault();

  const handleDrop = (e) => {
    e.preventDefault();
    handleImageChange(e);
  };

  const serviceImages = {
    discoverTalent: '../img/music.svg',
    paidServices: '../img/auction.svg',
    reviewArtists: '../img/verses.svg',
    offerMentorship: '../img/hands.svg',
  };

  const isFormValidForCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return artistData.artistName.trim().length >= 3 && artistData.name.trim().length >= 3;
        case 2:
        return artistData.country.trim() !== '' && artistData.expertType.trim() !== '' !== '' && artistData.experience.trim() !== '';
      case 3:
        return imagePreview !== null; // Stelle sicher, dass ein Bild hochgeladen wurde
      case 4:
        return artistData.country.trim() !== '' && artistData.country !== 'Select Country'; // Stelle sicher, dass ein Land ausgewählt wurde
      case 5:
        // Optional: Prüfe, ob mindestens ein Service ausgewählt wurde
        return Object.values(artistData).some(value => typeof value === "boolean" && value);
      default:
        return true;
    }
  };
  
  
const renderServiceOptions = () => {
  return (
    <div className="service-options-container">
      {services.map((service, index) => (
        <div
          key={service.name}
          className={`service-option ${service.checked ? 'checked' : ''}`}
          onClick={() => toggleService(index)}
        >
          <img src={serviceImages[service.name]} alt={service.title} />
          <p>{service.title}</p>
        </div>
      ))}
    </div>
  );
};

const handleBack = () => {
  setCurrentStep(currentStep - 1);
};


  const countries = [
    'China', 'India', 'United States', 'Indonesia', 'Pakistan',
    'Brazil', 'Nigeria', 'Bangladesh', 'Russia', 'Mexico',
    'Japan', 'Ethiopia', 'Philippines', 'Egypt', 'Vietnam',
    'Turkey', 'Iran', 'Germany', 'Thailand',
    'United Kingdom', 'France', 'Italy', 'South Africa',
    'Myanmar', 'Kenya', 'South Korea', 'Colombia', 'Spain',
    'Uganda', 'Argentina', 'Algeria', 'Sudan', 'Ukraine',
    'Iraq', 'Afghanistan', 'Poland', 'Canada', 'Morocco',
    'Austria', 'Belgium', 'Croatia', 'Denmark', 'Estonia',
    'Finland', 'Greece', 'Hungary', 'Ireland', 'Latvia',
    'Lithuania', 'Luxembourg', 'Netherlands', 'Norway', 'Portugal',
    'Romania', 'Slovakia', 'Slovenia', 'Sweden', 'Switzerland', 'Other'
  ];

  const sortedCountries = countries.sort();
  const countryOptions = ['Select Country', ...sortedCountries];

  const shakeAnimation = {
    x: [0, -5, 5, -5, 5, 0],
    y: [0, -2, 2, -2, 2, 0],
    rotate: [0, -2, 2, -2, 2, 0],
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  };  




  const handleSubmit = async () => {
    if (!userId) {
      console.error("No user signed in");
      return;
    }
    if (!validateForm()) {
      console.error("Please fill out all required forms");
      return;
    }

    if (!artistData.artistImageUrl) {
      console.error("Please upload a picture.");
      return;
    }

    try {
      await update(databaseRef(database, `Artists/${userId}`), {
        ...artistData,
        hasExpertSettings: true,
        isExpert: true,
        isApproved: false,
      });
      console.log("Settings saved successfully.");
      
      setUserData(prevData => ({
        ...prevData,
        ...artistData,
        isExpert: true,
        hasExpertSettings: true,
        isApproved: false,
      }));
      
      navigate('/dashboard');
    } catch (error) {
      console.error("Fehler beim Speichern der Einstellungen: ", error);
    }
  };

  

  // Hier kommt die UI für die einzelnen Schritte
  return (
    <div className="welcome-stepper-container" style={{ display: 'flex', minHeight: '100vh', }}>
                  <GenerativeOrbAnimation2 />
        <div className="song-stepper-header">
            <img src="./img/logo.svg" alt="" />
            <Link to="/">
            <div>
                <p style={{fontSize: '14px', marginTop: '17px'}}></p>
                    <img src="./img/delete.svg" alt="" />
                    </div>
                </Link>
                        </div>
      
      <div className="welcome-stepper-wrapper" >

      <div className="left-side-stepper" style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* Hier könnten Sie Beschreibungstexte oder Bilder basierend auf dem aktuellen Schritt einfügen */}
        <div>
          {currentStep === 1 && 
          <div className="left-welcome-side">
            <h2>Join the Inner Circle</h2>
          <p>Collaborate with top talent and amplify your music projects to unparalleled success.</p>
          </div>
          }
          {currentStep === 2 &&
                    <div className="left-welcome-side">
                                  <h2>Company Details</h2>
          <p>Drop a pin on your country and set your industry journey.</p>
          </div>
          }
          {currentStep === 3 && 
                              <div className="left-welcome-side">
                                        <h2>Profile Picture</h2>
                                        <p>A snapshot that introduces you to our community.</p>
        </div>
        }
          {currentStep === 4 && 
                                        <div className="left-welcome-side">
                                          <h2>Upload Logo</h2>
          <p>Add your company logo to elevate your profile's appeal.</p>
          </div>
          }
          {currentStep === 5 && 
       <div className="left-welcome-side">
                                          <h2>Genre Selection</h2>
          <p>Pick your favorite genres to tailor your experience.</p>
          </div>
          }

{currentStep === 6 && 
       <div className="left-welcome-side">
                                          <h2>Your Channels</h2>
          <p>Add your website and social media for others to explore.</p>
          </div>
          }
           {currentStep === 7 && 
       <div className="left-welcome-side">
                                          <h2>Choose Free Tools</h2>
          <p>What else are you interested in?</p>
          </div>
          }
        </div>
      </div>
      <div className="stepper-wrapper" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {/* Schritt-basierte Inhalte */}
        {currentStep === 1 && (
        <div className="select-genre-div" id="first-jury-stepper">
        <label>Company Name:</label>
        <input
          type="text"
          name="artistName"
          value={artistData.artistName}
          onChange={handleChange}
          placeholder="e.g. Triple A Records"
        />
         <label>Name:</label>
    <input
      type="text"
      name="name"
      value={artistData.name}
      onChange={handleChange}
      placeholder="e.g. Luke Mayer"
    />
        <button className="welcome-next-button" onClick={handleNext} disabled={!isFormValidForCurrentStep()}>Next</button>
      </div>
      
    )}
{currentStep === 2 && (
  <div className="select-genre-div">
    <label>Country:</label>
    <select className="select-genre-list" name="country" value={artistData.country} onChange={handleCountryChange}>
      {countryOptions.map(country => (
        <option key={country} value={country === 'Select Country' ? '' : country}>{country}</option>
      ))}
    </select>

    <label>Company Type:</label>
    <ExpertTypes className="select-genre-list" value={artistData.expertType} onChange={handleArtistTypeChange} />

    <label>Work Experience:</label>
    <select className="select-genre-list" name="experience" value={artistData.experience} onChange={(e) => setArtistData({...artistData, experience: e.target.value})}>
      <option value="" disabled selected>Add Experience</option>
      <option value="0-1 Year">0-1 Year</option>
      <option value="1-3 Years">1-3 Years</option>
      <option value="3-5 Years">3-5 Years</option>
      <option value="5-10 Years">5-10 Years</option>
      <option value="10+ Years">10+ Years</option>
    </select>

    <button className="welcome-back-button" onClick={handleBack}>Back</button>
    <button className="welcome-next-button" onClick={handleNext} disabled={!isFormValidForCurrentStep()}>Continue</button>
  </div>
)}



{currentStep === 3 && (
  <div className="image-upload-container">
    <div className="upload-area" style={{backgroundColor: 'var(--black)'}} onDragOver={handleDragOver} onDrop={handleDrop} onClick={() => !uploading && document.getElementById('artist-file-input').click()}>
      {uploading ? (
    <div className="uploading-loader">
    <img className="uploading-image" src="./img/icon.png" alt="" />
    <div className="spinner-3"></div>
  </div>
      ) : imagePreview ? (
        <img src={imagePreview} alt="Preview" className="image-preview-upload" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '25px'}} />
      ) : (
        <div className="upload-prompt">
          <div className="upload-area-inside">
            <img src="../img/upload.svg" alt="" />
            <p>Please click to upload your image.</p>
          </div>
          <div className="upload-area-frame"></div>
        </div>
      )}
      <input id="artist-file-input" type="file" onChange={(e) => handleImageChange(e, 'artistImage')} style={{ display: 'none' }} disabled={uploading} />
    </div>
    <button className="welcome-back-button" onClick={handleBack}>Back</button>
    <button 
      className="welcome-next-button" 
      onClick={handleNext} 
      disabled={!imagePreview || uploading}
    >
      Next
    </button>
  </div>
)}

{currentStep === 4 && (
  <div className="image-upload-container" >
    <div className="upload-area-2"  style={{backgroundColor: 'var(--black)'}} onDragOver={handleDragOver} onDrop={handleDrop} onClick={() => !uploadingLogo && document.getElementById('company-file-input').click()}>
      {uploadingLogo ? (
           <div className="uploading-loader">
           <img className="uploading-image" src="./img/icon.png" alt="" />
           <div className="spinner-3"></div>
         </div>
      ) : logoPreview ? (
        <img src={logoPreview} alt="Logo Preview" className="image-preview-upload" style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '1000px'}} />
      ) : (
        <div className="upload-prompt">
          <div className="upload-area-inside">
            <img src="../img/upload.svg" alt="" />
            <p>Please drag and drop or click to upload your logo.</p>
          </div>
          <div className="upload-area-frame-2"></div>
        </div>
      )}
      <input id="company-file-input" type="file" onChange={(e) => handleImageChange(e, 'companyLogo')} style={{ display: 'none' }} disabled={uploadingLogo} />
    </div>
    <button className="welcome-back-button" onClick={handleBack}>Back</button>
    <button 
      className="welcome-next-button" 
      onClick={handleNext} 
      disabled={!logoPreview || uploadingLogo}
    >
      Next
    </button>
  </div>
)}

{currentStep === 5 && (
  <div className="select-genre-div-2">
  <div className="mood-selection-container">
  <p style={{width: '100%', textAlign: 'center', fontSize: '14px', color: 'grey', marginTop: '0px'}}></p>
            {genres.map((genre) => (
              <button
                key={genre}
                className={`mood-button ${artistData.genre.includes(genre) ? "selected" : ""}`}
                onClick={() => toggleGenre(genre)}
                >
                {genre}
              </button>
            ))}
            </div>

    <button className="welcome-back-button" onClick={handleBack}>Back</button>
    <button 
      className="welcome-next-button" 
      onClick={handleNext}
      disabled={!artistData.genre || artistData.genre.length === 0}
      >
      Next
    </button>
  </div>
)}

{currentStep === 6 && (
  <div className="select-genre-div">
    <label style={{marginBottom: '10px'}}>Website:</label>
    <motion.div
      className="song-stepper-input"
      id="online-links-div"
      style={{
        border: websiteError ? '1px solid red' : '1px solid grey',
        width: 'unset'
      }}
      animate={websiteError ? shakeAnimation : {}}
    >
      <input
        type="text"
        style={{ width: '100%', paddingLeft: '10px' }}
        name="website"
        placeholder="Enter your website URL"
        value={artistData.website || ''}
        onChange={(e) => setArtistData({...artistData, website: e.target.value})}
        onBlur={() => validateWebsite(artistData.website)}
        pattern="^(https:\/\/|www\.).+"
        title="Website must start with 'www.' or 'https://'"
      />
    </motion.div>
    {websiteError && <p className="error-message">{websiteError}</p>}

    <label style={{marginBottom: '10px'}}>Social Media:</label>
    <motion.div
      className="song-stepper-input"
      id="online-links-div"
      style={{
        border: '1px solid grey',
      }}
    >
      <input
        type="text"
        style={{ width: '100%', paddingLeft: '10px' }}
        name="socialMedia"
        placeholder="Enter a social media URL (optional)"
        value={artistData.socialMedia || ''}
        onChange={(e) => setArtistData({...artistData, socialMedia: e.target.value})}
      />
    </motion.div>

    <button className="welcome-back-button" onClick={handleBack}>Back</button>
    <button 
      className="welcome-next-button" 
      onClick={handleNext}
      disabled={artistData.country === '' || artistData.country === 'Select Country' || !artistData.website || websiteError}
    >
      Next
    </button>
  </div>
)}


{currentStep === 7 && (
  <div className="service-options-containers">
    {renderServiceOptions()}
    <div className="terms-privacy-checkboxes">
      <div>
        <label>
            <div className="tos-wrapper" style={{marginBottom: '5px'}}>
            <ToggleSwitch isChecked={agreedToTerms} onChange={() => setAgreedToTerms(!agreedToTerms)} />
            <p>I agree to the <a href="/terms-of-use" target="_blank">Terms & Conditions</a> and understand that my profile will be publicly visible on aaartists.com.</p>
          </div>
        </label>
      </div>
      <div>
        <label>
        <div className="tos-wrapper">
        <ToggleSwitch isChecked={agreedToPrivacy} onChange={() => setAgreedToPrivacy(!agreedToPrivacy)} />
        <p>Send me notifications and marketing emails. (You can unsubscribe at any time)</p>
          </div>
        </label>
      </div>
    </div>
    <button className="welcome-back-button" onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
    <button
      className="welcome-next-button" 
      onClick={handleSubmit}
      disabled={services.filter(service => service.checked).length < 1 || !agreedToTerms || !agreedToPrivacy}
    >
      Submit Profile
    </button>
  </div>
)}

      </div>
      </div>
    </div>
  );
}

export default ExpertStepper;