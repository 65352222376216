import React, { useState, useEffect } from 'react';
import { ref as databaseRef, onValue } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './GigReviewWidget.css';
import GigReviewList from './GigReviewList';

const GigReviewWidget = ({ pageId }) => {
    const [averageRatings, setAverageRatings] = useState({
        overall: 0,
        communication: 0,
        delivery: 0,
        quality: 0,
    });
    const [totalReviews, setTotalReviews] = useState(0);

    useEffect(() => {
        const reviewsRef = databaseRef(database, `/Pages/${pageId}/pageData/reviews`);

        onValue(reviewsRef, (snapshot) => {
            if (snapshot.exists()) {
                const reviews = snapshot.val();
                const reviewValues = Object.values(reviews);

                const total = reviewValues.length;
                setTotalReviews(total);

                const sums = reviewValues.reduce(
                    (acc, review) => {
                        acc.overall +=
                            (review.ratings.quality +
                                review.ratings.delivery +
                                review.ratings.communication) /
                            3;
                        acc.communication += review.ratings.communication;
                        acc.delivery += review.ratings.delivery;
                        acc.quality += review.ratings.quality;
                        return acc;
                    },
                    { overall: 0, communication: 0, delivery: 0, quality: 0 }
                );

                setAverageRatings({
                    overall: sums.overall / total,
                    communication: sums.communication / total,
                    delivery: sums.delivery / total,
                    quality: sums.quality / total,
                });
            } else {
                setAverageRatings({
                    overall: 0,
                    communication: 0,
                    delivery: 0,
                    quality: 0,
                });
                setTotalReviews(0);
            }
        });
    }, [pageId]);

    const renderStars = (score) => {
        const fullStars = Math.floor(score);
        const hasHalfStar = score % 1 !== 0;
        const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

        return (
            <div className="review-stars">
                {Array.from({ length: fullStars }).map((_, index) => (
                    <img key={`full-${index}`} src="../../img/yellow_star.svg" alt="Full Star" className="star" />
                ))}
                {hasHalfStar && <img src="../../img/half_star.svg" alt="Half Star" className="star" />}
                {Array.from({ length: emptyStars }).map((_, index) => (
                    <img key={`empty-${index}`} src="../../img/grey_star.svg" alt="Empty Star" className="star" />
                ))}
            </div>
        );
    };

    return (
        <div className="gig-review-widget">
         
            <div className="gig-review-breiter">
            <div className="gig-reviews-header">
                <h3>Reviews</h3>
                <p className="total-reviews-text">({totalReviews} reviews)</p>
            </div>
            <div className="review-summary">
                <div className="review-item">
                    <h4 style={{fontSize: '24px'}}>Overall Rating</h4>
                    <div  style={{marginBottom: '30px'}} className="review-score-wrapper">
                    {renderStars(averageRatings.overall)}
                    <p className="review-score-text">{averageRatings.overall.toFixed(1)}</p>
                    </div>
                </div>
                <div className="review-item">
                    <h4>Communication</h4>
                    <div className="review-score-wrapper">
                    {renderStars(averageRatings.communication)}
                    <p className="review-score-text">{averageRatings.communication.toFixed(1)}</p>
                    </div>
                </div>
                <div className="review-item">
                    <h4>Delivery</h4>
                  <div className="review-score-wrapper">
                  {renderStars(averageRatings.delivery)}
                    <p className="review-score-text">{averageRatings.delivery.toFixed(1)}</p>
                  </div>
                  
                </div>
                <div className="review-item">
                    <h4>Quality</h4>
                    <div className="review-score-wrapper">
                    {renderStars(averageRatings.quality)}
                    <p className="review-score-text">{averageRatings.quality.toFixed(1)}</p>
                    </div>
                </div>
                </div>
            </div>
            <div>
            </div>
            <div className="gig-review-list-wraper">
            <GigReviewList pageId={pageId} />

            </div>
        </div>
    );
};

export default GigReviewWidget;
