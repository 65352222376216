import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

function AwardPlaque() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>Award Plaque Authentication</h2>
      <div style={{ display: 'inline-block', padding: '20px', background: '#f0f0f0', borderRadius: '10px' }}>
        <QRCodeCanvas 
          value="https://www.aaartists.com/authenticate" 
          size={600} 
          bgColor={"#ffffff"}
          fgColor={"#000000"}
          level={"H"}
          includeMargin={true}
        />
      </div>
    </div>
  );
}

export default AwardPlaque;
