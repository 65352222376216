import React, { useState } from 'react';
import './ChartlistAndAwardManager.css';

function GenerateReview({ setEditorQuote, setMariusReviewText, setMoesardEmail, setLadyEmail, generateSummary }) {
  const [generatedSongReview, setGeneratedSongReview] = useState('');
  const [generatedMariusReview, setGeneratedMariusReview] = useState('');
  const [generatedMoesardEmail, setGeneratedMoesardEmail] = useState('');  // State for Moesard email
  const [generatedLadyEmail, setGeneratedLadyEmail] = useState('');  // State for Lady email
  const [loading, setLoading] = useState(false);

  const summary = generateSummary();  // Get the summary text once

  const handleGenerateAllEmails = async () => {
    setLoading(true);  // Set loading state
  
    try {
      // Generate Song Review
      const songReviewResponse = await fetch('https://us-central1-awards-13a46.cloudfunctions.net/getSongReviewEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ summary }),  // Send the summary in request body
      });
  
      if (songReviewResponse.ok) {
        const songReviewData = await songReviewResponse.json();
        setGeneratedSongReview(songReviewData.text);
        setEditorQuote(songReviewData.text);  // Store the Song Review as EditorQuote
      }
  
      // Generate Marius Review after a brief delay
      const mariusReviewResponse = await fetch('https://us-central1-awards-13a46.cloudfunctions.net/getMariusReviewText', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ summary }),  // Send the summary in request body
      });
  
      if (mariusReviewResponse.ok) {
        const mariusReviewData = await mariusReviewResponse.json();
        setGeneratedMariusReview(mariusReviewData.text);
        setMariusReviewText(mariusReviewData.text);  // Store the Marius Review
      }
  
      // Generate Moesard Email
      const moesardEmailResponse = await fetch('https://us-central1-awards-13a46.cloudfunctions.net/getMoesardEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ summary }),  // Send the summary in request body
      });
  
      if (moesardEmailResponse.ok) {
        const moesardEmailData = await moesardEmailResponse.json();
        console.log('Moesard Email generated successfully:', moesardEmailData);
        setGeneratedMoesardEmail(moesardEmailData.text);  // Store the Moesard Email text
        setMoesardEmail(moesardEmailData.text);  // Pass it to the parent component if needed
      }

      // Generate Lady Email (new function)
      const ladyEmailResponse = await fetch('https://us-central1-awards-13a46.cloudfunctions.net/getLadyEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ summary }),  // Send the summary in request body
      });
  
      if (ladyEmailResponse.ok) {
        const ladyEmailData = await ladyEmailResponse.json();
        console.log('Lady Email generated successfully:', ladyEmailData);
        setGeneratedLadyEmail(ladyEmailData.text);  // Store the Lady Email text
        setLadyEmail(ladyEmailData.text);  // Pass it to the parent component if needed
      }
  
    } catch (error) {
      console.error('Error generating emails:', error);
    } finally {
      setLoading(false);  // Reset loading state
    }
  };
  

  return (
    <div className="editor-review-section">
      <div className="generate-review-section">
        <button onClick={handleGenerateAllEmails} disabled={loading}>
          {loading ? 'Generating...' : 'AI Generate'}
        </button>
      </div>



      {generatedMariusReview && (
        <div className="generated-review">
          <h4>Marius Text:</h4>
          <p>{generatedMariusReview}</p>  {/* Display the generated Marius Review */}
        </div>
      )}

      {generatedMoesardEmail && (
        <div className="generated-review">
          <h4>Moesard Email:</h4>
          <p>{generatedMoesardEmail}</p>  {/* Display the generated Moesard Email */}
        </div>
      )}

      {generatedLadyEmail && (
        <div className="generated-review">
          <h4>Lady Email:</h4>
          <p>{generatedLadyEmail}</p>  {/* Display the generated Lady Email */}
        </div>
      )}
    </div>
  );
}

export default GenerateReview;
