import React, { useState } from 'react';
import './TheGig.css';
import contentMap from '../Page/ContentMap';
import { usePageDetails } from '../../Utility/PageDetailsContext';
import OrderStartChat from '../Chat/OrderStartChat';
import { motion, AnimatePresence } from 'framer-motion';
import CollabLoginPopup from '../../Pages/CollabLoginPopup';
import { auth } from '../../firebaseConfig'; // Importiere Firebase Auth

function TheGig({ onToggleSettings, active, settingsRoute, selectedStyle, isWhiteMode, pageId }) {
    const { pageDetails } = usePageDetails();
    const { contactEmail, whatsappNumber, directMessageEnabled, emailEnabled } = pageDetails || {};
    const offers = pageDetails.offers || [];
    const firstOffer = offers[0] || {};
    const features = firstOffer.features || [];
    const placeholderImage = "/img/service.svg"; // Setze hier den Pfad zu deinem Dummy-Bild
    const [isChatModalOpen, setChatModalOpen] = useState(false);
    const [isLoginPopupOpen, setLoginPopupOpen] = useState(false); // State für das Login-Popup

    const primaryColorClass = `primary-color-${selectedStyle}${isWhiteMode ? '-white' : ''}`;
    const backgroundClass = `gig-background-${selectedStyle}${isWhiteMode ? '-white' : ''}`;
    const className = `gig_component ${settingsRoute ? 'settings-route' : ''} ${active ? 'is-active' : ''} ${backgroundClass}`;
    const [showModal, setShowModal] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState(null);

    const featureDetails = features.map(featureName => {
        for (const category in contentMap) {
            const foundFeature = contentMap[category].find(f => f.name === featureName);
            if (foundFeature) return foundFeature;
        }
        return { name: featureName, img: "/img/service.svg" }; // Fallback if no feature is found
    });

    const handleFeatureClick = (feature) => {
        if (!settingsRoute) {
            setSelectedFeature(feature);
            setShowModal(true);
        }
    };

    const handleChatButtonClick = () => {
        const currentUser = auth.currentUser; // Prüfe den Auth-Status
        if (currentUser) {
            setChatModalOpen(true);
        } else {
            setLoginPopupOpen(true);
        }
    };

    const Modal = ({ feature, onClose }) => {
        if (!feature) return null;
    
        return (
            <div className="feature-modal-backdrop" onClick={onClose}>
                <div className="feature-modal-content" onClick={(e) => e.stopPropagation()}>
                    <h4>{feature.name}</h4>
                    <p>{feature.text || "No additional information available."}</p>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        );
    };

    const modalVariants = {
        hidden: { opacity: 0, scale: 0.95, transition: { duration: 0.3 } },
        visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } }
    };

    return (
        <div onClick={onToggleSettings} className={className}>
            {settingsRoute && (
                <div className="edit-overlay">
                    <img src="../../img/edit.webp" alt="Edit" />
                    <span>Edit</span>
                </div>
            )}
            <div className="gig_header_1">
                <h2>{firstOffer.gigTitle}</h2>
                <div>
                    <p className={pageDetails.payoutOption === "collaborate" ? "free-text" : ""}>
                        {pageDetails.payoutOption === "collaborate" ? "FREE" :
                         pageDetails.payoutOption === "earnRoyalties" ? `${firstOffer.earnAmount}%` :
                         `$${firstOffer.earnAmount}`}
                    </p>
                    <h4>
                        {pageDetails.payoutOption === "earnMoney" && "price"}
                        {pageDetails.payoutOption === "earnRoyalties" && "royalties"}
                        {pageDetails.payoutOption === "collaborate" && "collab"}
                    </h4> 
                </div>
            </div>

            <div className="gig_desc">
                <p>{firstOffer.gigText}</p>
            </div>
            <div className="gig_feature">
                <img src="../../img/rush.svg" alt="" />
                <p style={{display: 'flex', alignItems: 'center'}}>
                    {firstOffer.gigTime} Day Delivery
                    {firstOffer.gigTime < 2 && <span style={{ marginLeft: '7px', fontSize: '10px', fontWeight: '600', backgroundColor: 'var(--color-3)', padding: '3px 7px', borderRadius: '100px' }}>Turbo</span>}
                </p>
            </div>
            <div className="gig_feature" style={{marginTop: '15px', marginBottom: '20px'}}>
                <img src="../../img/infinity_3.svg" alt="" />
                <p>
                    {firstOffer.gigRevisions > 5 
                     ? 'Unlimited Revisions' 
                     : `${firstOffer.gigRevisions} ${firstOffer.gigRevisions <= 1 ? 'Revision' : 'Revisions'}`}
                </p>
            </div>
            <div className="features-container">
                {featureDetails.map((feature, index) => (
                    <div key={index} className="feature-item" onClick={() => handleFeatureClick(feature)}>
                        <img src="../../img/check.svg" className="feature-checkmark" alt="" />
                        <img src={feature.img} alt={feature.name} />
                        <div>
                            <p>{feature.name}</p>
                        </div>
                    </div>
                ))}
                {showModal && <Modal feature={selectedFeature} onClose={() => setShowModal(false)} />}
            </div>

            <div className="gig-contact-buttons">
                {directMessageEnabled && (
                    <button onClick={handleChatButtonClick} disabled={settingsRoute} className={`gig-contact-button ${primaryColorClass}`}>
                        <p>Chat</p>
                        <img style={{width: '20px', filter: 'invert(1)', marginLeft: '10px'}} src="../../img/sendo.svg" alt=""/>
                    </button>
                )}
                {emailEnabled && contactEmail && (
                    <a href={`mailto:${contactEmail}`} className="whats-contact-button">
                        <p>MAIL</p>
                        <img src="../../img/mail.svg" alt=""/>
                    </a>
                )}
                {whatsappNumber && (
                    <a href={`https://wa.me/${whatsappNumber}`} className="whats-contact-button-2">
                       <p>WhatsApp</p>
                       <img src="../../img/whatsapp.svg" alt=""/>
                    </a>
                )}
            </div>

            <AnimatePresence>
                {isChatModalOpen && (
                    <OrderStartChat 
                        pageId={pageId}     
                        isOpen={isChatModalOpen}     
                        buttonStyleClass={primaryColorClass}
                        onClose={() => setChatModalOpen(false)} 
                    />
                )}
                {isLoginPopupOpen && (
                    <CollabLoginPopup
                        isOpen={isLoginPopupOpen}
                        onClose={() => setLoginPopupOpen(false)}
                    />
                )}
            </AnimatePresence>
        </div>
    );
}

export default TheGig;
