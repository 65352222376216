import React, { useState, useEffect } from 'react';
import { database } from "../firebaseConfig";
import { ref, get, query, orderByChild, equalTo } from 'firebase/database';
import './ArtistSongs.css';
import playIcon from '../img/play.svg';
import pauseIcon from '../img/pause.svg';
import { Link } from 'react-router-dom';

function ArtistSongs({ artistID, currentSong, isPlaying, togglePlayPause }) {
  const [songs, setSongs] = useState([]);

  useEffect(() => {
    const fetchSongs = async () => {
      const songsRef = query(ref(database, 'Songs'), orderByChild('artistID'), equalTo(artistID));
      const snapshot = await get(songsRef);
      if (snapshot.exists()) {
        const songsData = snapshot.val();
        const songsArray = Object.entries(songsData).map(([id, data]) => ({
          id,
          ...data,
        }));
        setSongs(songsArray);
      }
    };

    fetchSongs();
  }, [artistID]);

  // Wenn keine Songs vorhanden sind, gib nichts zurück
  if (songs.length === 0) {
    return null;
  }

  return (
    <div className="home-nominees-2" style={{ minHeight: '50vh' }}>
      <h3>Tracks</h3>

      <div className="home-nominees-grid-3" style={{ paddingBottom: '30px' }}>
        {songs.map(song => (
          <div key={song.id} className="home-nominee-song-4">
            <div className="home-nominee-song-top">
              <img src={song.songCoverUrl} alt={`${song.name} cover`} />
              <button
                className="play-pause-button-4"
                style={{ position: 'absolute', bottom: '85px', left: '35px' }}
                onClick={() => togglePlayPause(song)}
              >
                {isPlaying && currentSong && currentSong.id === song.id ? (
                  <img src={pauseIcon} style={{ marginLeft: '1px' }} className="pause-icon" alt="Pause" />
                ) : (
                  <img src={playIcon} className="play-icon" alt="Play" />
                )}
              </button>
            </div>
            <div className="home-nominee-song-bottom">
              <Link to={`/song/${song.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <h4>{song.name}</h4>
              </Link>
              <p>{song.artist}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ArtistSongs;
