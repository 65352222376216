import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ref as databaseRef, push } from 'firebase/database';
import { database } from '../firebaseConfig'; // Deine Firebase-Konfiguration
import './GigFilterComponent.css'; // Verwende dasselbe CSS wie in der Hauptkomponente

const textVariants = [
  {
    id: 1,
    title: "Apply as a Seller",
    description: "Earn 100% of your Income",
    buttonText: "Get Started"
  },
  {
    id: 2,
    title: "Become a Top Seller",
    description: "Boost Your Earnings",
    buttonText: "Join Now"
  },
  {
    id: 3,
    title: "Start Selling Today",
    description: "Keep All Your Profits",
    buttonText: "Sign Up"
  }
];

const GigFilterSideContainer = () => {
  const [currentVariant, setCurrentVariant] = useState(textVariants[0]);

  // Zufällige Auswahl einer Textvariante beim Laden der Komponente
  useEffect(() => {
    const randomVariantIndex = Math.floor(Math.random() * textVariants.length);
    setCurrentVariant(textVariants[randomVariantIndex]);
  }, []);

  const handleButtonClick = () => {
    // Daten für Analytics vorbereiten
    const clickData = {
      variantId: currentVariant.id,
      title: currentVariant.title,
      description: currentVariant.description,
      buttonText: currentVariant.buttonText,
      timestamp: Date.now()
    };

    // Daten in Firebase speichern
    const analyticsRef = databaseRef(database, '/Analytics/MarketSideFilter');
    push(analyticsRef, clickData);

    console.log('Data pushed to Firebase:', clickData);
  };

  return (
    <div className="gig-filter-side-container">
      <img src="./img/seller_award.webp" alt="Seller Award" className="gig-cash-icon"/> 
      <h2>{currentVariant.title}</h2>
      <p>
        <img src="./img/check.svg" alt="Check" className="check-icon" />{currentVariant.description}
      </p>
      <Link to="/pages" className="gig-join-button" onClick={handleButtonClick}>
        {currentVariant.buttonText}
      </Link>
    </div>
  );
};

export default GigFilterSideContainer;
