import React, { useEffect, useState } from 'react'; // Importieren von useEffect und useState
import { ref as databaseRef, get, update, set } from 'firebase/database';
import { database } from '../../firebaseConfig'; // Stellen Sie sicher, dass der Pfad korrekt ist
import ThankYouStep from './ThankYouStep';





const serviceTemplates = {
    "Mix & Master": {
      introTitle: "Hey, I'm {{artistName}}. Let me bring out the best in your tracks with my mixing and mastering skills.",
      introText: "With over {{experience}} of experience in genres like {{genres}}, I'm here to help you achieve the perfect sound.",
      gigTitle: "Professional Mix and Mastering Service",
      gigText: "Get your track professionally mixed and mastered, ensuring top quality sound, ready for commercial release.",
      gigTime: 2
    },
    "Song Production": {
      introTitle: "Hi, I'm {{artistName}}, your next song producer.",
      introText: "Whether you're into {{genres}}, I bring {{experience}} of experience to craft your next hit.",
      gigTitle: "Complete Song Production",
      gigTime: 2,
      gigText: "From writing to production to final polish, let's make your musical vision come to life."
    },
    "Vocal Feature": {
      introTitle: "Need a unique voice on your track? I'm {{artistName}}, ready to elevate your song.",
      introText: "I've worked in {{genres}} music for over {{experience}}, collaborating with artists to blend perfect vocal layers.",
      gigTitle: "Vocal Feature Services",
      gigTime: 2,
      gigText: "Offering standout vocal features that can fit any style or genre you're aiming for."
    },
    "Rap Feature": {
      introTitle: "Looking for sharp bars? I'm {{artistName}}, here to deliver.",
      introText: "With {{experience}} spitting verses across various {{genres}}, I can add the edge your track needs.",
      gigTime: 2,
      gigTitle: "Custom Rap Verses",
      gigText: "Get custom-written rap verses that match the vibe and message of your song."
    },
    "Sell Song Licenses": {
      introTitle: "Expand your repertoire with my exclusive tracks. I'm {{artistName}}, ready to license my work.",
      gigTime: 2,
      introText: "My extensive experience in {{genres}} over {{experience}} ensures high-quality music for your projects.",
      gigTitle: "Song Licensing Opportunities",
      gigText: "License original songs from a diverse catalog, ready for commercial use."
    },

    "Beat Production": {
      introTitle: "Turn your ideas into beats. I'm {{artistName}}, at your service for custom beat production.",
      introText: "Specializing in {{genres}}, with over {{experience}} in the industry, I can create exactly what you need.",
      gigTime: 2,
      gigTitle: "Custom Beat Making",
      gigText: "Collaborate to create beats tailored to your specifications and sound preferences."
    },
    "Instrument Recording": {
      introTitle: "Need high-quality recording sessions? Contact me, {{artistName}}, for the best session experience.",
      introText: "With {{experience}} in the music industry, focusing on {{genres}}, I offer top-tier instrument recording services.",
      gigTime: 2,
      gigTitle: "Professional Instrument Recording",
      gigText: "Book a session with state-of-the-art equipment and experienced engineering."
    },
    "Ghostwriting": {
      introTitle: "Looking for top-notch ghostwriting? Reach out to me, {{artistName}}, for compelling and creative writing.",
      gigTime: 2,
      introText: "With {{experience}} years in the writing industry, specializing in {{genres}}, I provide first-class ghostwriting services.",
      gigTitle: "Expert Ghostwriting Services",
      gigText: "Get your story told or your ideas penned down by an expert with a knack for capturing voices and styles."
    },    
    "Artist Management": {
      introTitle: "Advance your career with expert management from me, {{artistName}}, a dedicated industry professional.",
      introText: "Using my extensive experience to strategically guide your music career and business decisions.",
      gigTitle: "Comprehensive Artist Management",
      gigTime: 2,
      gigText: "From scheduling to negotiations, I handle all aspects to maximize your artistic and commercial potential."
    },
    "YouTube Promotion": {
      introTitle: "Enhance your YouTube presence with tailored promotion by {{artistName}}, your video marketing expert.",
      introText: "Utilizing cutting-edge strategies to boost your visibility and subscriber count.",
      gigTitle: "Targeted YouTube Growth Campaigns",
      gigTime: 2,
      gigText: "Increase your channel's reach and engagement through optimized content and promotional tactics."
    },
    "Spotify Promotion": {
      introTitle: "Boost your Spotify streams and followers with specialized promotion from {{artistName}}, a music marketing specialist.",
      introText: "Applying proven techniques to enhance your presence and playlist placements.",
      gigTitle: "Spotify Visibility Enhancement",
      gigTime: 2,
      gigText: "Elevate your music with strategic promotions that ensure your tracks get the attention they deserve."
    },
    "Blog/Magazine Promotion": {
      introTitle: "Get featured in top blogs and magazines with personalized promotion by {{artistName}}, your PR expert.",
      introText: "Exploiting industry connections to secure high-profile placements and articles.",
      gigTitle: "Exclusive Blog and Magazine Features",
      gigTime: 2,
      gigText: "Land features in prestigious publications to significantly increase your music's exposure."
    },
    "Graphic Design": {
      introTitle: "Get bespoke graphic designs crafted to perfection by {{artistName}}, your go-to design expert."      ,
      introText: "I create graphic design solutions tailored specifically to your needs.",
      gigTitle: "5 Star Graphic Design Works",
      gigTime: 3,
      gigText: "Stand out with custom graphic designs that elevate your brand's visual identity."
    },
    "Video Editing": {
      introTitle: "Experience music videos edited to your style by {{artistName}}, your trusted video editing professional."      ,
      introText: "I edit your music videos in the style that best reflects your vision.",
      gigTitle: "Pro Video Editing Skills",
      gigTime: 7,
      gigText: "Transform your music video into a captivating visual experience that resonates with your audience."
    },
    "Music Mentoring": {
      introTitle: "Elevate your music career with mentoring from me, {{artistName}}, a seasoned {{genres}} expert.",
      introText: "Leveraging {{experience}} of industry knowledge to guide you through your musical journey.",
      gigTitle: "Personalized Music Mentoring",
      gigTime: 2,
      gigText: "Receive tailored advice and strategies to improve your skills and navigate the music industry."
    },
    "TikTok Influencer": {
      introTitle: "Expand your reach with viral TikTok campaigns led by {{artistName}}, a renowned TikTok influencer.",
      introText: "Harnessing the power of viral content to dramatically increase your music's exposure.",
      gigTitle: "Viral TikTok Music Promotions",
      gigTime: 2,
      gigText: "Utilize my large TikTok following to propel your tracks to widespread popularity."
    },
    "Instagram Influencer": {
      introTitle: "Grow your fanbase with strategic Instagram promotions by {{artistName}}, an expert in social media influence.",
      introText: "Engaging with a broad audience through creative and impactful Instagram content.",
      gigTitle: "Instagram Music Marketing",
      gigTime: 2,
      gigText: "Leverage my Instagram influence to significantly boost your music's online presence."
    }    
};

 
const AutoGigFill = ({ artistID, pageId, onSuccess }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const artistRef = databaseRef(database, `/Artists/${artistID}/artistName`);
    const pageDataRef = databaseRef(database, `/Pages/${pageId}/pageData`);

    get(artistRef).then(artistSnapshot => {
      if (artistSnapshot.exists()) {
        const artistName = artistSnapshot.val();  // Get artist name
        get(pageDataRef).then(pageDataSnapshot => {
          if (pageDataSnapshot.exists()) {
            const pageData = pageDataSnapshot.val();
            const details = generateServiceDetails(pageData.service, artistName, pageData.experience, pageData.genres);

            // Hinzufügen der Widgets basierend auf serviceType
            if (pageData.serviceType === "Music Artist") {
              details.widgets = [{
                position: 0,
                widget: "GigSongs",
                widgetSettingsClick: "GigSongs"
              }];
            } else if (pageData.serviceType === "Music Industry") {
              details.widgets = [{
                position: 0,
                widget: "PageTextEdit",
                widgetSettingsClick: "TextEdit"
              }];
            }

            updateServiceDetailsInDatabase(pageId, details).then(() => {
              setLoading(false);
              onSuccess();  // Trigger success after a delay to ensure user sees the thank you message
            }).catch(error => {
              console.error('Error updating data:', error);
              setLoading(false);
            });
          } else {
            console.error('Page data not found');
            setLoading(false);
          }
        });
      } else {
        console.error('Artist name not found');
        setLoading(false);
      }
    }).catch(error => {
      console.error('Error fetching artist name:', error);
      setLoading(false);
    });

    // Set a timer to trigger success even if data operations are still running
    const timer = setTimeout(() => {
      setLoading(false);
      onSuccess();
    }, 2000);  // Assume minimum wait time of 2 seconds for user experience

    return () => clearTimeout(timer);  // Cleanup the timer
  }, [artistID, pageId, onSuccess]);

  return loading ? <ThankYouStep /> : <ThankYouStep />;
};

const generateServiceDetails = (service, artistName, experience, genres) => {
  const template = serviceTemplates[service];
  if (!template) {
    console.error('No template found for service:', service);
    return {};
  }

  return {
    introTitle: template.introTitle.replace('{{artistName}}', artistName),
    introText: template.introText.replace('{{experience}}', experience).replace('{{genres}}', genres.join(' or ')),
    gigTitle: template.gigTitle,
    gigText: template.gigText,
    gigRevisions: "2",
    gigTime: template.gigTime
  };
};

const updateServiceDetailsInDatabase = async (pageId, details) => {
  const pageDataRef = databaseRef(database, `/Pages/${pageId}/pageData`);
  const snapshot = await get(pageDataRef);
  let updates = {
    'introTitle': details.introTitle,
    'introText': details.introText,
    'offers/0/gigTitle': details.gigTitle,
    'offers/0/gigText': details.gigText,
    'offers/0/gigRevisions': details.gigRevisions,
    'offers/0/gigTime': details.gigTime,
    'hasGigSettings': true,
    'widgets': details.widgets || []
  };

  if (snapshot.exists() && !snapshot.val().pageCreated) {
    updates['pageCreated'] = new Date().toISOString();
  } else if (!snapshot.exists()) {
    updates['pageCreated'] = new Date().toISOString();
  }

  return update(pageDataRef, updates)
    .then(() => {
      console.log('Update successful');
    })
    .catch((error) => {
      console.error('Error updating data:', error);
    });
};

export default AutoGigFill;