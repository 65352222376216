import React from 'react';

const CountryFlagSVG2 = ({ country }) => {
    const countryToSvg = {
        'China': 'china.svg',
        'India': 'india.svg',
        'United States': 'united-states.svg',
        'Indonesia': 'indonesia.svg',
        'Pakistan': 'pakistan.svg',
        'Brazil': 'brazil.svg',
        'Australia': 'australia.svg',
        'Nigeria': 'nigeria.svg',
        'Bangladesh': 'bangladesh.svg',
        'Russia': 'russia.svg',
        'Mexico': 'mexico.svg',
        'Japan': 'japan.svg',
        'Ethiopia': 'ethiopia.svg',
        'Philippines': 'philippines.svg',
        'Egypt': 'egypt.svg',
        'Vietnam': 'vietnam.svg',
        'Turkey': 'turkey.svg',
        'Iran': 'iran.svg',
        'Germany': 'germany.svg',
        'Thailand': 'thailand.svg',
        'United Kingdom': 'united-kingdom.svg',
        'France': 'france.svg',
        'Italy': 'italy.svg',
        'Tanzania': 'tanzania.svg',
        'South Africa': 'south-africa.svg',
        'Myanmar': 'myanmar.svg',
        'Kenya': 'kenya.svg',
        'South Korea': 'south-korea.svg',
        'Colombia': 'colombia.svg',
        'Spain': 'spain.svg',
        'Uganda': 'uganda.svg',
        'Argentina': 'argentina.svg',
        'Sudan': 'sudan.svg',
        'Ukraine': 'ukraine.svg',
        'Poland': 'poland.svg',
        'Canada': 'canada.svg',
        'Morocco': 'morocco.svg',
        'Austria': 'austria.svg',
        'Belgium': 'belgium.svg',
        'Croatia': 'croatia.svg',
        'Denmark': 'denmark.svg',
        'Estonia': 'estonia.svg',
        'Finland': 'finland.svg',
        'Greece': 'greece.svg',
        'Hungary': 'hungary.svg',
        'Ireland': 'ireland.svg',
        'Latvia': 'latvia.svg',
        'Lithuania': 'lithuania.svg',
        'Luxembourg': 'luxembourg.svg',
        'Netherlands': 'netherlands.svg',
        'Norway': 'norway.svg',
        'Portugal': 'portugal.svg',
        'Romania': 'romania.svg',
        'Slovakia': 'slovakia.svg',
        'Slovenia': 'slovenia.svg',
        'Sweden': 'sweden.svg',
        'Switzerland': 'switzerland.svg',
        'Iceland': 'iceland.svg',
        'Costa Rica': 'costa-rica.svg',
    };

    const svgFileName = countryToSvg[country]; // Kein Fallback-Wert

    // Wenn kein SVG-Dateiname vorhanden ist, rendern Sie ein leeres <p>-Tag
    if (!svgFileName) {
        return <p></p>; // Gibt ein leeres <p>-Tag zurück, wenn kein Land gefunden wird
    }

    return (
        <img style={{width: '20px', height: '20px', marginLeft: '10px'}} src={`../../country/${svgFileName}`} alt={country} />
    );
};

export default CountryFlagSVG2;
