import React, { useEffect, useState } from 'react';
import { ref as databaseRef, onValue } from 'firebase/database';
import { database } from "../firebaseConfig";
import { Bar } from 'react-chartjs-2';
import './ArtistStats.css'; // Verwendet die gleichen Stile wie ArtistStats
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function getDayLabel(date) {
  return date.toLocaleDateString('en-US', { weekday: 'short', month: 'numeric', day: 'numeric' });
}

function WeeklyStats() {
  const [dailyNewArtists, setDailyNewArtists] = useState(Array(7).fill(0));
  const [totalWeeklyArtists, setTotalWeeklyArtists] = useState(0); // Zustand für die Gesamtanzahl der Künst

  const labels = Array.from({ length: 7 }).map((_, i) => {
    const date = new Date();
    date.setDate(date.getDate() - (6 - i));
    return getDayLabel(date);
  });

  useEffect(() => {
    const artistsRef = databaseRef(database, 'Artists');
    onValue(artistsRef, (snapshot) => {
      const artistsData = snapshot.val();
      const tempDailyNewArtists = Array(7).fill(0);

      if (artistsData) {
        Object.keys(artistsData).forEach(key => {
          const artist = artistsData[key];
          const signupDate = new Date(artist.signupTimestamp);
          const diffDays = Math.floor((new Date() - signupDate) / (1000 * 60 * 60 * 24));
          if (diffDays < 7) {
            tempDailyNewArtists[6 - diffDays] += 1; // Erhöhe den Zähler für den entsprechenden Tag
          }
        });
      }

      setDailyNewArtists(tempDailyNewArtists);
    });
  }, []);

  useEffect(() => {
    const artistsRef = databaseRef(database, 'Artists');
    onValue(artistsRef, (snapshot) => {
      const artistsData = snapshot.val();
      const tempDailyNewArtists = Array(7).fill(0);

      if (artistsData) {
        Object.keys(artistsData).forEach(key => {
          const artist = artistsData[key];
          const signupDate = new Date(artist.signupTimestamp);
          const diffDays = Math.floor((new Date() - signupDate) / (1000 * 60 * 60 * 24));
          if (diffDays < 7) {
            tempDailyNewArtists[6 - diffDays] += 1; // Erhöhe den Zähler für den entsprechenden Tag
          }
        });
      }

      setDailyNewArtists(tempDailyNewArtists);
      // Berechne die Gesamtanzahl der Künstler der letzten Woche
      setTotalWeeklyArtists(tempDailyNewArtists.reduce((acc, curr) => acc + curr, 0));
    });
  }, []);

  const data = {
    labels,
    datasets: [
      {
        label: 'New Artists',
        data: dailyNewArtists,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div className="artist-stats-container">
      <h2>Weekly New Artists: {totalWeeklyArtists}</h2>
      <div className="chart-container">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}

export default WeeklyStats;
