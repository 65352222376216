import React from 'react';
import './ReadMore.css';

const ReadMore = ({ publications }) => {
  if (!publications || !Array.isArray(publications) || publications.length === 0) {
    return null; // Nichts rendern, wenn keine Publikationen vorhanden sind
  }

  const formatLink = (url) => {
    if (!url) return '';
    // Überprüft, ob der URL mit http:// oder https:// beginnt
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    // Fügt https:// hinzu, wenn der URL mit www. beginnt
    if (url.startsWith('www.')) {
      return `https://${url}`;
    }
    // Für den Fall, dass der URL ohne www. oder http(s):// beginnt
    return `https://${url}`;
  };

  return (
    <div className="read-more">
      <h3>Featured in</h3>
      <div className="publication-container">
        {publications.map((pub, index) => (
          <a key={index} 
             href={formatLink(pub.pubLink)} 
             target="_blank" 
             rel="noopener noreferrer" 
             className="publication" 
             style={{textDecoration: 'none', color: 'inherit'}}>
            {pub.pubImg && <img src={pub.pubImg} className="pub-header-img" alt="Publication" />}
            <div className="publication-text">
              <h5>{pub.pubLink.length > 40 ? `${pub.pubLink.substring(0, 40)}...` : pub.pubLink}</h5>
              <p><strong>{pub.pubText}</strong></p>
              <span>Read more</span>
            </div>
            <img className="forward_icon" src="../img/forward.svg" alt="" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default ReadMore;
