import React, { useState, useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import './GigSong.css'


function GigSong2({ song, isPlaying, onPlay }) {
    const waveformRef = useRef(null);
    const wavesurferRef = useRef(null);
    const [playing, setPlaying] = useState(false);

    useEffect(() => {
        if (waveformRef.current) {
            const ws = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: 'grey',
                progressColor: '#4246D5',
                cursorColor: '#4246D5',
                barWidth: 2,
                barRadius: 2,
                responsive: true,
                height: 34,
                normalize: true,
                backend: 'WebAudio',
                renderFunction: (channels, ctx) => {
                    const { width, height } = ctx.canvas;
                    const scale = channels[0].length / width;
                    const step = 10;
    
                    ctx.translate(0, height / 2);
                    ctx.strokeStyle = ctx.fillStyle;
                    ctx.beginPath();
    
                    for (let i = 0; i < width; i += step * 2) {
                        const index = Math.floor(i * scale);
                        const value = Math.abs(channels[0][index]);
                        let x = i;
                        let y = value * height;
    
                        ctx.moveTo(x, 0);
                        ctx.lineTo(x, y);
                        ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, true);
                        ctx.lineTo(x + step, 0);
    
                        x = x + step;
                        y = -y;
                        ctx.moveTo(x, 0);
                        ctx.lineTo(x, y);
                        ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, false);
                        ctx.lineTo(x + step, 0);
                    }
    
                    ctx.stroke();
                    ctx.closePath();
                }
        
            });
    
            ws.load(song.fileUrl)
              .catch(error => {
                  if (error.name !== 'AbortError') {
                      console.error('Load error:', error);
                  }
              });
            wavesurferRef.current = ws;
    
            ws.on('finish', () => {
                setPlaying(false);
            });
        }
    
        return () => {
            if (wavesurferRef.current) {
                wavesurferRef.current.destroy();
            }
        };
    }, [song.fileUrl]);
    
    const handlePlayPause = () => {
        setPlaying(!playing);
        onPlay(); // Call the function passed from the parent
        if (wavesurferRef.current) {
            wavesurferRef.current.playPause();
        }
    };

   useEffect(() => {
    if (!isPlaying && playing && wavesurferRef.current) {
        wavesurferRef.current.pause();
        setPlaying(false);
    }
}, [isPlaying, playing]);


    return (
        <div className="gig-song-2">
            <h3>{song.name}</h3>
            <div>
            <button onClick={handlePlayPause}>
            <img 
                src={playing ? '../../img/pause.svg' : '../../img/play.svg'} 
                alt={playing ? 'play' : 'pause'} 
                style={{ cursor: 'pointer' }} 
            />
            </button>
            <div ref={waveformRef} style={{ width: '90%', marginRight: '10px' }}></div>
        </div>
        </div>

    );
}

export default GigSong2;
