import React from 'react';
import './WatchVideo.css';

const WatchVideo = ({ videoLink }) => {
  // Versuch, die Video-ID aus dem YouTube-Link zu extrahieren, unabhängig vom Format
  let videoId = null;
  const videoIdMatch = videoLink.match(/[?&]v=([^&#]+)/);
  if (videoIdMatch) {
    videoId = videoIdMatch[1];
  }

  // URL für den YouTube-Embed-Link
  const embedUrl = videoId ? `https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0` : '';

  if (!videoId) {
    return <div>Video-Link ist nicht korrekt oder nicht verfügbar.</div>;
  }

  return (
    <div className="watch-video-container">
      <h3>Watch Video</h3>
      <iframe
        width="90%"
        height="500" // Passen Sie die Höhe nach Bedarf an
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Video Player"
      ></iframe>
    </div>
  );
};

export default WatchVideo;

