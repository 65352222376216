import React, { useState, useEffect } from 'react';
import { ref as databaseRef, push, set, update, get, query, orderByChild, equalTo } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import PromoText from './PromoText';
import SuperChatReviews from './SuperChatReviews';
import './SuperChat.css';

const SuperChat = ({ chatId, receiverId }) => {
    const [modalShow, setModalShow] = useState(false);
    const [gigs, setGigs] = useState([]);
    const [selectedGigId, setSelectedGigId] = useState('');
    const [isReviewRequestSent, setIsReviewRequestSent] = useState(false);
    const [viewMode, setViewMode] = useState('Promo');
    const artistId = auth.currentUser ? auth.currentUser.uid : null;

    useEffect(() => {
        const fetchGigs = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                console.error('No user logged in');
                return;
            }

            const pagesRef = query(
                databaseRef(database, 'Pages'),
                orderByChild('artistId'),
                equalTo(currentUser.uid)
            );

            const snapshot = await get(pagesRef);
            const gigsArray = [];

            snapshot.forEach(childSnapshot => {
                const gigData = childSnapshot.val();
                if (gigData.pageData && gigData.pageData.pageIsLive) {
                    gigsArray.push({
                        id: childSnapshot.key,
                        name: gigData.pageData.service || 'Untitled Gig',
                    });
                }
            });

            setGigs(gigsArray);
            setSelectedGigId(gigsArray.length > 0 ? gigsArray[0].id : '');
        };

        fetchGigs();
    }, []);

    const toggleModal = () => setModalShow(!modalShow);
    const handleCloseModal = () => setModalShow(false);

    const checkIfReviewExists = async (gigId) => {
        const reviewsRef = query(
            databaseRef(database, `Pages/${gigId}/pageData/reviews`),
            orderByChild('reviewWriter'),
            equalTo(receiverId)
        );

        const snapshot = await get(reviewsRef);
        return snapshot.exists();
    };

    const handleSendReviewRequest = async () => {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.error('No user logged in');
            return;
        }

        if (!selectedGigId) {
            console.error('No gig selected');
            return;
        }

        if (!receiverId) {
            console.error('Receiver ID is undefined');
            return;
        }

        const reviewExists = await checkIfReviewExists(selectedGigId);
        if (reviewExists) {
            console.error('This user has already left a review for this gig.');
            setIsReviewRequestSent(true);
            return;
        }

        const messagesRef = databaseRef(database, `Chats/${chatId}/messages`);
        const newMessageRef = push(messagesRef);
        const timestamp = new Date().toISOString();

        await set(newMessageRef, {
            message: "Do you want to give me a review?",
            imageUrl: '',
            senderId: currentUser.uid,
            receiverId: receiverId,
            type: "AskReview",
            gigId: selectedGigId,
            timestamp,
            readStatus: false
        });

        const chatRef = databaseRef(database, `Chats/${chatId}`);
        await update(chatRef, {
            lastMessageAt: timestamp
        });

        setIsReviewRequestSent(false);
        toggleModal();
    };

    return (
        <div>
            <button className="superchat-open-button" onClick={toggleModal}>
                <img src="../../img/plus.svg" alt="plus" />
            </button>
            {modalShow && (
                <div className="superchat-modal-backdrop">
                    <div className="superchat-modal-content">
                        <div className="superchat-modal-header">
                            <button className="superchat-close-button" onClick={toggleModal}>&times;</button>
                        </div>
                        <div className="superchat-modal-body">
                            <div className="superchat-top-buttons">
                                <button
                                    className={`super-chat-promo-button ${viewMode === 'Promo' ? 'active' : ''}`}
                                    onClick={() => setViewMode('Promo')}
                                >
                                    Promo Texts
                                </button>
                                <button
                                    className={`super-chat-review-button ${viewMode === 'Reviews' ? 'active' : ''}`}
                                    onClick={() => setViewMode('Reviews')}
                                >
                                    Reviews
                                </button>
                            </div>
                            {viewMode === 'Promo' ? (
                                <PromoText artistId={artistId} chatId={chatId} onClose={handleCloseModal} /> 
                            ) : (
                                <SuperChatReviews
                                    gigs={gigs}
                                    selectedGigId={selectedGigId}
                                    setSelectedGigId={setSelectedGigId}
                                    handleSendReviewRequest={handleSendReviewRequest}
                                    isReviewRequestSent={isReviewRequestSent}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SuperChat;
