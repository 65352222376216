import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../img/logo_2.png';
import { useAppContext } from '../Utility/AppContext';
import './NewMobileHeader.css';
import { useChat } from '../Utility/ChatContext';

function NewMobileHeader() {
    const { userData } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (userData.artistImageUrl !== undefined) {
            setIsLoading(false);
        }
    }, [userData.artistImageUrl]);

    const avatarImage = !isLoading && userData.artistImageUrl ? userData.artistImageUrl : "../img/avatar.svg";
    const { totalUnread } = useChat();

    return (
        <div className="mobile-new-header-wrapper">
            <div className="mobile-new-header">
                <div className="mobile-header-new-left">
                    <Link to="/">
                        <img style={{ width: '100px', marginTop: '5px' }} src="../../img/logo.svg" alt="Logo" />           
                    </Link>
                </div>

                <div className="mobile-header-new-middle"></div>

                <div className="header-new-right">
                    <NavLink to="/favorites" activeclassname="active">
                        <img style={{ width: '17px', marginLeft: '8px', filter: 'invert(1)' }} src="../../img/love_2.svg" alt="Favorites" />           
                    </NavLink>
                    <NavLink to="/chats" activeclassname="active">
                        <div className="chat-notification">
                            <img style={{ width: '20px', filter: 'invert(1)' }} src="../../img/chat_2.svg" alt="Chats" />
                            {totalUnread > 0 && (
                                <div className="total-unread-messages-menu-2">{totalUnread}</div>
                            )}
                        </div>
                    </NavLink>
                    <NavLink to="/dashboard" activeclassname="active">
                        <img src={avatarImage} alt="Avatar" className="header-avatar" />
                    </NavLink>
                </div>
            </div>
            <div className="mobile-new-header-add">
                <NavLink to="/collaborations" activeclassname="active">Connect</NavLink>
                <NavLink to="/" activeclassname="active">Awards</NavLink>
                <NavLink to="/market" activeclassname="active">Market</NavLink>
            </div>
        </div>
    );
}

export default NewMobileHeader;
