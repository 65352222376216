import React, { useState, useEffect } from 'react';
import { ref as databaseRef, push, set, update, get } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import './ChatList.css';
import SuperChat from './SuperChat';
import ImageUploadComponentChat from './ImageUploadComponent';

const ChatInput = ({ chatId }) => {
    const [inputMessage, setInputMessage] = useState('');
    const [imageUploadUrl, setImageUploadUrl] = useState('');
    const [pdfUploadUrl, setPdfUploadUrl] = useState('');
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [resetImageUpload, setResetImageUpload] = useState(false); // Toggle zum Reset
    const [receiverId, setReceiverId] = useState(null);

    useEffect(() => {
        const fetchChatData = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                console.error('No user logged in');
                return;
            }

            const chatRef = databaseRef(database, `Chats/${chatId}`);
            const chatSnapshot = await get(chatRef);
            if (chatSnapshot.exists()) {
                const chatData = chatSnapshot.val();
                const otherUserId = chatData.initiatorId === currentUser.uid ? chatData.recipientId : chatData.initiatorId;
                setReceiverId(otherUserId);
            }
        };

        fetchChatData();
    }, [chatId]);

    const handleUploadSuccess = (url, fileType) => {
        if (fileType === 'pdf') {
            setPdfUploadUrl(url); // Setzt die PDF-URL
            setImageUploadUrl(''); // Leert die Bild-URL, falls vorher ein Bild hochgeladen war
        } else if (fileType === 'image') {
            setImageUploadUrl(url); // Setzt die Bild-URL
            setPdfUploadUrl(''); // Leert die PDF-URL, falls vorher ein PDF hochgeladen war
        }
    };

    const updateTypingStatus = () => {
        const typingRef = databaseRef(database, `Chats/${chatId}/typing/${auth.currentUser.uid}`);
        set(typingRef, true);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const newTimeout = setTimeout(() => {
            set(typingRef, false);
        }, 3000);

        setTypingTimeout(newTimeout);
    };

    const handleTyping = (value) => {
        setInputMessage(value);
        updateTypingStatus();
    };

    const handleSendMessage = async () => {
        if (!inputMessage && !imageUploadUrl && !pdfUploadUrl) {
            console.error('No message, image, or PDF to send');
            return;
        }

        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.error('No user logged in');
            return;
        }

        const messagesRef = databaseRef(database, `Chats/${chatId}/messages`);
        const newMessageRef = push(messagesRef);
        const timestamp = new Date().toISOString();

        await set(newMessageRef, {
            message: inputMessage,
            imageUrl: imageUploadUrl,
            pdfUrl: pdfUploadUrl,
            senderId: currentUser.uid,
            type: "normal",
            timestamp,
            readStatus: false
        });

        const chatRef = databaseRef(database, `Chats/${chatId}`);
        await update(chatRef, {
            lastMessageAt: timestamp
        });

        // Eingabefelder und Vorschau-Trigger zurücksetzen
        setInputMessage('');
        setImageUploadUrl('');
        setPdfUploadUrl('');
        
        // Reset-Trigger toggeln, um die Vorschauen in ImageUploadComponentChat zurückzusetzen
        setResetImageUpload(prevState => !prevState);

        // Typing-Status auf false setzen
        set(databaseRef(database, `Chats/${chatId}/typing/${currentUser.uid}`), false);
    };

    return (
        <div className="chat-message-input">
            <textarea
                value={inputMessage}
                onChange={e => handleTyping(e.target.value)}
                placeholder="Type here"
            />
            {/* SuperChat-Komponente für erweiterte Chat-Funktionalitäten */}
            <SuperChat onUploadSuccess={(url) => handleUploadSuccess(url, 'image')} resetTrigger={resetImageUpload} chatId={chatId} receiverId={receiverId} />
            {/* ImageUploadComponentChat für Bild- und PDF-Uploads */}
            <ImageUploadComponentChat onUploadSuccess={handleUploadSuccess} resetTrigger={resetImageUpload} />
            <button className="chat-image-send-button" onClick={handleSendMessage}>
                <img src="../../img/sendo.svg" alt="Send" />
            </button>
        </div>
    );
};

export default ChatInput;
