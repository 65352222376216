import React from 'react';
import { usePageDetails } from '../../Utility/PageDetailsContext';
import './GigIntro.css';

function GigIntro({ onToggleSettings, active, settingsRoute }) {
    const { pageDetails } = usePageDetails();

    const introTitle = pageDetails && pageDetails.introTitle ? pageDetails.introTitle : "Loading intro title...";
    const introText = pageDetails && pageDetails.introText ? pageDetails.introText : "Loading intro text...";

    // Function to handle click, which toggles the active setting
    const handleOnClick = () => {
        onToggleSettings('GigIntro');
    };

    // Build the class string conditionally
    const className = `gig-intro-wrapper ${settingsRoute ? 'settings-route' : ''} ${active ? 'is-active' : ''}`;

    return (
        <div className={className} onClick={handleOnClick}>
            <h2>{introTitle}</h2>
            <p>{introText}</p>
            {settingsRoute && (
                <div className="edit-overlay">
                    <img src="../../img/edit.webp" alt="Edit" />
                    <span>Edit</span>
                </div>
            )}
        </div>
    );
}

export default GigIntro;

