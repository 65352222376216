import React, { useState, useEffect } from 'react';
import { ref, set, get } from 'firebase/database';
import { database } from '../../firebaseConfig';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importiere Quill Styles
import HeaderLabel from '../PageUtility/HeaderLabel';
import CategoryLabel from '../PageUtility/CategoryLabel';
import './TextEditorComponent.css';

function TextEditorComponent({ pageId, widgetKey }) {
    const [text, setText] = useState('');

    // Lade den initialen Textinhalt aus Firebase beim Initialisieren und bei Änderungen von pageId oder widgetKey
    useEffect(() => {
        const textRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/longText`);
        get(textRef).then((snapshot) => {
            if (snapshot.exists()) {
                setText(snapshot.val());
            }
        }).catch(error => {
            console.error('Failed to fetch text data:', error);
        });
    }, [pageId, widgetKey]);

    const handleTextChange = (content) => {
        setText(content);
    };

    const toolbarOptions = [
        ['bold', 'italic', 'underline'],        // toggled buttons
        [{ 'color': ['var(--blue)', 'var(--red)'] }], // custom color choices
        [{ 'list': 'bullet' }],
        [{ 'header': [ 2, 3, 4, false] }],
    ];

    const saveText = () => {
        const textRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/longText`);
        set(textRef, text).then(() => {
        }).catch(error => {
            console.error('Failed to save text:', error);
        });
    };

    return (
        <div className="text-editor-container">
            <HeaderLabel text="Detailed Description" />
            <CategoryLabel text="Text Editor" />
            <div className="react-quill-container">
                <ReactQuill
                    value={text}
                    onChange={handleTextChange}
                    modules={{ toolbar: toolbarOptions }}
                />
            </div>
            <button className="text-editor-container-save" onClick={saveText}>Save Text</button>
        </div>
    );
}

export default TextEditorComponent;
