import React, { useState } from 'react';
import CollabSongs from './CollabSongs';
import HomeNewHeader from './HomeNewHeader';
import NewMobileHeader from './NewMobileHeader';
import CollabUnlockButton from './CollabUnlockButton';
import './CollabPage.css';
import CountryCounter from '../UserBackend/PageUtility/CountryCounter';

function CollabPage() {
  const [filteredSongIds, setFilteredSongIds] = useState([]);  // Zustand für die gefilterten Song-IDs

  return (
    <div className="collab-page">
      <HomeNewHeader />
      <NewMobileHeader />
      <div className="collab-page-header">
        <div className="collab-page-header-left">
          <h1 style={{color: 'white', textAlign: 'left', marginLeft: '2%', fontSize: '52px', marginBottom: '10px', fontFamily: 'Wide'}}>Collab & Connect <span>AI</span></h1>
          <p>Unleash your creativity and collaborate with top-tier artists from across the globe. With our CollabAI feature at your fingertips, take your music to the next level and start making connections that could change your sound—and your career—forever.</p>
        </div>
        <CollabUnlockButton />
      </div>
      <CountryCounter 
        setFilteredSongIds={setFilteredSongIds}  // Direktes Setzen der gefilterten IDs
      />
      <CollabSongs 
        filteredSongIds={filteredSongIds}  // Übergibt die gefilterten IDs an CollabSongs
      />
    </div>
  );
}

export default CollabPage;
