import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ref, get, set } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './PageSettings.css';
import PagePreview from './PagePreview';
import { usePageDetails } from '../../Utility/PageDetailsContext';
import WidgetSettings from './WidgetSettings';
import { useNavigate } from 'react-router-dom';
import SidebarSide from './SidebarSide';
import PageLiveToggle from '../PageUtility/PageStatusToggle';

function PageSettings() {
  const [loading, setLoading] = useState(true);
  const { pageId } = useParams();
  const { pageDetails, updatePageDetails } = usePageDetails();
  const [error, setError] = useState(null);
  const [activeSettingsType, setActiveSettingsType] = useState(null);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isWhiteMode, setIsWhiteMode] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(0);
  const [routeName, setRouteName] = useState('');
  const navigate = useNavigate();
  const [isLivePage, setIsLivePage] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const [widgetKey, setWidgetKey] = useState(null);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 900);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (isExpanded) {
      setActiveSettingsType(null);
    }
  };

  useEffect(() => {
    if (activeSettingsType && !isExpanded) {
      setIsExpanded(true);
    }
  }, [activeSettingsType, isExpanded]);

  useEffect(() => {
    const root = document.documentElement;
    if (isWhiteMode) {
      root.setAttribute('data-theme', 'white');
    } else {
      root.setAttribute('data-theme', 'black');
    }
  }, [isWhiteMode]);

  useEffect(() => {
    setLoading(true);
    const pageRef = ref(database, `Pages/${pageId}`);

    get(pageRef).then(snapshot => {
      if (snapshot.exists()) {
        const pageData = snapshot.val().pageData || {};
        const route = snapshot.val().route || '@DefaultRoute';
        updatePageDetails(pageData);
        setIsWhiteMode(pageData.isWhiteMode || false);
        setSelectedStyle(pageData.selectedStyle || 0);
        setRouteName(route);
        setIsLivePage(pageData.pageIsLive || false);
      } else {
        setError('No page details available.');
        console.error('No data available at this path:', pageRef.path);
      }
      setLoading(false);
    }).catch(error => {
      console.error('Error fetching page details:', error);
      setError('Failed to fetch page details.');
      setLoading(false);
    });
  }, [pageId, updatePageDetails]);

  if (!pageDetails) {
    return <div>Loading or no page found...</div>;
  }

  const toggleWhiteMode = () => {
    const newWhiteMode = !isWhiteMode;
    const modeRef = ref(database, `Pages/${pageId}/pageData/isWhiteMode`);
    set(modeRef, newWhiteMode).then(() => {
      setIsWhiteMode(newWhiteMode);
    }).catch(error => {
      console.error('Failed to update white mode:', error);
    });
  };

  const handleWidgetsButtonClick = () => {
    setActiveSettingsType('SelectWidgetSettings');
  };

  const handleToggleSettings = (settingsType, widgetKey) => {
    if (activeSettingsType === settingsType) {
      setActiveSettingsType(null);
      setWidgetKey(widgetKey);
    } else {
      setActiveSettingsType(settingsType);
      setWidgetKey(widgetKey);
    }
  };

  return (
    <div className="page-settings-container">
      <div className="page-settings-header">
        <div className="page-settings-header-left">
          <Link to="/pages">
            <img className="settings-header-logo" src="../../img/logo.svg" alt="" />
            <img className="pages-page-settings-back" src="../../img/down.svg" alt="" />
            <p style={{ marginLeft: '20px' }}>{routeName}</p>
          </Link>
        </div>
        <div className="page-settings-header-center"></div>
        <div className="page-settings-header-right">
          <PageLiveToggle pageId={pageId} />
          {isLivePage ? (
            <Link to={`/${routeName}`} target="_blank" rel="noopener noreferrer">
              <button>Your Page</button>
            </Link>
          ) : (
            <button disabled>Your Page</button>
          )}
        </div>
      </div>
      <div className="page-settings-layout">
        <img src="/img/curve.svg" className="dashboardNav-curve-2 top" alt="Curve Top" />
        <div className="page-settings-sidebar">
          {!isMobile && (
            <>
              <SidebarSide activeSettingsType={activeSettingsType} onFirstButtonClick={handleToggleSettings} onWidgetsButtonClick={handleWidgetsButtonClick} />
              <WidgetSettings pageId={pageId} activeSettingsType={activeSettingsType} widgetKey={widgetKey} /> 
            </>
          )}
        </div>
        <div className={`page-settings-bottom-mobile ${isExpanded ? 'expanded' : ''}`}>
          {isMobile && (
            <div className="settings-widget-mobile-container">
              <WidgetSettings widgetKey={widgetKey}  pageId={pageId} activeSettingsType={activeSettingsType} />
            </div>
          )}
          <div className="bottom-toggle-plus">
            <div className="plus-toggle-button" onClick={toggleExpand}>
              <img src="../../img/plus.svg" alt="Expand/Collapse" />
            </div>
            <p>Click Plus to Edit</p>
          </div>
        </div>
        <div className={`page-settings-main ${isLivePage ? 'Live' : 'Pedro'}`}>
        <PagePreview
    pageId={pageId}
    onToggleSettings={handleToggleSettings}
    activeSettingsType={activeSettingsType}
    isWhiteMode={isWhiteMode}
    selectedStyle={selectedStyle}
    isLivePage={false}
    setWidgetKey={setWidgetKey} // <--- Hier hinzugefügt
/>

        </div>
      </div>
    </div>
  );
}

export default PageSettings;
