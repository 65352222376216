import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import './SignUp.css'
import { Link } from 'react-router-dom';
import GenerativeOrbAnimation from '../Utility/GenerativeOrbAnimation';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        const auth = getAuth();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage("Check your email for a link to reset your password.");
        } catch (error) {
            setMessage("Error: " + error.message);
        }
    };

    return (
        <div className="login">
            <GenerativeOrbAnimation />
            <div className="login-container">
        <div className="signup-screen">
            <h2 className="login-header">Reset Password</h2>
            <p>Enter the email address associated with your account, and we'll send you a link to reset your password.</p>
            <form onSubmit={handlePasswordReset}>
                <div>
                    <label className="login-label">Email:</label>
                    <div className="login-input-wrapper">
                    <input type="email" className="login-input" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <img src="./img/mail.svg" alt="" />
                </div>
                </div>
                <button className="login-btn-1" type="submit">Send Reset Email</button>
            </form>
            <Link to="/signup" className="login-btn-5">Back to Sign-Up</Link>
            <p>{message}</p>
        </div>
        </div>
        </div>
    );
}

export default ForgotPassword;
