import React from 'react'
import DashboardMobileNav from './DashboardMobileNav'
import DashboardNav from './DashboardNav'
import GeneralSettings from './GeneralSettings'

function Settings() {
  return (
    <div>
        <DashboardNav />
        <DashboardMobileNav />
        <GeneralSettings />
    </div>
  )
}

export default Settings