import React, { useState, useEffect } from 'react';
import { ref as databaseRef, onValue } from 'firebase/database';
import { database } from '../../firebaseConfig';
import './GigReviewList.css';

const GigReviewList = ({ pageId }) => {
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        const reviewsRef = databaseRef(database, `/Pages/${pageId}/pageData/reviews`);

        onValue(reviewsRef, (snapshot) => {
            if (snapshot.exists()) {
                const reviewsData = snapshot.val();
                const reviewList = Object.entries(reviewsData).map(([key, review]) => ({
                    id: key,
                    ...review,
                }));

                // Sort reviews by timestamp in descending order
                reviewList.sort((a, b) => b.timestamp - a.timestamp);

                setReviews(reviewList);
            } else {
                setReviews([]);
            }
        });
    }, [pageId]);

    return (
        <div className="gig-review-list">
            {reviews.map((review) => (
                <GigReviewItem key={review.id} review={review} />
            ))}
        </div>
    );
};

const GigReviewItem = ({ review }) => {
    const [reviewWriterData, setReviewWriterData] = useState(null);

    useEffect(() => {
        if (review.reviewWriter) {
            const artistRef = databaseRef(database, `/Artists/${review.reviewWriter}`);

            onValue(artistRef, (snapshot) => {
                if (snapshot.exists()) {
                    setReviewWriterData(snapshot.val());
                }
            });
        }
    }, [review.reviewWriter]);

    const calculateOverallRating = () => {
        const { quality, delivery, communication } = review.ratings;
        return (quality + delivery + communication) / 3;
    };

    const renderStars = (score) => {
        const fullStars = Math.floor(score);
        const hasHalfStar = score % 1 !== 0;
        const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

        return (
            <div className="review-stars-mini">
                {Array.from({ length: fullStars }).map((_, index) => (
                    <img key={`full-${index}`} src="../../img/yellow_star.svg" alt="Full Star" className="star" />
                ))}
                {hasHalfStar && <img src="../../img/half_star.svg" alt="Half Star" className="star" />}
                {Array.from({ length: emptyStars }).map((_, index) => (
                    <img key={`empty-${index}`} src="../../img/grey_star.svg" alt="Empty Star" className="star" />
                ))}
            </div>
        );
    };

    const overallRating = calculateOverallRating();

    // Fallback to WriterName, WriterImg, and WriterType if reviewWriterData is not available
    const writerName = reviewWriterData?.name || review.WriterName;
    const writerImage = reviewWriterData?.artistImageUrl || review.WriterImg || '../img/placeholder_avatar.png';
    const writerType = reviewWriterData?.artistType || review.WriterType;

    return (
        <div className="gig-review-item-wraps">
            <div className="gig-review-item-single">
                <div className="gig-review-item-header">
                    <img
                        src={writerImage}
                        alt={writerName}
                        className="review-writer-image"
                    />

                    <div className="review-writer-info">
                        <div className="review-rating-mini">
                            {renderStars(overallRating)}
                            <p className="review-score-text-mini">{overallRating.toFixed(1)}</p>
                        </div>
                        <p className="review-writer-name">{writerName}</p>
                        <p className="review-writer-type">{writerType}</p>
                        <p className="review-timestamp">
                            {new Date(review.timestamp).toLocaleDateString()}
                        </p>
                    </div>
                    <p className="review-feedback">{review.feedback}</p>
                </div>
            </div>
        </div>
    );
};

export default GigReviewList;
