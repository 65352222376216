import React, { useEffect, useState } from 'react';
import { database } from '../../firebaseConfig';
import { ref, get } from 'firebase/database';
import './GigHighlights.css';

function GigHighlights({ pageId }) {
  const [highlights, setHighlights] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch artistId
        const artistIdRef = ref(database, `/Pages/${pageId}/artistId`);
        const artistIdSnapshot = await get(artistIdRef);
        if (artistIdSnapshot.exists()) {
          const artistId = artistIdSnapshot.val();

          // Fetch isVerified status
          const isVerifiedRef = ref(database, `/Pages/${pageId}/pageData/isVerified`);
          const isVerifiedSnapshot = await get(isVerifiedRef);
          const isVerified = isVerifiedSnapshot.exists() ? isVerifiedSnapshot.val() : false;

          // Fetch number of awards and nominations
          const artistRef = ref(database, `/Artists/${artistId}`);
          const artistSnapshot = await get(artistRef);
          if (artistSnapshot.exists()) {
            const artistData = artistSnapshot.val();
            const { numberAwards, numberNominated } = artistData;

            const hasAward = numberAwards > 0;
            const hasNomination = numberNominated > 0;

            if (isVerified || hasAward || hasNomination) {
              setHighlights({ isVerified, numberAwards, numberNominated });
            }
          }
        }
      } catch (error) {
        console.error('Failed to fetch artist highlights:', error);
      }
    };

    fetchData();
  }, [pageId]);

  if (!highlights) return null;

  return (
    <div className="gig-highlights-wrapper">
    <div className="gig-highlights-container">
      {highlights.isVerified && (
        <div className="highlight-verified">
          <img src="../../img/verified_2.webp" alt="Verified" className="highlight-icon" />
          <div className="highlight-info">
            <p className="highlight-title">ID Verified Seller</p>
            <small className="highlight-description">This is a AAA verified seller.</small>
          </div>
        </div>
      )}
      {highlights.numberAwards > 0 && (
        <div className="highlight-award">
          <img src="../../img/aaa_pass.webp" alt="Award" className="highlight-icon" />
          <div className="highlight-info">
            <p className="highlight-title">Award Winner</p>
            <small className="highlight-description">This artist has won {highlights.numberAwards} {highlights.numberAwards === 1 ? 'award' : 'awards'}.</small>
          </div>
        </div>
      )}
      {highlights.numberNominated > 0 && (
        <div className="highlight-nomination">
          <img src="../../img/aaa_pass.webp" alt="Nomination" className="highlight-icon" />
          <div className="highlight-info">
            <p className="highlight-title">Nominated Artist</p>
            <small className="highlight-description">This artist has been nominated for {highlights.numberNominated} {highlights.numberNominated === 1 ? 'nomination' : 'nominations'}.</small>
          </div>
        </div>
      )} 
    </div>
    </div>

  );
}

export default GigHighlights;
