import React from 'react';
import './CategoryLabel.css';

const CategoryLabel = ({ text }) => {
  return (
    <div className="category-label">
      <span>{text}</span>
      <hr />
    </div>
  );
};

export default CategoryLabel;
