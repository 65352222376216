import React, { useEffect, useState } from "react";
import { getDatabase, ref, runTransaction, onValue } from "firebase/database";

const LikeTrader = ({ walletID }) => {
  const db = getDatabase();
  const [likes, setLikes] = useState(0);

  // Hole die aktuellen Likes aus Firebase
  useEffect(() => {
    const walletRef = ref(db, `/IA/wallets/${walletID}/likes`);
    const unsubscribe = onValue(walletRef, (snapshot) => {
      setLikes(snapshot.val() || 0); // Setze Likes oder 0, falls nicht vorhanden
    });
    return () => unsubscribe();
  }, [db, walletID]);

  // Funktion zum Hinzufügen eines Likes
  const handleLike = () => {
    const walletRef = ref(db, `/IA/wallets/${walletID}/likes`);
    runTransaction(walletRef, (currentValue) => {
      return (currentValue || 0) + 1; // Erhöhe den Like-Zähler um 1
    }).catch((error) => console.error("Error updating likes:", error));
  };

  // Funktion zum Hinzufügen eines Dislikes
  const handleDislike = () => {
    const walletRef = ref(db, `/IA/wallets/${walletID}/likes`);
    runTransaction(walletRef, (currentValue) => {
      return (currentValue || 0) - 1; // Verringere den Like-Zähler um 1
    }).catch((error) => console.error("Error updating dislikes:", error));
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {/* Like Button */}
      <span
        style={{
          cursor: "pointer",
          fontSize: "24px",
          color: "red",
        }}
        onClick={handleLike}
      >
        ❤️
      </span>

      {/* Dislike Button */}
      <span
        style={{
          cursor: "pointer",
          fontSize: "24px",
          color: "darkred",
        }}
        onClick={handleDislike}
      >
        😡
      </span>

      {/* Likes/Dislikes Score */}
      <span style={{ fontSize: "18px" }}>{likes}</span>
    </div>
  );
};

export default LikeTrader;
