import React, { useState } from 'react';
import './BenefitItem.css'

const BenefitItem = ({ icon, title, desc, benefit1, benefit2, benefit3, showDivider }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleInfo = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="all-pages-benefit">
      <div>
        <img src={icon} alt={title} />
        <div>
          <h4>{title}</h4>
        </div>
        <button className={`plus-button-all-pages ${isOpen ? 'open' : ''}`} onClick={toggleInfo}>
          <img src="../../img/down.webp" alt="" />
        </button>
      </div>
      <div className={`all-pages-info-points ${isOpen ? 'open' : ''}`}>  {/* Ändern der Klassen basierend auf dem Zustand */}
      <p>{desc}</p>
        <div className="all-pages-info-point">
          <img src="../../img/check.webp" alt="" />
          <span>{benefit1}</span>
        </div>
        <div className="all-pages-info-point">
          <img src="../../img/check.webp" alt="" />
          <span>{benefit2}</span>
        </div>
        <div className="all-pages-info-point">
          <img src="../../img/check.webp" alt="" />
          <span>{benefit3}</span>
        </div>
      </div>
      {showDivider && <hr style={{width:'100%', backgroundColor: '#424242', border: 'none', height: '1px'}}/>}
    </div>
  );
};

export default BenefitItem;
