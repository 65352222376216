const services = [
  { 
    name: "Ghostwriting", 
    description: "I will write lyrics that have the potential to be a hit.", 
    img: "../../service/writing.svg",
    type: "Music Artist" 
  },
  { 
    name: "Song Production", 
    description: "I will produce a full song from the initial melody to final mastering.", 
    img: "../../service/production.svg",
    type: "Music Artist" 
  },
  { 
    name: "Beat Production", 
    description: "I will create custom beats for other artists.", 
    img: "../../service/drum.svg",
    type: "Music Artist"
  },
  { 
    name: "Vocal Feature", 
    description: "I will provide vocal features on songs of other music artists.", 
    img: "../../service/vocal.svg",
    type: "Music Artist"
  },
  { 
    name: "Rap Feature", 
    description: "I will record rap verses and features.", 
    img: "../../service/rap.svg",
    type: "Music Artist"
  },
  { 
    name: "Instrument Recordings", 
    description: "I will offer high-quality recordings of my instruments.", 
    img: "../../service/instrument.svg",
    type: "Music Artist"
  },
  { 
    name: "Mix & Master", 
    description: "I will mix and master the tracks of aspiring artists.", 
    img: "../../service/mix.svg",
    type: "Music Artist"
  },
  { 
    name: "Sell Song Licenses", 
    description: "I will sell my songs for various uses, including commercials, movies, and more.", 
    img: "../../service/cd.svg",
    type: "Music Artist"
  },
  { 
    name: "Artist Management", 
    description: "I will guide your career, handling business affairs to boost your artistic success.", 
    img: "../../service/management.svg", 
    type: "Music Industry" 
  },
  { 
    name: "YouTube Promotion", 
    description: "I will amplify your presence on YouTube to attract more viewers and subscribers.", 
    img: "../../service/youtube.svg",
    type: "Music Industry"
  },
  { 
    name: "Spotify Promotion", 
    description: "I will enhance your visibility on Spotify, increasing streams and followers.", 
    img: "../../service/spotify.svg",
    type: "Music Industry"
  },
  { 
    name: "Blog/Magazine Promotion", 
    description: "I will feature your music in leading blogs and magazines for greater exposure.", 
    img: "../../service/blog.svg",
    type: "Music Industry"
  },
  { 
    name: "Graphic Design", 
    description: "I create bespoke graphic design solutions tailored specifically to your needs.", 
    img: "../../service/pen-tablet.svg",
    type: "Music Industry"
  },
  { 
    name: "Video Editing", 
    description: "I edit your music videos in the style that best reflects your vision.", 
    img: "../../service/trim.svg",
    type: "Music Industry"
  },
  { 
    name: "Music Mentoring", 
    description: "I will offer personalized advice and strategies to help you thrive in the music industry.", 
    img: "../../service/mentoring.svg",
    type: "Music Industry"
  },
  { 
    name: "TikTok Influencer", 
    description: "I will leverage my TikTok influence to dramatically increase your music's reach.", 
    img: "../../service/tiktok.svg",
    type: "Music Industry"
  },
  { 
    name: "Instagram Influencer", 
    description: "I will use my Instagram platform to promote your tracks and grow your fanbase.", 
    img: "../../service/instagram.svg",
    type: "Music Industry"
  }
];

export default services;
