import React from 'react';
import './SubGenreList.css'

const SubGenreList = ({ onChange, value, className }) => {
  const genres = [
    "HipHop", "Pop", "Pop Rock", "Indie",
    "Trap", "Old School HipHop", "Emo Rap",
    "R&B", "Soul", "Reggae", "Dancehall", "Akustik", "EDM", "House",  "Funk",
  ];

  return (
    <select className={className} onChange={onChange} value={value}>
            <option value="">Select Genre...</option>
      {genres.map(genre => (
        <option key={genre} value={genre}>{genre}</option>
      ))}
    </select>
  );
};

export default SubGenreList;
