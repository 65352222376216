import React, { useState } from 'react';
import './FAQPage.css';

const faqs = [
  { question: 'Is uploading my demo really free?', answer: 'Yes, you can upload your demo for free! If you`re looking for more features and flexibility, we also offer a Pro account with extra benefits.'},
  { question: 'How will I know if I’ve won an award?', answer: 'You’ll receive an email notification if you’ve won or been nominated for an award—so keep an eye on your inbox for the exciting news! Also check spam.' },
  { question: 'How many songs can I upload?', answer: 'With a free account, you can upload one song. But with a Pro account, the sky`s the limit—you can upload unlimited tracks.' },
  { question: 'Why is the Pro account worth it, and how much does it cost?', answer: 'Our AAA Pro account is currently only €79/year, giving you access to connect with artists worldwide, grow your network, and thrive in the industry for less than the price of a pizza per month.' },
  { question: 'Who’s behind the AAA jury that will rate my music?', answer: 'As the successor of Democharts.org founded in 2018, we have a jury of over 500 industry professionals, including scouts from top brands like Disney and Nike. Depending on availability, experts from this pool will rate your song and feature it in our charts.' },
  { question: 'How does the award voting process really work?', answer: 'Voting is done by our expert panel, who evaluate submissions based on several criteria, helping the best talent rise to the top.' },
  { question: 'How will I receive my award?', answer: 'You can download your customized digital AAA award directly from your dashboard. There’s also an option to get a physical plaque for your studio for $249, including shipping.' },
  { question: 'What kind of exposure can I expect as an artist on Triple A?  ', answer: 'Triple A provides opportunities to get noticed by industry scouts, featured in curated playlists, and even win your first music award—giving your career a major boost.' },
];

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bc-faq-page-container">
      <h1 className="bc-faq-title">Frequently Asked Questions</h1>
      <div className="bc-faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="bc-faq-item">
            <div className="bc-faq-question-container" onClick={() => toggleFAQ(index)}>
              <p className="bc-faq-question">{faq.question}</p>
              <span className="bc-faq-icon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div className={`bc-faq-answer ${activeIndex === index ? 'active' : ''}`}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQPage;
