import React, { useState, useEffect } from 'react';
import { ref, get, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';
import ResponseRateStats from './ResponseRateStats';

function PromoChats() {
  const [promoChats, setPromoChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('Overall');
  const [responseRates, setResponseRates] = useState({});
  const [responseRatesByInitiator, setResponseRatesByInitiator] = useState({});

  useEffect(() => {
    const fetchPromoChats = async () => {
      try {
        const tasksRef = ref(database, 'Tasks');
        const tasksSnapshot = await get(tasksRef);
        const tasksData = tasksSnapshot.val();

        // Extract all senderUserIds from tasks
        const senderUserIds = new Set();
        if (tasksData) {
          Object.keys(tasksData).forEach(taskName => {
            const task = tasksData[taskName];
            if (task.senderUserId) {
              senderUserIds.add(task.senderUserId);
            }
          });
        }

        console.log('Extracted senderUserIds from tasks:', Array.from(senderUserIds));

        // Array to hold promo chat details
        const chatsWithDetails = [];

        // Fetch all chats and include those initiated by our senderUserIds
        const chatsRef = ref(database, 'Chats');
        onValue(chatsRef, async (snapshot) => {
          const chatData = snapshot.val();

          if (chatData) {
            const artistNamePromises = [];

            Object.keys(chatData).forEach(chatId => {
              const chat = chatData[chatId];

              // Check if the initiatorId is in the senderUserIds set
              if (senderUserIds.has(chat.initiatorId)) {
                const messages = chat.messages || {};
                const messageKeys = Object.keys(messages);
                const lastMessageKey = messageKeys[messageKeys.length - 1];
                const lastMessage = lastMessageKey ? messages[lastMessageKey] : null;

                // Determine if a response exists (a reply from the recipient)
                const hasResponse = messageKeys.some(key => messages[key].senderId !== chat.initiatorId);

                // Prepare a promise to fetch initiator and recipient names
                const initiatorNameRef = ref(database, `Artists/${chat.initiatorId}/name`);
                const recipientNameRef = ref(database, `Artists/${chat.initiatorId_recipientId.replace(`${chat.initiatorId}_`, '')}/name`);
                const lastMessageSenderNameRef = lastMessage ? ref(database, `Artists/${lastMessage.senderId}/name`) : null;

                artistNamePromises.push(
                  Promise.all([
                    get(initiatorNameRef),
                    get(recipientNameRef),
                    lastMessageSenderNameRef ? get(lastMessageSenderNameRef) : Promise.resolve({ val: () => 'Unknown' })
                  ]).then(([initiatorSnapshot, recipientSnapshot, senderSnapshot]) => {
                    const initiatorName = initiatorSnapshot.exists() ? initiatorSnapshot.val() : 'Unknown';
                    const recipientName = recipientSnapshot.exists() ? recipientSnapshot.val() : 'Unknown';
                    const lastMessageSenderName = senderSnapshot.exists() ? senderSnapshot.val() : 'Unknown';

                    chatsWithDetails.push({
                      chatId,
                      initiatorId: chat.initiatorId,
                      initiatorName,
                      recipientName,
                      lastMessage: lastMessage ? lastMessage.message : 'No messages',
                      lastMessageSenderName,
                      lastMessageReadStatus: lastMessage ? lastMessage.readStatus : 'N/A',
                      lastMessageAt: chat.lastMessageAt,
                      messageCount: messageKeys.length,
                      responseStatus: hasResponse ? 'Replied' : 'No Reply',
                    });
                  })
                );
              }
            });

            await Promise.all(artistNamePromises);

            // Sort chats by the lastMessageAt in descending order (most recent first)
            chatsWithDetails.sort((a, b) => new Date(b.lastMessageAt) - new Date(a.lastMessageAt));
            setPromoChats(chatsWithDetails);

            // Calculate response rates per month and by initiator
            calculateResponseRates(chatsWithDetails);
          }
          setLoading(false);
        });
      } catch (error) {
        console.error('Error fetching promo chats:', error);
        setLoading(false);
      }
    };

    const calculateResponseRates = (chats) => {
      const now = new Date();
      const responseRatesData = {};
      const responseRatesByInitiator = {};
    
      // Generate data for each of the last 6 months plus "Overall"
      for (let i = 0; i <= 6; i++) {
        const month = new Date(now.getFullYear(), now.getMonth() - i);
        const monthKey = month.toLocaleString('default', { month: 'long', year: 'numeric' });
    
        const chatsForMonth = chats.filter(chat => {
          const chatDate = new Date(chat.lastMessageAt);
          return chatDate.getMonth() === month.getMonth() && chatDate.getFullYear() === month.getFullYear();
        });
    
        const totalChats = chatsForMonth.length;
        const repliedChats = chatsForMonth.filter(chat => chat.responseStatus === 'Replied').length;
    
        responseRatesData[monthKey] = {
          totalChats,
          repliedChats,
          responseRate: totalChats > 0 ? Math.round((repliedChats / totalChats) * 100) : 0,
        };
      }
    
      // Calculate the overall response rate
      const totalChats = chats.length;
      const repliedChats = chats.filter(chat => chat.responseStatus === 'Replied').length;
      responseRatesData['Overall'] = {
        totalChats,
        repliedChats,
        responseRate: totalChats > 0 ? Math.round((repliedChats / totalChats) * 100) : 0,
      };
    
      // Calculate response rates per initiator with names
      chats.forEach(chat => {
        const { initiatorId, initiatorName, responseStatus } = chat;
    
        if (!responseRatesByInitiator[initiatorName]) {
          responseRatesByInitiator[initiatorName] = { totalChats: 0, repliedChats: 0 };
        }
    
        responseRatesByInitiator[initiatorName].totalChats += 1;
        if (responseStatus === 'Replied') {
          responseRatesByInitiator[initiatorName].repliedChats += 1;
        }
      });
    
      // Calculate response rates for each initiator
      Object.keys(responseRatesByInitiator).forEach(initiatorName => {
        const { totalChats, repliedChats } = responseRatesByInitiator[initiatorName];
        responseRatesByInitiator[initiatorName].responseRate = totalChats > 0 ? Math.round((repliedChats / totalChats) * 100) : 0;
      });
    
      setResponseRates(responseRatesData);
      setResponseRatesByInitiator(responseRatesByInitiator);
    };
    

    fetchPromoChats();
  }, []);

  if (loading) {
    return <div>Loading promo chats...</div>;
  }

  return (
    <div>
      <h2>Promo Chats</h2>
      <ResponseRateStats
        responseRates={responseRates}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        responseRatesByInitiator={responseRatesByInitiator}
      />

      {/* Other content like promoChats table */}
      <h2>Promo Chats with Unread Messages</h2>
      {promoChats.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Initiator Name</th>
              <th>Recipient Name</th>
              <th>Last Message</th>
              <th>Last Message Sender</th>
              <th>Read Status</th>
              <th>Last Message At</th>
              <th>Message Count</th>
              <th>Response Status</th>
            </tr>
          </thead>
          <tbody>
            {promoChats.map(chat => (
              <tr key={chat.chatId}>
                <td>{chat.initiatorName}</td>
                <td>{chat.recipientName}</td>
                <td>{chat.lastMessage}</td>
                <td>{chat.lastMessageSenderName}</td>
                <td>{chat.lastMessageReadStatus ? 'Read' : 'Unread'}</td>
                <td>{new Date(chat.lastMessageAt).toLocaleString()}</td>
                <td>{chat.messageCount}</td>
                <td>{chat.responseStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No promo chats found.</p>
      )}
    </div>
  );
}

export default PromoChats;
