import React from 'react';
import { motion } from 'framer-motion';

const PageTransition = ({ children }) => {
  return (
    <motion.div
      initial={{ y: -20, opacity: 0 }}  // Startposition etwas oberhalb
      animate={{ y: 0, opacity: 1 }}    // Endposition mit Sichtbarkeit
      exit={{ y: 20, opacity: 0 }}      // Beim Verlassen leicht nach unten mit Ausblendung
      transition={{
        duration: 0.8,                  // Verlängerte Dauer für einen flüssigeren Übergang
        delay: 0,                       // Verzögerung vor dem Beginn der Animation
        ease: [0.22, 1, 0.36, 1]        // Custom Cubic Bezier Curve für eine natürliche Bewegung
      }}
    >
      {children}
    </motion.div>
  );
};

export default PageTransition;
