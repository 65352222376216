import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import für die Navigation
import './PremiumSubButton.css';
import { ref as databaseRef, push } from 'firebase/database';
import { database } from '../firebaseConfig';

// Define button variants with types and texts
const buttonVariants = [
  { type: "Type 1", text: "Upgrade Now" },
  { type: "Type 1", text: "Go VIP" },
  { type: "Type 2", text: "VIP Access" },
  { type: "Type 2", text: "VIP Area" },
  { type: "Type 3", text: "UNLOCK VIP" },
  { type: "Type 3", text: "Secret Features" },
];

function PremiumSubButton() {
  const [buttonVariant, setButtonVariant] = useState(null);
  const navigate = useNavigate(); // Initialisiere den Navigator

  useEffect(() => {
    // Randomly select a button variant on component mount
    const randomVariant = buttonVariants[Math.floor(Math.random() * buttonVariants.length)];
    setButtonVariant(randomVariant);
  }, []);

  const handlePremiumClick = () => {
    const clickData = {
      type: buttonVariant.type,
      variant: buttonVariant.text,
      timestamp: Date.now(),
    };

    // Push click data to the database
    const analyticsRef = databaseRef(database, '/Analytics/premiumButtonClicks');
    push(analyticsRef, clickData);

    // Optional analytics tracking
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'premium_button_click',
        type: buttonVariant.type,
        variant: buttonVariant.text,
      });
    }

    // Navigate to /buy
    navigate('/vip');
  };

  return (
    <>
      {buttonVariant && buttonVariant.type === "Type 1" && (
        <button className="premium-sub-button-1" onClick={handlePremiumClick}>
          {buttonVariant.text}
        </button>
      )}
      {buttonVariant && buttonVariant.type === "Type 2" && (
        <button className="premium-sub-button-2" onClick={handlePremiumClick}>
          <div className="premium-sub-button-2-text">
            <h4>{buttonVariant.text}</h4>
          </div>
          <img src="../../img/aaa_pass.webp" alt="" />
        </button>
      )}
      {buttonVariant && buttonVariant.type === "Type 3" && (
        <button className="premium-sub-button-2" onClick={handlePremiumClick}>
          <div className="premium-sub-button-2-text">
            <h4>{buttonVariant.text}</h4>
          </div>
          <img src="../../img/lock.svg" alt="" />
        </button>
      )}
    </>
  );
}

export default PremiumSubButton;
