import React, { useState, useEffect, useCallback } from 'react';
import { ref, get, update } from 'firebase/database';
import { database } from '../../firebaseConfig';
import { usePageDetails } from '../../Utility/PageDetailsContext';
import ButtonSelection from '../PageUtility/ButtonSelection';
import HeaderLabel from '../PageUtility/HeaderLabel';
import CategoryLabel from '../PageUtility/CategoryLabel';
import SettingsSingleInput from '../PageUtility/SettingsSingleInput';
import { availableGenres, availableMoods, availableTypes } from '../PageUtility/AvailableInfos';

function GigSellerInfoSettings({ pageId }) {
    const { pageDetails } = usePageDetails();
    const [isLoading, setLoading] = useState(true);
    const [experience, setExperience] = useState('');
    const [genres, setGenres] = useState([]);
    const [moods, setMoods] = useState([]);
    const [serviceType, setServiceType] = useState('');
    const [companyTypes, setCompanyTypes] = useState([]);
    const [gigRepresentative, setGigRepresentative] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (pageId) {
            const pageDataRef = ref(database, `Pages/${pageId}/pageData`);
            get(pageDataRef).then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setExperience(data.experience || '');
                    setGenres(data.genres || []);
                    setMoods(data.moods || []);
                    setServiceType(data.serviceType || '');
                    setCompanyTypes(data.companyType || []);
                    setGigRepresentative(data.gigRepresentative || '');
                    setLoading(false);
                }
            }).catch(error => {
                console.error('Failed to fetch page data:', error);
                setError('Failed to fetch page data');
            });
        }
    }, [pageId]);

    const savePageData = (data) => {
        if (pageId) {
            update(ref(database, `Pages/${pageId}/pageData`), data).catch(error => {
                console.error('Failed to save page data:', error);
                setError('Failed to save settings: ' + error.message);
            });
        }
    };

    // Debounce function to delay the API call
    const debounce = (func, delay) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), delay);
        };
    };

    const handleGigRepresentativeChange = (value) => {
        setGigRepresentative(value);
        debouncedSavePageData({ gigRepresentative: value });
    };

    const debouncedSavePageData = useCallback(debounce(savePageData, 500), []); // 500ms delay

    const handleExperienceChange = (value) => {
        setExperience(value);
        savePageData({ experience: value });
    };

    const handleGenresChange = (value) => {
        setGenres(value);
        savePageData({ genres: value });
    };

    const handleMoodsChange = (value) => {
        setMoods(value);
        savePageData({ moods: value });
    };

    const handleCompanyTypeChange = (value) => {
        setCompanyTypes(value);
        savePageData({ companyType: value });
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{marginBottom: '60px'}}>
            <HeaderLabel text="Details" />
            <CategoryLabel text="Basic Information"/>
            <SettingsSingleInput
                value={gigRepresentative}
                onChange={e => handleGigRepresentativeChange(e.target.value)}
                placeholder="Enter Gig Representative"
                labelText="Gig Representative"
            />
            <ButtonSelection
                options={Array.from({ length: 9 }, (_, i) => `${i + 1} Years`).concat("10+ Years")}
                value={experience}
                onChange={handleExperienceChange}
                labelText="Experience"
                className="mood-selection-container-2"
            />
            <ButtonSelection
                options={availableGenres}
                value={genres}
                onChange={handleGenresChange}
                labelText="Genres"
                multiSelect
                className="mood-selection-container-2"
                maxSelections={6}
                minSelections={1}
            />
            {serviceType === "Music Industry" ? (
                <ButtonSelection
                    options={availableTypes}
                    value={companyTypes}
                    onChange={handleCompanyTypeChange}
                    labelText="Company Types"
                    multiSelect
                    className="mood-selection-container-2"
                    maxSelections={2}
                    minSelections={1} 
                />
            ) : (
                <ButtonSelection
                    options={availableMoods}
                    value={moods}
                    onChange={handleMoodsChange}
                    labelText="Moods"
                    multiSelect
                    className="mood-selection-container-2"
                    maxSelections={4}
                    minSelections={1} 
                />
            )}
        </div>
    );
}

export default GigSellerInfoSettings;
