// EquipmentList.js
import React from 'react';

function EquipmentList({ onChange, value }) {
  // List of studio equipment
  const equipmentItems = [
    'Microphone', 'Synthesizer', 'Audio Interface', 'MIDI Keyboard',
    'Studio Monitors', 'Headphones', 'Sequencer', 'Drum Machine',
    'Compressor', 'Equalizer', 'Reverb Unit', 'Delay Unit',
    'Sampler', 'Mixing Desk', 'DAW Software', 'Looper', 'Guitar Amplifier',
    'Bass Amplifier', 'Effect Pedals', 'Vocoder', 'Dynamic Microphone',
    'Condenser Microphone', 'Tape Machine', 'Digital Recorder', 'Field Recorder',
    'DI Box', 'Preamp', 'Master Keyboard', 'Drum Pad', 'Audio Plugin'
  ];

  return (
    <select onChange={onChange} value={value} className="equipment-select">
      <option value="">Select Equipment...</option>
      {equipmentItems.map((item, index) => (
        <option key={index} value={item}>{item}</option>
      ))}
    </select>
  );
}

export default EquipmentList;
