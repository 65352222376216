import React, { useState, useEffect, useRef } from 'react';
import { ref, set, get, push, remove } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { database } from '../../firebaseConfig';
import HeaderLabel from '../PageUtility/HeaderLabel';
import CategoryLabel from '../PageUtility/CategoryLabel';

function GigSongsSettings({ pageId, widgetKey }) {
    const [songs, setSongs] = useState([]);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [songName, setSongName] = useState('');
    const [uploading, setUploading] = useState(false);
    const audioRefs = useRef([]);

    useEffect(() => {
        const songsRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/songs`);
        get(songsRef).then((snapshot) => {
            if (snapshot.exists()) {
                setSongs(Object.entries(snapshot.val()).map(([key, value]) => ({ id: key, ...value })));
            }
        }).catch(error => {
            console.error('Failed to fetch song data:', error);
        });
    }, [pageId, widgetKey]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
            if (fileExtension === "mp3" || fileExtension === "wav") {
                setFile(selectedFile);
                setFileName(selectedFile.name);
            } else {
                alert("Only MP3 or WAV files are allowed.");
                event.target.value = null;
            }
        }
    };

    const addNewSong = () => {
        if (!file || !songName) {
            alert('Please provide both a song file and a name.');
            return;
        }

        setUploading(true);

        const storage = getStorage();
        const songFileRef = storageRef(storage, `songs/${pageId}/${file.name}`);
        uploadBytes(songFileRef, file).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                const newSongRef = push(ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/songs`));
                const newSong = { id: newSongRef.key, name: songName, fileUrl: downloadURL };

                set(newSongRef, newSong).then(() => {
                    setSongs([...songs, newSong]);
                    setSongName('');
                    setFile(null);
                    setUploading(false);
                    setFileName('');
                }).catch(error => {
                    console.error('Failed to add new song:', error);
                    setUploading(false);
                });
            });
        }).catch(error => {
            console.error('Failed to upload file:', error);
            setUploading(false);
        });
    };

    const deleteSong = (songId) => {
        const songRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/songs/${songId}`);
        remove(songRef).then(() => {
            setSongs(songs.filter(song => song.id !== songId));
        }).catch(error => {
            console.error('Failed to delete song:', error);
        });
    };

    const togglePlay = (index) => {
        const currentAudio = audioRefs.current[index];
        if (currentAudio) {
            if (currentAudio.paused) {
                currentAudio.play();
                setSongs(songs.map((song, idx) => idx === index ? { ...song, playing: true } : { ...song, playing: false }));
            } else {
                currentAudio.pause();
                setSongs(songs.map((song, idx) => idx === index ? { ...song, playing: false } : song));
            }
        }
    };

    return (
        <div className="gig-songs-settings-container">
            <HeaderLabel text="Upload Songs" />
            <CategoryLabel text="Song Upload" />
            <div className="gig-songs-settings-list">
                {songs.map((song, index) => (
                    <div key={song.id} className="gig-songs-settings-song">
                        <audio ref={el => audioRefs.current[index] = el} src={song.fileUrl} hidden />
                        <button onClick={() => togglePlay(index)} className={`gig-songs-settings-play-pause-btn ${song.playing ? 'pause' : 'play'}`}>
                            <img src={song.playing ? '../../img/pause.svg' : '../../img/play.svg'} alt={song.playing ? 'Pause' : 'Play'} />
                        </button>
                        <span className="song-name">{song && song.name}</span>
                        <button className="gig-songs-settings-delete-btn" onClick={() => deleteSong(song.id)}>Delete</button>
                    </div>
                ))}
            </div>
            {uploading && (
                <div className="unique-image-display" style={{ height: '100px' }}>
                    <div className="unique-image-placeholder">
                        <img style={{ width: '30px' }} className="uploading-image" src="../../img/icon.png" alt="" />
                        <div className="spinner-3"></div>
                    </div>
                </div>
            )}
            <div className="gig-songs-settings-add-new">
                {!uploading && (
                    <>
                        <label htmlFor="song-upload" className="gig-songs-settings-upload-label">
                            {fileName || <div className="gig-choose-file-icon"><p>Select File</p><img src="../../img/upload.svg" alt="" /></div>}
                            <input
                                id="song-upload"
                                type="file"
                                onChange={handleFileChange}
                                className="gig-songs-settings-upload-input"
                                hidden
                                accept=".mp3,.wav"
                            />
                        </label>
                        <input
                            className="gig-songs-settings-name-input"
                            type="text"
                            placeholder="Enter song name"
                            value={songName}
                            onChange={(e) => setSongName(e.target.value)}
                        />
                        <button className="gig-songs-settings-add-btn" onClick={addNewSong}>Add Song</button>
                    </>
                )}
            </div>
        </div>
    );
}

export default GigSongsSettings;

