import React from 'react';

function SettingsSingleTextarea({ value, onChange, placeholder, labelText, maxLength }) {
    return (
        <div className="settings-single-textarea-container">
            {labelText && <label className="settings-single-textarea-label">{labelText}</label>}
            <textarea
                className="settings-single-textarea-field"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                maxLength={maxLength}  
            />
        </div>
    );
}

export default SettingsSingleTextarea;
