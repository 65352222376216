import React from 'react';

function ResponseRateStats({ responseRates, selectedTab, setSelectedTab, responseRatesByInitiator }) {
  const renderTabs = () => {
    return Object.keys(responseRates).map((month) => (
      <button
        key={month}
        onClick={() => setSelectedTab(month)}
        className={selectedTab === month ? 'active-tab' : ''}
      >
        {month}
      </button>
    ));
  };

  return (
    <div>
      <h2>Response Rate Statistics</h2>
      <div className="tabs">{renderTabs()}</div>

      <h3>{selectedTab} Response Rate</h3>
      <p>Total Chats: {responseRates[selectedTab]?.totalChats}</p>
      <p>Replied Chats: {responseRates[selectedTab]?.repliedChats}</p>
      <p>Response Rate: {responseRates[selectedTab]?.responseRate}%</p>

      <h2>Response Rates by Initiator</h2>
      {Object.keys(responseRatesByInitiator).length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Initiator Name</th>
              <th>Total Chats</th>
              <th>Replied Chats</th>
              <th>Response Rate (%)</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(responseRatesByInitiator).map(([initiatorName, stats]) => (
              <tr key={initiatorName}>
                <td>{initiatorName}</td>
                <td>{stats.totalChats}</td>
                <td>{stats.repliedChats}</td>
                <td>{stats.responseRate}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No response rate data available for initiators.</p>
      )}
    </div>
  );
}

export default ResponseRateStats;

