import React, { useState, useEffect, useCallback } from 'react';
import { ref as databaseRef, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import SaveFavoritesButton from '../UserBackend/PageUtility/SaveFavoritesButton';
import GigDetailedPreview from './GigDetailedPreview';
import './AllGigs.css';
import LoadingScreen from '../Utility/LoadingScreen';
import DashboardNav from '../UserBackend/DashboardNav';
import HomeHeader from './HomeHeader';
import GigFilterComponent from '../Market/GigFilterComponent';
import { Link } from 'react-router-dom';

const AllGigs = () => {
    const [gigs, setGigs] = useState([]);
    const [filteredGigs, setFilteredGigs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const services = [
        "Artist Management",
        "Blog/Magazine Promotion",
        "Beat Production",
        "Ghostwriting",
        "Vocal Feature",
        "Song Production",
        "Rap Feature",
        "Instrument Recordings",
        "Mix & Master",
        "Sell Song Licenses",
        "YouTube Promotion",
        "Spotify Promotion",
        "Graphic Design",
        "Video Editing",
        "Music Mentoring",
        "TikTok Influencer",
        "Instagram Influencer"
    ];

    useEffect(() => {
        const fetchGigs = async () => {
            const gigsData = [];
            const pagesRef = databaseRef(database, '/Pages');
    
            const snapshot = await get(pagesRef);
            if (snapshot.exists()) {
                const pages = snapshot.val();
                for (const pageId in pages) {
                    const page = pages[pageId];
                    if (page.pageData && page.pageData.offers && page.pageData.offers.length > 0 &&
                        page.pageData.pageIsLive === true &&
                        page.pageData.addToMarketplace && page.pageData.addToMarketplace.confirmed === true &&
                        page.pageData.isApproved === true) {
                        
                        const offer = page.pageData.offers[0];
                        const artistId = page.artistId;

                        // Künstlerinformationen laden
                        const artistRef = databaseRef(database, `/Artists/${artistId}`);
                        const artistSnapshot = await get(artistRef);
                        let artistInfo = {};
                        if (artistSnapshot.exists()) {
                            artistInfo = artistSnapshot.val();
                        } else {
                            continue;  // Überspringt diesen Gig, wenn keine Künstlerdaten gefunden wurden
                        }

                        // Prüfen, ob die Seite hervorgehoben ist
                        const isHighlight = page.pageData.isHighlight || false;

                        // Zusammenstellen der kompletten Gig-Informationen
                        const gigInfo = {
                            pageId,
                            artistId,
                            artistName: artistInfo.artistName || 'Unbekannt',
                            artistImageUrl: artistInfo.artistImageUrl || '',
                            artistType: artistInfo.artistType || 'Unbekannt',
                            country: artistInfo.country || 'Unbekannt',
                            route: page.route || '',
                            directMessageEnabled: page.pageData.directMessageEnabled || false,
                            service: page.pageData.service,
                            gigTitle: offer.gigTitle,
                            gigText: offer.gigText,
                            earnAmount: offer.earnAmount,
                            gigTime: offer.gigTime,
                            payoutOption: page.pageData.payoutOption, 
                            gallery: page.pageData.gallery ? Object.values(page.pageData.gallery) : [],
                            isHighlight // Hinzufügen der Hervorhebungsinformation
                        };
                        gigsData.push(gigInfo);
                    }
                }

                // Sortieren der Gigs nach Hervorhebung
                gigsData.sort((a, b) => b.isHighlight - a.isHighlight);
            }
            setGigs(gigsData);
            setFilteredGigs(gigsData); // Setze initial alle Gigs als gefilterte Gigs
            setIsLoading(false);
        };
    
        fetchGigs();
    }, []);

    const handleFilterChange = useCallback((searchTerm, category) => {
        let filtered = gigs;

        if (searchTerm) {
            filtered = filtered.filter(gig =>
                gig.gigTitle && gig.gigTitle.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (category) {
            filtered = filtered.filter(gig => gig.service === category);
        }

        setFilteredGigs(filtered);
    }, [gigs]);

    if (isLoading) {
        return <div>
            <LoadingScreen />
        </div>;
    }

    return (
        <div>
            <GigFilterComponent services={services} onFilterChange={handleFilterChange} />
            <div className="gigs-basic-grid">
                {filteredGigs.length > 0 ? (
                    filteredGigs.map((gig, index) => (
                        <div key={gig.pageId} className="basic-gig-item">
                            <SaveFavoritesButton artistId={gig.artistId} pageId={gig.pageId} className="save-button-container" />
                            <GigDetailedPreview gig={gig} />
                        </div>
                    ))
                ) : (
                    <div className="no-gigs-message">
                        <h2>No Gigs Yet</h2>
                        <p>No gigs available at the moment. Please check back later.</p>
                        <Link to="/pages">Be the first</Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllGigs;
