import React, { useEffect, useState } from 'react';
import { ref as databaseRef, onValue } from 'firebase/database';
import { database } from "../firebaseConfig";
import { Bar } from 'react-chartjs-2';
import './ArtistStats.css'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import WeeklyStats from './WeeklyStats';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function getMonthName(date) {
  return date.toLocaleDateString('en-US', { month: 'long' });
}



function ArtistStats() {
    const [signupStats, setSignupStats] = useState({
        thisMonth: 0,
        lastMonth: 0,
        twoMonthsAgo: 0,
      });
      const [totalSignups, setTotalSignups] = useState(0);
    
      // 'now' außerhalb von useEffect definieren, um es in der ganzen Komponente verfügbar zu machen
      const now = new Date();
      const labels = [
        getMonthName(new Date(now.getFullYear(), now.getMonth() - 2, 1)),
        getMonthName(new Date(now.getFullYear(), now.getMonth() - 1, 1)),
        getMonthName(new Date(now.getFullYear(), now.getMonth(), 1))
      ];
    
      useEffect(() => {
        const artistsRef = databaseRef(database, 'Artists');
        onValue(artistsRef, (snapshot) => {
          const artistsData = snapshot.val();
          const artistsList = artistsData ? Object.keys(artistsData).map(key => ({
            id: key,
            ...artistsData[key],
          })) : [];
    
          setTotalSignups(artistsList.length); // Setze die Gesamtzahl der Anmeldungen
    
          // Datumsberechnungen innerhalb von useEffect mit dem bereits definierten 'now'
          const thisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
          const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          const twoMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 2, 1);
    
          const countSignups = (date) => artistsList.filter(artist => {
            const signupDate = new Date(artist.signupTimestamp);
            return signupDate >= date && signupDate < new Date(date.getFullYear(), date.getMonth() + 1, 1);
          }).length;
    
          setSignupStats({
            thisMonth: countSignups(thisMonth),
            lastMonth: countSignups(lastMonth),
            twoMonthsAgo: countSignups(twoMonthsAgo),
          });
        });
      }, []); 

  const data = {
    labels: labels, // Verwende die 'labels' Variable hier
    datasets: [
      {
        label: 'Artist Signups', // Beschreibt, was im Datensatz dargestellt wird
        data: [signupStats.twoMonthsAgo, signupStats.lastMonth, signupStats.thisMonth],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        color: '#FFFFFF', // Farbe der Schrift
        anchor: 'center', // Verankert die Labels in der Mitte der Balken
        align: 'center', // Zentriert die Labels vertikal und horizontal
        font: {
          weight: 'bold', // Optional: Schriftgewicht
        },
      }
    },
    maintainAspectRatio: false,
    responsive: true,
  };
  
  

  return (
    <div className="artist-stats-container">
      <h2>Total Artist Signups: {totalSignups}</h2>
    <div className="chart-container">
    <Bar data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
    <WeeklyStats/>
  </div>
  
  );
}

export default ArtistStats;
