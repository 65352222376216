import React, { useState, useEffect } from 'react';
import { ref as databaseRef, update, get } from 'firebase/database';
import { database } from "../firebaseConfig";
import { uploadFile } from '../Utility/UploadUtlility';
import './AddEditorModal.css'


function AddEditorComponent({ songId, onClose, isOpen }) {
    const [editorName, setEditorName] = useState('');
    const [editorCompany, setEditorCompany] = useState('');
    const [editorQuote, setEditorQuote] = useState('');
    const [editorImg, setEditorImg] = useState(null);
    const [currentImgUrl, setCurrentImgUrl] = useState('');
    const [uploading, setUploading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        const fetchEditorInfo = async () => {
            const editorRef = databaseRef(database, `Songs/${songId}`);
            const snapshot = await get(editorRef);
            const song = snapshot.val();
            if (song) {
                setEditorName(song.editorName || '');
                setEditorCompany(song.editorCompany || '');
                setEditorQuote(song.editorQuote || '');
                setCurrentImgUrl(song.editorImg || ''); 
            }
        };

        fetchEditorInfo();
    }, [isOpen, songId]);

    const handleAddEditorInfo = async () => {
        if (!songId || !editorName.trim() || !editorCompany.trim() || !editorQuote.trim()) {
            alert("Please fill out all the fields.");
            return;
        }

        setUploading(true);
        let editorImgUrl = currentImgUrl;
        if (editorImg) {
            const fileExtension = editorImg.name.split('.').pop().toLowerCase();
            if (!['png', 'jpeg', 'jpg', 'webp'].includes(fileExtension)) {
                alert("Please upload files only in PNG, JPEG, or WEBP format.");
                setUploading(false);
                return;
            }

            try {
                editorImgUrl = await uploadFile(editorImg, 'editorImages');
            } catch (error) {
                alert("Error uploading the image. Please try again.");
                setUploading(false);
                return;
            }
        }

        const editorData = {
            editorName,
            editorCompany,
            editorQuote,
            editorImg: editorImgUrl
        };

        try {
            await update(databaseRef(database, `Songs/${songId}`), editorData);
            setCurrentImgUrl(editorImgUrl);
            setSuccessMessage('Editor-Informationen erfolgreich gespeichert.');

            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);

        } catch (error) {
            console.error('Fehler beim Speichern der Editor-Informationen: ', error);
            alert("Fehler beim Speichern der Informationen. Bitte versuchen Sie es erneut.");
        } finally {
            setUploading(false);
        }
    };

    if (!isOpen) {
        return null;
    }


    return (
        <div className="edit-modal-overlay">
            <div className="edit-modal-content">
                <h2>Edit Editor Info</h2>
                <input type="text" value={editorName} onChange={(e) => setEditorName(e.target.value)} placeholder="Editor Name" />
                <input type="text" value={editorCompany} onChange={(e) => setEditorCompany(e.target.value)} placeholder="Editor Company" />
                <textarea value={editorQuote} onChange={(e) => setEditorQuote(e.target.value)} placeholder="Editor Quote"></textarea>
                {currentImgUrl && <img src={currentImgUrl} alt="Editor Preview" className="editor-img-preview"/>}
                <input type="file" onChange={(e) => setEditorImg(e.target.files[0])} />
                {uploading ? (
                    <div className="uploading-indicator">Speichern...</div>
                ) : (
                    <button onClick={handleAddEditorInfo} disabled={uploading}>Save Editor Info</button>
                )}
                {successMessage && <div className="success-message">{successMessage}</div>}
                <button onClick={onClose} className="loco-button">Close</button>
            </div>
        </div>
    );
    
}

export default AddEditorComponent;