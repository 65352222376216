import React, { useEffect, useState } from 'react';
import { ref as databaseRef, onValue, remove, update } from 'firebase/database';
import { database } from "../firebaseConfig";
import ReviewCreator from './ReviewCreator';
import DeleteReviewsModal from './DeleteReviewsModal';
import './ListSongs.css';

function ListPages() {
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Limit to 10 pages per page
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const pagesRef = databaseRef(database, 'Pages');
    onValue(pagesRef, (snapshot) => {
      const pagesData = snapshot.val();
      let pagesList = pagesData ? Object.keys(pagesData).map(key => {
        const pageData = pagesData[key];
        const offers = pageData.pageData?.offers || [];
        const earnAmount = offers.length > 0 ? offers[0].earnAmount : null;
  
        return {
          id: key,
          artistId: pageData.artistId,
          route: pageData.route,
          service: pageData.pageData?.service,
          serviceType: pageData.pageData?.serviceType,
          earnAmount: earnAmount,
          isVerified: pageData.pageData?.isVerified || false,
          isApproved: pageData.pageData?.isApproved || false,
          confirmed: pageData.pageData?.addToMarketplace?.confirmed || false,
          pageIsLive: pageData.pageData?.pageIsLive || false,
          pageCreated: pageData.pageData?.pageCreated || 0,  // Correct access path
          isHighlight: pageData.pageData?.isHighlight || false, // New property
        };
      }) : [];
  
      // Sort by pageCreated date (newest first)
      pagesList.sort((a, b) => new Date(b.pageCreated) - new Date(a.pageCreated));
  
      setPages(pagesList);
    });
  }, []);
  
  // Pagination logic
  const indexOfLastPage = currentPage * itemsPerPage;
  const indexOfFirstPage = indexOfLastPage - itemsPerPage;
  const currentPages = pages.slice(indexOfFirstPage, indexOfLastPage);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(pages.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const toggleStatus = (pageId, currentStatus, statusKey) => {
    const updatedStatus = !currentStatus;
    update(databaseRef(database, `Pages/${pageId}/pageData`), {
      [statusKey]: updatedStatus,
    }).then(() => {
      console.log(`${statusKey} status aktualisiert`);
    }).catch((error) => {
      console.error(`Fehler beim Aktualisieren des ${statusKey} Status:`, error);
    });
  };

  const handleOpenDeleteModal = (pageId) => {
    setSelectedPageId(pageId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedPageId(null);
  };

  const handleDeletePage = (pageId, route) => {
    if (window.confirm(`Are you sure you want to delete the page "${route}"? This action cannot be undone.`)) {
      remove(databaseRef(database, `Pages/${pageId}`))
        .then(() => {
          console.log(`Page ${route} deleted successfully.`);
        })
        .catch((error) => {
          console.error(`Error deleting page ${route}:`, error);
        });
    }
  };

  return (
    <div className="list-songs-container">
      <h1>Pageliste</h1>
      <p>Gesamtanzahl der Einträge: {pages.length}</p>
      <div className="table-scroll-container" style={{display: 'flex'}}>
        <ul className="pagination-page-item">
          {pageNumbers.map(number => (
            <li key={number} className="page-item-list-song" style={{display: 'flex'}}>
              <button onClick={() => paginate(number)} className="page-link">
                {number}
              </button>
            </li>
          ))}
        </ul>

        <table className="list-songs-table">
          <thead>
            <tr>
              <th className="list-songs-name-field">Route</th>
              <th>Künstler</th>
              <th>Service</th>
              <th>Service Typ</th>
              <th>Verdienst</th>
              <th>Links</th>
              <th>Verifiziert</th>
              <th>Genehmigt</th>
              <th>Bestätigt</th>
              <th>Online</th>
              <th>Highlight</th> {/* New column for Highlight status */}
              <th>Add Review</th>
              <th>Delete Reviews</th>
              <th>Delete Page</th> {/* New column for deleting pages */}
            </tr>
          </thead>
          <tbody>
            {currentPages.map((page) => (
              <tr 
                key={page.id}
                style={{
                  backgroundColor: page.pageIsLive && page.confirmed && !page.isApproved ? 'rgba(0, 128, 0, 0.1)' : 'transparent'
                }}
              >
                <td 
                  style={{
                    textDecoration: page.pageIsLive && page.confirmed && page.isApproved ? 'underline' : 'none',
                    color: page.pageIsLive && page.confirmed && page.isApproved ? 'blue' : 'inherit'
                  }}
                >
                  {page.route}
                </td>
                <td>{page.artistId}</td>
                <td>{page.service}</td>
                <td>{page.serviceType}</td>
                <td>{page.earnAmount}</td>
                <td>
                  <a href={`/${page.route}`} target="_blank" rel="noopener noreferrer">Page</a> |{' '}
                  <a href={`/artist/${page.artistId}`} target="_blank" rel="noopener noreferrer">Artist Profile</a>
                </td>
                <td>
                  <button onClick={() => toggleStatus(page.id, page.isVerified, 'isVerified')}>
                    {page.isVerified ? 'Unverify' : 'Verify'}
                  </button>
                </td>
                <td>
                  <button onClick={() => toggleStatus(page.id, page.isApproved, 'isApproved')}>
                    {page.isApproved ? 'Unapprove' : 'Approve'}
                  </button>
                </td>
                <td>
                  {page.confirmed ? 'Confirmed' : 'Not Confirmed'}
                </td>
                <td>
                  {page.pageIsLive ? 'Online' : 'Hidden'}
                </td>
                <td>
                  <button onClick={() => toggleStatus(page.id, page.isHighlight, 'isHighlight')}>
                    {page.isHighlight ? 'Remove Highlight' : 'Highlight'}
                  </button>
                </td>
                <td>
                  <ReviewCreator pageId={page.id} />
                </td>
                <td>
                  <button onClick={() => handleOpenDeleteModal(page.id)}>Delete Reviews</button>
                </td>
                <td>
                  <button onClick={() => handleDeletePage(page.id, page.route)}>Delete Page</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDeleteModal && (
        <DeleteReviewsModal
          pageId={selectedPageId}
          onClose={handleCloseDeleteModal}
        />
      )}
    </div>
  );
}

export default ListPages;
