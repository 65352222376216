import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { database } from "../firebaseConfig";
import './ArtistOTD.css';
import AudioBar from '../Audio/AudioBar';
import playIcon from '../img/play.svg';
import pauseIcon from '../img/pause.svg';
import { CSSTransition } from 'react-transition-group';
import SpotifySongs from '../Audio/SpotifySongs';
import PageTransition from '../Utility/PageTransitionWrapper';
import BuyProModal from './BuyProModal';

function ArtistOTD() {
  const [spotlightData, setSpotlightData] = useState({
    artistName: "Mesiah",
    description: "Check out the unique sound of Mesiah.",
    imageUrl: "../img/demo-img.jpg", // Pfad zum Standardbild
    buttonLink: "", // Standardwert für Button-Link
    spotifyLink: "linkspotify", // Standardwert für Spotify-Link
    songUrl: "", // Fügen Sie hier die URL des Songs hinzu
    songName: "", // Fügen Sie hier den Namen des Songs hinzu
    songImg: "" // Fügen Sie hier das Bild des Songs hinzu
  });

  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false); // Zustand für Abspielstatus
  const [showAudioBar, setShowAudioBar] = useState(false); // Zustand für die Anzeige der AudioBar
  const [showBuyProModal, setShowBuyProModal] = useState(false); // State für das Modal
  const [user, setUser] = useState(null);
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userRef = databaseRef(database, `Artists/${currentUser.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setIsPremium(userData.isPremium || false);
        }
      } else {
        setUser(null);
        setIsPremium(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchSpotlightData = async () => {
      const spotlightRef = databaseRef(database, 'Spotlight');
      try {
        const snapshot = await get(spotlightRef);
        if (snapshot.exists()) {
          setSpotlightData(snapshot.val());
        }
        setTimeout(() => setLoading(false), 500);
      } catch (error) {
        console.error('Fehler beim Laden der Spotlight-Daten:', error);
        setTimeout(() => setLoading(false), 1000);
      }
    };

    fetchSpotlightData();
  }, []);

  const togglePlayPause = () => {
    if (isPlaying) {
      setShowAudioBar(false);
    } else {
      setShowAudioBar(true);
    }
    setIsPlaying(!isPlaying);
  };

  const handleApplyClick = () => {
    if (isPremium) {
      // E-Mail senden
      window.location.href = `mailto:hey@aaartists.com?subject=Apply for Spotlight&body=I would like to apply for the Spotlight feature.`;
    } else {
      // BuyProModal öffnen
      setShowBuyProModal(true);
    }
  };

  return (
    <div className="artist-otd-container">
      {loading ? (
        <div className="skeleton-image"></div>
      ) : (
        <>
          <img src={spotlightData.coverImageUrl || "../img/demo-img.jpg"} alt="Preload" style={{display: "none"}} onLoad={() => setImageLoaded(true)} />
          {imageLoaded && (
            <div style={{width: '100%', height: '100%', objectFit: 'cover', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <img src={spotlightData.coverImageUrl || "../img/demo-img.jpg"} alt="Artist of the Day" id="image-desktop" className="artist-otd-image" />          
              <img src={spotlightData.coverImageMobileUrl || "../img/demo-img.jpg"} alt="Artist of the Day" id="image-mobile" className="artist-otd-image" />
            </div>
          )}
        </>
      )}
      {!loading && (
        <div className="artist-otd-text">
          <PageTransition>
            <p style={{margin: '0px 0px -10px 0px', color: 'lightgray'}}>SPOTLIGHT</p>
            <h2>{spotlightData.title}</h2>
            <p>{spotlightData.text}</p>
            <div className="artist-otd-cta">
              <a href={spotlightData.buttonLink} className="feature-artist-button" target="_blank" rel="noopener noreferrer">
                {spotlightData.buttonText}
              </a>
              <button className="play-pause-button" style={{marginLeft: '10px', marginTop: '10px', width: '45px', height: '45px', border: '1px solid white'}} onClick={togglePlayPause}>
                {isPlaying ? <img src={pauseIcon} style={{marginLeft: '1px'}} className="pause-icon" alt="Pause" /> : <img src={playIcon} className="play-icon" alt="Play" />}
              </button>
            </div>
            {isPlaying && spotlightData.spotifyLink && (
              <SpotifySongs spotifyUrl={spotlightData.spotifyLink} />
            )}
          </PageTransition>
        </div>
      )}
      {/* Apply Button - nur anzeigen, wenn der Nutzer eingeloggt ist */}
      {user && (
        <button className="spotlight-apply-button" onClick={handleApplyClick}>
          Apply for Spotlight
        </button>
      )}

      {/* BuyProModal */}
      {showBuyProModal && <BuyProModal onClose={() => setShowBuyProModal(false)} />}
    </div>
  );
}

export default ArtistOTD;
