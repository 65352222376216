import React, { useState } from 'react';
import { ref as databaseRef, push, set } from 'firebase/database';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { database, storage } from "../firebaseConfig";
import SubGenreList from '../Utility/SubGenreList';
import './AdminAddSong.css'
import EquipmentList from '../Utility/EquipmentList';
import AllCountries from '../Utility/AllCountries';
import ArtistTypes from '../Utility/ArtistTypes';
import { compressImage } from '../Utility/ImageCompressor'; // Stellen Sie sicher, dass der Pfad korrekt ist


function AdminAddSong() {
  const [audioFile, setAudioFile] = useState(null);
  const [coverFile, setCoverFile] = useState(null); // Zustand für das Coverbild
  const [spotifyLink, setSpotifyLink] = useState('');
  const [songName, setSongName] = useState('');
  const [artistName, setArtistName] = useState('');
  const [genre, setGenre] = useState('');
  const [isNominated, setIsNominated] = useState(false);
  const [artistMail, setArtistMail] = useState('');
  const [hasAward, setHasAward] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [editorQuote, setEditorQuote] = useState('');
  const [publications, setPublications] = useState([]);
  const [musicVideoLink, setMusicVideoLink] = useState('');
const [equipment, setEquipment] = useState([]);
  const [numberVotes, setNumberVotes] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedArtistType, setSelectedArtistType] = useState('');
  const [artistStatus, setArtistStatus] = useState('');

  // Generische Funktion zum Hochladen einer Datei
  const uploadFile = (file, folder) => {
    return new Promise((resolve, reject) => {
      const fileRef = storageRef(storage, `${folder}/${file.name}`);
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Optional: Fortschritt des Uploads verfolgen
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error('Fehler beim Hochladen der Datei: ', error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };



  
  const handleUploads = async () => {
    setUploading(true);
    let audioUrl = '', coverUrl = '';
  
    // Mappe Publikationsbilder zu Upload-Promises
    const publicationImagesUploads = publications.map(async (pub, index) => { // async hinzugefügt
      if (pub.pubFile) {
        try {
          // Komprimiere das Publikationsbild, bevor es hochgeladen wird
          const compressedPubBlob = await compressImage(pub.pubFile);
          const compressedPubFile = new File([compressedPubBlob], pub.pubFile.name, {
            type: 'image/jpeg',
          });
  
          // Jetzt den komprimierten Blob hochladen
          const imageUrl = await uploadFile(compressedPubFile, 'publicationImages');
          updatePublication(index, 'pubImg', imageUrl);
        } catch (error) {
          console.error('Fehler beim Komprimieren/Hochladen des Publikationsbildes: ', error);
          // Fehlerbehandlung, z.B. Setzen eines Fehlers im State, um Feedback im UI zu geben
        }
      }
    });
  
    try {
      // Komprimierung und Upload des Coverbilds, wenn vorhanden
      if (coverFile) {
        const compressedCoverBlob = await compressImage(coverFile);
        const compressedCoverFile = new File([compressedCoverBlob], coverFile.name, {
          type: 'image/jpeg',
        });
        coverUrl = await uploadFile(compressedCoverFile, 'songCovers');
      }
  
      // Upload der Audiodatei, wenn vorhanden
      if (audioFile) {
        audioUrl = await uploadFile(audioFile, 'songAudios');
      }
  
      // Warte auf den Abschluss aller Publikationsbild-Uploads
      await Promise.all(publicationImagesUploads);
  
      // Speichern der Songinformationen mit den neuen URLs
      await saveSongInfo(audioUrl, coverUrl);
      alert("Song und zugehörige Daten erfolgreich hochgeladen.");
  
    } catch (error) {
      console.error("Fehler beim Hochladen: ", error);
      alert('Fehler beim Hochladen der Dateien: ' + error.message);
    } finally {
      setUploading(false);
    }
  };
  
  

  const handleArtistTypeChange = (event) => {
    setSelectedArtistType(event.target.value);
  };
  
  const addPublication = () => {
    setPublications([...publications, { pubLink: '', pubText: '', pubFile: null }]);
  };
    
  const handleEquipmentChange = (index, event) => {
    const updatedEquipment = equipment.map((item, idx) => {
      if (idx === index) {
        return { ...item, type: event.target.value };
      }
      return item;
    });
    setEquipment(updatedEquipment);
  };
  
  
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  

  const updatePublication = (index, field, value) => {
    const newPublications = [...publications];
    newPublications[index][field] = value;
    setPublications(newPublications);
  };
  
  
  const removePublication = (index) => {
    const newPublications = [...publications];
    newPublications.splice(index, 1);
    setPublications(newPublications);
  };

  const addEquipmentItem = () => {
    setEquipment([...equipment, { type: '', name: '' }]);
  };
  
  const updateEquipmentItem = (index, field, value) => {
    const newEquipment = [...equipment];
    newEquipment[index][field] = value;
    setEquipment(newEquipment);
  };

  const removeEquipmentItem = (index) => {
    const newEquipment = [...equipment];
    newEquipment.splice(index, 1);
    setEquipment(newEquipment);
  };


  const saveSongInfo = (fileUrl, songCoverUrl) => {
    const newSongRef = push(databaseRef(database, 'Songs'));
    const songData = {
      fileUrl,
      songCoverUrl,
      spotifyLink,
      name: songName,
      artist: artistName,
      genre,
      isNominated,
      hasAward,
      artistMail,
      artistID: '',
      editorQuote,
      publications,
      musicVideoLink,
      equipment,
      numberVotes,
      selectedCountry,
      selectedArtistType,
      artistStatus, // Hinzugefügt
      releaseTimestamp: new Date().toISOString(), // Speichert den aktuellen Timestamp
    };

    set(newSongRef, songData).then(() => {
      alert("Song erfolgreich hinzugefügt!");
      setSpotifyLink('');
      setSongName('');
      setArtistName('');
      setGenre('');
      setArtistMail('');
      setIsNominated(false);
      setHasAward(false);
      setAudioFile(null);
      setCoverFile(null);
      setUploading(false);
    }).catch((error) => {
      console.error("Fehler beim Hinzufügen des Songs: ", error);
      alert('Fehler beim Speichern der Songinformationen');
      setUploading(false);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Überprüfen, ob die Pflichtfelder ausgefüllt sind
    if (!songName.trim()) {
        alert("Bitte geben Sie den Namen des Songs ein.");
        return;
    }

    if (!artistName.trim()) {
        alert("Bitte geben Sie den Namen des Künstlers ein.");
        return;
    }

    if (!coverFile) {
        alert("Bitte laden Sie ein Coverbild hoch.");
        return;
    }

    // Wenn alle Pflichtfelder ausgefüllt sind, fahren Sie mit dem Hochladen fort
    handleUploads();
};

return (
  <div className="admin-add-song">
    <h1 className="add-song-title">Song hinzufügen</h1>
    <form onSubmit={handleSubmit} className="add-song-form">
      <div className="file-upload-section">
        <label className="file-upload-label">
          Audiodatei:
          <input type="file" onChange={e => setAudioFile(e.target.files[0])} disabled={uploading} className="file-upload-input" />
        </label>
        <label className="file-upload-label">
          Coverbild:
          <input type="file" onChange={e => setCoverFile(e.target.files[0])} disabled={uploading} className="file-upload-input" />
        </label>
      </div>
      <div className="awarded-boxes">
        <div>
      <label className="checkbox-label">
          Nominee:
          <input type="checkbox" checked={isNominated} onChange={e => setIsNominated(e.target.checked)} disabled={uploading} className="nomination-checkbox" />
        </label>
        <label className="checkbox-label">
          Award Winner:
          <input type="checkbox" checked={hasAward} onChange={e => setHasAward(e.target.checked)} disabled={uploading} className="award-checkbox" />
        </label>
        </div>
        <label className="genre-label">
          Genre:
          <SubGenreList  value={genre} onChange={(e) => setGenre(e.target.value)}/>
        </label>
        <label>Country:</label>
        <AllCountries  onChange={handleCountryChange} value={selectedCountry} />
        <label>Artist Type:</label>
        <ArtistTypes onChange={handleArtistTypeChange} value={selectedArtistType} />
        <label>Artist Status:</label>
<select 
  value={artistStatus} 
  onChange={(e) => setArtistStatus(e.target.value)} 
  className=""
>
  <option value="">Select Status...</option>
  <option value="Independent">Independent</option>
  <option value="Signed">Signed</option>
</select>
        </div>
      <div className="input-section">
        <input type="text" placeholder="Songname" value={songName} onChange={e => setSongName(e.target.value)} disabled={uploading} className="text-input" />
        <input type="text" placeholder="Künstlername" value={artistName} onChange={e => setArtistName(e.target.value)} disabled={uploading} className="text-input" />
        <input type="text" placeholder="Spotify-Link" value={spotifyLink} onChange={e => setSpotifyLink(e.target.value)} disabled={uploading} className="text-input" />
        <input type="email" placeholder="Artist Mail" value={artistMail} onChange={e => setArtistMail(e.target.value)} disabled={uploading} className="text-input" />


   <input 
          type="text" 
          placeholder="Music Video Link" 
          value={musicVideoLink} 
          onChange={e => setMusicVideoLink(e.target.value)} 
          disabled={uploading}
          className="text-input"
        />
             <textarea 
          placeholder="Editor's Quote" 
          value={editorQuote} 
          onChange={e => setEditorQuote(e.target.value)} 
          disabled={uploading}
          className="text-input"
        />
</div>
<div className="input-group-wrapper-pub">
        {publications.map((pub, index) => (
          <div key={index} className="publication-input-group">
              <button 
              type="button" 
              onClick={() => removePublication(index)} 
              disabled={uploading}
              className="remove-button-pub"
            >
              X
            </button>
            <input 
              type="text" 
              placeholder="Publication Link" 
              value={pub.pubLink} 
              onChange={(e) => updatePublication(index, 'pubLink', e.target.value)} 
              disabled={uploading}
              className="text-input"
            />
            <input 
              type="text" 
              placeholder="Publication Text" 
              value={pub.pubText} 
              onChange={(e) => updatePublication(index, 'pubText', e.target.value)} 
              disabled={uploading}
              className="text-input"
            />
              <input 
      type="file" 
      onChange={(e) => updatePublication(index, 'pubFile', e.target.files[0])} 
      disabled={uploading}
      className="file-upload-input"
    />

          
          </div>
        ))}
        <button 
          type="button" 
          onClick={addPublication} 
          disabled={uploading}
          className="add-button"
        >
          Add Magazine
        </button>
        </div>
     
<div className="input-group-wrapper-pub">

{equipment.map((equip, index) => (
  <div key={index} className="equipment-input-group">
    <label>Equipment:</label>
    <EquipmentList 
      value={equip.type} 
      onChange={(e) => handleEquipmentChange(index, e)} 
    />
    <input 
      type="text" 
      placeholder="Equipment Name: e.g. Shure SM57" 
      value={equip.name} 
      onChange={(e) => updateEquipmentItem(index, 'name', e.target.value)} 
      disabled={uploading}
      className="text-input"
    />
    <button 
      type="button" 
      onClick={() => removeEquipmentItem(index)} 
      disabled={uploading}
      className="remove-button-pub"
    >
      X
    </button>
  </div>
))}

        <button 
          type="button" 
          onClick={addEquipmentItem} 
          disabled={uploading}
          className="add-button"
        >
          Add Equipment
        </button>
       
      </div>
      <button type="submit" disabled={uploading} className="submit-button">ADD TO SONGS</button>
    </form>
    {uploading && <p className="upload-status">Upload läuft...</p>}
  </div>
);

  }  
export default AdminAddSong;
