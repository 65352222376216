import React, { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';
import { database } from '../../firebaseConfig';
import GigIntroSettings from '../PageCompSettings/GigIntroSettings';
import TheGigSettings from '../PageCompSettings/TheGigSettings';
import GigSongsSettings from '../PageCompSettings/GigSongsSettings';
import GigSellerInfoSettings from '../PageCompSettings/GigSellerInfoSettings'; // Neu hinzugefügte Importanweisung
import TheHeaderSettings from '../PageCompSettings/TheHeaderSettings';
import DefaultSettings from '../PageCompSettings/DefaultSettings';
import TextEditorComponent from '../PageCompSettings/TextEditorComponent';
import AddPageProjectSettings from '../PageCompSettings/AddPageProjectSettings';
import AddEquipmentSettings from '../PageCompSettings/AddEquipmentSettings';
import PageQaSettings from '../PageCompSettings/PageQaSettings';
import SelectWidgetSettings from '../PageCompSettings/SelectWidgetSettings';
import './WidgetSettingsContainer.css';

function WidgetSettings({ pageId, activeSettingsType, widgetKey }) {
    const [pageData, setPageData] = useState({});
    const [offersData, setOffersData] = useState({});

    useEffect(() => {
        const pageDataRef = ref(database, `Pages/${pageId}/pageData`);
        get(pageDataRef).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setPageData(data);
                if (data.offers && data.offers.length > 0) {
                    setOffersData(data.offers[0]); // Assuming the first offer is what you need
                }
            } else {
                console.error('No page data found for this page ID:', pageId);
            }
        }).catch(error => {
            console.error('Failed to fetch page data:', error);
        });
    }, [pageId]);

    const renderSettings = () => {
        switch (activeSettingsType) {
            case 'TheGig':
                return <TheGigSettings pageId={pageId} gigData={offersData} pageData={pageData} widgetKey={widgetKey} />;
            case 'PageHeader':
                return <TheHeaderSettings pageId={pageId} pageData={pageData} widgetKey={widgetKey} />;
            case 'GigIntro':
                return <GigIntroSettings pageId={pageId} pageData={pageData} widgetKey={widgetKey} />;
            case 'GigSongs':
                return <GigSongsSettings pageId={pageId} pageData={pageData} widgetKey={widgetKey} />;
            case 'GigSellerInfos':
                return <GigSellerInfoSettings pageId={pageId} widgetKey={widgetKey} />;
            case 'TextEdit':
                return <TextEditorComponent pageId={pageId} widgetKey={widgetKey} />;
            case 'Equipment':
                return <AddEquipmentSettings pageId={pageId} widgetKey={widgetKey} />;
            case 'AddProject':
                return <AddPageProjectSettings pageId={pageId} widgetKey={widgetKey} />;
            case 'SelectWidgetSettings':
                return <SelectWidgetSettings pageId={pageId} widgetKey={widgetKey} />;
            case 'QA':
                return <PageQaSettings pageId={pageId} widgetKey={widgetKey} />;
            default:
                return <DefaultSettings pageId={pageId} widgetKey={widgetKey} />;
        }
    };

    return (
        <div className="widget-settings-container">
            {renderSettings()}
        </div>
    );
}

export default WidgetSettings;
