import React, { useState, useEffect } from 'react';
import { ref as databaseRef, update, push, get } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import './ReviewChat.css';

const ReviewChat = ({ gigId, senderId, recipientId, messageId, chatId }) => {
    const [ratings, setRatings] = useState({ quality: 0, delivery: 0, communication: 0 });
    const [feedback, setFeedback] = useState('');
    const [serviceName, setServiceName] = useState('');

    useEffect(() => {
        const fetchServiceName = async () => {
            if (gigId) {
                const gigRef = databaseRef(database, `Pages/${gigId}/pageData/service`);
                const snapshot = await get(gigRef);
                if (snapshot.exists()) {
                    setServiceName(snapshot.val());
                } else {
                    console.error('Service name not found for gigId:', gigId);
                }
            }
        };

        fetchServiceName();
    }, [gigId]);

    const handleRating = (category, star) => {
        setRatings(prevRatings => ({ ...prevRatings, [category]: star }));
    };

    const handleSubmitReview = async () => {
        if (!gigId || !recipientId) return;

        // Review Daten vorbereiten
        const reviewData = {
            reviewWriter: auth.currentUser.uid,
            reviewGetter: senderId,
            ratings,
            feedback,
            timestamp: Date.now(),
        };

        const reviewsRef = databaseRef(database, `Pages/${gigId}/pageData/reviews`);
        await push(reviewsRef, reviewData);

        // Vorhandene Bewertungen abrufen
        const reviewsSnapshot = await get(reviewsRef);
        let totalReviews = 0;
        let totalScore = 0;

        reviewsSnapshot.forEach(childSnapshot => {
            const review = childSnapshot.val();
            totalReviews += 1;
            const averageScore = (review.ratings.quality + review.ratings.delivery + review.ratings.communication) / 3;
            totalScore += averageScore;
        });

        // Neue Gesamtzahl der Bewertungen und Durchschnittswert berechnen
        const updatedTotalReviews = totalReviews;
        const updatedReviewScore = totalScore / updatedTotalReviews;

        // Die neuen Werte in der Datenbank speichern
        await update(databaseRef(database, `Pages/${gigId}/pageData`), {
            totalReviews: updatedTotalReviews,
            reviewScore: updatedReviewScore,
        });

        // Nachrichtentyp in der Chat-Nachricht aktualisieren
        const messageRef = databaseRef(database, `Chats/${chatId}/messages/${messageId}`);
        await update(messageRef, { type: 'ReviewSent' });
    };

    // Unterscheidung zwischen Sender und Empfänger
    const isCurrentUserSender = auth.currentUser.uid === senderId;

    return (
        <div className="review-chat">
            {isCurrentUserSender ? (
                <p>Review Pending</p>
            ) : (
                <>
                    <p>Review: <strong>{serviceName || 'Service not found'}</strong></p>
                    <div className="review-category">
                    <p>Quality of work:</p>
                      <div>
                        {[1, 2, 3, 4, 5].map(star => (
                            <img
                                key={star}
                                src={star <= ratings.quality ? './img/star_color.svg' : './img/star_black.svg'}
                                alt={`${star} stars`}
                                onClick={() => handleRating('quality', star)}
                                className="star"
                            />
                        ))}
                                              </div>

                    </div>
                    <div className="review-category">
                        <p>Delivery Time:</p>
                        <div>
                        {[1, 2, 3, 4, 5].map(star => (
                            <img
                                key={star}
                                src={star <= ratings.delivery ? './img/star_color.svg' : './img/star_black.svg'}
                                alt={`${star} stars`}
                                onClick={() => handleRating('delivery', star)}
                                className="star"
                            />
                        ))}
                                                </div>

                    </div>
                    <div className="review-category">
                    <p>Communication:</p>
                     <div>
                        {[1, 2, 3, 4, 5].map(star => (
                            <img
                                key={star}
                                src={star <= ratings.communication ? './img/star_color.svg' : './img/star_black.svg'}
                                alt={`${star} stars`}
                                onClick={() => handleRating('communication', star)}
                                className="star"
                            />
                        ))}
                    </div>
                    </div>

                    <textarea
                        value={feedback}
                        onChange={e => setFeedback(e.target.value)}
                        placeholder="Add your feedback..."
                        className="feedback-textarea"
                    />
                    <button className="submit-review-button" onClick={handleSubmitReview}>
                        Submit Review
                    </button>
                </>
            )}
        </div>
    );
};

export default ReviewChat;
