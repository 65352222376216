import React from 'react';
import './ThreeStepIndicator.css'; // Stellen Sie sicher, dass der CSS-Dateipfad korrekt ist

function ThreeStepIndicator() {
  return (
    <div className="tier-header-top">
          <div className="labels-container">
        <div className="step-label">Submitted</div>
        <div className="step-label">Nominated</div>
        <div className="step-label">Awarded</div>
      </div>
      <div className="steps-container">
        <div className="step active">
          <div className="number-circle">1</div>
        </div>
        <div className="step">
          <div className="number-circle">2</div>
        </div>
        <div className="step">
          <div className="number-circle">3</div>
        </div>
      </div>
    </div>
  );
}

export default ThreeStepIndicator;
