import React from 'react';
import './UtilitySettings.css'

function InputNumberField({ value, onChange, placeholder, imgSrc, labelText, max, min }) {
    return (
        <div className="input-number-field-container">
            {labelText && <label className="input-number-field-label">{labelText}</label>}
            <div className="input-number-field-wrapper">
                <span className="input-number-field-prefix">{imgSrc && <img src={imgSrc} alt="Prefix Icon" className="input-number-field-img" />}</span>
                <input
                    className="input-number-field"
                    type="number"
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    max={max}
                    min={min}
                />
            </div>
        </div>
    );
}

export default InputNumberField;
