// EquipmentUsed.js
import React from 'react';
import './EquipmentUsed.css'; // Stellen Sie sicher, dass Sie den Pfad zur CSS-Datei anpassen

const EquipmentUsed = ({ equipment }) => {
  if (!equipment || equipment.length === 0) {
    return null; // Nichts rendern, wenn kein Equipment vorhanden ist
  }

  return (
      <div className="equipment-used">
                        <h3>Equipment Used</h3>
    <div className="equipment-used-container">
      {equipment.map((equip, index) => (
        <div key={index} className="equipment-item">
          <p>{equip.type}</p>
          <h4>{equip.name}</h4>
        </div>
      ))}
    </div>
    </div>

  );
};

export default EquipmentUsed;
