import React, { useState, useEffect } from 'react';
import { ref as databaseRef, update, get } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import './SelectContactSettings.css';

function SelectContactSettings({ pageId, onDisableNext, onError }) {
    const [contactEmail, setContactEmail] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [directMessageEnabled, setDirectMessageEnabled] = useState(true); // Direktnachrichten sind immer aktiviert und können nicht deaktiviert werden
    const [whatsappEnabled, setWhatsappEnabled] = useState(false);
    const [emailEnabled, setEmailEnabled] = useState(false); // E-Mail ist voreingestellt aktiviert, da vorausgefüllt
    const [error, setError] = useState('');

    const handleWhatsappNumberChange = (event) => {
        const newNumber = event.target.value.replace(/[^0-9]/g, ''); // Entferne alle nicht-numerischen Zeichen
        setWhatsappNumber(newNumber);
    };

    useEffect(() => {
        const isValidWhatsapp = !whatsappEnabled || (whatsappEnabled && whatsappNumber.length >= 5);
        const isContactValid = (emailEnabled || directMessageEnabled) && isValidWhatsapp;
        onDisableNext(!isContactValid);
    }, [emailEnabled, directMessageEnabled, whatsappEnabled, whatsappNumber, onDisableNext]);

    const toggleWhatsapp = () => {
        setWhatsappEnabled(!whatsappEnabled);
    };

    const toggleEmail = () => {
        setEmailEnabled(!emailEnabled);
    };

    useEffect(() => {
        const userId = auth.currentUser?.uid;
        if (userId) {
            const emailRef = databaseRef(database, `/Artists/${userId}/email`);
            get(emailRef).then((snapshot) => {
                if (snapshot.exists()) {
                    setContactEmail(snapshot.val());
                } else {
                    setError('Email not found.');
                }
            }).catch((error) => {
                console.error('Failed to fetch email:', error);
                setError('Failed to fetch email: ' + error.message);
            });
        } else {
            setError('User is not authenticated.');
        }
    }, []);

    const updateDatabase = async (path, data) => {
        try {
            await update(databaseRef(database, path), data);
        } catch (error) {
            console.error(`Failed to update ${path}:`, error);
            onError(error.message);
        }
    };

    useEffect(() => {
        if (!pageId) return;
        updateDatabase(`Pages/${pageId}/pageData`, { emailEnabled });
    }, [emailEnabled, pageId]);

    useEffect(() => {
        if (!pageId) return;
        updateDatabase(`Pages/${pageId}/pageData`, { whatsappEnabled });
    }, [whatsappEnabled, pageId]);

    useEffect(() => {
        if (!pageId) return;
        updateDatabase(`Pages/${pageId}/pageData`, { directMessageEnabled });
    }, [directMessageEnabled, pageId]);

    useEffect(() => {
        const saveWhatsappNumber = async () => {
            if (!pageId || !whatsappEnabled) return;

            try {
                await update(databaseRef(database, `Pages/${pageId}/pageData`), { whatsappNumber });
            } catch (error) {
                console.error('Error updating WhatsApp number:', error);
                onError(error.message);
            }
        };

        saveWhatsappNumber();
    }, [whatsappNumber, pageId, whatsappEnabled, onError]);

    useEffect(() => {
        const saveInitialData = async () => {
            if (!pageId) {
                console.error('No pageId provided');
                onError('No pageId provided');
                return;
            }

            const initialData = {
                contactEmail: contactEmail || null,  // Sicherstellen, dass die E-Mail nicht undefined ist
                emailEnabled
            };

            try {
                await update(databaseRef(database, `Pages/${pageId}/pageData`), initialData);
            } catch (error) {
                console.error('Failed to save initial contact data:', error);
                onError(error.message);
            }
        };

        saveInitialData();
    }, [pageId, contactEmail, emailEnabled, onError]);  // Abhängigkeiten für den useEffect

    return (
        <div className="select-contact-container">
            <div className="create-page-header-fonts">
                <h2>Contact</h2>
                <p>Let us know what name you want to have my dear.</p>
            </div>
            <div className="contact-toggle-div">
                <div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img className="contact-toggle-img" src='../../img/send.webp' alt="" style={{width: '55px', marginRight: '5px'}}/>
                        <div className="contact-toggle-message-div">
                            <h3>Direct Message</h3>
                            <h5>Chat directly on AAA</h5>
                        </div>
                    </div>
                    <label className="select-contact-label">
                        <input type="checkbox" checked={directMessageEnabled} disabled className="toggle-input" />
                        <span className="toggle-slider"></span>
                    </label>
                </div>
            </div>
            <div className="contact-toggle-div">
                <div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img className="contact-toggle-img" src='../../img/mail.webp' alt="" />
                        <div className="contact-toggle-message-div">
                            <h3>Email</h3>
                            <h5>Clients can send you emails</h5>
                        </div>
                    </div>
                    <label className="select-contact-label">
                        <input type="checkbox" checked={emailEnabled} onChange={toggleEmail} className="toggle-input" />
                        <span className="toggle-slider"></span>
                    </label>
                </div>
                {emailEnabled && <input style={{color: 'grey'}} type="email" value={contactEmail} readOnly className="select-contact-input" />}
            </div>
            <div className="contact-toggle-div">
                <div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img className="contact-toggle-img" src='../../img/whats.webp' alt="" />
                        <div className="contact-toggle-message-div">
                            <h3>WhatsApp</h3>
                            <h5>Get contacted via WhatsApp</h5>
                        </div>
                    </div>
                    <label className="select-contact-label">
                        <input type="checkbox" checked={whatsappEnabled} onChange={toggleWhatsapp} className="toggle-input" />
                        <span className="toggle-slider"></span>
                    </label>
                </div>
                {whatsappEnabled && <input type="text" placeholder="Enter WhatsApp number" value={whatsappNumber} onChange={handleWhatsappNumberChange} className="select-contact-input" />}
            </div>
        </div>
    );
}

export default SelectContactSettings;
