import React from 'react';
import './SidebarSide.css';

function SidebarSide({ activeSettingsType, onFirstButtonClick, onWidgetsButtonClick }) {
    const isFirstButtonActive = activeSettingsType === null;
    const isWidgetsButtonActive = activeSettingsType === 'SelectWidgetSettings';

    return (
        <div className="sidebar-side">
            <button 
                className={`circle-button ${isFirstButtonActive ? 'activado' : ''}`}
                onClick={() => onFirstButtonClick(null)}  // Setzt den Wert direkt auf null
            >
               <img src="../../img/design.webp" alt="" />
               <p>Design</p>
            </button>
         
            <button 
                className={`circle-button ${isWidgetsButtonActive ? 'activado' : ''}`}
                onClick={() => onWidgetsButtonClick('SelectWidgetSettings')}
            >
               <img src="../../img/widget.webp" alt="" />
               <p>Add-Ons</p>
            </button>
        </div>
    );
}

export default SidebarSide;
