import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { database, auth } from '../../firebaseConfig';
import { ref as databaseRef, onValue, off } from 'firebase/database';
import BenefitItem from '../PageUtility/BenefitItem';
import GenerativeOrbAnimation5 from '../../Utility/GenerativeOrbAnimation5';

function EmptyState() {
  const [remainingDays, setRemainingDays] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
      const userRef = databaseRef(database, `/Artists/${userId}/signupTimestamp`);

      onValue(userRef, (snapshot) => {
        if (snapshot.exists()) {
          const signupDate = new Date(snapshot.val());
          const currentDate = new Date();
          const diffTime = Math.abs(currentDate - signupDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          const daysLeft = 8 - diffDays;

          if (daysLeft > 0 && daysLeft <= 30) {
            setRemainingDays(daysLeft);
          }
        }
        setLoading(false);
        setTimeout(() => setShowSkeleton(false), 300);  // 2 seconds delay
      }, (error) => {
        console.error('Error fetching signup timestamp:', error);
        setLoading(false);
      });

      return () => off(userRef);
    }
  }, []);

  return (
    <motion.div
      className="all-pages-empty-state"
      initial="hidden"
      exit="exit"
    >
      <div className="all-pages-empty-state-inner">
        <GenerativeOrbAnimation5 />
        <img src="../../img/dollar_2.webp" alt="Sell Art, keep Soul" />
        <h2>Sell Art, keep Soul.</h2>
        <p> With AAA you can earn more money from your music by connecting directly with artists willing to pay for exclusive services.</p>
        <div className="all-pages-benefits">
          {loading || showSkeleton ? (
            <div className="skeleton-loader-benefits"></div>  // Skeleton Loader
          ) : (
            <>
              {/* {remainingDays !== null && (
                <div className="empty-page-discount-banner">
                  <img src="../../img/bird.webp" alt="" />
                  <p>Free for Early Birds. Claim within {remainingDays} days.</p>
                </div>
              )} */}
                                     <BenefitItem 
                                        icon="../../img/verses.svg"
                                        title="Sell features, lyrics, beats and more"
                                        desc="Monetize your talent by offering exclusive services like custom songwriting, session work, and vocal coaching directly through our platform."
                                        benefit1="Earn real money with your skills"
                                        benefit2="Help talented newcomers succeed"
                                        benefit3="Offer over 50+ different services"
                                        showDivider={true}
                                    />
                                    <BenefitItem 
                                        icon="../../img/free_2.svg"
                                        title="Collab with award winners & pros"
                                        desc="We believe strong connections are the backbone of a successful music career. Our platform is designed to help you build and nurture these relationships."
                                        benefit1="Excusive, worldwide community."
                                        benefit2="Top-Level artists only"
                                        benefit3="Discover new opportunities"
                                        showDivider={true}
                                    />
                                    <BenefitItem 
                                        icon="../../img/coin.svg"
                                        title="Get paid in royalties and cash"
                                        desc="Maximize your income by setting up a seller profile on AAA, where clients and fellow artists can hire you for services, collaborations, and special projects."
                                        benefit1="0% transaction fees"
                                        benefit2="Keep 100% of the profit"
                                        benefit3="Get paid in cash and royalties"
                                    />
            </>
          )}
        </div>
        <Link to="/create-page" className="all-pages-empty-state-link">
          <p>CREATE YOUR PAGE</p>
          <img src="../../img/next.webp" alt="Create Page" />
        </Link>
      </div>
    </motion.div>
  );
};

export default EmptyState;
