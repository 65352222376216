import React, { useRef, useEffect } from 'react';

function ScrollVideoMobile() {
  const videoRef = useRef(); // Ref für das Video-Element

  const handleScroll = () => {
    const video = videoRef.current;
    if (video) {
      if (!video.playing) {
        // Versuchen, die Wiedergabe zu starten, wenn das Video noch nicht spielt.
        // Beachten Sie, dass dies durch Browser-Richtlinien blockiert werden könnte.
        video.play().catch((error) => console.error("Video play failed", error));
      }

      const startScroll = 900;
      const endScroll = document.body.scrollHeight - window.innerHeight;
      const scrollRange = endScroll - startScroll;

      if (window.scrollY > startScroll && window.scrollY < endScroll && isFinite(video.duration)) {
        const scrollFraction = (window.scrollY - startScroll) / scrollRange;
        const videoTime = scrollFraction * video.duration;
        video.currentTime = videoTime;
      } else {
        video.currentTime = 0;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <video ref={videoRef} width="100%" style={{ opacity: '1', zIndex: '1' }} preload="auto" muted playsInline>
      <source src="/img/animation.mp4" type="video/mp4" />
      Your browser does not support videos.
    </video>
  );
}

export default ScrollVideoMobile;
