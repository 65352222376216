import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database, auth } from '../firebaseConfig';
import DashboardMobileNav from '../UserBackend/DashboardMobileNav';
import GenerativeOrbAnimation4 from '../Utility/GenerativeOrbAnimation4';
import HomeNewHeader from './HomeNewHeader';
import ListTikTokVideos from './ListTiktokVideos';
import NewMobileHeader from './NewMobileHeader';
import PlaySpy from './PlaySpy'; // Importiere die PlaySpy-Komponente
import './ViralVault.css'; // Importiere die CSS-Datei
import PlayTreasure1 from './PlayTresure1';

function ViralVault() {
  const [selectedType, setSelectedType] = useState(''); // State für den ausgewählten Filter
  const [page, setPage] = useState(0); // State für die aktuelle Seite
  const [favorites, setFavorites] = useState([]); // Favoriten aus der Datenbank
  const [showPlaySpy, setShowPlaySpy] = useState(false); // State zum Steuern des PlaySpy-Popups
  const [showPlayTreasure, setShowPlayTreasure] = useState(false); // State zum Steuern des PlayTreasure1-Popups

  const artistId = auth.currentUser?.uid; // Aktueller Benutzer (Artist)

  useEffect(() => {
    if (!artistId) return;

    // Lade die Favoriten-Videos des Künstlers
    const savedVideosRef = ref(database, `/Artists/${artistId}/viral/tiktok`);
    onValue(savedVideosRef, (snapshot) => {
      const savedData = snapshot.val() || []; // Wenn keine Daten vorhanden sind, setze savedData auf ein leeres Array
      setFavorites(savedData); // Setze die Liste der gespeicherten Favoriten-Videos
    });
  }, [artistId]);

  const handleTypeChange = (type) => {
    setSelectedType(type); // Den ausgewählten Filtertyp setzen
    setPage(0); // Zurück zur ersten Seite springen
  };

  const handleH2Click = () => {
    setShowPlaySpy(true); // Zeige das PlaySpy-Popup, wenn auf das H2 geklickt wird
  };

  const handleClosePlaySpy = () => {
    setShowPlaySpy(false); // Schließe das PlaySpy-Popup
  };

  const handleTreasureClick = () => {
    setShowPlaySpy(false); // Schließt PlaySpy
    setShowPlayTreasure(true); // Öffnet PlayTreasure1
  };

  const handleClosePlayTreasure = () => {
    setShowPlayTreasure(false); // Schließe das PlayTreasure1-Popup
  };

  return (
    <div className="viral-vault-container">
      <HomeNewHeader />
      <NewMobileHeader />
      <DashboardMobileNav />
      <div className="viral-vault-header">
        <h5>FREE FOR VIP MEMBERS</h5>
        <h2 onClick={handleH2Click}>Tiktok Trend Spy <span>🔎</span> for Music Artists</h2>
        <p>Find trending Tiktok Videos that will serve as inspiration for your art</p>
      </div>

      {/* Filter Buttons mit Bildern */}
      <div className="viral-vault-type-selector">
        <button 
          className={`viral-filter-button ${selectedType === '' ? 'active' : ''}`} 
          onClick={() => handleTypeChange('')}
        >
          <span>All</span>
        </button>
        <button 
          className={`viral-filter-button ${selectedType === 'favorites' ? 'active' : ''}`} 
          onClick={() => handleTypeChange('favorites')}
          style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
        >
          <img style={{width: '40px', marginBottom: '10px'}} src="../../img/heart_red.webp" alt="" />
          <span>Favorites</span>
        </button>
        <button
          className={`viral-filter-button ${selectedType === 'dance' ? 'active' : ''}`} 
          onClick={() => handleTypeChange('dance')}
        >
          <img src="../../img/vogg.webp" alt="Performance" className="viral-filter-icon" />
          <div className="viral-filter-button-text">
            <span>Dance / Performance</span>
            <p>Check out the latest performance videos</p>
          </div>
        </button>
        <button
          className={`viral-filter-button ${selectedType === 'entertainment' ? 'active' : ''}`} 
          onClick={() => handleTypeChange('entertainment')}
        >
          <img src="../../img/guy.webp" alt="Entertainment" className="viral-filter-icon" />
          <div className="viral-filter-button-text">
            <span>Entertainment</span>
            <p>Check out the latest entertaining videos</p>
          </div>
        </button>
        <button
          className={`viral-filter-button ${selectedType === 'promotion' ? 'active' : ''}`} 
          onClick={() => handleTypeChange('promotion')}
        >
          <img src="../../img/rapper.webp" alt="Teaser" className="viral-filter-icon" />
          <div className="viral-filter-button-text">
            <span>Promotional</span>
            <p>Check out the latest teaser videos</p>
          </div>
        </button>
        <button
          className={`viral-filter-button ${selectedType === 'lyrics' ? 'active' : ''}`} 
          onClick={() => handleTypeChange('lyrics')}
        >
          <img src="../../img/girl.webp" alt="Lyric Video" className="viral-filter-icon" />
          <div className="viral-filter-button-text">
            <span>Lyric Videos</span>
            <p>Check out the latest lyric videos</p>
          </div>
        </button>
        <button
          className={`viral-filter-button ${selectedType === 'funny' ? 'active' : ''}`} 
          onClick={() => handleTypeChange('funny')}
        >
          <img src="../../img/funy-gyal.webp" alt="Funny" className="viral-filter-icon" />
          <div className="viral-filter-button-text">
            <span>Music Comedy</span>
            <p>Check out the latest funny videos</p>
          </div>
        </button>
        <button
          className={`viral-filter-button ${selectedType === 'story' ? 'active' : ''}`} 
          onClick={() => handleTypeChange('story')}
        >
          <img src="../../img/gal.webp" alt="Funny" className="viral-filter-icon" />
          <div className="viral-filter-button-text">
            <span>Storytelling</span>
            <p>Check out the latest storytelling videos</p>
          </div>
        </button>
        <button
          className={`viral-filter-button ${selectedType === 'infotainment' ? 'active' : ''}`} 
          onClick={() => handleTypeChange('infotainment')}
        >
          <img src="../../img/influencer.webp" alt="Infotainment" className="viral-filter-icon" />
          <div className="viral-filter-button-text">
            <span>Infotainment</span>
            <p>Check out the latest infotainment videos</p>
          </div>
        </button>
      </div>
      <GenerativeOrbAnimation4/>

      {/* ListTikTokVideos Komponente mit dem Filtertyp und der aktuellen Seite */}
      <ListTikTokVideos selectedType={selectedType} page={page} setPage={setPage} favorites={favorites}  />

      {/* Zeige das PlaySpy-Popup nur, wenn showPlaySpy true ist */}
      {showPlaySpy && <PlaySpy onClose={handleClosePlaySpy} onTreasureClick={handleTreasureClick} />}

      {/* Zeige das PlayTreasure1-Popup nur, wenn showPlayTreasure true ist */}
      {showPlayTreasure && <PlayTreasure1 onClose={handleClosePlayTreasure} />}
    </div>
  );
}

export default ViralVault;
