import React, { useEffect, useRef, useState } from 'react';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ref as databaseRef, set, get } from 'firebase/database';
import { storage, database } from '../../firebaseConfig';
import Resizer from 'react-image-file-resizer';
import './UtilitySettings.css';

function ProfileImageUploader({ pageId, initialImageUrl, onSave }) {
    const [currentImageUrl, setCurrentImageUrl] = useState(initialImageUrl);
    const fileInputRef = useRef(null);
    const [uploading, setUploading] = useState(false);  // Zustand für den Upload-Status

    useEffect(() => {
        const imgRef = databaseRef(database, `Pages/${pageId}/pageData/profileImg`);
        get(imgRef).then((snapshot) => {
            if (snapshot.exists()) {
                setCurrentImageUrl(snapshot.val());
            } else {
                console.log('Kein Profilbild vorhanden.');
            }
        }).catch((error) => {
            console.error('Fehler beim Abrufen des Profilbildes:', error);
        });
    }, [pageId]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            console.error('No file selected.');
            return;
        }
        setUploading(true);  // Start des Uploads

        Resizer.imageFileResizer(
            file,
            800, // maximale Breite
            800, // maximale Höhe
            'JPEG',
            90, // Qualität
            0,
            uri => {
                uploadImage(uri);
            },
            'blob'
        );
    };

    const uploadImage = (blob) => {
        const fileRef = storageRef(storage, `images/${pageId}/profileImg/${Date.now()}.jpeg`);
        const uploadTask = uploadBytesResumable(fileRef, blob);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.error('Upload failed:', error);
                setUploading(false);  // Beenden des Uploads bei Fehler
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    updateDatabase(downloadURL);
                });
            }
        );
    };

    const updateDatabase = (imageUrl) => {
        const updateRef = databaseRef(database, `Pages/${pageId}/pageData/profileImg`);
        set(updateRef, imageUrl).catch(error => {
            console.error('Failed to update profile image in database:', error);
        });
        setCurrentImageUrl(imageUrl);
        setUploading(false);  // Beenden des Uploads nach Erfolg
        if (onSave) {
            onSave(imageUrl);
        }
    };
    if (uploading) {
        return (
            <div className="unique-image-display uploading-display">
                <div className="unique-image-placeholder">
                    <img style={{width: '30px'}} className="uploading-image" src="../../img/icon.png" alt="" />
                    <div className="spinner-3"></div>
                </div>
            </div>
        );
    }

    return (
        <div className="uploader-profile-image-uploader">
            <input
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/jpeg,image/png"
            />
            <div className="uploader-profile-image-container" onClick={() => fileInputRef.current.click()}>
                {currentImageUrl ? (
                    <div style={{width: '100%'}}>
                      <img src={currentImageUrl} alt="Profile" className="uploader-profile-image" />
                      <img style={{top: '33%'}} src="../../img/upload.svg" alt="Upload" className="upload-icon" /> 
                       <button className="uploader-file-input" onClick={() => fileInputRef.current.click()}>
                           <p>Upload Profile Picture</p>
                           <img src="../../img/plus.svg" alt="" />
                           </button>
                   
                    </div>
              
                    ) : (
                    <div className="uploader-profile-placeholder">
                        <img src="../../img/avatar-3.svg" alt="" />
                        <span>Upload your Profile Picture</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProfileImageUploader;