import React, { useEffect, useState } from "react";
import { database } from "../../firebaseConfig";
import { ref, onValue } from "firebase/database";
import SaveLogs from "./SaveLogs";
import "./LogsView.css";
import CoinInfo from "./CoinInfo";
import TradeInfo from "./WalletTrades";
import TradeInfoModal from "./TradeInfoModal";

const LogsView = () => {
  const [wallets, setWallets] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);

  // Fetch all wallet data from Firebase
  useEffect(() => {
    const walletsRef = ref(database, "/IA/wallets");
    onValue(walletsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const walletsArray = Object.keys(data).map((key) => ({
          walletID: key,
          ...data[key],
        }));
        setWallets(walletsArray);
      }
    });
  }, []);



  useEffect(() => {
    const oneHourAgo = Date.now() - 1 * 60 * 60 * 1000; // Eine Stunde

    // Collect all activities from all wallets
    const allActivities = wallets.flatMap((wallet) =>
      (wallet.activities || []).map((activity) => ({
        ...activity,
        wallet_name: wallet.name || "Unknown Wallet",
        wallet_group: wallet.group || "Unknown Group",
        wallet_id: wallet.walletID,
      }))
    );

    // Filter activities within the last hour and process Wrapped SOL trades
    const recentActivities = allActivities
      .filter((activity) => activity.block_time * 1000 >= oneHourAgo && activity.value > 1000)
      .sort((a, b) => b.block_time - a.block_time); // Sort by block time (newest first)

    const filteredActivities = [];
    const visitedIndexes = new Set();

    recentActivities.forEach((activity, index, arr) => {
      if (visitedIndexes.has(index)) return; // Skip already processed entries

      const nextActivity = arr[index + 1];

      // Handle Double Swaps (reverse trades)
      if (
        nextActivity &&
        activity.token1_name === nextActivity.token2_name &&
        activity.token2_name === nextActivity.token1_name
      ) {
        // Mark both activities as visited and exclude them from results
        visitedIndexes.add(index);
        visitedIndexes.add(index + 1);
      } else {
        filteredActivities.push(activity);
      }
    });

    setFilteredLogs(filteredActivities);
  }, [wallets]);

  // Generate Dexscreener URL for each token
  const getDexscreenerLink = (tokenAddress) => {
    return `https://dexscreener.com/solana/${tokenAddress}`;
  };

  // Determine CSS class based on activity type
  const getActivityClass = (activity) => {
    if (activity.token2_name === "Wrapped SOL") {
      return "activity-sell"; // Rot
    } else {
      return "activity-buy"; // Grün
    }
  };

  // Render all activities
  const renderActivities = () => {
    if (!filteredLogs.length) {
      return <p>No relevant activities found.</p>;
    }

    return filteredLogs.map((activity, index) => {
      const tokenLink1 = getDexscreenerLink(activity.token1_address);
      const tokenLink2 = getDexscreenerLink(activity.token2_address);

      // Define the activity class for each activity
      const activityClass = getActivityClass(activity);

      return (
        <div key={index} className={`logs-view-transaction-card ${activityClass}`}>
          <p>
            <strong>Block Time:</strong> {new Date(activity.block_time * 1000).toLocaleString()}
          </p>
          <p>
            <strong>From:</strong> {activity.token1_name} (
            {parseFloat(activity.token1_amount || 0).toFixed(2)}) → 
            <strong>To:</strong> {activity.token2_name} (
            {parseFloat(activity.token2_amount || 0).toFixed(2)})
          </p>
          <p>
            <strong>Value:</strong> {parseFloat(activity.value || 0).toFixed(2)}
          </p>
          <p>
            <strong>Wallet Name:</strong> {activity.wallet_name}
          </p>
          <p>
            <strong>Wallet Group:</strong> {activity.wallet_group}
          </p>
          <p>
            <strong>Dexscreener (From):</strong>{" "}
            <a href={tokenLink1} target="_blank" rel="noopener noreferrer">
              {activity.token1_name}
            </a>
          </p>
          <p>
            <strong>Dexscreener (To):</strong>{" "}
            <a href={tokenLink2} target="_blank" rel="noopener noreferrer">
              {activity.token2_name}
            </a>
          </p>
        </div>
      );
    });
  };

  return (
    <div className="logs-view-container-dark">
      <h1>All Wallet Activities</h1>
      <CoinInfo filteredLogs={filteredLogs} />
      <SaveLogs filteredLogs={filteredLogs} />
      {renderActivities()}
    </div>
  );
};

export default LogsView;
