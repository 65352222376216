import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importieren Sie useNavigate
import GenerativeOrbAnimation from '../Utility/GenerativeOrbAnimation';
import './SubmissionPage.css';

const SubmissionPage = () => {
    let navigate = useNavigate(); // useNavigate Hook initialisieren
  
    const handleApplicationBoxClick = (path) => {
      navigate(path); // Leitet den Benutzer zur angegebenen Seite um
    };

    return (
        <div className="submission-page">
            <GenerativeOrbAnimation />
            <div className="application-box" onClick={() => handleApplicationBoxClick('/signup')}>
              <h2>For Artists</h2>
              <p>Unlock your potential! Create an account and complete our application form to showcase your talent.</p>
              <button className="outline-button">Apply as Artist</button>
            </div>
            <div className="application-box" onClick={() => handleApplicationBoxClick('/signup')}>
              <h2>For Labels & Scouts</h2>
              <p>Discover the next big thing! Sign up and complete our application form to access fresh talent.</p>
              <button className="outline-button">Apply as Label</button>
            </div>
        </div>
      );
};

export default SubmissionPage;
