// AllCountries.js
import React from 'react';

function AllCountries({ onChange, value }) {
  // List of European countries and the top 50 most populous countries worldwide
  // Note: For brevity, this list might not be fully accurate or complete.
  const countries = [
    'China', 'Australia', 'India', 'United States', 'Indonesia', 'Pakistan',
    'Brazil', 'Nigeria', 'Bangladesh', 'Russia', 'Mexico',
    'Japan', 'Ethiopia', 'Philippines', 'Egypt', 'Vietnam',
    'DR Congo', 'Turkey', 'Iran', 'Germany', 'Thailand',
    'United Kingdom', 'France', 'Italy', 'Tanzania', 'South Africa',
    'Myanmar', 'Kenya', 'South Korea', 'Colombia', 'Spain',
    'Uganda', 'Argentina', 'Algeria', 'Sudan', 'Ukraine',
    'Iraq', 'Afghanistan', 'Poland', 'Canada', 'Morocco',
    'Austria', 'Belgium', 'Croatia', 'Denmark', 'Estonia',
    'Finland', 'Greece', 'Hungary', 'Ireland', 'Latvia',
    'Lithuania', 'Luxembourg', 'Netherlands', 'Norway', 'Portugal',
    'Romania', 'Slovakia', 'Slovenia', 'Sweden', 'Switzerland', 'Other'
  ];

  // Sorting the countries alphabetically
  const sortedCountries = countries.sort();

  return (
    <select onChange={onChange} value={value} className="country-select">
      <option value="">Select Country...</option>
      {sortedCountries.map((country, index) => (
        <option key={index} value={country}>{country}</option>
      ))}
    </select>
  );
}

export default AllCountries;
