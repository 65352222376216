import { useEffect } from 'react';

function useUTMParams() {
  useEffect(() => {
    if (sessionStorage.getItem('utm_data')) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const utmData = {};

    if (params.get('utm_source')) utmData.utm_source = params.get('utm_source');
    if (params.get('utm_medium')) utmData.utm_medium = params.get('utm_medium');
    if (params.get('utm_campaign')) utmData.utm_campaign = params.get('utm_campaign');
    if (params.get('utm_term')) utmData.utm_term = params.get('utm_term');
    if (params.get('utm_content')) utmData.utm_content = params.get('utm_content');

    if (Object.keys(utmData).length > 0) {
      sessionStorage.setItem('utm_data', JSON.stringify(utmData));
    }
  }, []);
}

export default useUTMParams;
