import { FastAverageColor } from 'fast-average-color';

export const getDominantColor = (imageUrl, callback) => {
    const fac = new FastAverageColor();
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = imageUrl;

    img.onload = () => {
        const color = fac.getColor(img);
        const [r, g, b] = color.value;

        // Calculate brightness using the luminance formula
        const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

        // Check if brightness is in the top 40%
        if (brightness >= 153) { // 153 is 60% of 255 (the max brightness value)
            // If brightness is in the top 40%, return black with 0.7 opacity
            callback('rgba(0, 0, 0, 0.1)');
        } else {
            // Otherwise, return the original color with 0.8 opacity
            const rgbaColor = `rgba(${r}, ${g}, ${b}, 0.6)`;
            callback(rgbaColor);
        }
    };

    img.onerror = (err) => {
        console.error('Error loading image:', err);
        callback(null);
    };
};
