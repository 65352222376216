import React, { useRef, useState } from 'react';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebaseConfig';
import Resizer from 'react-image-file-resizer';

function ProjectImageUploader({ projectId, pageId, initialImageUrl, onImageChange }) {
    const [uploading, setUploading] = useState(false);
    const [imageUrl, setImageUrl] = useState(initialImageUrl || ''); // Setzt initialImageUrl oder leeren String
    const fileInputRef = useRef(null);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setUploading(true);

        Resizer.imageFileResizer(
            file,
            800, // maximale Breite
            800, // maximale Höhe
            'JPEG',
            90, // Qualität
            0,
            (uri) => {
                uploadImage(uri);
            },
            'blob'
        );
    };

    const uploadImage = (blob) => {
        const fileRef = storageRef(storage, `projects/${pageId}/${projectId}/${Date.now()}.jpeg`);
        const uploadTask = uploadBytesResumable(fileRef, blob);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.error('Upload failed:', error);
                setUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    setImageUrl(downloadURL);
                    onImageChange(downloadURL);
                    setUploading(false);
                }).catch((error) => {
                    console.error('Error retrieving download URL:', error);
                    setUploading(false);
                });
            }
        );
    };

    return (
        <div className="uploader-profile-image-container" onClick={() => fileInputRef.current.click()}>
            <input
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
            />
            {uploading ? (
                <div className="unique-image-display" style={{ height: '100px' }}>
                    <div className="unique-image-placeholder">
                        <img style={{ width: '30px' }} className="uploading-image" src="../../img/icon.png" alt="" />
                        <div className="spinner-3"></div>
                    </div>
                </div>
            ) : (
                <div className="uploader-profile-image-container">
                    {imageUrl ? (
                        <div style={{ width: '100%' }}>
                            <img src={imageUrl} alt="Project" className="uploader-profile-image-2" />
                            <img src="../../img/upload.svg" alt="Upload" className="upload-icon" />
                        </div>
                    ) : (
                        <div className="uploader-profile-placeholder-2">
                            <img src="../../img/uploading.svg" alt="" />
                            <span>Upload Project Image</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default ProjectImageUploader;
