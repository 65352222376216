import React, { useState, useEffect } from 'react';
import { ref as databaseRef, get, update } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';
import './MoodSelection.css'
import { availableTypes } from '../PageUtility/AvailableInfos'


function IndustryMoods({ pageId, onDisableNext, onError }) {
    const [selectedTypes, setSelectedTypes] = useState([]);

    useEffect(() => {
        if (pageId) {
            const pageDataRef = databaseRef(database, `Pages/${pageId}/pageData`);
            get(pageDataRef).then((snapshot) => {
                if (snapshot.exists() && snapshot.val().companyType) {
                    setSelectedTypes(snapshot.val().companyType);
                }
            }).catch(error => {
                console.error('Failed to fetch page data:', error);
                onError && onError('Failed to fetch page data');
            });
        }
    }, [pageId, onError]);

    useEffect(() => {
        // Disable button unless at least one type is selected
        onDisableNext(selectedTypes.length < 1);
    }, [selectedTypes, onDisableNext]);

    const toggleTypeSelection = (type) => {
        const currentIndex = selectedTypes.indexOf(type);
        const newList = [...selectedTypes];

        if (currentIndex === -1) {
            // Add new type only if less than the maximum is currently selected
            if (selectedTypes.length < 2) {
                newList.push(type);
            }
        } else {
            // Remove type only if more than the minimum required is currently selected
            if (selectedTypes.length > 1) {
                newList.splice(currentIndex, 1);
            }
        }

        setSelectedTypes(newList);
        savePageData({ companyType: newList });
    };

    const savePageData = (data) => {
        const userId = auth.currentUser?.uid;
        if (userId && pageId) {
            const pageDataRef = databaseRef(database, `Pages/${pageId}/pageData`);
            update(pageDataRef, data).catch(error => console.error('Failed to save page data:', error));
        } else {
            console.error('No pageId provided or user is not logged in');
        }
    };

    return (
        <div className="page-mood-selection">
            <div className="create-page-header-fonts">
                <h2>Select Industry Role</h2>
                <p>Select up to 2 roles that best describe you in the industry.</p>
            </div>
            <div className="mood-selection-container">
                {availableTypes.map((type) => (
                    <button key={type} className={`mood-button ${selectedTypes.includes(type) ? "selected" : ""}`} onClick={() => toggleTypeSelection(type)}>
                        {type}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default IndustryMoods;
