import React from 'react';
import { Link } from 'react-router-dom';

function SongItem({
  song,
  confirmDelete,
  isLoading,
  handleImageLoaded,
  NomineeRibbon,
  AwardWinnerRibbon,
  SkeletonLoader,
  isInPlaylist
}) {
  return (
    <div key={song.id} className="songItem">
      <div className="delete-box" onClick={() => confirmDelete(song.id)}>
        <img src="./img/delete.svg" alt="Delete" className="deleteIcon" />
      </div>
      {song.nominatedDate && !song.awardDate && <NomineeRibbon />}
      {song.awardDate && <AwardWinnerRibbon />}
      {isLoading ? (
        <SkeletonLoader />
      ) : (
      <a href={`/song/${song.id}`} target="_blank">
        <img
          src={song.songCoverUrl}
          alt={`${song.name} cover`}
          className="songCover"
          onLoad={() => handleImageLoaded(song.id)}
        />
              </a>
      )}
      <div className="songDetails">
        <Link to={`/song/${song.id}`} style={{ color: 'inherit' }}>
          <h3>{song.name}</h3>
        </Link>
        <p>{song.artist}</p>
        <div className="song-details-nominations">
          {song.nominatedDate && !song.awardDate && (
            <div>
              <p>Nominated</p>
            </div>
          )}
          {song.awardDate && (
            <div style={{ backgroundColor: 'var(--red)' }}>
              <p>Award Winner</p>
            </div>
          )}
          {!song.nominatedDate && !song.awardDate && (
            <div style={{ backgroundColor: '#102974' }}>
              {isInPlaylist(song.chartlists) ? (
                <p className="status-text added-to-playlist">Added to Playlist</p>
              ) : (
                <p className="status-text in-review">In Review</p>
              )}
            </div>
          )}
        </div>
        <div className="profile-rating-number">
          <p></p>
          <div>
            <p></p>
          </div>
        </div>
      </div>
      {(song.nominatedDate || song.awardDate) ? (
        <Link style={{ textDecoration: 'none' }} to="/myawards">
          <div className="profile-download-certificate">
            <img src="./img/download.svg" alt="" />
            <p>Go to Certificate</p>
          </div>
        </Link>
      ) : (
        <div
          style={{ borderTop: '1px solid rgb(75, 75, 75)', animation: 'none' }}
          className="profile-download-certificate"
        >
          <p style={{ color: 'gray' }}>No Certificate Available</p>
        </div>
      )}
    </div>
  );
}

export default SongItem;
