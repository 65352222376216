import React from 'react';
import '../Team/ChartlistAndAwardManager.css';
import GenerateSongReview from '../Team/GenerateSongReview';  // Import GenerateSongReview
import GenerateMariusReview from '../Team/GenerateMariusReview';  // Import GenerateMariusReview
import GenerateReview from '../Team/GenerateReview';

function ChartlistAndAwardHelper({
  localChartlists,
  localIsNominated,
  localHasAward,
  awardNominatedInfo,
  selectedCompliments,
  toggleOption,
  handleChartlistToggle,
  handleAwardNominatedInfoChange,
  handleComplimentToggle,
  saveSettings,
  successMessage,
  editorQuote,
  setEditorQuote,
  generateSummary,
  artistName,
  songName,
  editorImgUrl,
  generatedMariusReview,
  setGeneratedMariusReview,
  setGeneratedSongReview,
  setMoesardEmail,
  setLadyEmail,
  ladyEmail
}) {
  const chartlistOptions = [
    { name: "Newcomers", icon: "/icons/new.svg" },
    { name: "Hiphop", icon: "/icons/hiphop.svg" },
    { name: "PopRadio", icon: "/icons/pop.svg" },
    { name: "Nominees", icon: "/icons/nominee.svg" },
    { name: "AwardWinners", icon: "/icons/award.svg" },
    { name: "Editor", icon: "/icons/writer.svg" },
    { name: "International", icon: "/icons/international.svg" }
  ];

  const complimentOptions = [
    // Quality Category
    { title: "Great Sound Quality", text: "the outstanding sound quality", image: './service/production.svg', category: "Quality" },
    { title: "Clean Mix", text: "the clean and well-balanced mix", image: './service/production.svg', category: "Quality" },
    { title: "Great Vocal Production", text: "the top-notch vocal production", image: './service/production.svg', category: "Quality" },
    { title: "Crisp High-End", text: "the crisp and clear high-end frequencies", image: './service/production.svg', category: "Quality" },
    { title: "Tight Low-End", text: "the tight and punchy low-end", image: './service/production.svg', category: "Quality" },
  
    // Vibe Category
    { title: "Powerful Lyrics", text: "the powerful and impactful lyrics", image: './service/ear.svg', category: "Vibe" },
    { title: "Catchy Melody", text: "the catchy and memorable melody", image: './service/ear.svg', category: "Vibe" },
    { title: "Smooth Vibes", text: "the smooth and relaxing vibes", image: './service/ear.svg', category: "Vibe" },
    { title: "Electrifying Beat", text: "the electrifying and energetic beat", image: './service/ear.svg', category: "Vibe" },
    { title: "Hypnotic Flow", text: "the hypnotic and seamless flow", image: './service/ear.svg', category: "Vibe" },
    { title: "Raw Energy", text: "the raw energy that takes over the track", image: './service/ear.svg', category: "Vibe" },
    { title: "Uplifting Energy", text: "the uplifting and positive energy", image: './service/ear.svg', category: "Vibe" },
    { title: "Dope Flow", text: "that dope and relentless flow", image: './service/ear.svg', category: "Vibe" },
    { title: "Next-Level Flow", text: "the next-level flow that goes off", image: './service/ear.svg', category: "Vibe" },
  
    // Creativity Category
    { title: "Impressive Arrangement", text: "the impressive and creative arrangement", image: './service/artwork.svg', category: "Creativity" },
    { title: "Innovative Sound Design", text: "the innovative and fresh sound design", image: './service/artwork.svg', category: "Creativity" },
    { title: "Powerful Hook", text: "the powerful and unforgettable hook", image: './service/artwork.svg', category: "Creativity" },
    { title: "Emotional Lyrics", text: "the deeply emotional and touching lyrics", image: './service/artwork.svg', category: "Creativity" },
    { title: "Bold Creativity", text: "the bold and daring creativity", image: './service/artwork.svg', category: "Creativity" },
    { title: "Memorable Chorus", text: "the memorable and striking chorus", image: './service/artwork.svg', category: "Creativity" }
  ];
  
  return (
    <div className="caam-chartlist-award-container">
     <h2 style={{marginBottom: '30px',}}>Song Review Generator</h2>
   <div style={{display: 'flex', width: '100%' , justifyContent: 'center', marginTop: '-15px'}}>
       <h4 style={{margin: '0px 10px 0 0'}}>{artistName}</h4>
       <p style={{margin: '0px 0 0 0'}}>{songName}</p>
   </div>
      <div className="caam-award-options-container">
        <div
          className={`caam-award-option-item ${localIsNominated ? 'selected' : ''}`}
          onClick={() => toggleOption("Nominated")}
        >
          <img src="/icons/nominee.svg" alt="Nominated" />
          <p>Nominated</p>
        </div>

        <div
          className={`caam-award-option-item ${localHasAward ? 'selected' : ''}`}
          onClick={() => toggleOption("Awarded")}
        >
          <img src="/icons/award.svg" alt="Awarded" />
          <p>Awarded</p>
        </div>
      </div>


      {(localIsNominated || localHasAward) && (
  <div className="caam-award-info">
    <div
      className={`award-toggle ${awardNominatedInfo === "Best Vocals" ? "active" : ""}`}
      onClick={() => handleAwardNominatedInfoChange("Best Vocals")}
    >
        <img src="/img/verses.svg" alt="Awarded" />Best Vocals
    </div>
    <div
      className={`award-toggle ${awardNominatedInfo === "Best Production" ? "active" : ""}`}
      onClick={() => handleAwardNominatedInfoChange("Best Production")}
    >
   <img src="/img/music.svg" alt="Awarded" />Best Production
    </div>
    <div
      className={`award-toggle ${awardNominatedInfo === "Best Newcomer" ? "active" : ""}`}
      onClick={() => handleAwardNominatedInfoChange("Best Newcomer")}
    >
       <img src="/img/flash.svg" alt="Awarded" />Best Newcomer
    </div>
  </div>
)}
  


      <div className="caam-chartlist-award-grid">
      <h2>Playlists</h2>

        {chartlistOptions.map((option) => (
          <div
            key={option.name}
            className={`caam-chartlist-award-grid-item ${localChartlists[option.name] ? 'selected' : ''}`}
            onClick={() => handleChartlistToggle(option, !localChartlists[option.name])}
          >
            <img src={option.icon} alt={option.name} />
            <p>{option.name}</p>
          </div>
        ))}
      </div>

      <h3>What do you like about the Song?</h3>
{/* Iterate over the categories */}
{["Quality", "Vibe", "Creativity"].map((category) => (
  <div key={category}>
    {/* Category Title */}
    <h5>{category}</h5>
    
    {/* Grid Container for the items of this category */}
    <div className="caam-compliment-grid">
      {complimentOptions
        .filter((compliment) => compliment.category === category)
        .map((compliment) => (
          <div
            key={compliment.title}
            className={`caam-compliment-item ${selectedCompliments.includes(compliment.text) ? 'selected' : ''}`}
            onClick={() => handleComplimentToggle(compliment.text)}
          >
            <img src={compliment.image} alt={compliment.title} className="caam-compliment-img" />
            <div>
              <h4>{compliment.title}</h4>
              <p>{compliment.text}</p>
            </div>
          </div>
        ))}
    </div>
  </div>
))}

{editorQuote && (
        <div className="editor-quote-section">
          <div className="quote-editor-details">
            <img src={editorImgUrl} alt="Editor" className="editor-img" />
            <div>
              <p>{editorQuote}</p>
            </div>
          </div>
        </div>
      )}


      <GenerateReview
        artistName={artistName}
        editorImgUrl={editorImgUrl}
        editorQuote={editorQuote}
        setEditorQuote={setEditorQuote}
        generateSummary={generateSummary}
        setMoesardEmail={setMoesardEmail} 
        setLadyEmail={setLadyEmail} 
       setSongReviewText={setGeneratedSongReview} 
        setMariusReviewText={setGeneratedMariusReview}  // Diese Zeile sicherstellen!
     />

      <div className="caam-chartlist-award-buttons">
      <button 
  onClick={saveSettings} 
  disabled={!ladyEmail} 
  className={`caam-save-button ${!ladyEmail ? 'caam-disabled-button' : ''}`}
>
    Save</button>
      </div>

      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>
  );
}

export default ChartlistAndAwardHelper;
