import React, { useEffect, useState, useMemo } from 'react';
import { ref as databaseRef, onValue, off } from 'firebase/database';
import { database } from '../../firebaseConfig';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importiere Quill Styles
import './PageTextEdit.css';

function PageTextEdit({ pageId, widgetKey, onToggleSettings, isWhiteMode, active, settingsRoute }) {
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editorStyle, setEditorStyle] = useState({});

  useEffect(() => {

    const textRef = databaseRef(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/longText`);

    const handleValueChange = (snapshot) => {
      setIsLoading(false);
      if (snapshot.exists()) {
        const newText = snapshot.val();
        if (newText !== text) {
          setText(newText);
        }
      } else {
        setText('');
      }
    };

    const handleError = (error) => {
      console.error('Error fetching text data:', error);
      setError(error);
      setIsLoading(false);
    };

    const unsubscribe = onValue(textRef, handleValueChange, handleError);

    return () => {
      off(textRef, 'value', unsubscribe);
    };
  }, [pageId, widgetKey]);

  useEffect(() => {
    const newStyle = {
      color: isWhiteMode ? 'black' : 'white'
    };
    // Nur aktualisieren, wenn sich der Stil tatsächlich ändert
    if (editorStyle.color !== newStyle.color) {
      setEditorStyle(newStyle);
    }
  }, [isWhiteMode, editorStyle.color]);

  const memoizedQuill = useMemo(() => {
    return <ReactQuill value={text} readOnly={true} theme="bubble" style={editorStyle} />;
  }, [text, editorStyle]);

  const isEmptyText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return !doc.body.textContent.trim();
  };

  const handleClick = () => {
    if (onToggleSettings) {
      onToggleSettings('TextEdit');
    }
  };

  const className = `page-text-header ${settingsRoute ? 'settings-route' : ''} ${active ? 'is-active' : ''}`;

  return (
    <div className={className} onClick={handleClick}>
      {settingsRoute && (
        <div className="edit-overlay">
          <img src="../../img/edit.webp" alt="Edit" />
          <span>Edit</span>
        </div>
      )}
      {isLoading ? (
        <div className="loading-spinner">Loading...</div>
      ) : error ? (
        <div>Error loading text: {error.message}</div>
      ) : (
        !isEmptyText(text) ? (
          <div className="gig-text-header">
            <div>
              <h2>About</h2>
            </div>
            {memoizedQuill}
          </div>
        ) : (
          settingsRoute && (
            <div className="gig-upload-song-button" onClick={() => onToggleSettings('TextEditor')}>
              <h5>Detailed Description</h5>
              <img src="../../img/plus.svg" alt="Add" />
            </div>
          )
        )
      )}
    </div>
  );
}

export default PageTextEdit;
