// widgetDataMap.js
export const widgetDataMap = {
    GigSongs: {
        title: "Your Songs",
        description: "A list of songs for your gig.",
        imageUrl: "../../img/wave_background_2.webp",
        widgetSettingsClick: "GigSongs"
    },
    PageTextEdit: {
        title: "Detailed Text",
        description: "Editable text area for your page.",
        imageUrl: "../../img/desc_widget_2.webp",
        widgetSettingsClick: "TextEdit"
    },
    AddProject: {
        title: "My Projects",
        description: "Add and showcase your projects.",
        imageUrl: "../../img/project_widget_2.webp",
        widgetSettingsClick: "AddProject"
    },
    PageEquipment: {
        title: "Equipment List",
        description: "List of equipment used.",
        imageUrl: "../../img/equip_widget_2.webp",
        widgetSettingsClick: "Equipment"
    },
    PageQa: {
        title: "Question & Answers",
        description: "Questions and answers section.",
        imageUrl: "../../img/qa_widget_2.webp",
        widgetSettingsClick: "QA"
    }
};
