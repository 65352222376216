import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HomePromoBanner1.css';
import { ref as databaseRef, push } from 'firebase/database';
import { database } from '../firebaseConfig'; // Firebase-Konfiguration
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Firebase Auth
import HomeInfoBoxen from '../Utility/HomeInfoBoxen'; // HomeInfoBoxen Komponente

const ctaBigAwardsBannerVariants = [
  {
    id: 1,
    header: "Join thousands of Incredible Artists...",
    highlightWord: "thousands", // Das Wort, das hervorgehoben werden soll
    description: "Collaborate with award-winning professionals directly on Triple A.",
    showHomeInfoBoxen: true
  },
  {
    id: 2,
    header: "Only 1% of Submissions Get Approved...",
    highlightWord: "Only 1%", // Das Wort, das hervorgehoben werden soll
    description: "Connect with the best in the industry on Triple A.",
    showHomeInfoBoxen: true
  },
  {
    id: 3,
    header: "Find Pros for Your Next Project...",
    highlightWord: "Pros", // Das Wort, das hervorgehoben werden soll
    description: "Join a network of top-notch professionals.",
    showHomeInfoBoxen: false
  },
  {
    id: 4,
    header: "Access the Worlds Top 1% Artists...",
    highlightWord: "Access", // Das Wort, das hervorgehoben werden soll
    description: "Work with industry leaders on your terms.",
    showHomeInfoBoxen: false
  }
];

function HomePromoBanner2() {
  const [currentVariant, setCurrentVariant] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Zustand für Login-Status

  // Überprüfe, ob der Benutzer eingeloggt ist
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true); // Benutzer ist eingeloggt
      } else {
        setIsLoggedIn(false); // Benutzer ist nicht eingeloggt
      }
    });

    // Zufällige Variante beim Laden der Seite auswählen
    const randomVariantIndex = Math.floor(Math.random() * ctaBigAwardsBannerVariants.length);
    setCurrentVariant(ctaBigAwardsBannerVariants[randomVariantIndex]);

    // Aufräumen, wenn die Komponente unmontiert wird
    return () => unsubscribe();
  }, []);

  // Funktion, um die Daten bei Klick in die Firebase-Datenbank zu pushen
  const handleClick = () => {
    if (currentVariant) {
      const clickData = {
        variantId: currentVariant.id,
        header: currentVariant.header,
        description: currentVariant.description,
        timestamp: Date.now()
      };

      const analyticsRef = databaseRef(database, '/Analytics/TopPageAwardsBanner');
      push(analyticsRef, clickData); // Pusht die Daten in die Firebase-Realtime-Datenbank

      console.log('Data pushed to Firebase:', clickData);
    }
  };

  if (!currentVariant || isLoggedIn) {
    return null; // Wenn keine Variante gesetzt ist oder der Benutzer eingeloggt ist, nichts rendern
  }

  // Funktion, um das highlightWord im Header hervorzuheben
  const renderHeaderWithHighlight = () => {
    const { header, highlightWord } = currentVariant;
    const parts = header.split(highlightWord); // Header in zwei Teile teilen

    return (
      <>
        {parts[0]}
        <span className="highlight">{highlightWord}</span>
        {parts[1]}
      </>
    );
  };

  return (
    <div className="home-promo-banner" style={{height: '400px'}}>
      <div className="home-promo-banner-wrapper">
      <img id="promo-blur-banner" src="./img/Background_Blur.webp" alt="Collab & Connect" />

        <div className="home-promo-banner-wrapper-left">
       
          <h2>{renderHeaderWithHighlight()}</h2>
          <p>{currentVariant.description}</p>
         

          <Link
            to="/signup"
            className="cta-promo-link"
            onClick={handleClick}
          >
            <p>Apply With Your Song</p>
          </Link>
        </div>
        <div className="home-promo-banner-wrapper-right">
          <img src="./img/democharts_boys_2.webp" alt="Collab & Connect" />
        </div>
      </div>
    </div>
  );
}

export default HomePromoBanner2;
