import React, { useState, useEffect } from 'react';

function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  // Eine Funktion, die als Error Boundary fungiert, indem sie die Fehler fängt.
  const ErrorFallback = () => <h1>An error has occurred.</h1>;

  // Ein Error Handler, der den Zustand auf true setzt, wenn ein Fehler auftritt.
  const handleError = (error, errorInfo) => {
    console.error("ErrorBoundary caught an error:", error, errorInfo);
    setHasError(true);
  };

  // Effekt, der React's Error Boundary-ähnliches Verhalten simuliert.
  useEffect(() => {
    // Diese Funktion imitiert die componentDidCatch Lifecycle-Methode in Klassenkomponenten.
    const catchError = (event) => {
      handleError(event.error, { componentStack: event.error.stack });
    };

    window.addEventListener('error', catchError);
    
    // Cleanup-Funktion, um den Event Listener zu entfernen.
    return () => {
      window.removeEventListener('error', catchError);
    };
  }, []);

  // Bedingtes Rendering basierend auf dem Zustand `hasError`.
  if (hasError) {
    return <ErrorFallback />;
  }

  return children;
}

export default ErrorBoundary;
