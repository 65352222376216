import React from 'react';
import './DummyChatInput.css'; // Create this CSS file for styling

const DummyChatInput = () => {
    return (
        <div className="dummy-chat-input">
            <p>Chat locked until response</p>
            <img src="./img/lock_2.svg" alt="" />
        </div>
    );
};

export default DummyChatInput;
