import React, { useEffect } from 'react';
import './CollabSuccessModal.css';

const CollabSuccessModal = ({ onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (onClose) onClose();
    }, 2000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="collab-success-modal-overlay" onClick={onClose}>
      <div className="collab-success-modal" onClick={(e) => e.stopPropagation()}>
        <div className="collab-success-header">
          <img src="../../img/check.webp" alt="checkmark" />
          <h2>Sent Successfully</h2>
          <p>Your message has been sent successfully.</p>
        </div>
        <div className="start-over-button" style={{ textDecoration: 'none' }}>
          <button
            style={{
              backgroundColor: 'transparent',
              zIndex: '20',
              color: 'white',
              border: 'none',
              fontWeight: '600',
              textTransform: 'uppercase',
            }}
          >
            Close
          </button>
          <div className="completed-stepper-progress-container">
            <div className="completed-stepper-progress-bar"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollabSuccessModal;
