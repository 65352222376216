import React, { useEffect, useState } from 'react';
import { ref as databaseRef, get, set, remove } from 'firebase/database';
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import { database, storage } from "../firebaseConfig";
import ShowRssCsv from './ShowRssCsv';
import ShowDailyContent from './ShowDailyContent';
import './RSSGetter.css';

const RSSGetter = () => {
  const [channelId, setChannelId] = useState("");
  const [youtubeName, setYoutubeName] = useState("");
  const [group, setGroup] = useState("rapper");
  const [targets, setTargets] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [entries, setEntries] = useState([]);
  const [showEntries, setShowEntries] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state

  useEffect(() => {
    fetchTargets();
    fetchCsvData();
    if (showEntries) {
      fetchEntriesFromDatabase();
    }
  }, [showEntries]);

  const fetchTargets = async () => {
    const targetsRef = databaseRef(database, 'Rss/targets');
    const snapshot = await get(targetsRef);
    if (snapshot.exists()) {
      const targetData = snapshot.val();
      setTargets(Object.keys(targetData).map(id => ({ id, ...targetData[id] })));
    }
  };

  const fetchEntriesFromDatabase = async () => {
    try {
      const entriesRef = databaseRef(database, 'Rss/entries');
      const snapshot = await get(entriesRef);
      if (snapshot.exists()) {
        const entryData = snapshot.val();
        const newEntries = Object.entries(entryData).map(([id, entry]) => ({ id, ...entry }));
        setEntries(newEntries);
      }
    } catch (error) {
      console.error("Error fetching entries from database:", error);
    }
  };

  const fetchCsvData = async () => {
    try {
      const url = await getDownloadURL(storageRef(storage, 'rss_entries.csv'));
      const response = await fetch(url);
      const text = await response.text();
      const rows = text.trim().split("\n").map(row => row.split(","));
      
      const header = rows[0];
      const data = rows.slice(1).map(row => {
        const rowData = {};
        row.forEach((cell, index) => {
          rowData[header[index]] = cell;
        });
        return rowData;
      });
      setCsvData(data);
    } catch (error) {
      console.error("Error fetching CSV data:", error);
    }
  };

  const handleAddTarget = async () => {
    if (channelId && youtubeName) {
      const rssLink = `https://www.youtube.com/feeds/videos.xml?channel_id=${channelId}`;
      const targetId = `target_${Date.now()}`;
      const newTarget = { 
        name: youtubeName,
        link: rssLink, 
        group, 
        type: "YouTube Channel" 
      };
      const targetRef = databaseRef(database, `Rss/targets/${targetId}`);
      await set(targetRef, newTarget);
      setTargets([...targets, { id: targetId, ...newTarget }]);
      setChannelId("");
      setYoutubeName(""); 
    } else {
      alert("Please enter a valid YouTube channel ID and name.");
    }
  };

  const handleDeleteTarget = async (id) => {
    const targetRef = databaseRef(database, `Rss/targets/${id}`);
    await remove(targetRef);
    setTargets(targets.filter(target => target.id !== id));
  };

  const handleFetchRSS = async () => {
    setLoading(true); // Start loading spinner
    try {
      const urls = targets.map(target => target.link);
      const response = await fetch("https://us-central1-aaartistsdev.cloudfunctions.net/fetchRSSFeeds", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          urls, 
          group, 
        }),
      });
    
      const data = await response.json();
      if (response.ok) {
        console.log("Fetched entries from RSS:", data);
        alert("RSS entries fetched and saved successfully!");
      } else {
        console.error("Error fetching entries:", data.error);
        alert("Error fetching RSS entries.");
      }
    } catch (error) {
      console.error("Error calling Cloud Function:", error);
      alert("Error calling Cloud Function.");
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };
  
  const handleDeleteCsv = () => {
    setCsvData([]);
  };

  return (
    <div className="rss-container">
      <h2 className="rss-header">RSS Getter</h2>
      
      <div className="rss-toggle-buttons">
        <button onClick={() => setShowEntries(true)}>Entries</button>
        <button onClick={() => setShowEntries(false)}>Target Accounts</button>
      </div>

      {showEntries ? (
        <ShowDailyContent entries={entries} />
      ) : (
        <>
          <div>
            <button className="rss-fetch-button" onClick={handleFetchRSS}>
              {loading ? <span className="spinner"></span> : "Fetch Entries"}
            </button>
          </div>

          <div className="rss-input-container">
            <input
              type="text"
              placeholder="Enter YouTube channel ID"
              value={channelId}
              onChange={(e) => setChannelId(e.target.value)}
            />
            <input
              type="text"
              placeholder="Enter YouTube target name"
              value={youtubeName}
              onChange={(e) => setYoutubeName(e.target.value)}
            />
            <select value={group} onChange={(e) => setGroup(e.target.value)}>
              <option value="rapper">Rapper</option>
              <option value="experts">Experts</option>
            </select>
            <button className="rss-save-button" onClick={handleAddTarget}>Save YouTube Target</button>
          </div>

          <h3 className="rss-header">Saved RSS Targets</h3>
          <ul className="rss-target-list">
            {targets.map((target) => (
              <li className="rss-target-item" key={target.id}>
                <span>
                  {target.name ? `${target.name} - ` : ""} 
                  {target.group} - {target.type}
                </span>
                <a href={target.link} target="_blank" rel="noopener noreferrer" className="rss-watch-button">
                  View Profile
                </a>
                <button
                  className="rss-delete-button"
                  onClick={() => handleDeleteTarget(target.id)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>

          <ShowRssCsv csvData={csvData} onDeleteCsv={handleDeleteCsv} />
        </>
      )}
    </div>
  );
};

export default RSSGetter;
