import React from 'react';

const ReviewChatSent = ({ currentUser, senderId }) => {
    const isCurrentUserSender = currentUser.uid === senderId;

    return (
        <div className="review-chat-sent">
            {isCurrentUserSender ? (
                <p>You have a new review!</p>
            ) : (
                <p>Thanks for sending your review</p>
            )}
        </div>
    );
};

export default ReviewChatSent;
