import React, { useEffect, useState } from 'react';
import { getStorage, ref as storageRef, getDownloadURL, uploadString } from "firebase/storage";
import './ShowRssCsv.css';

const ShowRssCsv = () => {
  const [csvData, setCsvData] = useState([]); // State to hold CSV data

  useEffect(() => {
    fetchCsvData();
  }, []);

  const fetchCsvData = async () => {
    try {
      const storage = getStorage();
      const csvRef = storageRef(storage, 'rss_entries.csv');
      const url = await getDownloadURL(csvRef);

      const response = await fetch(url);
      const text = await response.text();

      const rows = text.trim().split("\n").map(row => row.split(","));
      const header = rows[0].map(cell => cell.replace(/"/g, '').trim());

      const data = rows.slice(1)
        .map(row => {
          const rowData = {};
          row.forEach((cell, index) => {
            rowData[header[index]] = cell.replace(/"/g, '').trim();
          });
          return rowData;
        })
        .filter(entry => entry.Title && entry.Link)
        .filter((entry, index, self) => 
          index === self.findIndex((e) => e.Title === entry.Title && e.Link === entry.Link)
        );

      setCsvData(data);
    } catch (error) {
      console.error("Error fetching CSV data:", error);
    }
  };

  const handleDeleteCsvData = async () => {
    try {
      const storage = getStorage();
      const csvRef = storageRef(storage, 'rss_entries.csv');
      const headerRow = "Title,Link,Pub Date,Group,Status,Fetched At\n";
      await uploadString(csvRef, headerRow, 'raw', { contentType: 'text/csv' });
      setCsvData([]);
    } catch (error) {
      console.error("Error deleting CSV data:", error);
    }
  };

  const getYouTubeEmbedUrl = (link) => {
    try {
      const url = new URL(link);
      const videoId = url.searchParams.get("v");
      return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
    } catch (error) {
      return null;
    }
  };

  return (
    <div className="rss-csv-container">
      <h3 className="rss-csv-header">CSV Data</h3>
      <button className="rss-clear-button" onClick={handleDeleteCsvData}>Clear Data (Keep Header)</button>

      <div className="rss-youtube-grid">
        {csvData.map((entry, index) => (
          <div key={index} className="rss-youtube-item">
            <h4>{entry.Title}</h4>
            <p>Published: {entry['Pub Date']}</p>
            {getYouTubeEmbedUrl(entry.Link) ? (
              <iframe
                className="rss-video-iframe"
                src={getYouTubeEmbedUrl(entry.Link)}
                title={entry.Title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <a href={entry.Link} target="_blank" rel="noopener noreferrer" className="rss-watch-button">
                Watch on YouTube
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShowRssCsv;
