import React from 'react';

const SuperChatReviews = ({ gigs, selectedGigId, setSelectedGigId, handleSendReviewRequest, isReviewRequestSent }) => {
    return (
        <div>
            {isReviewRequestSent ? (
                <p>This user has already left a review for this gig.</p>
            ) : gigs.length > 0 ? (
                <div className="request-review-top">
                    <h2>Ask for Gig Review</h2>
                    <div className="superchat-gig-selector">
                        <div>
                            <label htmlFor="gig-select">Select the gig:</label>
                            <select
                                id="gig-select"
                                value={selectedGigId}
                                onChange={(e) => setSelectedGigId(e.target.value)}
                                className="superchat-gig-select"
                            >
                                {gigs.map(gig => (
                                    <option key={gig.id} value={gig.id}>
                                        {gig.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button onClick={handleSendReviewRequest} className="review-request-button">
                            Request a Review
                            <img src="./img/plus.svg" alt="" />
                        </button>
                    </div>
                </div>
            ) : (
                <p style={{marginTop: '40px'}}>No live gigs available for review.</p>
            )}
        </div>
    );
};

export default SuperChatReviews;
