export const generateSenderText = (senderInfo) => {
    if (!senderInfo) return '';
  
    const { artistName, artistType, country, numberAwards = 0, numberNominated = 0 } = senderInfo;
  
    let awardsMessage = "";
    if (numberAwards > 0) {
      awardsMessage = "I've been awarded with a triple a award.";
    } else if (numberNominated > 0) {
      awardsMessage = "I've been nominated for a triple a award.";
    }
  
    return `My Artist Name is ${artistName}. I'm a ${artistType} from ${country}. ${awardsMessage}`;
  };
  
  export const generateReceiverText = (receiverInfo) => {
    if (!receiverInfo) return '';
  
    const { artistName, artistType, country, openForCollabs, sellBeats, sellLicense, sellVerse } = receiverInfo;
  
    const services = [];
    if (openForCollabs) services.push("collaborations");
    if (sellBeats) services.push("selling beats");
    if (sellLicense) services.push("selling licenses");
    if (sellVerse) services.push("selling verses");
  
    const servicesMessage = services.length > 0 ? `I saw that they offer ${services.join(', ')} and I'm interested in a collaboration.` : '';
  
    return `I want to collaborate with ${artistName}, a ${artistType} from ${country}. ${servicesMessage}`;
  };
  