import React, { useState, useEffect, useRef } from 'react';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ref as databaseRef, onValue, off, set } from 'firebase/database';
import { storage, database } from '../../firebaseConfig';
import './UtilitySettings.css';

function VideoUploader({ pageId, onVideoUpload }) {
    const [videos, setVideos] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadingThumbnail, setUploadingThumbnail] = useState(false);
    const [videoFile, setVideoFile] = useState(null);
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const [thumbnailPreview, setThumbnailPreview] = useState(null);
    const inputFileRef = useRef(null);
    const inputThumbnailRef = useRef(null);
    const [refreshNeeded, setRefreshNeeded] = useState(false);

    useEffect(() => {
        const videoGalleryRef = databaseRef(database, `Pages/${pageId}/pageData/videoGallery`);
        onValue(videoGalleryRef, (snapshot) => {
            if (snapshot.exists()) {
                setVideos(snapshot.val() || []);
            }
        });

        return () => off(videoGalleryRef);
    }, [pageId]);

    const handleVideoFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.size <= 50 * 1024 * 1024) { // 50 MB limit
            setVideoFile(file);
            setVideoPreview(URL.createObjectURL(file)); // Create a preview URL
        } else {
            alert('Video file is too big. The limit is 50 MB.');
        }
    };

    const handleThumbnailFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setThumbnailFile(file);
            setThumbnailPreview(URL.createObjectURL(file)); // Create a preview URL
        }
    };

    const handleUpload = async () => {
        if (!videoFile || !thumbnailFile) {
            alert('Please select both a video file and a thumbnail.');
            return;
        }

        setUploading(true);
        setUploadingThumbnail(true);

        const uploadVideoTask = uploadFile(videoFile, `videos/${pageId}/${new Date().getTime()}-${videoFile.name}`);
        const uploadThumbnailTask = uploadFile(thumbnailFile, `thumbnails/${pageId}/${new Date().getTime()}-${thumbnailFile.name}`);

        try {
            const [videoURL, thumbnailURL] = await Promise.all([uploadVideoTask, uploadThumbnailTask]);

            const newVideo = { videoFile: videoURL, videoThumbnail: thumbnailURL };
            const updatedVideos = [...videos, newVideo];

            await set(databaseRef(database, `Pages/${pageId}/pageData/videoGallery`), updatedVideos);
            setVideos(updatedVideos);
            setUploading(false);
            setUploadingThumbnail(false);
            setRefreshNeeded(true);
            onVideoUpload && onVideoUpload(updatedVideos); // Propagate the update through a callback
            setVideoFile(null);
            setThumbnailFile(null);
            setVideoPreview(null);
            setThumbnailPreview(null);
        } catch (error) {
            console.error('Upload failed:', error);
            setUploading(false);
            setUploadingThumbnail(false);
        }
    };

    const uploadFile = (file, path) => {
        return new Promise((resolve, reject) => {
            const fileRef = storageRef(storage, path);
            const uploadTask = uploadBytesResumable(fileRef, file);

            uploadTask.on('state_changed',
                () => { },
                error => reject(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(resolve).catch(reject);
                }
            );
        });
    };

    const deleteVideo = (videoUrl) => {
        const updatedVideos = videos.filter(v => v.videoFile !== videoUrl);
        setVideos(updatedVideos);
        set(databaseRef(database, `Pages/${pageId}/pageData/videoGallery`), updatedVideos);
    };

    return (
        <div className="unique-video-uploader-container">
            {videos.map((video, index) => (
                <div key={index} className="unique-video-display">
                    <img src={video.videoThumbnail} alt="Thumbnail" className="video-thumbnail-2" style={{ maxWidth: '300px' }} />
                    <video controls src={video.videoFile} style={{ maxWidth: '300px' }} />
                    <div className="unique-video-delete-button" onClick={() => deleteVideo(video.videoFile)}>
                        <img src="../../img/delete.svg" alt="Delete" className="unique-video-delete-icon" />
                    </div>
                </div>
            ))}
            {uploading && (
                <div className="unique-image-display">
                    <div className="unique-image-placeholder">
                        <img style={{ width: '30px' }} className="uploading-image" src="../../img/icon.png" alt="" />
                        <div className="spinner-3"></div>
                    </div>
                </div>
            )}
            {refreshNeeded && (
                <div className="refresh-message">
                    <p>Refresh page to see changes.</p>
                </div>
            )}
            <div className="preview-container">
                {videoPreview && (
                    <div className="preview-item">
                        <video controls src={videoPreview} style={{ maxWidth: '300px' }} />
                    </div>
                )}
                {thumbnailPreview && (
                    <div className="preview-item">
                        <img src={thumbnailPreview} alt="Thumbnail Preview" style={{ maxWidth: '300px' }} />
                    </div>
                )}
            </div>
            <div className="upload-buttons">
                <button className="uploader-file-input" onClick={() => inputFileRef.current.click()}>
                    <p>Select Video</p>
                    <img src="../../img/plus.svg" alt="" />
                    <input
                        ref={inputFileRef}
                        type="file"
                        onChange={handleVideoFileChange}
                        accept="video/*"
                        style={{ display: 'none' }}
                    />
                </button>
                <button className="uploader-file-input" onClick={() => inputThumbnailRef.current.click()}>
                    <p>Select Thumbnail</p>
                    <img src="../../img/plus.svg" alt="" />
                    <input
                        ref={inputThumbnailRef}
                        type="file"
                        onChange={handleThumbnailFileChange}
                        accept="image/*"
                        style={{ display: 'none' }}
                    />
                </button>
            </div>
            <button 
                className="video-gallery-submit-button" 
                onClick={handleUpload} 
                disabled={!videoFile || !thumbnailFile || uploading || uploadingThumbnail}
                style={{ opacity: (!videoFile || !thumbnailFile) ? 0.5 : 1, marginTop: '15px' }}
            >
                <p>Upload Video and Thumbnail</p>
            </button>
        </div>
    );
}

export default VideoUploader;
