import React, { useState, useEffect, useRef, useCallback } from 'react';
import CollabSong from './CollabSong';
import AudioBar from '../Audio/AudioBar';
import SpotifySongs from '../Audio/SpotifySongs';
import CreateCollabModal from './CreateCollabModal';
import UpgradePackageModal from './UpgradePackageModal';
import FinishSettingsModal from './FinishSettingsModal';
import CollabLoginPopup from './CollabLoginPopup';
import CollabSuccessModal from './CollabSuccessModal';
import './CollabSongs.css';
import { auth } from '../firebaseConfig';
import { ref as databaseRef, get, set } from 'firebase/database';
import { database } from '../firebaseConfig';
import { getDominantColor } from '../Utility/ColorFetcherUtils';
import DashboardNav from '../UserBackend/DashboardNav';
import HomeNewHeader from './HomeNewHeader';
import LoadingScreen from '../Utility/LoadingScreen';
import BuyProModal from './BuyProModal';
import ViralBuyModal from './ViralBuyModal';

let randomSongIds = []; // Randomized list of song IDs
let currentBatchIndex = 0; // Track the current batch index

function CollabSongs({ filteredSongIds = [] }) {
  const [collabSongs, setCollabSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showAudioBar, setShowAudioBar] = useState(false);
  const [showCollabModal, setShowCollabModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showFinishSettingsModal, setShowFinishSettingsModal] = useState(false);
  const [showCollabLoginPopup, setShowCollabLoginPopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedReceiverId, setSelectedReceiverId] = useState(null);
  const [existingChats, setExistingChats] = useState({});
  const [userCredits, setUserCredits] = useState(0);
  const [isPremium, setIsPremium] = useState(false);
  const [hasSettings, setHasSettings] = useState(null);
  const [hasExpertSettings, setHasExpertSettings] = useState(null);
  const [visibleSongs, setVisibleSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMoreSongs, setHasMoreSongs] = useState(true);
  const songRefs = useRef([]);
  const [receiverImageUrl, setReceiverImageUrl] = useState('');
  const [loaderLoading, setLoaderLoading] = useState(true);
  const [selectedModal, setSelectedModal] = useState(null); // Zufällig ausgewähltes Modal

  const senderId = auth.currentUser ? auth.currentUser.uid : null;


  useEffect(() => {
    if (filteredSongIds.length > 0) {
      fetchFilteredSongs(filteredSongIds);
    } else {
      initializeSongs();
    }
    const randomModal = Math.random() < 0.5 ? 'BuyProModal' : 'ViralBuyModal';
    setSelectedModal(randomModal);
  }, [filteredSongIds]);

  

  const fetchFilteredSongs = async (songIds) => {
    const songs = await fetchSongsByIds(songIds, songIds.length);
    setCollabSongs(songs);
    setVisibleSongs(songs);
  };

  const initializeSongs = async () => {
    setLoading(true);
    const initialSongs = await initializeAndFetchSongs(36);
    setCollabSongs(initialSongs);
    setVisibleSongs(initialSongs);
    setLoading(false);
    setLoaderLoading(false); // Beendet den initialen Ladezustand

  };

  const loadMoreSongs = useCallback(async () => {
    if (loading || !hasMoreSongs || filteredSongIds.length > 0) return;

    setLoading(true);
    const newSongs = await fetchCollabSongs();

    if (newSongs.length === 0) {
      setHasMoreSongs(false);
    } else {
      const uniqueNewSongs = newSongs.filter(
        (newSong) => !collabSongs.some((song) => song.id === newSong.id)
      );

      if (uniqueNewSongs.length > 0) {
        setCollabSongs((prev) => [...prev, ...uniqueNewSongs]);
        setVisibleSongs((prev) => [...prev, ...uniqueNewSongs]);
      }
    }

    setLoading(false);
  }, [loading, hasMoreSongs, collabSongs, filteredSongIds]);

  const handleScroll = useCallback(() => {
    if (loading || !hasMoreSongs || filteredSongIds.length > 0) return;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const bodyHeight = document.documentElement.scrollHeight;

    const threshold = bodyHeight - windowHeight - 2400; // 2400px bevor das Ende erreicht wird

    if (scrollTop >= threshold) {
      loadMoreSongs();
    }
  }, [loading, hasMoreSongs, loadMoreSongs, filteredSongIds]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    visibleSongs.forEach((song, index) => {
      const buttonElement = songRefs.current[index]?.querySelector('.collab-contact-artist-button');
      if (song.artistImageUrl && buttonElement) {
        setButtonBackgroundColor(song.artistImageUrl, buttonElement);
      }
    });
  }, [visibleSongs]);

  const togglePlayPause = (song) => {
    if (currentSong && song.id === currentSong.id) {
      setIsPlaying(!isPlaying);
    } else {
      setCurrentSong(song);
      setIsPlaying(true);
      setShowAudioBar(true);
    }
  };

  const openCollabModal = (receiverId) => {
    if (!senderId) {
      setShowCollabLoginPopup(true);
      return;
    }
  
    if (!hasSettings && !hasExpertSettings) {
      setShowFinishSettingsModal(true);
      return;
    }
  
    if (isPremium || userCredits > 0) {
      const selectedSong = collabSongs.find(song => song.artistID === receiverId);
      const receiverImageUrl = selectedSong?.artistImageUrl || ''; // Hol das Bild basierend auf der artistID
      setSelectedReceiverId(receiverId);
      setShowCollabModal(true);
      setReceiverImageUrl(receiverImageUrl); // Setze das Bild für das Modal
    } else {
      setShowUpgradeModal(true);
    }
  };
  

  const handleCollabMessageSent = (receiverId) => {
    setExistingChats((prevChats) => ({
      ...prevChats,
      [receiverId]: true,
    }));

    setShowSuccessModal(true);

    setTimeout(() => {
      setShowSuccessModal(false);
    }, 3000);
  };

  const initializeAndFetchSongs = async (batchSize = 36) => {
    const songsRef = databaseRef(database, 'Songs');
    const songsSnapshot = await get(songsRef);

    if (songsSnapshot.exists()) {
      const songsData = songsSnapshot.val();
      randomSongIds = Object.keys(songsData).sort(() => Math.random() - 0.5);

      // Initialen Batch der Songs abrufen
      const initialBatch = await fetchSongsByIds(randomSongIds.slice(0, batchSize));
      currentBatchIndex += batchSize;

      return initialBatch;
    }
    return [];
  };

  const fetchCollabSongs = async (batchSize = 20) => {
    const nextBatch = await fetchSongsByIds(randomSongIds.slice(currentBatchIndex, currentBatchIndex + batchSize));
    currentBatchIndex += batchSize;
    return nextBatch;
  };

  const fetchSongsByIds = async (ids) => {
    const senderId = auth.currentUser ? auth.currentUser.uid : null;
  
    const songPromises = ids.map(async (id) => {
      const songRef = databaseRef(database, `Songs/${id}`);
      const songSnapshot = await get(songRef);
      if (songSnapshot.exists()) {
        const songData = songSnapshot.val();
  
        // Check if the song has a valid artistID
        if (!songData.artistID) {
          return null; // Skip this song if artistID doesn't exist
        }
  
        const isInChartlist = songData.chartlists && Object.values(songData.chartlists).some(value => value === true);
        if (!isInChartlist) {
          return null;
        }
  
        const artistRef = databaseRef(database, `Artists/${songData.artistID}`);
        const artistSnapshot = await get(artistRef);
        if (artistSnapshot.exists()) {
          const artistData = artistSnapshot.val();
  
          const hasUserLiked = senderId && songData.likes && songData.likes[senderId];
          return {
            id,
            ...songData,
            artistID: songData.artistID.trim(),
            artistImageUrl: artistData.artistImageUrl,
            numberAwards: artistData.numberAwards || 0,
            numberNominated: artistData.numberNominated || 0,
            openForCollabs: artistData.openForCollabs,
            sellBeats: artistData.sellBeats,
            sellLicense: artistData.sellLicense,
            sellVerse: artistData.sellVerse,
            hasUserLiked,
            totalLikes: songData.likes ? Object.keys(songData.likes).length : 0
          };
        }
      }
      return null;
    });
  
    const songsArray = (await Promise.all(songPromises)).filter(Boolean);
    return songsArray;
  };
  
  // Function to save or remove a song from collabFavorites (toggle)
  const saveCollabFavorite = async (songId, artistId) => {
    if (!senderId) return;

    const favoriteRef = databaseRef(database, `Artists/${senderId}/collabFavorites/${songId}`);
    const favoriteSnapshot = await get(favoriteRef);

    if (favoriteSnapshot.exists()) {
      // Remove the favorite if it exists
      await set(favoriteRef, null);
    } else {
      // Add the favorite if it does not exist
      const favoriteData = {
        artistId: artistId,
        pageId: songId,
        timestamp: new Date().toISOString(),
        type: 'collab'
      };
      await set(favoriteRef, favoriteData);
    }
  };

  // Function to like or unlike a song (toggle)
  const likeSong = async (songId) => {
    if (!senderId) return;

    const songRef = databaseRef(database, `Songs/${songId}/likes/${senderId}`);
    const likeSnapshot = await get(songRef);

    if (likeSnapshot.exists()) {
      // Remove the like if it exists
      await set(songRef, null);

      // Update the total like count in the state
      const songIndex = collabSongs.findIndex(song => song.id === songId);
      if (songIndex >= 0) {
        const updatedSongs = [...collabSongs];
        updatedSongs[songIndex].totalLikes = Math.max((updatedSongs[songIndex].totalLikes || 1) - 1, 0);
        updatedSongs[songIndex].hasUserLiked = false;
        setCollabSongs(updatedSongs);
      }
    } else {
      // Add the like if it does not exist
      const likeData = {
        timestamp: new Date().toISOString()
      };
      await set(songRef, likeData);

      // Update the total like count in the state
      const songIndex = collabSongs.findIndex(song => song.id === songId);
      if (songIndex >= 0) {
        const updatedSongs = [...collabSongs];
        updatedSongs[songIndex].totalLikes = (updatedSongs[songIndex].totalLikes || 0) + 1;
        updatedSongs[songIndex].hasUserLiked = true;
        setCollabSongs(updatedSongs);
      }
    }
  };

  const setButtonBackgroundColor = (imageUrl, button) => {
    if (!button) return;

    getDominantColor(imageUrl, (color) => {
      if (color && button) {
        button.style.backgroundColor = color;
      }
    });
  };
  
  useEffect(() => {
    if (senderId) {
      const fetchUserData = async () => {
        const userRef = databaseRef(database, `Artists/${senderId}`);
        const userSnapshot = await get(userRef);
  
        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          setUserCredits(userData.collabCredits || 0);
          setIsPremium(userData.isPremium || false);
          setHasSettings(userData.hasSettings || false);
          setHasExpertSettings(userData.hasExpertSettings || false);
        }
      };
  
      const fetchExistingChats = async () => {
        // Füge hier die Logik hinzu, um bestehende Chats abzurufen
        const chatPromises = collabSongs.map(async (song) => {
          const chatId = [senderId, song.artistID].sort().join('_');
          const chatRef = databaseRef(database, `Chats/${chatId}`);
          const chatSnapshot = await get(chatRef);
          return { artistId: song.artistID, exists: chatSnapshot.exists() };
        });
  
        const chatResults = await Promise.all(chatPromises);
        const chatsMap = chatResults.reduce((acc, { artistId, exists }) => {
          acc[artistId] = exists;
          return acc;
        }, {});
  
        setExistingChats((prevChats) => ({ ...prevChats, ...chatsMap }));
      };
  
      fetchUserData();
      fetchExistingChats(); // Rufe die Funktion zum Abrufen der bestehenden Chats auf
    }
  }, [senderId, collabSongs]);

  

  return (
    <div className="collab-songs">
      <div className="collab-songs-grid">
        {visibleSongs.map((song, index) => (
          <CollabSong
            key={song.id}
            song={song}
            isPlaying={isPlaying}
            currentSong={currentSong}
            togglePlayPause={() => togglePlayPause(song)}
            openCollabModal={openCollabModal}
            existingChats={existingChats}
            saveCollabFavorite={saveCollabFavorite}
            likeSong={likeSong}
            songRef={(el) => (songRefs.current[index] = el)}
          />
        ))}
      </div>
      {showAudioBar && isPlaying && currentSong && (
        <div className="audio-bar-container">
          {currentSong.fileUrl ? (
            <AudioBar
              url={currentSong.fileUrl}
              songImg={currentSong.songCoverUrl || currentSong.imageUrl}
              artistName={currentSong.artist}
              songName={currentSong.name}
              isPlaying={isPlaying}
              togglePlayPause={() => setIsPlaying(false)}
            />
          ) : (
            <SpotifySongs spotifyUrl={currentSong.spotifyLink} />
          )}
        </div>
      )}
      {showCollabModal && selectedReceiverId && (
        <CreateCollabModal
          senderId={senderId}
          receiverId={selectedReceiverId}
          onClose={() => setShowCollabModal(false)}
          onMessageSent={handleCollabMessageSent}
          receiverImageUrl={receiverImageUrl} // Übergebe das Bild hier

          />
      )}
        {showUpgradeModal && selectedModal === 'BuyProModal' && (
        <BuyProModal onClose={() => setShowUpgradeModal(false)} />
      )}
      {showUpgradeModal && selectedModal === 'ViralBuyModal' && (
        <ViralBuyModal isOpen={showUpgradeModal} onClose={() => setShowUpgradeModal(false)} />
      )}

      {showFinishSettingsModal && (
        <FinishSettingsModal onClose={() => setShowFinishSettingsModal(false)} />
      )}
      {showCollabLoginPopup && (
        <CollabLoginPopup onClose={() => setShowCollabLoginPopup(false)} />
      )}
      {showSuccessModal && (
        <CollabSuccessModal onClose={() => setShowSuccessModal(false)} />
      )}
      {loaderLoading && <div className="loading-spinner-3">
       <LoadingScreen />
        </div>}
    </div>
  );
}

export default CollabSongs;
