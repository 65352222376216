import React, { useState, useEffect } from 'react';
import { ref, set, onValue, remove, update } from 'firebase/database';
import { database } from '../firebaseConfig';

function AddTikTokVideo() {
  const [videoUrl, setVideoUrl] = useState('');
  const [videoType, setVideoType] = useState('');
  const [videos, setVideos] = useState([]);
  const videosPerPage = 6;
  const [page, setPage] = useState(0);

  useEffect(() => {
    const videosRef = ref(database, '/Viral/tiktok/');
    onValue(videosRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const videoList = Object.keys(data).map((key) => ({
          id: key,
          url: data[key].url,
          type: data[key].type || '',
          timestamp: data[key].timestamp || 0, // Fallback zu 0, falls kein Timestamp vorhanden ist
        }));

        // Sortieren nach Timestamp (neueste zuerst)
        videoList.sort((a, b) => b.timestamp - a.timestamp);

        setVideos(videoList);
      } else {
        setVideos([]);
      }
    });
  }, []);

  const handleAddVideo = (e) => {
    e.preventDefault();
    if (videoType === '') {
      alert('Please select a type for the video.');
      return;
    }

    const videoId = Date.now().toString();
    const videoRef = ref(database, `/Viral/tiktok/${videoId}`);

    set(videoRef, {
      url: videoUrl,
      type: videoType,
      timestamp: Date.now(), // Hochladedatum speichern
    }).then(() => {
      setVideoUrl('');
      setVideoType('');
    }).catch((error) => {
      console.error('Error adding TikTok video:', error);
    });
  };

  const handleDeleteVideo = (id) => {
    const videoRef = ref(database, `/Viral/tiktok/${id}`);
    remove(videoRef).catch((error) => {
      console.error('Error deleting TikTok video:', error);
    });
  };

  const handleUpdateType = (id, newType) => {
    const videoRef = ref(database, `/Viral/tiktok/${id}`);
    update(videoRef, { type: newType }).catch((error) => {
      console.error('Error updating TikTok video type:', error);
    });
  };

  const extractVideoId = (url) => {
    const match = url.match(/\/video\/(\d+)/);
    return match ? match[1] : null;
  };

  const goToNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, Math.ceil(videos.length / videosPerPage) - 1));
    window.scrollTo(0, 0);
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <h2>Add TikTok Video</h2>
      <form onSubmit={handleAddVideo}>
        <input
          type="text"
          placeholder="TikTok Video URL"
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
          required
        />
        <select
          value={videoType}
          onChange={(e) => setVideoType(e.target.value)}
          required
        >
          <option value="">Select Type</option>
          <option value="dance">Performance</option>
          <option value="story">Storytelling</option>
          <option value="promotion">Artist Promotion</option>
          <option value="lyrics">Lyric Video</option>
          <option value="funny">Funny</option>
          <option value="infotainment">Infotainment</option>
          <option value="entertainment">Entertainment</option>
        </select>
        <button type="submit">Add Video</button>
      </form>

      <h2>Video Dashboard</h2>
      <div style={styles.videoContainer}>
        {videos.slice(page * videosPerPage, (page + 1) * videosPerPage).map((video) => {
          const videoId = extractVideoId(video.url);
          if (!videoId) {
            console.error('Invalid TikTok video URL:', video.url);
            return null;
          }

          const embedUrl = `https://www.tiktok.com/player/v1/${videoId}?controls=1&autoplay=0&loop=0`;

          return (
            <div key={video.id} style={styles.videoCard}>
              <iframe
                width="400"
                height="600"
                src={embedUrl}
                allow="fullscreen"
                title={`TikTok Video ${videoId}`}
                style={styles.iframe}
              />
              <div style={styles.controls}>
                <select
                  value={video.type || ''}
                  onChange={(e) => handleUpdateType(video.id, e.target.value)}
                  style={styles.select}
                >
             <option value="">Select Type</option>
          <option value="dance">Performance</option>
          <option value="story">Storytelling</option>
          <option value="promotion">Artist Promotion</option>
          <option value="lyrics">Lyric Video</option>
          <option value="funny">Funny</option>
          <option value="infotainment">Infotainment</option>
          <option value="entertainment">Entertainment</option>
                </select>
                <button onClick={() => handleDeleteVideo(video.id)} style={styles.deleteButton}>
                  Delete
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div style={styles.pagination}>
        <button onClick={goToPreviousPage} disabled={page === 0}>Previous</button>
        <span>Page {page + 1} of {Math.ceil(videos.length / videosPerPage)}</span>
        <button onClick={goToNextPage} disabled={(page + 1) * videosPerPage >= videos.length}>Next</button>
      </div>
    </div>
  );
}

const styles = {
  videoContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '20px',
    padding: '20px',
    justifyContent: 'center',
  },
  videoCard: {
    borderRadius: '10px',
    overflow: 'hidden',
    backgroundColor: '#f0f0f0',
    padding: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '400px',
  },
  iframe: {
    border: 'none',
    width: '100%',
    height: '600px',
    marginBottom: '10px',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  select: {
    flex: 1,
    marginRight: '10px',
  },
  deleteButton: {
    backgroundColor: '#ff4d4d',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    cursor: 'pointer',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: '20px',
  },
};

export default AddTikTokVideo;
