import React, { useState, useEffect, useCallback } from 'react';
import './SelectWidgetSettings.css';
import { ref, get, set, onValue } from 'firebase/database';
import { database } from '../../firebaseConfig';
import { widgetDataMap } from './WidgetDataMap';

function SelectWidgetSettings({ pageId }) {
  const [existingWidgets, setExistingWidgets] = useState([]);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    const widgetsRef = ref(database, `Pages/${pageId}/pageData/widgets`);
    
    const fetchExistingWidgets = async () => {
      const snapshot = await get(widgetsRef);
      const widgets = snapshot.val() || {};
      const existingWidgetKeys = Object.values(widgets).map(widget => widget.widget);
      setExistingWidgets(existingWidgetKeys);
    };

    fetchExistingWidgets();

    // Set up a listener for changes
    const listener = onValue(widgetsRef, (snapshot) => {
      const widgets = snapshot.val() || {};
      const existingWidgetKeys = Object.values(widgets).map(widget => widget.widget);
      setExistingWidgets(existingWidgetKeys);
    });

    // Cleanup the listener when the component unmounts
    return () => {
      listener();
    };
  }, [pageId]);

  const addWidgetToDatabase = useCallback(async (widgetKey) => {
    if (isAdding || existingWidgets.includes(widgetKey)) {
      console.log(`Widget "${widgetKey}" is already in the list or being added.`);
      return;
    }

    setIsAdding(true);

    const widgetData = widgetDataMap[widgetKey];
    if (!widgetData) {
      setIsAdding(false);
      return;
    }

    const widgetsRef = ref(database, `Pages/${pageId}/pageData/widgets`);
    const snapshot = await get(widgetsRef);
    const widgets = snapshot.val() || {};

    // Find the highest existing index and increment it for the new position
    const existingIndices = Object.keys(widgets).map(Number);
    const newPosition = existingIndices.length ? Math.max(...existingIndices) + 1 : 0;

    const newWidgetRef = ref(database, `Pages/${pageId}/pageData/widgets/${newPosition}`);
    await set(newWidgetRef, {
      widget: widgetKey,
      position: newPosition,
      ...widgetData.data,
      widgetSettingsClick: widgetData.widgetSettingsClick
    });

    setExistingWidgets(prevWidgets => [...prevWidgets, widgetKey]);
    setIsAdding(false);
  }, [isAdding, existingWidgets, pageId]);

  return (
    <div className="select-widget-settings">
      <div className="widget-preview-list">
        {Object.keys(widgetDataMap).map((widgetKey) => {
          const widget = widgetDataMap[widgetKey];
          const isDisabled = existingWidgets.includes(widgetKey);

          return (
            <div
              key={widgetKey}
              className={`widget-preview ${isDisabled ? 'disabled' : ''}`}
              onClick={() => !isDisabled && addWidgetToDatabase(widgetKey)}
            >
              <div className="widget-preview-info">
                <h5>{widget.title}</h5>
                <p>{widget.description}</p>
              </div>
              <div className="widget-preview-image-container">
                <img
                  src={widget.imageUrl}
                  alt={widget.title}
                  className={`widget-preview-image ${isDisabled ? 'disabled-image' : ''}`}
                />
                {isDisabled && 
                  <div className="widget-added-confirm">
                    <img src="../../img/check.webp" alt="" />
                    <p className="widget-disabled-text">Added</p>
                  </div>
                }
                {!isDisabled && 
                  <div className="widget-added-text">
                    <img src="../../img/plus_icon.webp" alt="" />
                    <p className="widget-add-text">Add widget</p>
                  </div>
                }
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SelectWidgetSettings;
