import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Importieren für Authentifizierungsstatus
import { database } from '../firebaseConfig';
import SongVote from './SongVote';
import HomeHeader from './HomeHeader';
import EditorQuote from './EditorQuote';
import ReadMore from './ReadMore';
import WatchVideo from './WatchVideo';
import EquipmentUsed from './EquipmentUsed';
import HideEquipment from './HideEquipment'; // Ihre HideEquipment-Komponente
import SellArt from './SellArt';
import ArtistBio from './ArtistBio';
import LoadingScreen from '../Utility/LoadingScreen';
import SongAwardInfo from './SongAwardInfo';
import SongNomineeInfo from './SongNomineeInfo';
import Footer from './Footer';
import HomeCharts from './HomeCharts';
import HomeNominees from './HomeNominees';
import HomeNewHeader from './HomeNewHeader';
import LoadingScreenFull from '../Utility/LoadingScreenFull';
import NewMobileHeader from './NewMobileHeader';

const SongDetailPage = () => {
  const { songID } = useParams();
  const [songDetails, setSongDetails] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Zustand für den Login-Status

  useEffect(() => {
    const songRef = ref(database, `Songs/${songID}`);
    const auth = getAuth(); // Authentifizierungsdienst von Firebase holen

    // Authentifizierungsstatus überwachen
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user); // Setzt isLoggedIn auf true, wenn user existiert, sonst auf false
    });

    onValue(songRef, (snapshot) => {
      const data = snapshot.val();
      setSongDetails(data);
    });

    // Cleanup-Funktion, um den Listener zu entfernen, wenn die Komponente unmountet wird
    return () => unsubscribe();
  }, [songID]);

  if (!songDetails) {
    return <div>
      <LoadingScreen />
    </div>;
  }

  return (
    <div>     
      <HomeNewHeader/>
      {songDetails && <SongAwardInfo hasAward={songDetails.hasAward} awardDate={songDetails.awardDate}/>} {/* Einbindung der SongAwardInfo Komponente */}
      {songDetails.isNominated && !songDetails.hasAward && <SongNomineeInfo isNominated={songDetails.isNominated} nominatedDate={songDetails.nominatedDate}/>}
      {songDetails && <SongVote songDetails={songDetails} artistId={songDetails.artistID} />}
      {songDetails.editorQuote && <EditorQuote quote={songDetails.editorQuote} />}
      {songDetails && songDetails.publications && <ReadMore publications={songDetails.publications} />}
      {songDetails.musicVideoLink && <WatchVideo videoLink={songDetails.musicVideoLink} />}
      {songDetails && songDetails.equipment && (
    isLoggedIn ? <EquipmentUsed equipment={songDetails.equipment} /> : <HideEquipment />
)}

          {songDetails && songDetails.artistID && <SellArt artistId={songDetails.artistID} />}
          {songDetails && songDetails.artistID && <ArtistBio artistId={songDetails.artistID} />}
         <HomeCharts />
         <HomeNominees />
          <Footer />
    </div>
  );
};

export default SongDetailPage;
