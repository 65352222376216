import React, { useState, useEffect } from 'react';
import { ref as databaseRef, onValue, get, remove, update } from 'firebase/database';
import { database } from '../firebaseConfig';
import './DeleteReviewsModal.css';

const DeleteReviewsModal = ({ pageId, onClose }) => {
    const [reviews, setReviews] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const reviewsRef = databaseRef(database, `Pages/${pageId}/pageData/reviews`);

        onValue(reviewsRef, async (snapshot) => {
            if (snapshot.exists()) {
                const reviewsData = snapshot.val();
                const reviewsList = await Promise.all(Object.entries(reviewsData).map(async ([key, review]) => {
                    let writerName = review.WriterName;

                    if (review.reviewWriter) {
                        const artistRef = databaseRef(database, `Artists/${review.reviewWriter}/name`);
                        const artistSnapshot = await get(artistRef);
                        if (artistSnapshot.exists()) {
                            writerName = artistSnapshot.val();
                        }
                    }

                    const averageScore = ((review.ratings.quality + review.ratings.delivery + review.ratings.communication) / 3).toFixed(1);

                    return {
                        id: key,
                        ...review,
                        WriterName: writerName || 'No Name',
                        averageScore,
                    };
                }));
                setReviews(reviewsList);
            } else {
                setReviews([]);
            }
        });
    }, [pageId]);

    const handleDeleteReview = async (reviewId) => {
        setIsLoading(true);
        const reviewRef = databaseRef(database, `Pages/${pageId}/pageData/reviews/${reviewId}`);

        // Remove the selected review
        await remove(reviewRef);

        // Recalculate totalReviews and reviewScore
        const reviewsRef = databaseRef(database, `Pages/${pageId}/pageData/reviews`);
        const reviewsSnapshot = await get(reviewsRef);

        let totalReviews = 0;
        let totalScore = 0;

        reviewsSnapshot.forEach((childSnapshot) => {
            const review = childSnapshot.val();
            totalReviews += 1;
            const averageScore = (review.ratings.quality + review.ratings.delivery + review.ratings.communication) / 3;
            totalScore += averageScore;
        });

        const updatedTotalReviews = totalReviews;
        const updatedReviewScore = updatedTotalReviews > 0 ? totalScore / updatedTotalReviews : 0;

        await update(databaseRef(database, `Pages/${pageId}/pageData`), {
            totalReviews: updatedTotalReviews,
            reviewScore: updatedReviewScore,
        });

        setIsLoading(false);
    };

    return (
        <div className="delete-reviews-modal">
            <div className="delete-reviews-modal-content">
                <span className="delete-reviews-close" onClick={onClose}>&times;</span>
                <h2>Manage Reviews</h2>
                {reviews.length > 0 ? (
                    <ul className="delete-reviews-list">
                        {reviews.map((review) => (
                            <li key={review.id} className="delete-review-item">
                                <div className="delete-review-details">
                                    <p className="delete-review-writer">{review.WriterName}</p>
                                    <p>{review.feedback}</p>
                                    <p className="delete-review-average-score">⭐ {review.averageScore}</p>
                                </div>
                                <button
                                    className="delete-review-button"
                                    onClick={() => handleDeleteReview(review.id)}
                                    disabled={isLoading}
                                >
                                    &times;
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No reviews available.</p>
                )}
            </div>
        </div>
    );
};

export default DeleteReviewsModal;
