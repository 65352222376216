import React, { useState, useEffect } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { database } from '../../firebaseConfig';
import GigSong2 from './GigSong2';  // Importiere die richtige Komponente
import './GigSongs.css';

function GigSongs({ onToggleSettings, pageId, widgetKey, active, settingsRoute, isLivePage }) {
    const [songs, setSongs] = useState([]);
    const [currentPlayingId, setCurrentPlayingId] = useState(null);

    const handlePlaySong = (songId) => {
        setCurrentPlayingId(songId);
    };

    useEffect(() => {
        const songsRef = ref(database, `Pages/${pageId}/pageData/widgets/${widgetKey}/songs`);
        const unsubscribe = onValue(songsRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setSongs(data ? Object.values(data) : []);
            } else {
                setSongs([]);
            }
        }, (error) => {
            console.error('Failed to fetch song data:', error);
        });

        return () => off(songsRef, 'value', unsubscribe);
    }, [pageId, widgetKey]);

    if (isLivePage && songs.length === 0) {
        return <div />; // Gibt ein leeres div zurück, wenn isLivePage true ist und keine Songs vorhanden sind
    }

    const className = `gig-songs ${settingsRoute ? 'settings-route' : ''} ${active ? 'is-active' : ''}`;

    const handleUploadSongClick = () => {
        onToggleSettings('GigSongs');
    };

    return (
        <div className={className} onClick={handleUploadSongClick}>
            {settingsRoute && (
                <div className="edit-overlay">
                    <img src="../../img/edit.webp" alt="Edit" />
                    <span>Edit</span>
                </div>
            )}

            <div className="gig-songs-list">
                {songs.map((song, index) => (
                    <div key={song.id}>
                        <GigSong2
                            song={song}
                            index={index}
                            isPlaying={currentPlayingId === index}
                            onPlay={() => handlePlaySong(index)}
                        />
                    </div>
                ))}
            </div>

            {songs.length === 0 && !isLivePage && (
                <div className="gig-upload-song-button" onClick={handleUploadSongClick}>
                   <h5>Upload Songs</h5>
                   <img src="../../img/plus.svg" alt="Upload" />
                </div>
            )}
        </div>
    );
}

export default GigSongs;
