import React, { useState, useEffect, useRef } from 'react';
import { ref as databaseRef, get, update } from 'firebase/database';
import { database, auth } from '../firebaseConfig';
import './CreateCollabModal.css';
import { generateSenderText, generateReceiverText } from '../Utility/collabTextUtils';
import { saveMessage } from '../Utility/saveMessageUtils'; 

function CreateCollabModal({ senderId, receiverId, receiverImageUrl, onClose, onMessageSent }) {  // Callback als Prop hinzufügen
  const [senderInfo, setSenderInfo] = useState(null);
  const [receiverInfo, setReceiverInfo] = useState(null);
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [customText, setCustomText] = useState('');
  const [toggleTextGeneration, setToggleTextGeneration] = useState(true);
  const [loading, setLoading] = useState(false);
  const textAreaRef = useRef(null);

  const containers = [
    { id: 1, type: 'Connect', title: "I want to connect", text: "I am here to connect and collaborate with other artists.", imgSrc: "../../service/unplugged.svg" },
    { id: 2, type: 'Vocals', title: "I will record vocals", text: "I will record vocals on your beat or instrumental.", imgSrc: "../../service/vocal.svg" },
    { id: 3, type: 'Create Beat', title: "I will create a beat.", text: "I will create a beat so you can record vocals.", imgSrc: "../../service/cd.svg" },
    { id: 4, type: 'Promote Music', title: "I will promote your music.", text: "I will promote your music via. my channels.", imgSrc: "../../service/management.svg" },
    { id: 5, type: 'Write Lyrics', title: "I will write lyrics.", text: "I will write lyrics for you to record.", imgSrc: "../../service/blog.svg" },
    { id: 6, type: 'Record Guitar', title: "I will record my guitar", text: "I will record guitar melodies and chords.", imgSrc: "../../service/instrument.svg" },
    { id: 7, type: 'Mix and Master', title: "I will mix and master your track", text: "I will mix and master your track to professional quality.", imgSrc: "../../service/studio.svg" },
    { id: 8, type: 'Remix', title: "I will remix your song", text: "I will remix your song and give it a new flavor.", imgSrc: "../../service/remix.svg" },
    { id: 9, type: 'Music Video', title: "I will create the music video", text: "I will create a music video for your track.", imgSrc: "../../service/video.svg" },
    { id: 10, type: 'Ghost Produce', title: "I will ghost produce a song", text: "I will ghost produce a track for you.", imgSrc: "../../service/ghost.svg" },
    { id: 11, type: 'Artwork', title: "I will create the artwork for a song", text: "I will create the artwork for your song or album.", imgSrc: "../../service/artwork.svg" },
    { id: 12, type: 'Management', title: "I will manage you", text: "I will manage your career and help you grow.", imgSrc: "../../service/manage.svg" }
];

  useEffect(() => {
    const fetchArtistInfo = async (artistId, setInfo) => {
      try {
        const artistRef = databaseRef(database, `Artists/${artistId}`);
        const artistSnapshot = await get(artistRef);

        if (artistSnapshot.exists()) {
          const artistData = artistSnapshot.val();
          setInfo(artistData);
        }
      } catch (error) {
        console.error(`Error fetching artist info for ${artistId}:`, error);
      }
    };

    fetchArtistInfo(senderId, setSenderInfo);
    fetchArtistInfo(receiverId, setReceiverInfo);
  }, [senderId, receiverId]);

  const toggleContainerSelection = (id) => {
    if (selectedContainers.includes(id)) {
      setSelectedContainers(selectedContainers.filter(containerId => containerId !== id));
    } else {
      setSelectedContainers([...selectedContainers, id]);
    }
  };

  const handleCreateCollab = () => {
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleGenerateText = async () => {
    setLoading(true);
    try {
      const senderText = generateSenderText(senderInfo);
      const receiverText = generateReceiverText(receiverInfo);
      const containersText = selectedContainers.map(id => containers.find(c => c.id === id).text).join(' ');
  
      const response = await fetch('https://us-central1-awards-13a46.cloudfunctions.net/getCollabText', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          senderText,
          receiverText,
          containersText
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate text');
      }
  
      const data = await response.json();
      setCustomText(data.text);
      setToggleTextGeneration(false);
    } catch (error) {
      console.error('Error generating text:', error);
    } finally {
      setLoading(false);
    }
  };

  

  const handleRegenText = async () => {
    setLoading(true);
    try {
      const senderText = generateSenderText(senderInfo);
      const receiverText = generateReceiverText(receiverInfo);
      const containersText = selectedContainers.map(id => containers.find(c => c.id === id).text).join(' ');

      const response = await fetch('https://us-central1-awards-13a46.cloudfunctions.net/getCollabText', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          senderText,
          receiverText,
          containersText
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate text');
      }

      const data = await response.json();
      setCustomText(data.text);
      if (textAreaRef.current) textAreaRef.current.focus();
    } catch (error) {
      console.error('Error generating text:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleWriteText = () => {
    setCustomText('');
    setToggleTextGeneration(false);
    if (textAreaRef.current) textAreaRef.current.focus();
  };

  const handleTextAreaBlur = () => {
    if (customText.trim() === '') {
      setToggleTextGeneration(true);
    }
  };

  const isSendButtonDisabled = customText.trim() === ''; 

  const handleSend = async () => {
    if (isSendButtonDisabled) return;
  
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No user logged in');
      return;
    }
  
    try {
      // Nachricht speichern
      await saveMessage(senderId, receiverId, customText, selectedContainers, containers);

      // Aktuelle Collab-Credits des Benutzers abrufen
      const userRef = databaseRef(database, `Artists/${senderId}`);
      const userSnapshot = await get(userRef);

      if (userSnapshot.exists()) {
        let currentCredits = userSnapshot.val().collabCredits || 0;

        // CollabCredits um 1 verringern, wenn größer als 0
        if (currentCredits > 0) {
          currentCredits -= 1;
        } else if (currentCredits < 0) {
          currentCredits = 0;
        }

        // Aktualisieren der CollabCredits in der Datenbank
        await update(userRef, { collabCredits: currentCredits });
      }

      // Nach erfolgreicher Nachricht das UI zurücksetzen
      setCustomText('');
      setSelectedContainers([]);
      setToggleTextGeneration(true);
      if (typeof onMessageSent === 'function') {
        onMessageSent(receiverId); // Callback auslösen, um den Button in der übergeordneten Komponente zu aktualisieren
      }
      onClose();
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  
  return (
    <div className="create-collab-modal-overlay" onClick={handleOverlayClick}>
      <div className="create-collab-modal-wrapper" onClick={handleOverlayClick}>
        <div className="create-collab-modal" onClick={(e) => e.stopPropagation()}>
          <div className="create-collab-modal-content">
            <div className="create-collab-modal-header">
           <div style={{height: '90%'}}>
            {receiverImageUrl && (
                <img 
                  src={receiverImageUrl} 
                  alt={receiverInfo?.artistName || "Receiver"} 
                  style={{ width: '85px', height: '85px', borderRadius: '50%', marginLeft: '10px', objectFit: 'cover' }} 
                />
              )}
                         </div>

              <h2>Collaborate with <span>{receiverInfo?.artistName}</span></h2>
     <p>Select boxes and generate message with AI.</p>
            </div>
            <div className="create-collab-modal-body">
              <div className="collab-container-scrollable">
                {containers.map(container => (
                  <div 
                    key={container.id} 
                    className={`collab-container ${selectedContainers.includes(container.id) ? 'selected' : ''}`} 
                    onClick={() => toggleContainerSelection(container.id)}
                  >
                    <img src={container.imgSrc} alt={container.title} className="collab-container-image" />
                    <div className="collab-container-text">
                      <h4>{container.title}</h4>
                      <p>{container.text}</p>
                    </div>
                    <div className="collab-container-icon">
                      {selectedContainers.includes(container.id) ? (
                        <img src="../../img/check_4.webp" alt="Selected"  style={{width: '30px' , height: '30px', marginRight: '-4px'}}/>
                      ) : (
                        <img style={{filter: 'invert(1)', opacity: '0.5'}} src="../../img/plus.svg" alt="Select" />
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="text-generation-wrapper">
                {toggleTextGeneration ? (
                  <div className="text-generation-buttons">
                    <button 
                      className="create-collab-modal-button" 
                      onClick={handleGenerateText}
                      id="collab-ai-generate-button"
                      disabled={loading}
                    >
                      {loading ? (
                        <div className="collab-button-loading-spinner"></div>
                      ) : (
                        <>
                          <img src="../../img/ai.svg" alt="" />
                          <p>Text Generate</p>
                          {selectedContainers.length > 0 && (
                            <div className="selected-count-circle">
                              {selectedContainers.length}
                            </div>
                          )}
                        </>
                      )}
                    </button>
                    <button 
                      className="create-collab-modal-button" 
                      onClick={handleWriteText}
                      id="collab-write-generate-button"
                      disabled={loading}
                    >
                      <img style={{marginTop: '5px'}} src="../../img/chet.svg" alt="" />
                      <p>Write</p>
                    </button>
                  </div>
                ) : (
                  <>
                    <textarea 
                      value={customText} 
                      onChange={(e) => setCustomText(e.target.value)} 
                      onBlur={handleTextAreaBlur}
                      className="custom-collab-textarea"
                      placeholder="Write your own text here..."
                      ref={textAreaRef}
                      rows="1"
                    />
                    <button 
                      className="collab-regen-button" 
                      onClick={handleRegenText}
                      disabled={loading}
                    >
                      {loading ? (
                        <div className="collab-button-loading-spinner"></div>
                      ) : (
                        <img src="../../img/ai.svg" alt="Regenerate" />
                      )}
                    </button>
                  </>
                )}
                <div>
                  <button 
                    className={`collab-send-button ${isSendButtonDisabled ? 'disabled' : ''}`} 
                    disabled={isSendButtonDisabled}
                    onClick={handleSend} 
                  >
                    <img src="../../img/down.svg" alt="Send" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCollabModal;
